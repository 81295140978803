globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback } from 'react';
import { useNavigationContext } from '../context';
export var useRouterBackCallback = function useRouterBackCallback(url) {
  var _useNavigationContext = useNavigationContext(),
      router = _useNavigationContext.router;

  var onRouterBack = useCallback(function () {
    var historyState = window.history.state;
    if ((historyState === null || historyState === void 0 ? void 0 : historyState.idx) > 0) router === null || router === void 0 ? void 0 : router.back();else if (url) router === null || router === void 0 ? void 0 : router.push(url);
  }, [router, url]);
  return {
    onRouterBack: onRouterBack
  };
};