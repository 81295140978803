var _process$env$VERCEL_U;

globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
var isServer = false;
export var BASE_URL = (_process$env$VERCEL_U = process.env.VERCEL_URL) !== null && _process$env$VERCEL_U !== void 0 ? _process$env$VERCEL_U : !isServer && window.location.origin;
export var FULL_URL = !isServer ? window.location.href : '';
export var CLIENT_BASE_URL = !isServer ? window.location.origin : '';
export var CUSTOM_BASE_URL = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'https://restworld.it' : process.env.NEXT_PUBLIC_APP_ENV === 'development' ? 'http://localhost:4200' : 'https://dev.worker.restworld.it';
export var CUSTOM_EMPLOYER_BASE_URL = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'https://employer.restworld.it/' : process.env.NEXT_PUBLIC_APP_ENV === 'development' ? 'http://localhost:4201' : 'https://dev.employer.restworld.it';