import { useCallback, useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';

import { useWorkersService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';

import { workerSkillsAtom } from '../job-position.atoms';
import { workerAtom } from '../../profile/global-state';

export const useWorkerSkills = () => {
  const [, loader] = useState(false);
  const setWorkerSkills = useSetAtom(workerSkillsAtom);

  const { user } = useAuth();
  const workerService = useWorkersService();
  const [, setWorkerAtom] = useAtom(workerAtom);

  const getWorkerData = useCallback(async () => {
    if (!user?.id) return;
    try {
      loader(true);
      const workerData = (await workerService.fetchWorker(user?.id)).data;
      const currentSkills = workerData['skills']?.map((v) => v.label_it.skill_id);
      const previousSkills = workerData['past_experiences']?.flatMap((v) => v.skills).map((v) => v.label_it.skill_id);
      const aggregatedSkill = [...(currentSkills || []), ...(previousSkills || [])];
      setWorkerAtom({ data: workerData });
      setWorkerSkills([...aggregatedSkill]);
    } catch (error) {
      console.error('Error in fetching worker', error);
    } finally {
      loader(false);
    }
  }, [setWorkerAtom, setWorkerSkills, user?.id, workerService]);

  useEffect(() => {
    getWorkerData();
  }, [getWorkerData]);
};
