import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var computeRange = function computeRange(number) {
  var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  if (result.length === number) return result;
  var returnValue = computeRange(number, [].concat(_toConsumableArray(result), [result.length]));
  return returnValue.filter(Boolean);
};
export var getRange = function getRange(end) {
  var start = 1;
  var length = end;
  return Array.from({
    length: length
  }, function (_, i) {
    return start + i;
  });
};