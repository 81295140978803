import { Button, OTPInput, Paragraph } from '@restworld/ui-ds';
import { memo, useEffect } from 'react';
import { useOTPForm } from './hooks/use-otp-form';
import { AuthTitle } from '../common/auth-title';
import { AuthSubtitle } from '../common/auth-subtitle';
import { Controller } from 'react-hook-form';
import { usePhoneNumberLoginForm } from './hooks/use-phone-number-login-form';
import tw, { styled } from 'twin.macro';
import { useAtomValue } from 'jotai';
import { phoneNumberValidationDetails } from '../../context/auth-flow';
import { HeaderWrapper } from '../common/head-wrapper';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'next-i18next';
import { useAnalytics, useCountDownTimer } from '@restworld/utils-common';

export type VerifyOtpFormProps = {
  isModal?: boolean;
};

export const VerifyOtpForm = memo(({ isModal }: VerifyOtpFormProps) => {
  const { waitTime, phoneNumber } = useAtomValue(phoneNumberValidationDetails);
  const { show: showIntercom } = useIntercom();
  const { t } = useTranslation('auth-flow');
  const { t: commonT } = useTranslation('common');
  const {
    loading,
    onSubmit,
    handleSubmit,
    control,
    errors,
    serverValidationFailed,
    setServerValidationFailed,
    isSuccess,
  } = useOTPForm({
    phoneNumber,
    isModal,
  });

  const {
    loading: requestOTPLoading,
    onSubmit: requestNewOTPCode,
    remainingTimes,
  } = usePhoneNumberLoginForm({
    isModal,
  });

  const { hasExpired, label, resetTimer } = useCountDownTimer({ timeInSeconds: waitTime });
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (remainingTimes > 0) {
      resetTimer();
    }
  }, [remainingTimes, resetTimer]);

  function onRequestNewOTPCode() {
    trackEvent('worker_request_otp_for_login', { resend_code: true, phone_number: phoneNumber });
    requestNewOTPCode({ phoneNumber });
  }

  return (
    <FormWrapper {...{ isModal }}>
      <form tw="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div tw="flex flex-col items-center w-full my-10 gap-4">
          <Controller
            name="otpCode"
            control={control}
            rules={{
              required: t('phone_number_login_form_insert_valid_otp_code'),
              validate: (value) => value?.toString().length === 6,
            }}
            render={({ field }) => (
              <OTPInput
                onChange={(otp) => {
                  field.onChange(otp);
                  serverValidationFailed && setServerValidationFailed(false);
                }}
                variant={errors['otpCode'] ? 'error' : 'primary'}
                value={field?.value?.toString()}
                isSuccess={isSuccess}
              />
            )}
          />

          {(serverValidationFailed || errors['otpCode']) && (
            <p tw="text-base leading-6 text-red-600 text-center">
              {serverValidationFailed && (
                <span>
                  {t('phone_number_login_form_otp_assistance_text_1')}{' '}
                  <StyledLink
                    onClick={(e) => {
                      e.preventDefault();
                      showIntercom();
                      // trackEvent('Auth flow', {
                      //   phase: 'Phone number login',
                      //   step: 'contact assistance',
                      //   mode: logMode,
                      // });
                    }}
                  >
                    {t('phone_number_login_form_otp_assistance_text_2')}
                  </StyledLink>
                </span>
              )}
              {errors['otpCode'] && !serverValidationFailed && errors['otpCode']?.message}
            </p>
          )}
        </div>
        <div tw="flex flex-col items-center w-full gap-4">
          <Button fullwidth={true} type="submit" loading={loading}>
            <span tw="text-black">{commonT('login')}</span>
          </Button>
          <div tw="flex flex-col items-center w-full gap-2">
            <Button
              fullwidth={true}
              disabled={!hasExpired || remainingTimes === 0}
              variant="secondary-outline"
              loading={requestOTPLoading}
              onClick={() => onRequestNewOTPCode()}
            >
              <span tw="text-black">{`${t('phone_number_login_form_otp_resend_text_1')}  ${
                !hasExpired ? `${t('phone_number_login_form_otp_resend_text_2')} ${label}` : ''
              }`}</span>
            </Button>
            {remainingTimes < 3 && (
              <Paragraph color="gray-500">
                {remainingTimes} {t('phone_number_login_form_otp_sent_count')}
              </Paragraph>
            )}
          </div>
        </div>
      </form>
    </FormWrapper>
  );
});

const FormWrapper = styled.div<{ isModal?: boolean }>(({ isModal = true }) => [
  tw`flex flex-col w-full`,
  isModal && tw`mt-5`,
]);

export const VerifyOtpHeader = ({ isModal = true }: { isModal?: boolean }) => {
  const { phoneNumber } = useAtomValue(phoneNumberValidationDetails);
  const { t } = useTranslation('auth-flow');
  return (
    <HeaderWrapper tw="gap-2 mt-2" isModal={isModal}>
      <AuthTitle>{t('phone_number_login_form_otp_header')}</AuthTitle>
      <AuthSubtitle>
        {t('phone_number_login_form_otp_subheader')} {phoneNumber}
      </AuthSubtitle>
    </HeaderWrapper>
  );
};

const StyledLink = styled.p(() => [tw`text-red-600 underline cursor-pointer`]);
