import * as React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue } from 'jotai';

import { Title, Button } from '@restworld/ui-ds';

import { companyProfileAtom, fullRatingAtom, fullViewAtom } from '../../job-position/job-position.atoms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICompanyProfileHeaderProps {}
export const CompanyProfileHeader: React.FunctionComponent<ICompanyProfileHeaderProps> = () => {
  const { restaurantName } = useAtomValue(companyProfileAtom);
  const [fullView, setFullView] = useAtom(fullViewAtom);
  const [fullRating, setFullRating] = useAtom(fullRatingAtom);

  const { t } = useTranslation('common');

  const clickHandler = React.useCallback(() => {
    setFullView(false);
    setFullRating(false);
  }, [setFullRating, setFullView]);

  return (
    <>
      <Title content={t('company')} icon="BuildingOfficePurpleIcon" fontSize="extraLarge" isCenter />
      <StoreTitleWrapper>
        {(fullView || fullRating) && <Button icon="ChevronLeftIcon" onClick={clickHandler} variant="ghost" />}
        {restaurantName && <Title content={restaurantName} fontSize="extraLarge" isCenter fontWeight="medium" />}
      </StoreTitleWrapper>
    </>
  );
};
export default CompanyProfileHeader;
const StoreTitleWrapper = tw.div`flex justify-center gap-4`;
