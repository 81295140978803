import { memo } from 'react';
import { useAtomValue } from 'jotai';
import { currentUrl, isEmailLoginModalOpen } from '../../context/auth-flow';
import { LoginByEmailForm, LoginByEmailHeader } from './login-by-email-form';
import 'twin.macro';

export const LoginByEmailComponentModal = memo(() => {
  const open = useAtomValue(isEmailLoginModalOpen);
  const activeUrl = useAtomValue(currentUrl);

  if (open) {
    return (
      <div tw="flex box-border max-w-full flex-col  mt-5 ">
        <LoginByEmailHeader {...{ isModal: true }} />
        <LoginByEmailForm
          {...{
            activeUrl,
            isModal: true,
          }}
        />
      </div>
    );
  }
  return null;
});
