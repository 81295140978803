import { useMemo } from 'react';
import 'twin.macro';
import { useRouter } from 'next/router';

export default function JobBoardPixels() {
  const router = useRouter();
  const { query } = router;

  const jobRapidoCid = useMemo(() => {
    return query?.utm_source === 'JobRapido' || query?.utm_source === 'JobRapido_adv' ? query?.jr_cid : null;
  }, [query?.jr_cid, query?.utm_source]);

  const JobRapidoPixel = useMemo(() => {
    if (!jobRapidoCid) return null;
    return (
      <img
        src={`https://zelda.app.jobrapido.com/postback?clickId=${jobRapidoCid}`}
        width="1"
        height="1"
        alt="JobRapido pixel"
        tw="hidden"
      />
    );
  }, [jobRapidoCid]);

  if (process.env['NODE_ENV'] === 'development') return null;

  return (
    <>
      <img
        src="https://www.talent.com/tracker/img-pixel.php?tracker=restworld-tracker"
        alt="Talent pixel"
        tw="hidden w-6 h-6"
      />
      <iframe src="https://www.adzuna.it/app_complete/10153" title="Adzuna pixel" width="1" height="1" tw="hidden" />
      {JobRapidoPixel}
    </>
  );
}
