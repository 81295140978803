import { RefObject } from 'react';
import { MapRef } from 'react-map-gl';
import { atom } from 'jotai';

import { JobPosition } from '@restworld/utility-types';
import { JobCoordinateParamsType, JobLocationCoordinates } from '@restworld/data-services';
import { defaultItalyCoordinates } from '@restworld/utils-common';

import { Coordinate } from './job-explorer.types';

export type JobExplorerMapAtomType = {
  centerPoint?: [number, number];
  zoomLevel?: number;
  mapRef?: RefObject<MapRef>;
  userCoordinates?: Coordinate;
  toggleFullScreen?: boolean;
  userApproxCoordinates?: Coordinate;
  boundingRadius?: number;
  popupDetails?: JobPosition;
  popupLocationCoordinates?: JobLocationCoordinates;
  params: JobCoordinateParamsType;
  loader: boolean;
  coordinates?: JobLocationCoordinates[];
  hasMapView?: boolean;
  mapPopupLoading?: boolean;
};

const centerPointAtom = atom<[number, number]>(defaultItalyCoordinates);
export const getCenterPointAtom = atom((get) => get(centerPointAtom));
export const setCenterPointAtom = atom(null, (_, set, centerPoint: [number, number]) =>
  set(centerPointAtom, centerPoint)
);

const userCoordinatesAtom = atom<Coordinate>({});
export const getUserCoordinatesAtom = atom((get) => get(userCoordinatesAtom));
export const setUserCoordinatesAtom = atom(null, (_, set, userCoordinates: Coordinate) =>
  set(userCoordinatesAtom, userCoordinates)
);

const toggleFullScreenAtom = atom<boolean>(false);
export const getToggleFullScreenAtom = atom((get) => get(toggleFullScreenAtom));
export const fullScreenTogglerAtom = atom(null, (_, set) =>
  set(toggleFullScreenAtom, (toggleFullScreen) => !toggleFullScreen)
);
export const setFullScreenAtom = atom(null, (_, set, toggleFullScreen: boolean) =>
  set(toggleFullScreenAtom, toggleFullScreen)
);

const zoomLevelAtom = atom<number>(5);
export const getZoomLevelAtom = atom((get) => get(zoomLevelAtom));
export const setZoomLevelAtom = atom(null, (_, set, zoomLevel: number) => set(zoomLevelAtom, zoomLevel));

const popupDetailsAtom = atom<Partial<JobPosition>>({});
export const getPopupDetailsAtom = atom((get) => get(popupDetailsAtom));
export const setPopupDetailsAtom = atom(null, (_, set, popupDetails: Partial<JobPosition>) =>
  set(popupDetailsAtom, () => popupDetails)
);

const popupLocationCoordinatesAtom = atom<Partial<JobLocationCoordinates>>({});
popupLocationCoordinatesAtom.debugLabel = 'popupLocationCoordinatesAtom';
export const getPopupLocationCoordinatesAtom = atom((get) => get(popupLocationCoordinatesAtom));
export const setPopupLocationCoordinatesAtom = atom(
  null,
  (_, set, popupLocationCoordinates?: Partial<JobLocationCoordinates>) =>
    set(popupLocationCoordinatesAtom, () => popupLocationCoordinates)
);

const userApproxCoordinatesAtom = atom<Coordinate>({});
export const getUserApproxCoordinatesAtom = atom((get) => get(userApproxCoordinatesAtom));
export const setUserApproxCoordinatesAtom = atom(null, (_, set, userApproxCoordinates: Coordinate) =>
  set(userApproxCoordinatesAtom, userApproxCoordinates)
);

const boundingRadiusAtom = atom<number>(0);
export const getBoundingRadiusAtom = atom((get) => get(boundingRadiusAtom));
export const setBoundingRadiusAtom = atom(null, (_, set, boundingRadius: number) =>
  set(boundingRadiusAtom, boundingRadius)
);

const mapRefAtom = atom<RefObject<MapRef>>({ current: null });
export const getMapRefAtom = atom((get) => get(mapRefAtom));

export const jobCoordinateParamsAtom = atom<JobExplorerMapAtomType['params']>({});
jobCoordinateParamsAtom.debugLabel = 'jobCoordinateParamsAtom';

export const jobLocationCoordinatesLoadingAtom = atom<boolean>(false);

export const jobLocationCoordinatesAtom = atom<JobLocationCoordinates[]>([]);
jobLocationCoordinatesAtom.debugLabel = 'jobLocationCoordinatesAtom';
export const mapPopupLoadingAtom = atom<boolean>(false);

export const coordinatesKeyAtom = atom((get) => ['jobPositionCoordinates', get(jobCoordinateParamsAtom)]);
