import { Button } from '@restworld/ui-ds';
import { GoogleIcon } from '@restworld/utils-common';
import 'twin.macro';
import { useGoogleLogin } from '@react-oauth/google';

import { useSocialAuthService } from '@restworld/data-services';
import { useAuthContext } from '@restworld/feature-authentication';
import { useTranslation } from 'next-i18next';

export type GoogleButtonProps = {
  isModal?: boolean;
  closeModal?: () => void;
};

export function GoogleButton({ isModal, closeModal }: GoogleButtonProps) {
  const authContext = useAuthContext();
  const socialAuthService = useSocialAuthService();
  const { t } = useTranslation('auth-flow');
  const handleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      socialAuthService
        .loginGoogle({ access_token: credentialResponse.access_token })
        .then((res) => {
          const jwtToken = res?.data?.jwt || null;
          if (authContext?.verifyToken) {
            authContext.verifyToken(jwtToken);
          }
          if (isModal && closeModal) {
            closeModal();
          }
        })
        .catch((error) => {
          /** */
        });
    },
    onError: () => {
      /***
       * To be implemented
       */
    },
  });
  return (
    <div tw="hidden">
      <Button variant="secondary-outline" fullwidth={true} onClick={() => handleLogin()}>
        <div tw="flex flex-row items-center gap-2">
          <GoogleIcon />
          <div tw="text-center w-79.25 text-base leading-6 text-black">{t('google_login_button_text')}</div>
        </div>
      </Button>
    </div>
  );
}
