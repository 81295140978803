import { useState, useEffect, useCallback } from 'react';
import { useAtom } from 'jotai';

import { SimilarPositionType, useJobPositionsService } from '@restworld/data-services';

import { useJobPositionId } from './use-job-position-id';
import { similarJobPositionsAtom } from '../components/job-position/job-position.atoms';

export const useSimilarJobPositions = () => {
  const [loading, setLoading] = useState(false);
  const [similarJobPositions, setSimilarJobPositions] = useAtom(similarJobPositionsAtom);
  const jobPositionId = useJobPositionId();
  const jobPositionService = useJobPositionsService();

  const callSimilarJobPositionApi = useCallback(async () => {
    try {
      setLoading(true);
      const response = await jobPositionService.similarJobPositions(jobPositionId);
      setSimilarJobPositions(response?.data ? formatSimilarJobPositions(response.data) : []);
      setLoading(false);
    } catch (error) {
      console.error(error, 'Error on similar jobs data');
      setLoading(false);
    }
  }, [jobPositionId, jobPositionService, setSimilarJobPositions]);

  useEffect(() => {
    callSimilarJobPositionApi();
  }, [callSimilarJobPositionApi]);

  return { callSimilarJobPositionApi, similarJobPositions, loading };
};

const formatSimilarJobPositions = (similarJobPositions: SimilarPositionType[]) =>
  similarJobPositions.map(
    ({
      published_at,
      restaurant,
      job_title,
      max_salary,
      min_salary,
      salary,
      job_type_var,
      occupation,
      semantic_unique_name,
      show_restaurant_details,
    }) => ({
      city: restaurant?.locality,
      restaurantName: restaurant?.restaurant_name,
      restaurantLogo: restaurant?.restaurant_logo,
      publishedAt: published_at,
      jobTitle: job_title,
      jobType: job_type_var?.label,
      occupation: occupation?.label_it.label,
      salaryMin: max_salary,
      salaryMax: min_salary,
      salary,
      showRestaurantDetails: show_restaurant_details,
      redirectUrl: semantic_unique_name ? `${window.location.origin}/posizione/${semantic_unique_name}` : '',
    }),
    []
  );
