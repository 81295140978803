import { SpeedInsights } from '@vercel/speed-insights/react';
import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { theme } from 'twin.macro';
import { DevTools } from 'jotai-devtools';
import NextNProgress from 'nextjs-progressbar';
import { Provider } from 'jotai';
import Cookies from 'js-cookie';
import Router, { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';

// --> imports
import { AuthGuard } from '@restworld/feature-authentication';
import { Spinner } from '@restworld/ui-ds';
// --> components and wrappers
import { MainContextWrapper } from '../context';
import { ErrorBoundaryWrapper } from '../components';
import GuardLoaderComponent from '../components/utility-components/guard-loader';
// --> css
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'rc-slider/assets/index.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import GlobalStyles from '../styles/GlobalStyles';
import '../styles/globals.css';
import nextI18NextConfig from '../next-i18next.config';

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const { locale, asPath, pathname } = useRouter();
  useEffect(() => {
    if (Cookies.get('NEXT_LOCALE') && locale !== Cookies.get('NEXT_LOCALE'))
      Router.push(pathname, asPath, { locale: Cookies.get('NEXT_LOCALE') });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Provider>
        <SpeedInsights route={pathname} />
        <GlobalStyles />
        <ErrorBoundaryWrapper>
          <MainContextWrapper>
            <AuthGuard loader={<Spinner size="lg" color="primary" />}>
              <GuardLoaderComponent>
                <NextNProgress color={theme`colors.primary.500`} />
              </GuardLoaderComponent>
              <Component {...pageProps} />
              <DevTools />
            </AuthGuard>
          </MainContextWrapper>
        </ErrorBoundaryWrapper>
      </Provider>
    </>
  );
};
export default appWithTranslation(CustomApp, nextI18NextConfig);
