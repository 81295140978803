globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var containerPadding = {
  "@media (min-width: 768px)": {
    "paddingLeft": "5%",
    "paddingRight": "5%"
  },
  "@media (min-width: 1024px)": {
    "paddingLeft": "10%",
    "paddingRight": "10%"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "13%",
    "paddingRight": "13%"
  }
};