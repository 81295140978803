globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var defaultToastConfig = {
  position: 'bottom-center',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  closeButton: false,
  toastClassName: function toastClassName() {
    return 'no-padding';
  },
  bodyClassName: function bodyClassName() {
    return 'no-padding';
  },
  style: {
    boxShadow: 'none',
    padding: 0,
    background: 'none',
    width: 'fit-content',
    zIndex: 99
  }
};