import { atom } from 'jotai';

import { JobCoordinateParamsType, JobLocationCoordinates, JobPositionListParamsType } from '@restworld/data-services';
import { JobPosition, Variable } from '@restworld/utility-types';

export const domainInterestAtom = atom<Variable[]>([]);
domainInterestAtom.debugLabel = 'domainInterestAtom';

export const serverListPayloadAtom = atom<JobPositionListParamsType>({});
serverListPayloadAtom.debugLabel = 'serverListPayloadAtom';

export const serverCoordinatePayloadAtom = atom<JobCoordinateParamsType>({});
serverCoordinatePayloadAtom.debugLabel = 'serverCoordinatePayloadAtom';

export const jobPositionListFromServerAtom = atom<JobPosition[]>([]);
jobPositionListFromServerAtom.debugLabel = 'jobPositionListFromServerAtom';

export const jobPositionCoordinatesFromServerAtom = atom<JobLocationCoordinates[]>([]);
jobPositionCoordinatesFromServerAtom.debugLabel = 'jobPositionCoordinatesFromServerAtom';
