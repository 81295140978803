globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useEffect, useState } from 'react';
export var useImageBlob = function useImageBlob(image) {
  var _useState = useState(),
      imageUrl = _useState[0],
      setImageUrl = _useState[1];

  useEffect(function () {
    if (!image) return;
    var imageBlob = new Blob([image], {
      type: 'image/jpeg'
    });
    var url = URL.createObjectURL(imageBlob);
    setImageUrl(url);
    return function () {
      return URL.revokeObjectURL(url);
    };
  }, [image]);
  return imageUrl;
};