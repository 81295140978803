globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './job-offer-landing';
export * from './rating-number-range';
export * from './base-url';
export * from './container-padding';
export * from './navbar-items';
export * from './job-explorer-filters.constants';
export * from './navbar-label-links';
export * from './default-seo';
export * from './notification-frequency-options';
export * from './default-italy-coordinates';
export * from './navigation-items-by-usertype';
export * from './query-keys';
export * from './domain-interest-options';
export * from './open-job-position-keys';