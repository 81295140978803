import tw from 'twin.macro';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import { Alert, PageInsiderNav as JobPositionInsiderNavigation, Title } from '@restworld/ui-ds';
import { useAmbassadorData } from '@restworld/utils-common';

import {
  JobDescriptionCard,
  CompanyProfileButton,
  PerformanceBlock,
  Skills,
  Shifts,
  JobLocationDetails,
  SimilarJobs,
  JobInfoCard,
  JobAdInformativeText,
  ContractBlockDesktop,
  ContractBlockMobile,
  JobApplicationSteps,
} from './blocks';
import {
  useSimilarJobPositions,
  useFavoriteJobStatus,
  useJobPlacesInformation,
  useCompatibilityScore,
  useFavoriteCallback,
  useJobApplyStatus,
} from '../../hooks';
import { useIntegrateLogView, useShowIntercom } from '../../hooks';
import { WhyTrustUs } from './blocks/why-trust-us';
import { jobPositionApiDataAtom } from './job-position.atoms';
import { checkIfJobPositionIsOutsideItaly } from '../../utils';

export const JobPositionComponent = () => {
  useSimilarJobPositions();
  useJobPlacesInformation();
  useIntegrateLogView();
  useCompatibilityScore();
  useJobApplyStatus();
  useFavoriteJobStatus();
  const { favoriteClickHandler, isJobPositionFavorited } = useFavoriteCallback();
  const ambassadorData = useAmbassadorData();
  useShowIntercom();
  const isJobPositionOutsideItaly = useCheckJobPositionOutsideItaly();
  const { t } = useTranslation('common');
  return (
    <PageContentWrapper>
      <div tw="lg:hidden">
        <JobPositionInsiderNavigation
          favoriteClickHandler={favoriteClickHandler}
          isJobPositionFavorited={isJobPositionFavorited}
          isJobPositionOutsideItaly={isJobPositionOutsideItaly}
        />
      </div>
      <JobInfoCard ambassadorRefCode={ambassadorData?.ambassador_code} testId="job-position-info-card" />
      <InitialContentWrapper>
        <div tw="hidden lg:block">
          <JobPositionInsiderNavigation
            favoriteClickHandler={favoriteClickHandler}
            isJobPositionFavorited={isJobPositionFavorited}
            isJobPositionOutsideItaly={isJobPositionOutsideItaly}
          />
        </div>
        {isJobPositionOutsideItaly && (
          <Alert
            text={<Title fontWeight="medium" textColor="green-700" content={t('global_opportunity_description')} />}
            variant="success"
            wrapperStyling={tw`w-full md:max-w-full rounded-lg md:p-4 bg-green-50`}
            icon={
              <ExclamationCircleIcon className="relative rotate-180 text-green-700 min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px]" />
            }
          />
        )}
        <JobDescriptionCard />
        <CompanyProfileButton />
        <ContractBlockDesktop />
        <PerformanceBlock />
        <ContractBlockMobile />
        <WhyTrustUs />
        <Skills ambassadorRefCode={ambassadorData?.ambassador_code} />
        <Shifts />
        <JobLocationDetails />
        <hr tw="text-gray-300" />
        <JobApplicationSteps ambassadorRefCode={ambassadorData?.ambassador_code} />
        <JobAdInformativeText />
        <SimilarJobs />
      </InitialContentWrapper>
    </PageContentWrapper>
  );
};
const PageContentWrapper = tw.div`w-full flex flex-col gap-8 lg:(flex flex-row-reverse justify-center gap-10)`;
const InitialContentWrapper = tw.div`flex flex-col gap-8 2xl:max-w-[calc(100vw - 864px)]  xl:max-w-[calc(100vw - 664px)] lg:max-w-[calc(100vw - 520px)] max-w-full`;
const useCheckJobPositionOutsideItaly = () => {
  const address = useAtomValue(jobPositionApiDataAtom)?.restaurant;
  return address
    ? checkIfJobPositionIsOutsideItaly({
        address: address?.address,
        country: address?.country,
      })
    : false;
};
