/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from 'react';
import { useFloating, offset, shift, flip, useTransitionStyles, autoUpdate } from '@floating-ui/react';
import { Marker } from 'react-map-gl';
import tw, { styled, theme } from 'twin.macro';
import { MarkerEvent } from 'react-map-gl/dist/esm/types';
import Supercluster from 'supercluster';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Source, Layer } from 'react-map-gl';
import * as turf from '@turf/turf';

import { JobLocationCoordinates, useRestaurantsService } from '@restworld/data-services';
import { Title } from '@restworld/ui-ds';
import { useMounted } from '@restworld/utils-common';

import { Pin } from '../pin';
import { useJobPositionApiData } from '../../../../hooks';
import PopupInfoEl from '../popup-info-el';
import {
  isRestaurantsExplorerAtom,
  explorerRestaurantMapMarkersAtom,
  explorerRestaurantSelectedMarkerAtom,
  explorerRestaurantListAtom,
} from '../../job-explorer-restaurant-explorer.atoms';
import {
  getPopupLocationCoordinatesAtom,
  setPopupLocationCoordinatesAtom,
  setPopupDetailsAtom,
  getMapRefAtom,
  jobLocationCoordinatesAtom,
  mapPopupLoadingAtom,
} from '../../job-explorer-map.atoms';
import { activeJpIdAtom, jobLocationPositionListAtom } from '../../job-explorer-list.atoms';

enum paymentTypeAbbr {
  hour = 'o',
  service = 'g',
  month = 'm',
}
type ClusterType = {
  properties: {
    cluster: any;
    point_count:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | ReactFragment
      | ReactPortal;
  };
  extra?: JobLocationCoordinates;
  id?: string | number;
  geometry: { coordinates: number[] };
};

export const useOccupationPins = () => {
  const popupLocationCoordinates = useAtomValue(getPopupLocationCoordinatesAtom);
  const clusteredMarkered = useClusters();
  useMounted();

  return (
    <>
      {clusteredMarkered}
      {popupLocationCoordinates?.coordinates ? (
        <div tw="md:hidden absolute bottom-[18%] md:(relative bottom-[unset]) w-full flex justify-center">
          <PopupInfoEl />
        </div>
      ) : null}
    </>
  );
};

export const useClusters = () => {
  const jobCoordinates = useAtomValue(jobLocationCoordinatesAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  const popupLocationCoordinates = useAtomValue(getPopupLocationCoordinatesAtom);
  const [activeJpId] = useAtom(activeJpIdAtom);
  const explorerRestaurantMapMarkers = useAtomValue(explorerRestaurantMapMarkersAtom);
  const isRestaurantsExplorer = useAtomValue(isRestaurantsExplorerAtom);
  const [clusterData, setClusterData] = useState<any>();

  const jobPositionMarkers = useMemo(() => {
    const offsetDistance = 0.0001; // Adjust this value to control the offset distance
    const seenCoordinates = new Set<string>();
    return isRestaurantsExplorer
      ? [...explorerRestaurantMapMarkers]
      : jobCoordinates &&
          [...jobCoordinates].reduce((prev, curr) => {
            if (curr.coordinates.lat !== null && curr.coordinates.lon !== null) {
              let { lat, lon } = curr.coordinates;
              let key = `${lat},${lon}`;
              // If the coordinates have been seen before, offset them slightly
              while (seenCoordinates.has(key)) {
                lat += offsetDistance;
                lon += offsetDistance;
                key = `${lat},${lon}`;
              }
              seenCoordinates.add(key);
              curr = {
                ...curr,
                coordinates: {
                  ...curr.coordinates,
                  lon,
                  lat,
                },
              };
              return [...prev, curr];
            }
            return [...prev];
          }, [] as JobLocationCoordinates[]);
  }, [explorerRestaurantMapMarkers, isRestaurantsExplorer, jobCoordinates]);

  const supercluster = useMemo(
    () =>
      new Supercluster({
        radius: 40, // cluster radius, in pixels.
        maxZoom: 20,
        minPoints: 2, // minimum number of points to form a cluster.
      }),
    []
  );

  const handleClusters = useCallback(() => {
    const bounds = mapRef.current?.getBounds();
    const zoom = mapRef.current?.getZoom();
    try {
      if (bounds) {
        const clusters = supercluster?.getClusters(
          [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
          Math.floor(zoom)
        );
        setClusterData(clusters);
      }
    } catch (error) {
      console.error(`error clustering`, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef?.current, supercluster]);

  const loadClusters = useCallback(() => {
    try {
      supercluster?.load(
        jobPositionMarkers.map((marker) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [marker.coordinates.lon, marker.coordinates.lat],
          },
          properties: null,
          extra: marker,
        }))
      );
      handleClusters();
    } catch (error) {
      console.error(`error loading clusters`, error);
    }
  }, [handleClusters, jobPositionMarkers, supercluster]);

  useEffect(() => {
    loadClusters();
  }, [loadClusters]);

  useEffect(() => {
    mapRef.current?.on('moveend', () => handleClusters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClusters, mapRef?.current]);

  const clusteredMarkered = useMemo(
    () =>
      clusterData?.length
        ? clusterData.map((cluster: ClusterType) => {
            const { id: clusterId } = cluster.extra || {};
            if (cluster?.properties?.cluster) {
              // grouped cluster
              return (
                <Marker
                  key={`marker-${cluster.id}`}
                  longitude={cluster.geometry.coordinates[0]}
                  latitude={cluster.geometry.coordinates[1]}
                  onClick={(e) => {
                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(+cluster.id), 20);
                    mapRef.current?.flyTo({
                      center: e.target.getLngLat(),
                      zoom: expansionZoom,
                      essential: true,
                    });
                  }}
                >
                  <ClusterMarker limit={+cluster.properties.point_count}>
                    <Title
                      fontSize="base"
                      twStyle={tw`leading-6`}
                      fontWeight="bold"
                      content={cluster.properties.point_count.toString()}
                    />
                  </ClusterMarker>
                </Marker>
              );
            }
            return (
              <MarkerWithFloatingPopup
                key={`marker-${clusterId}`}
                isOpen={popupLocationCoordinates?.id === clusterId}
                cluster={cluster}
              />
            );
          })
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeJpId, clusterData, mapRef?.current, popupLocationCoordinates?.id]
  );

  const buffered = useMemo(() => {
    const defaultKMRadius = 1;
    if (!popupLocationCoordinates?.coordinates?.is_approximate) return null;
    if (!popupLocationCoordinates?.coordinates?.lon) return null;
    try {
      return turf.buffer(
        turf.point([popupLocationCoordinates?.coordinates?.lon, popupLocationCoordinates?.coordinates?.lat]),
        defaultKMRadius,
        { units: 'kilometers' }
      );
    } catch (error) {
      console.error(error, `error on creating circular buffer`);
    }
  }, [
    popupLocationCoordinates?.coordinates?.is_approximate,
    popupLocationCoordinates?.coordinates?.lat,
    popupLocationCoordinates?.coordinates?.lon,
  ]);

  return (
    <>
      {clusteredMarkered}
      {!isRestaurantsExplorer && (
        <Source id="circular-radius" type="geojson" data={buffered}>
          <Layer
            id="point-90-hi"
            type="fill"
            paint={{
              'fill-color': theme`colors.primary.700`,
              'fill-opacity': 0.15,
            }}
          />
        </Source>
      )}
    </>
  );
};

const ClusterMarker = styled.div<{ limit?: number }>`
  ${tw`rounded-full bg-white shadow-lg text-black border border-gray-200 flex items-center justify-center text-lg cursor-pointer`}
  ${({ limit }) => (limit < 10 ? tw`h-[2rem] w-[2rem]` : limit < 20 ? tw`h-[3rem] w-[3rem]` : tw`h-[4rem] w-[4rem]`)}
`;

const MarkerWithFloatingPopup = ({
  isOpen,
  setIsOpen,
  cluster,
}: {
  isOpen: boolean;
  setIsOpen?: () => void;
  cluster?: ClusterType;
}) => {
  const setPopupDetails = useSetAtom(setPopupDetailsAtom);
  const setPopupLocationCoordinates = useSetAtom(setPopupLocationCoordinatesAtom);
  const jobLocationPositionList = useAtomValue(jobLocationPositionListAtom);
  const setMapPopupLoading = useSetAtom(mapPopupLoadingAtom);
  const [activeJpId, setActiveJpId] = useAtom(activeJpIdAtom);
  const { getJobPositionApiCall: getJobPositionDetails } = useJobPositionApiData();
  const employerService = useRestaurantsService();
  const isRestaurantsExplorer = useAtomValue(isRestaurantsExplorerAtom);
  const explorerRestaurantList = useAtomValue(explorerRestaurantListAtom);
  const setExplorerRestaurantSelectedMarker = useSetAtom(explorerRestaurantSelectedMarkerAtom);

  const ARROW_WIDTH = 30;
  const ARROW_HEIGHT = 15;
  const {
    id: clusterId,
    label: clusterLabel,
    salary,
    min_salary: minSalary,
    max_salary: maxSalary,
    payment_type,
    coordinates,
  } = cluster?.extra || {};
  const paymentType = useMemo(() => `€${payment_type ? `/${paymentTypeAbbr[payment_type]}` : ''}`, [payment_type]);
  const pinSubLabel = useMemo(
    () =>
      salary
        ? `${salary}${paymentType}`
        : minSalary
        ? `${minSalary}${maxSalary ? `-${maxSalary}` : '-n/a'}${paymentType}`
        : paymentType
        ? `- ${paymentType}`
        : '-/-',
    [maxSalary, minSalary, paymentType, salary]
  );
  const getStyle = useCallback(
    (id: string) => ({ ...(activeJpId === id ? { zIndex: 7 } : { zIndex: 1 }) }),
    [activeJpId]
  );
  const onHandleMarker = useCallback(
    (e: MarkerEvent<mapboxgl.Marker, MouseEvent>, id: string, location: JobLocationCoordinates) => {
      e.originalEvent.stopPropagation();
      setActiveJpId(id);
      setPopupLocationCoordinates(location);
    },
    [setActiveJpId, setPopupLocationCoordinates]
  );
  const handleRestaurantMarker = useCallback(
    async (selectedId: string) => {
      setExplorerRestaurantSelectedMarker({});

      const selected = explorerRestaurantList?.find((v) => v.id === selectedId);
      if (selected) setExplorerRestaurantSelectedMarker(selected);
      if (!selected) {
        try {
          setMapPopupLoading(true);
          const data = (await employerService.showPublicRestaurant(selectedId)).data?.restaurant;
          setExplorerRestaurantSelectedMarker(data);
        } catch (error) {
          console.error(error, 'error getting company profile');
        } finally {
          setMapPopupLoading(false);
        }
      }
    },
    [employerService, explorerRestaurantList, setExplorerRestaurantSelectedMarker, setMapPopupLoading]
  );
  const getJobDetails = useCallback(
    async (id: string) => {
      if (isRestaurantsExplorer) {
        handleRestaurantMarker(id);
        return;
      }
      const selectedPosition = jobLocationPositionList?.find((v) => v.id === id);
      if (selectedPosition) setPopupDetails(selectedPosition);
      else {
        try {
          setMapPopupLoading(true);
          const data = await getJobPositionDetails(id);

          setPopupDetails(data);
        } catch (error) {
          console.error(error, `error fetching showJobPosition`);
        } finally {
          setMapPopupLoading(false);
        }
      }
    },
    [
      getJobPositionDetails,
      handleRestaurantMarker,
      isRestaurantsExplorer,
      jobLocationPositionList,
      setMapPopupLoading,
      setPopupDetails,
    ]
  );
  const onHandleClick = useCallback(
    (e: MarkerEvent<mapboxgl.Marker, MouseEvent>, id: string, location: JobLocationCoordinates) => {
      e.originalEvent.stopPropagation();
      onHandleMarker(e, id, location);
      setPopupDetails({});
      getJobDetails(id);
    },
    [getJobDetails, onHandleMarker, setPopupDetails]
  );
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement: 'right',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(ARROW_HEIGHT), flip({ padding: 5 }), shift({ padding: 5 })],
    whileElementsMounted: autoUpdate,
  });
  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;
  const { styles } = useTransitionStyles(context, {
    initial: {
      transform: 'scale(0)',
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
  });
  return (
    <>
      <Marker
        key={`marker-${clusterId}`}
        longitude={cluster?.geometry.coordinates[0]}
        latitude={cluster?.geometry.coordinates[1]}
        onClick={(e) => onHandleClick(e, clusterId, cluster?.extra)}
        style={getStyle(clusterId)}
      >
        <div ref={refs.setReference} className="reference">
          <Pin
            label={clusterLabel}
            isActive={activeJpId === clusterId}
            subLabel={pinSubLabel}
            isApproximate={coordinates?.is_approximate}
          />
        </div>
        {isOpen && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            tw="hidden md:block"
            onMouseDown={(e) => e.stopPropagation()}
          >
            <div style={styles} className="floating">
              <PopupInfoEl />
            </div>
          </div>
        )}
      </Marker>
    </>
  );
};
