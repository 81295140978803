import { useCallback, useEffect } from 'react';
import { useAtomValue } from 'jotai';

import { useJobPositionsService } from '@restworld/data-services';
import { useUTMSource } from '@restworld/utils-common';

import { useJobPositionId } from './use-job-position-id';
import { showViolaBadgeAtom } from '../components/job-position/job-position.atoms';

export const useIntegrateLogView = () => {
  const jobPositionService = useJobPositionsService();
  const jobPositionId = useJobPositionId();
  const { refCode, utmSource } = useUTMSource();
  const isViola = useAtomValue(showViolaBadgeAtom);

  const callIntegrateLogView = useCallback(() => {
    try {
      if (jobPositionId && isViola !== null)
        jobPositionService.createJobPositionViewLog({
          jobPositionId,
          ambassadorCode: refCode || undefined,
          utmSource,
          isViola,
          ipAddress: '',
        });
    } catch (error) {
      console.error(error, 'error creating job position view log');
    }
  }, [jobPositionId, jobPositionService, refCode, utmSource, isViola]);

  useEffect(() => {
    callIntegrateLogView();
  }, [callIntegrateLogView]);

  return {
    callIntegrateLogView,
  };
};
