import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';

import { useWorkerJobPositionsService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';

import { useJobPositionId } from './use-job-position-id';
import { compatibilityScoreAtom } from '../components/job-position/job-position.atoms';

export const useCompatibilityScore = () => {
  const jobPositionId = useJobPositionId();
  const { user } = useAuth();
  const [compatibilityScore, setCompatibilityScore] = useAtom(compatibilityScoreAtom);

  const workerJobPositionsService = useWorkerJobPositionsService();

  const getCombustibilityScore = useCallback(async () => {
    try {
      if (!!user?.id && compatibilityScore !== -1) {
        const response = await workerJobPositionsService.getCompatibilityScore({ workerId: user.id, jobPositionId });
        setCompatibilityScore(response.data);
      }
    } catch (error) {
      console.error(error, 'Error on getting compatibility score');
    }
  }, [user?.id, compatibilityScore, workerJobPositionsService, jobPositionId, setCompatibilityScore]);

  useEffect(() => {
    getCombustibilityScore();
  }, [getCombustibilityScore]);
  return {
    getCombustibilityScore,
  };
};
