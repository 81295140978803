globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useRef, useEffect, useCallback } from 'react';
import axios, { isCancel } from 'axios';
/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {newCancelToken: function, isCancel: function}
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 * @example
 *  const { newCancelToken, isCancel } = useCancelToken();
 *  useEffect(() => {
 *   axios.get('/important_data', {
 *    cancelToken: newCancelToken()
 * }).then((response) => {
 *  setState(response.data);
 * }).catch((error) => {
 *  if (isCancel(error)) return;
 * });
 * }, [newCancelToken, isCancel]);
 */

export var useCancelToken = function useCancelToken() {
  var axiosSource = useRef(null);
  var newCancelToken = useCallback(function () {
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current.token;
  }, []);
  useEffect(function () {
    return function () {
      if (axiosSource.current) axiosSource.current.cancel();
    };
  }, []);
  return {
    newCancelToken: newCancelToken,
    isCancel: isCancel
  };
};