import { MutableRefObject, ReactNode, useCallback, useEffect, useMemo } from 'react';
import 'twin.macro';
import tw, { styled } from 'twin.macro';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Wizard, useWizard } from 'react-use-wizard';
import { useForm, FormProvider, SubmitHandler, useFormContext } from 'react-hook-form';
import { MapRef } from 'react-map-gl';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { BaseModal, Button } from '@restworld/ui-ds';
import { Address, SaveResearchType } from '@restworld/utility-types';
import { useAuth } from '@restworld/feature-authentication';
import { useWorkersService } from '@restworld/data-services';
import {
  defaultItalyAddressMetaData,
  defaultItalyCoordinates,
  useAnalytics,
  useFacebookPixel,
} from '@restworld/utils-common';

import { saveResearchModalV2Atom } from '../../job-explorer-restaurant-explorer.atoms';
import {
  RegionalAreaSelection,
  AddressViewAndSelection,
  FilterSelection,
  NotificationUpdateSelection,
  ResearchSaveSuccessMessage,
} from './job-explorer-save-research-v2.components';
import { getWorkerAtom } from '../../../profile/global-state';
import { useJobExplorerFiltersFromContextValues } from '../../job-explorer-more-filter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IJobExplorerSaveResearchV2Props {
  prefillValues?: SaveResearchFormFields;
  startIndex?: number;
}
export interface SaveResearchFormFields extends SaveResearchType {
  seasonal?: string;
  address?: Partial<Address>;
  postal_code?: string;
  locality?: string;
  street_number?: string;
  modify_user_locality?: boolean;
  terms_and_conditions?: boolean;
  domain_interest?: string[];
  occupation_id?: { [x: string]: string[] };
}
export const areaSelectionAtom = atom<'whole_italy' | 'regional' | 'italy_and_abroad'>('regional');
export const toggleAddressEditViewAtom = atom(false);
export const saveResearchMapRefAtom = atom<MutableRefObject<MapRef>>({ current: null });
export const recordJobExplorerModalAtom = atom<boolean>(false);
const submitSaveResearchLoadingAtom = atom(false);
const showFooterButtonAtom = atom(true);
export const activeWizardStateAtom = atom(0);

export const JobExplorerSaveResearchV2 = ({ prefillValues, startIndex = 0 }: IJobExplorerSaveResearchV2Props) => {
  const [saveResearchModal, setSaveResearchModal] = useAtom(saveResearchModalV2Atom);
  const areaSelection = useAtomValue(areaSelectionAtom);
  const showFooterButton = useAtomValue(showFooterButtonAtom);
  const methods = useForm<SaveResearchFormFields>({});
  const { defaultValues } = useDefaultValues();

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  useEffect(() => {
    methods.reset(prefillValues);
  }, [prefillValues, methods]);

  const { user } = useAuth();
  const { watch } = methods;
  const accomodation = watch('seasonal');
  const sector = watch('domain_interest');
  const qualification = watch('occupation_id');
  const { trackEvent } = useAnalytics();
  const activeWizardState = useAtomValue(activeWizardStateAtom);
  const recordActiveWizardState = useSetAtom(activeWizardStateAtom);
  const recordCloseEvent = useCallback(() => {
    trackEvent('save_search_job_details', {
      email: user?.email,
      origin: areaSelection,
      accomodation,
      sector: sector,
      qualification: qualification ? Object.values(qualification).flat().filter(Boolean) : undefined,
      close_button: true,
    });
  }, [accomodation, areaSelection, qualification, sector, trackEvent, user?.email]);
  return (
    <BaseModal
      modalStyles={[tw`lg:(max-h-fit)`, activeWizardState === 0 ? tw`md:max-w-[52rem]` : tw`md:max-w-lg`]}
      open={saveResearchModal}
      onClose={() => {
        methods.reset(defaultValues);
        setSaveResearchModal(false);
        recordCloseEvent();
        recordActiveWizardState(0);
      }}
      showMobileGestures
      closeIcon={<Button variant="ghost" icon="XMarkIconBlack" onClick={() => setSaveResearchModal(false)} />}
      padBodyBottom={showFooterButton}
    >
      <FormProvider {...methods}>
        <form
          data-cy="save-research-form"
          autoComplete="off"
          action="#!"
          method="post"
          onSubmit={(e) => e.preventDefault()}
        >
          <Wizard startIndex={startIndex} footer={<WizardFooter />}>
            <RegionalAreaSelection />
            <AddressViewAndSelection />
            <FilterSelection />
            <NotificationUpdateSelection />
            <ResearchSaveSuccessMessage />
          </Wizard>
        </form>
      </FormProvider>
    </BaseModal>
  );
};

export default JobExplorerSaveResearchV2;
const WizardFooter = () => {
  const { activeStep } = useWizard();
  const addressEditView = useAtomValue(toggleAddressEditViewAtom);
  const [showFooterButton, setShowFooterButton] = useAtom(showFooterButtonAtom);
  const recordActiveWizardState = useSetAtom(activeWizardStateAtom);

  useEffect(() => {
    recordActiveWizardState(activeStep);
  }, [activeStep, recordActiveWizardState]);

  useEffect(() => {
    setShowFooterButton(activeStep !== 0 && !addressEditView);
  }, [activeStep, addressEditView, setShowFooterButton]);

  return showFooterButton ? (
    <div data-cy="save-research-footer-button" tw="w-full px-6 fixed bottom-6 left-0 md:(relative px-0 pb-2 bottom-0)">
      <SubmitButton />
    </div>
  ) : null;
};
export const StepWrapper = ({ children }: { children: ReactNode }) => {
  const { activeStep, previousStep, goToStep } = useWizard();
  const areaSelection = useAtomValue(areaSelectionAtom);
  if (
    activeStep === 0 ||
    (activeStep === 2 && (areaSelection === 'whole_italy' || areaSelection === 'italy_and_abroad'))
  )
    return (
      <WrapperStyle activeStep={activeStep}>
        <div tw="w-full max-h-[calc(100svh - 16rem)] md:(max-h-[calc(100svh - 18rem)]) overflow-y-auto pb-4 md:pb-8 px-2">
          {children}
        </div>
      </WrapperStyle>
    );
  return (
    <>
      <WrapperStyle activeStep={activeStep}>
        {(activeStep === 2 || activeStep === 3) && (
          <Button
            twStyle={tw`!px-0 md:(absolute z-20 top-[12px]) lg:top-[14px]`}
            variant="ghost"
            onClick={() => {
              if (activeStep === 2 && areaSelection === 'whole_italy') {
                goToStep(0);
                return;
              }
              previousStep();
            }}
            icon="ArrowLeftIcon"
          />
        )}
        <div tw="w-full max-h-[calc(100svh - 16rem)] md:(max-h-[calc(100svh - 18rem)]) overflow-y-auto pb-4 md:pb-8 px-2">
          {children}
        </div>
      </WrapperStyle>
    </>
  );
};
const WrapperStyle = styled.div<{ activeStep: number }>``;
const SubmitButton = () => {
  const wizardValues = useWizard();
  const activeStep = wizardValues?.activeStep;
  const nextStep = wizardValues?.nextStep;
  const { trackEvent } = useAnalytics();

  const { handleSubmit, watch, reset } = useFormContext<SaveResearchFormFields>();
  const workersService = useWorkersService();
  const { user } = useAuth();
  const { defaultValues } = useDefaultValues();
  const [, setSaveResearchModal] = useAtom(saveResearchModalV2Atom);
  const loading = useAtomValue(submitSaveResearchLoadingAtom);
  const setLoading = useSetAtom(submitSaveResearchLoadingAtom);
  const areaSelection = useAtomValue(areaSelectionAtom);

  const { trackWorkerSavedResearch } = useFacebookPixel();
  const extractPayloadFromData = useCallback(
    (data: SaveResearchFormFields) => {
      const flattenOccupation = data.occupation_id ? Object.values(data.occupation_id).flat() : [];
      const occupationIds = flattenOccupation.filter(Boolean)?.length
        ? { occupation_id: flattenOccupation.filter(Boolean) }
        : {};
      const domainInterests = data.domain_interest?.length ? { domain_interest: data.domain_interest } : {};
      const seasonal = data.seasonal
        ? {
            contract_type: ['seasonal_accommodation'],
          }
        : {};
      const coordinates =
        areaSelection === 'regional'
          ? {
              ...(data.address_lat
                ? {
                    address_lat: data.address_lat,
                    address_lon: data.address_lon,
                    ...(data.area_distance ? { area_distance: data.area_distance * 1000 } : {}),
                  }
                : {
                    address_lon: defaultItalyCoordinates[0],
                    address_lat: defaultItalyCoordinates[1],
                    area_distance: 4000,
                  }),
              ...(data.area_distance ? { area_distance: data.area_distance * 1000 } : {}),
            }
          : {};
      const filters = {
        ...seasonal,
        ...domainInterests,
        ...occupationIds,
        ...(data.address && areaSelection === 'regional'
          ? { address: data.address }
          : areaSelection === 'whole_italy'
          ? {
              address: { ...defaultItalyAddressMetaData },
            }
          : {}),
      };
      const filterParams = {
        ...(Object.keys(filters).length ? { filter_params: filters } : {}),
      };
      const payload = {
        user_id: user?.id,
        frequency: data.frequency,
        preferred_location: areaSelection === 'whole_italy' ? ('italy' as const) : undefined,
        email: data.email,
        ...coordinates,
        ...filterParams,
      };
      return payload;
    },
    [areaSelection, user?.id]
  );

  const { filterValues } = useJobExplorerFiltersFromContextValues();
  const router = useRouter();
  const onSubmit: SubmitHandler<SaveResearchFormFields> = useCallback(
    async (data) => {
      try {
        const payload = extractPayloadFromData(data);

        setLoading(true);
        await workersService.saveJPResearch(payload);
        if (!router.pathname.includes('/posizione'))
          filterValues({
            domain_interest: data.domain_interest,
            occupation_ids: data.occupation_id,
          });

        trackWorkerSavedResearch({
          user_id: payload.user_id,
          frequency: payload.frequency,
        });
        trackEvent('complete_save_search', {
          email: user?.email,
          origin: areaSelection,
          frequency: data.frequency,
          close_button: false,
        });
        nextStep();
      } catch (error) {
        console.error(`error saving research ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [
      areaSelection,
      extractPayloadFromData,
      filterValues,
      nextStep,
      router.pathname,
      setLoading,
      trackEvent,
      trackWorkerSavedResearch,
      user?.email,
      workersService,
    ]
  );
  const { t: jobExplorerTranslate } = useTranslation('job-explorer-page');
  const { t: commonTranslate } = useTranslation('common');
  const accomodation = watch('seasonal');
  const qualification = watch('occupation_id');
  const sector = watch('domain_interest');

  const title = useMemo(
    () =>
      areaSelection === 'whole_italy' && activeStep === 2
        ? { title: commonTranslate('continue') }
        : activeStep === 4
        ? { title: commonTranslate('i_understand') }
        : activeStep === 3
        ? { title: jobExplorerTranslate('save_research') }
        : { title: `Continua (${activeStep} di 3)` },
    [activeStep, areaSelection, commonTranslate, jobExplorerTranslate]
  );
  const icon = useMemo(
    () =>
      activeStep === 3 && {
        icon: !watch('terms_and_conditions') ? ('BellIconGray' as const) : ('BellIconPurple' as const),
      },
    [activeStep, watch]
  );
  const handleClick = useCallback(() => {
    if (activeStep === 2) {
      trackEvent('save_search_job_details', {
        email: user?.email,
        origin: areaSelection,
        accomodation,
        sector: sector,
        qualification: qualification && Object.values(qualification).flat().filter(Boolean),
        close_button: false,
      });
    }
    if ([1, 2].includes(activeStep)) nextStep();
    if (activeStep === 3) handleSubmit(onSubmit)();
    if (activeStep === 4) {
      setSaveResearchModal(false);
      reset(defaultValues);
    }
  }, [
    accomodation,
    activeStep,
    areaSelection,
    defaultValues,
    handleSubmit,
    nextStep,
    onSubmit,
    qualification,
    reset,
    sector,
    setSaveResearchModal,
    trackEvent,
    user?.email,
  ]);

  return (
    <Button
      testId="save-research-continue-button"
      disabled={activeStep === 3 && !watch('terms_and_conditions')}
      loading={loading}
      fullwidth
      variant="normal"
      onClick={handleClick}
      {...title}
      {...icon}
    />
  );
};
const useDefaultValues = () => {
  const workerData = useAtomValue(getWorkerAtom);
  const { user } = useAuth();
  const defaultValues = useMemo(
    () => ({
      email: user?.email,
      area_distance: 4,
      address_lon: workerData?.data?.address_lon,
      address_lat: workerData?.data?.address_lat,
      address: workerData?.data
        ? {
            address: workerData?.data.address,
            address_lat: workerData?.data.address_lat,
            address_lon: workerData?.data.address_lon,
            administrative_area_level_1: workerData?.data.administrative_area_level_1,
            administrative_area_level_2: workerData?.data.administrative_area_level_2,
            country: workerData?.data.country,
            locality: workerData?.data.locality,
            postal_code: workerData?.data.postal_code,
          }
        : undefined,
    }),
    [user?.email, workerData?.data]
  );
  return {
    defaultValues,
  };
};
