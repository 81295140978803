import { atom } from 'jotai';

import { ExploreRestaurantsGraphql, RestaurantLocationCoordinates } from '@restworld/data-services';
import { Restaurant } from '@restworld/utility-types';

import { defaultJobListPayload } from './job-explorer.types';

export type ExplorerMapState = 'job_offers' | 'restaurants';
export const activeExplorerTypeAtom = atom<ExplorerMapState>('job_offers');
export const toggleActiveExplorerTypeAtom = atom(null, (_, set, type: ExplorerMapState) =>
  set(activeExplorerTypeAtom, type)
);
// restaurant explorer atoms
export const isRestaurantsExplorerAtom = atom((get) => get(activeExplorerTypeAtom) === 'restaurants');
type RestaurantExplorationPopupDetailsType = {
  restaurant_exploration_popup_show?: boolean;
  restaurant_exploration_popup_read_at?: string;
};
export const toggleRestaurantInfoAtom = atom<{
  showModal: boolean;
  showRestaurantInfo: boolean;
  restaurantExplorationPopupDetails: RestaurantExplorationPopupDetailsType | null;
}>({
  showModal: false,
  showRestaurantInfo: false,
  restaurantExplorationPopupDetails: null,
});
toggleRestaurantInfoAtom.debugLabel = 'toggleRestaurantInfoAtom';
export const restaurantInfoTogglerAtom = atom(null, (_, set, update: boolean) =>
  set(toggleRestaurantInfoAtom, (prev) => ({
    ...prev,
    showRestaurantInfo: update,
  }))
);
export const restaurantInfoModalTogglerAtom = atom(null, (_, set, update: boolean) => {
  set(toggleRestaurantInfoAtom, (prev) => ({
    ...prev,
    showModal: update,
  }));
});
export const setRestaurantExplorationPopupDetailsAtom = atom(
  null,
  (_, set, update: RestaurantExplorationPopupDetailsType) => {
    set(toggleRestaurantInfoAtom, (prev) => ({
      ...prev,
      restaurantExplorationPopupDetails: update,
    }));
  }
);
export const getRestaurantInfoModalTogglerAtom = atom((get) => get(toggleRestaurantInfoAtom).showModal);
export const getRestaurantInfoTogglerAtom = atom((get) => get(toggleRestaurantInfoAtom).showRestaurantInfo);
export const getRestaurantExplorationPopupDetailsAtom = atom(
  (get) => get(toggleRestaurantInfoAtom).restaurantExplorationPopupDetails
);
export const getRestaurantExplorationPopupDiffHoursAtom = atom((get) => {
  if (!get(getRestaurantExplorationPopupDetailsAtom)) return -24;
  const popupReadAt = get(getRestaurantExplorationPopupDetailsAtom)?.restaurant_exploration_popup_read_at;

  if (!popupReadAt) return 0;
  const readAtDate = new Date(popupReadAt);
  const currentNow = new Date();
  const diff = currentNow.getTime() - readAtDate.getTime();
  const diffInHours = diff / (1000 * 3600);
  return +diffInHours;
});
getRestaurantExplorationPopupDiffHoursAtom.debugLabel = 'getRestaurantExplorationPopupDiffHoursAtom';

const persistenceRestaurantExplorationPopupShowAtom = atom(
  (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('restaurant_exploration_popup_show')) ?? 'true'
);
export const setPersistenceRestaurantExplorationPopupShowAtom = atom(null, (_, set, update: string) => {
  sessionStorage.setItem('restaurant_exploration_popup_show', update);
  set(persistenceRestaurantExplorationPopupShowAtom, update);
});
export const getPersistenceRestaurantExplorationPopupShowAtom = atom((get) =>
  get(persistenceRestaurantExplorationPopupShowAtom)
);
persistenceRestaurantExplorationPopupShowAtom.debugLabel = 'persistenceRestaurantExplorationPopupShowAtom';

type NewExplorerRestaurantType = {
  explorerRestaurantList: Restaurant[];
  restaurantHits: ExploreRestaurantsGraphql['data']['explore_restaurants']['hits'];
  payload: Partial<typeof defaultJobListPayload>;
  hasLimitReached?: boolean;
  zoomLevel?: number;
  restaurantMapMarkers: RestaurantLocationCoordinates[];
  restaurantSelectedMarker: Partial<Restaurant> | null;
  explorerRestaurantMapLoadingAtom: boolean;
  explorerRestaurantListFetchLoading: boolean;
};
const defaultsOfExplorerRestaurant: NewExplorerRestaurantType = {
  explorerRestaurantList: [],
  restaurantHits: 0,
  payload: defaultJobListPayload,
  hasLimitReached: false,
  zoomLevel: 5,
  restaurantMapMarkers: [],
  restaurantSelectedMarker: null,
  explorerRestaurantMapLoadingAtom: false,
  explorerRestaurantListFetchLoading: false,
};
export const explorerRestaurantListAtom = atom<NewExplorerRestaurantType['explorerRestaurantList']>(
  defaultsOfExplorerRestaurant.explorerRestaurantList
);
export const explorerRestaurantHitsAtom = atom<NewExplorerRestaurantType['restaurantHits']>(
  defaultsOfExplorerRestaurant.restaurantHits
);
export const explorerRestaurantListLoadingAtom = atom<NewExplorerRestaurantType['explorerRestaurantListFetchLoading']>(
  defaultsOfExplorerRestaurant.explorerRestaurantListFetchLoading
);
export const explorerRestaurantListPayloadAtom = atom<NewExplorerRestaurantType['payload']>(
  defaultsOfExplorerRestaurant.payload
);
export const restaurantHasLimitReachedAtom = atom<NewExplorerRestaurantType['hasLimitReached']>(
  defaultsOfExplorerRestaurant.hasLimitReached
);
export const explorerRestaurantZoomLevelAtom = atom<NewExplorerRestaurantType['zoomLevel']>(
  defaultsOfExplorerRestaurant.zoomLevel
);
export const explorerRestaurantMapMarkersAtom = atom<NewExplorerRestaurantType['restaurantMapMarkers']>(
  defaultsOfExplorerRestaurant.restaurantMapMarkers
);
export const explorerRestaurantSelectedMarkerAtom = atom<NewExplorerRestaurantType['restaurantSelectedMarker']>(
  defaultsOfExplorerRestaurant.restaurantSelectedMarker
);
export const explorerRestaurantMapLoadingAtom = atom<NewExplorerRestaurantType['explorerRestaurantMapLoadingAtom']>(
  defaultsOfExplorerRestaurant.explorerRestaurantMapLoadingAtom
);
export const isRestaurantListAvailableAtom = atom((get) => get(explorerRestaurantListAtom).length !== 0);

// other map atoms
export const seasonalAccommodationAtom = atom<{
  isApplied: boolean;
  lastAppliedAt: Date | null;
  toggleModal?: boolean;
}>({
  isApplied: false,
  lastAppliedAt: null,
  toggleModal: false,
});
export const setSeasonalAccommodationToggleModalAtom = atom(null, (_, set, update: boolean) =>
  set(seasonalAccommodationAtom, (prev) => ({
    ...prev,
    toggleModal: update,
  }))
);
export const setSeasonalAccommodationAppliedAtom = atom(null, (_, set, update: boolean) =>
  set(seasonalAccommodationAtom, (prev) => ({
    ...prev,
    isApplied: update,
    lastAppliedAt: new Date(),
  }))
);
export const getSeasonalAccommodationAtom = atom((get) => get(seasonalAccommodationAtom));
export const isSeasonalAccommodationAppliedAtom = atom((get) => get(getSeasonalAccommodationAtom).isApplied);

export const saveResearchModalV2Atom = atom<boolean>(false);
