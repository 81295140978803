// export const checkIfJpisOutsieItalyUsingCoordinates = (latitude?: number, longitude?: number): boolean => {
//   if (!latitude || !longitude) {
//     return false; // Default to false if coordinates are not provided
//   }
//   // Bounding box for Italy (approximate)
//   const italyBounds = {
//     north: 47.092,
//     south: 35.492,
//     west: 6.627,
//     east: 18.784,
//   };

//   const isWithinItaly =
//     latitude >= italyBounds.south &&
//     latitude <= italyBounds.north &&
//     longitude >= italyBounds.west &&
//     longitude <= italyBounds.east;

//   return !isWithinItaly;
// };
export const checkIfJobPositionIsOutsideItaly = ({
  address,
  country,
}: {
  address?: string;
  country?: string;
  coordinates?: { latitude: number; longitude: number };
}): boolean => {
  // if (coordinates) checkIfJpisOutsieItalyUsingCoordinates(coordinates.latitude, coordinates.longitude);
  if (country) {
    return !['italy', 'italia'].some((term) => country.toLowerCase().includes(term));
  }

  if (address) {
    const lastwordofAddress = address.split(' ').pop();
    return !['italy', 'italia'].some((term) => lastwordofAddress.toLowerCase().includes(term));
  }

  return false; // Default to false if neither address nor country is provided
};
