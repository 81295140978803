globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var Types;

(function (Types) {
  Types["Initial"] = "INITIALIZE";
  Types["Login"] = "LOGIN";
  Types["Logout"] = "LOGOUT";
  Types["Register"] = "REGISTER";
  Types["Reset"] = "RESET_PASSWORD";
  Types["VerifyToken"] = "VERIFY_TOKEN";
  Types["AuthorizationReady"] = "AUTHORIZATION_READY";
})(Types || (Types = {}));