import { useAccountsService } from '@restworld/data-services';
import { showToast } from '@restworld/ui-ds';
import { useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { useAuth } from '@restworld/feature-authentication';

import {
  phoneNumberSentAtom,
  phoneNumberSentFlagAtom,
  phoneNumberVerificationDateNowAtom,
  phoneNumberVerificationModalVisibleAtom,
} from './update-phone-number-modal';
import { validateOtpInputVisibleAtom } from './otp-validation-modal';

export const useEmployerPhoneNumberUpdateMutation = () => {
  const acc = useAccountsService();
  const { user } = useAuth();
  const setPhoneNumberSentFlag = useSetAtom(phoneNumberSentFlagAtom);
  const setPhoneNumberSent = useSetAtom(phoneNumberSentAtom);
  const validateOtpInputToggler = useSetAtom(validateOtpInputVisibleAtom);
  const setPhoneNumberVerificationDateNow = useSetAtom(phoneNumberVerificationDateNowAtom);
  const phoneNumberVerificationModalVisibleToggler = useSetAtom(phoneNumberVerificationModalVisibleAtom);

  const mutation = useMutation({
    mutationKey: ['update-phone_number'],
    mutationFn: (params: { email?: string; phoneNumber?: string }) =>
      user?.phone_number ? acc.sendVerificationPhoneOtp() : acc.updateAuthenticationDetails(params),
    onMutate(variables) {
      /* */
    },
    onSuccess(data, variables, context) {
      setPhoneNumberSentFlag(true);
      setPhoneNumberSent(variables.phoneNumber);
      validateOtpInputToggler(true);
      phoneNumberVerificationModalVisibleToggler(false);
      setPhoneNumberVerificationDateNow(Date.now());
      return data;
    },
    onError(error) {
      return error;
    },
  });
  return mutation;
};
export const useOTPVerificationMutation = (props?: { onOTPVerificationSuccessFull?: () => void }) => {
  const { onOTPVerificationSuccessFull } = props;
  const acc = useAccountsService();
  const setPhoneNumberSentFlag = useSetAtom(phoneNumberSentFlagAtom);
  const setPhoneNumberSent = useSetAtom(phoneNumberSentAtom);
  const validateOtpInputToggler = useSetAtom(validateOtpInputVisibleAtom);
  const { t } = useTranslation('common');
  const setPhoneNumberVerificationDateNow = useSetAtom(phoneNumberVerificationDateNowAtom);

  const mutation = useMutation({
    mutationKey: ['phone_number-otp-verification'],
    mutationFn: (params: { otpCode: string }) => acc.verifyPhoneOtp(params),
    onMutate() {
      /* */
    },
    onSuccess(data) {
      setPhoneNumberSentFlag(false);
      setPhoneNumberSent('');
      setPhoneNumberVerificationDateNow(null);
      validateOtpInputToggler(false);
      showToast(t('phone_number_verified'), 'success', {
        position: 'top-right',
        style: {
          marginTop: 80,
        },
        autoClose: 10000,
      });
      !!onOTPVerificationSuccessFull && onOTPVerificationSuccessFull();
      return data;
    },
    onError(error) {
      return error;
    },
  });
  return mutation;
};
