import { useCallback, useEffect } from 'react';
import omitBy from 'lodash.omitby';
import { useAtomValue, useSetAtom } from 'jotai';

import { JobCoordinateParamsType, JobPositionListParamsType } from '@restworld/data-services';
import { useMapBoundingRadius, formatExplorerOverallFilterData } from '@restworld/utils-common';
import type { MultiSelectOptionType } from '@restworld/utils-common';

import { defaultJobListPayload, URLDataType } from '../job-explorer.types';
import { getMapRefAtom, jobCoordinateParamsAtom, setUserCoordinatesAtom } from '../job-explorer-map.atoms';
import { moreFiltersAtom, searchBarUrlParamsAtom, filterContextValuesAtom } from '../job-explorer-filters.atoms';
import { jobListParamsAtom } from '../job-explorer-list.atoms';

type SearchBarFilterType = {
  domain_interest_ids?: string[];
  occupation_ids?: number[];
};
export const getFormateOccupationFilters = (occupation: MultiSelectOptionType[]) =>
  occupation.reduce(
    (prev, curr) => ({
      ...prev,
      ...(curr.type === 'occupations'
        ? { occupation_ids: prev.occupation_ids ? [...prev.occupation_ids, +curr.value] : [+curr.value] }
        : {}),
      ...(curr.type === 'domain_interest'
        ? {
            domain_interest_ids: prev.domain_interest_ids ? [...prev.domain_interest_ids, curr.value] : [curr.value],
          }
        : {}),
    }),
    {} as SearchBarFilterType
  );
export const useExplorerFilterUrlParams = () => {
  const setMoreFilters = useSetAtom(moreFiltersAtom);
  const setSearchBarUrlParams = useSetAtom(searchBarUrlParamsAtom);
  const setFilterContextValues = useSetAtom(filterContextValuesAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  const setUserCoordinates = useSetAtom(setUserCoordinatesAtom);
  const { getRadius } = useMapBoundingRadius({ map: mapRef?.current });
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const setJobCoordinateParams = useSetAtom(jobCoordinateParamsAtom);

  const getUrlData = useCallback(() => {
    let urlData: URLDataType = {
      isSearchBarUrlParamsAvailable: false,
    };
    try {
      if (typeof window === 'undefined') return urlData;
      const urlParams = new URLSearchParams(window.location.search);
      const serializedSearchBarFiltersObject = urlParams.get('search_bar_filters');
      const serializedOverallFilters = urlParams.get('overall_filters');
      const serializedMapFilters = urlParams.get('map_filters');

      const searchBarFilterParams = JSON.parse(decodeURIComponent(serializedSearchBarFiltersObject as string));
      const overallFilterUrlParams = JSON.parse(decodeURIComponent(serializedOverallFilters as string));
      const mapFiltersURLParams = JSON.parse(decodeURIComponent(serializedMapFilters as string));

      const parsedSearchBarFilter: MultiSelectOptionType[] = searchBarFilterParams;
      const parsedOverallFilter: Record<string, any> = overallFilterUrlParams;
      const parsedMapFiltersURLParams: Record<string, any> = mapFiltersURLParams;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const locationUrlParams = parsedSearchBarFilter?.find((v) => v.key === 'selected_location') as any;
      const occupationUrlParams: MultiSelectOptionType[] = parsedSearchBarFilter?.filter(
        (v) => v.key !== 'selected_location'
      );
      const searchBarFilterRadius = locationUrlParams
        ? getRadius({ lon: locationUrlParams?.value?.['lng'], lat: locationUrlParams?.value['lat'] })
        : null;
      const mapFilterRadius = parsedMapFiltersURLParams?.['longitude']
        ? getRadius({
            lon: parsedMapFiltersURLParams?.['longitude'],
            lat: parsedMapFiltersURLParams?.['latitude'],
          })
        : null;
      const searchBarFilters = occupationUrlParams ? getFormateOccupationFilters(occupationUrlParams) : {};
      const doesFilterExist = Object.keys(searchBarFilters).length;
      const formatOverallFilter = parsedOverallFilter
        ? formatExplorerOverallFilterData({ values: parsedOverallFilter })
        : {};
      const urlParamsPayload = {
        ...(locationUrlParams ? { lon: locationUrlParams?.value['lng'] } : {}),
        ...(locationUrlParams ? { lat: locationUrlParams?.value['lat'] } : {}),
        ...(searchBarFilterRadius ? { radius: searchBarFilterRadius } : {}),
        ...(parsedMapFiltersURLParams
          ? {
              ...(parsedMapFiltersURLParams['longitude'] ? { lon: parsedMapFiltersURLParams['longitude'] } : {}),
              ...(parsedMapFiltersURLParams['latitude'] ? { lat: parsedMapFiltersURLParams['latitude'] } : {}),
            }
          : {}),
        ...(mapFilterRadius ? { radius: mapFilterRadius } : {}),
        ...(doesFilterExist ? { search_bar_filters: searchBarFilters } : {}),
        ...(formatOverallFilter ? { ...formatOverallFilter } : {}),
      };
      const jobListUrlParams: JobPositionListParamsType = {
        ...(Object.keys(urlParamsPayload).length ? { ...defaultJobListPayload } : {}),
        ...urlParamsPayload,
      };
      const jobLocationUrlParams: JobCoordinateParamsType = {
        ...(doesFilterExist ? { search_bar_filters: JSON.stringify(searchBarFilters) } : {}),
        ...(formatOverallFilter ? { ...formatOverallFilter } : {}),
      };
      urlData = {
        locationUrlParams,
        occupationUrlParams,
        isSearchBarUrlParamsAvailable: true,
        jobListUrlParams,
        jobLocationUrlParams,
        parsedOverallFilter,
        mapFiltersURLParams: parsedMapFiltersURLParams,
      };
      if (Object.keys(jobListUrlParams).length) setJobListParams((p) => ({ ...p, ...(jobListUrlParams ?? {}) }));
      if (Object.keys(jobLocationUrlParams).length)
        setJobCoordinateParams((p) => ({ ...p, ...(jobLocationUrlParams ?? {}) }));
      setSearchBarUrlParams(urlData);
      if (parsedOverallFilter) setFilterContextValues(parsedOverallFilter);
      setMoreFilters((p) => {
        const filterParams = omitBy(
          { ...formatOverallFilter },
          (value, key) =>
            typeof value === 'undefined' ||
            ['min_salary', 'max_salary', 'user_location_lat', 'user_location_lon', 'benefits'].includes(key)
        );
        return {
          ...p,
          ...(Object.keys(filterParams).length ? { ...filterParams } : {}),
        };
      });
      setUserCoordinates({
        lat: locationUrlParams?.value?.lat,
        lon: locationUrlParams?.value?.lng,
      });
    } catch (error) {
      console.error(`error getting data from url params`, error);
    }
  }, [
    getRadius,
    setFilterContextValues,
    setJobCoordinateParams,
    setJobListParams,
    setMoreFilters,
    setSearchBarUrlParams,
    setUserCoordinates,
  ]);
  useEffect(() => {
    getUrlData();
  }, [getUrlData]);
};
