globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback, useEffect, useState } from 'react';
export var formatTimeLabel = function formatTimeLabel(timeInSeconds) {
  var minutes = Math.floor(timeInSeconds / 60);
  var seconds = timeInSeconds % 60;
  return "".concat(minutes.toString().padStart(2, '0'), ":").concat(seconds.toString().padStart(2, '0'));
};
export function useCountDownTimer(_ref) {
  var timeInSeconds = _ref.timeInSeconds;

  var _useState = useState(timeInSeconds),
      secondsRemaining = _useState[0],
      setSecondsRemaining = _useState[1];

  var _useState2 = useState(formatTimeLabel(timeInSeconds)),
      label = _useState2[0],
      setLabel = _useState2[1];

  var _useState3 = useState(100),
      percentElapsed = _useState3[0],
      setPercentElapsed = _useState3[1];

  var _useState4 = useState(false),
      hasExpired = _useState4[0],
      setHasExpired = _useState4[1]; // Function to reset the timer


  var resetTimer = useCallback(function () {
    setSecondsRemaining(timeInSeconds);
    setLabel(formatTimeLabel(timeInSeconds));
    setPercentElapsed(100);
    setHasExpired(false);
  }, [timeInSeconds]); // Reset the timer when timeInSeconds changes

  useEffect(function () {
    return resetTimer();
  }, [resetTimer]);
  useEffect(function () {
    var timer = setInterval(function () {
      if (secondsRemaining > 0) {
        var newTime = secondsRemaining - 1;
        setSecondsRemaining(newTime);
        setLabel(formatTimeLabel(newTime));
        setPercentElapsed(newTime / timeInSeconds * 100);
      } else {
        setHasExpired(true);
        clearInterval(timer);
      }
    }, 1000);
    return function () {
      return clearInterval(timer);
    };
  }, [timeInSeconds, secondsRemaining]);
  return {
    label: label,
    percentElapsed: percentElapsed,
    hasExpired: hasExpired,
    resetTimer: resetTimer
  };
}