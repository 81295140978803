globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
var fromWord = 'da';
var andWord = 'e';
var toWord = 'a';
var shiftNotes = "Viene offerta la possibilit\xE0 di arrivare un po' prima a lavoro per pranzare/cenare nel locale.";
var jobType = 'full time (40 ore settimanali)';
var category = 'Doppio turno';
/*
  category --> it is a string (chosen between multiple available values)
  category_other --> free string value, which is present only if none of the above options was fit to the job position.
  days_per_week_number --> integer values indicating how many days the person will have to work in the given shift
  days_per_week --> The list of specific days the person will have to work in this shift
*/

var shiftsDouble = [{
  category: category,
  days_per_week: ['mo', 'tu', 'we', 'sa', 'su']
}, {
  hours: [{
    start_at: '11',
    end_at: '17'
  }],
  days_per_week_number: 2
}];
var shiftSingleWithCategory = [{
  days_per_week: ['mo', 'th']
}];
var shiftSingleWithoutCategory = [{
  category_other: 'Any other category',
  days_per_week: ['mo', 'th']
}];
/*
  daysRequiredPerWeekNumber --> integer values indicating how many days per week the person will work
  daysRequiredPerWeek --> optional list of days that the person will need to work
*/

var withDaysRequiredPerWeekNumberArgs = {
  jobType: jobType,
  daysRequiredPerWeekNumber: 5,
  shifts: [].concat(shiftsDouble),
  shift_notes: shiftNotes
};
var withDaysRequiredPerWeekArgs = {
  jobType: jobType,
  daysRequiredPerWeek: ['mo', 'fr'],
  shifts: [].concat(shiftsDouble),
  shiftNotes: shiftNotes
};
var emptyWorkingDaysArgs = {
  jobType: jobType,
  shifts: [].concat(shiftsDouble),
  shiftNotes: shiftNotes
};
var emptyShiftsArgs = {
  daysRequiredPerWeekNumber: 5,
  daysRequiredPerWeek: ['mo', 'fr'],
  jobType: jobType,
  shiftNotes: shiftNotes
};
var shiftsWithJustCategoryArgs = {
  jobType: jobType,
  daysRequiredPerWeekNumber: 5,
  shifts: [].concat(shiftSingleWithCategory),
  shiftNotes: shiftNotes
};
var shiftsWithOutCategoryArgs = {
  jobType: jobType,
  daysRequiredPerWeekNumber: 5,
  shifts: [].concat(shiftSingleWithoutCategory),
  shiftNotes: shiftNotes
};
var weekDayMap = {
  mo: 'lunedì',
  tu: 'martedì',
  we: 'mercoledì',
  th: 'giovedì',
  fr: 'venerdì',
  sa: 'sabato',
  su: 'domenica'
};
var weekDaySymbol = Object.keys(weekDayMap);
var fullWeek = {
  mo: 1,
  tu: 2,
  we: 3,
  th: 4,
  fr: 5,
  sa: 6,
  su: 7
};
export { withDaysRequiredPerWeekArgs, withDaysRequiredPerWeekNumberArgs, emptyShiftsArgs, emptyWorkingDaysArgs, shiftsWithJustCategoryArgs, shiftsWithOutCategoryArgs, weekDayMap, weekDaySymbol, fromWord, andWord, toWord, fullWeek };