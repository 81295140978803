import { ReactNode, useEffect, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import Image from 'next/image';
import { useHydrateAtoms } from 'jotai/utils';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { closeAllToasts, JobNotFoundPage, showToast } from '@restworld/ui-ds';
import { getAssetSrc } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';
import { useAccountsService } from '@restworld/data-services';

import ToastMessage from '../toast-message';
import { PosizionePageProps } from '../../pages/posizione/[slug]';
import JobPositionLayout from './job-position-layout';
import { useJobPositionClientData, useSetActiveStatus, useWorkerSkills } from './use-effect-hooks';
import { JobPositionComponent } from './job-position-page-content';
import JobAlreadyApplied from '../job-already-applied';
import { JobStatusInfo } from '../job-status-info';
import {
  jobActiveStatusAtom,
  jobPositionApiDataAtom,
  restworldGoogleRatingDataAtom,
  similarJobPositionsAtom,
} from './job-position.atoms';
import WorkerEntryPointModal from '../worker-entry-point/worker-entry-point-modal';
import WorkerManagedUsingKlaaryo from '../worker-managed-using-klaaryo';
import UpdatePhoneNumberModal from '../common-phone-number-otp-validation-modal/update-phone-number-modal';
import OTPValidationModal from '../common-phone-number-otp-validation-modal/otp-validation-modal';
import { useApplyJob, useJobPositionId } from '../../hooks';
import { JobExplorerSaveResearchV2 } from '../job-explorer/job-explorer-map/job-explorer-save-research-v2';
import { domainInterestAtom } from '../job-explorer/job-explorer-server.atoms';

export const JobPositionPage = (props: PosizionePageProps) => {
  return (
    <JobPositionLayout jobPositionStatic={props.jobPositionStatic}>
      {props.errorCode || !props.jobPositionStatic ? (
        <JobNotFoundPage
          twStyle={tw`pt-28`}
          icon={<Image width={320} height={324} src={getAssetSrc('/utils-assets/space-discovery.svg')} alt="" />}
        />
      ) : (
        <JobPositionContent {...props} />
      )}
    </JobPositionLayout>
  );
};

const JobPositionContent = (props: PosizionePageProps) => {
  const { jobPositionStatic, slug: id, restworldGoogleRatingData, jobActiveStatus, domainInterest } = props;
  const { user } = useAuth();
  const jobPositionId = useJobPositionId();
  const accServices = useAccountsService();
  useSetActiveStatus({ jobActiveStatus });
  useWorkerSkills();
  const { jobPositionData } = useJobPositionClientData(jobPositionStatic, id);
  const { t } = useTranslation();
  useHydrateAtoms([
    [domainInterestAtom, domainInterest],
    [jobPositionApiDataAtom, jobPositionData],
    [restworldGoogleRatingDataAtom, restworldGoogleRatingData],
    [jobActiveStatusAtom, jobActiveStatus],
  ]);
  const isEmployerWhoOwnsTheJp = useMemo(
    () => user?.id && user.id === jobPositionData?.restaurant?.employer_id,
    [jobPositionData?.restaurant?.employer_id, user?.id]
  );
  useEffect(() => {
    closeAllToasts();
    if (isEmployerWhoOwnsTheJp) {
      showToast(
        `${user?.name}, ${t('here_is_a_preview_of_how_candidates_view_your_job_posting_on_the_restworld_site')}`,
        'reversed'
      );
    }
  }, [isEmployerWhoOwnsTheJp, t, user?.name]);

  const { applyJobPosition } = useApplyJob({
    referralCode: useRouter()?.query?.['scout'],
  });
  return (
    <>
      <ToastMessage />
      <ComponentWrapper>
        <JobPositionComponent />
      </ComponentWrapper>
      <JobStatus />
      <JobAlreadyApplied />
      <WorkerEntryPointModal />
      <WorkerManagedUsingKlaaryo />
      <UpdatePhoneNumberModal
        headerTitle={t('common:verify_your_phone_number')}
        headerSubTitle={t('common:verify_phone_number_message')}
      />
      <OTPValidationModal
        onOTPVerificationSuccessFull={async () => {
          try {
            await accServices.me();
          } catch (error) {
            console.error(error, 'error on refetching me data');
          }
          applyJobPosition({ workerIdArg: user?.id, jobPositionId });
        }}
      />
      <JobExplorerSaveResearchV2
        prefillValues={{
          address: {
            address: jobPositionData?.restaurant?.address,
            address_lat: jobPositionData?.restaurant?.address_lat,
            address_lon: jobPositionData?.restaurant?.address_lon,
            country: jobPositionData?.restaurant?.country,
            locality: jobPositionData?.restaurant?.locality,
            postal_code: jobPositionData?.restaurant?.postal_code,
            administrative_area_level_1: jobPositionData?.restaurant?.administrative_area_level_1,
            administrative_area_level_2: jobPositionData?.restaurant?.administrative_area_level_2,
          },
          area_distance: 10,
          email: user?.email,
          domain_interest: jobPositionData?.domain_interest ? [jobPositionData?.domain_interest] : [],
        }}
        startIndex={0}
      />
    </>
  );
};

const ComponentWrapper = ({ children }: { children: ReactNode }) => (
  <MainWrapper>
    <Container>
      <SubContainer>{children}</SubContainer>
    </Container>
  </MainWrapper>
);
const JobStatus = () => {
  const similarJobPositions = useAtomValue(similarJobPositionsAtom);
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  return <JobStatusInfo jobPositionApiData={jobPositionApiData} similarJobPositions={similarJobPositions} />;
};

const MainWrapper = tw.div`w-full h-full`;
const Container = tw.div`lg:(rounded-none shadow-none)`;
const SubContainer = styled.div`
  ${tw`md:(flex justify-between)`}
`;
