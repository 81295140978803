globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './person-swing-illustration';
export * from './cv-parsing-loading-illustration-final';
export * from './cv-parsing-loading-illustration-initial';
export * from './hiring-people-illustration';
export * from './man-on-computer';
export * from './binocular-girl-illustration';
export * from './man-with-magnifier-illustration';
export * from './checkout-illustration';
export * from './couple-dinner-illustration';
export * from './time-management-illustration';