globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useEffect, useState, useMemo } from 'react';
export var useMounted = function useMounted() {
  var _useState = useState(false),
      isMounted = _useState[0],
      setIsMounted = _useState[1];

  useEffect(function () {
    setIsMounted(true);
    return function () {
      setIsMounted(false);
    };
  }, []);
  return useMemo(function () {
    return isMounted;
  }, [isMounted]);
};