import { CompanyProfileComponentPropsType } from '@restworld/utility-types';

export const structCompanyProfile = ({
  restaurant,
  openingHours,
  reviewRating,
  reviews,
  reviewNumbers,
  serviceList,
}: CompanyProfileComponentPropsType) => {
  const staffSize =
    restaurant?.staff_size_floor && restaurant?.staff_size_kitchen
      ? restaurant.staff_size_floor + restaurant.staff_size_kitchen
      : undefined;
  const restaurantTypes = restaurant?.types
    ? restaurant.types.map(({ type_var }) => ({
        label: type_var.label,
        id: type_var.id,
        key: type_var.key,
      }))
    : [];
  const clientTypes = restaurant?.client_types
    ? restaurant.client_types.map(({ client_type_var }) => ({
        label: client_type_var.label,
        id: client_type_var.id,
        key: client_type_var.key,
      }))
    : [];
  const insertedAt = restaurant?.inserted_at ? restaurant.inserted_at : '';
  const images = restaurant?.pictures?.length ? restaurant.pictures?.map((v) => v.url) : [];

  const companyProfileProps: CompanyProfileComponentPropsType = {
    restaurantName: restaurant?.restaurant_name,
    insertedAt,
    images,
    coversNumber: restaurant?.covers_per_service_var?.label,
    staffSize,
    staffSizeKitchen: restaurant?.staff_size_floor,
    staffSizeFloor: restaurant?.staff_size_kitchen,
    reviewRating: reviewRating || 0,
    reviewNumbers: reviewNumbers || 0,
    reviews: reviews?.length ? reviews : [],
    restaurantTypes,
    clientTypes,
    openingHours: openingHours?.length ? openingHours : [],
    serviceList: serviceList?.length ? serviceList : [],
  };
  return companyProfileProps;
};
