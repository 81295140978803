globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
//  --> UI blocks > Job Positions
export * from './ui-blocks'; // --> design system libraries

export * from './design-system'; // helper components

export * from './feature-components';