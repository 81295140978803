import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useAuth } from '@restworld/feature-authentication';

import { useJobPositionId } from '../../../hooks';
import { compatibilityScoreAtom, jobPositionApiDataAtom, workerSkillsAtom } from '../job-position.atoms';

export const useSkills = () => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const compatibilityScore = useAtomValue(compatibilityScoreAtom);
  const jobPositionId = useJobPositionId();
  const { isAuthenticated } = useAuth();
  const workerSkills = useAtomValue(workerSkillsAtom);

  const { skillData, isEmptyData } = useMemo(() => {
    try {
      const certificates = jobPositionApiData?.certificates?.map(({ certificate_var }) => certificate_var.key);
      const education = jobPositionApiData?.education?.map(({ education_var }) => education_var.key);
      const requiredSkills = jobPositionApiData?.required_skills?.map(({ label_it }) => ({
        key: label_it?.label,
        label: label_it?.label,
        id: label_it?.id?.toString(),
      }));
      const otherRequirements = jobPositionApiData?.other_requirements?.map(
        ({ other_requirement_var }) => other_requirement_var.key
      );
      const languages = jobPositionApiData?.languages?.map(({ language_var, level_var }) => ({
        key: language_var.key,
        label: language_var?.label,
        level: level_var?.label,
      }));
      const optionalSkills = jobPositionApiData?.optional_skills?.map(({ label_it }) => label_it?.label);

      let data = {
        otherRequirements,
        education,
        certificates,
        languages,
        requiredSkills,
        optionalSkills,
        compatibility_score: compatibilityScore,
        workerSkills: [],
        jobPositionId,
      };

      if (isAuthenticated) {
        data = {
          ...data,
          workerSkills: [...workerSkills],
        };
      }

      const isEmptyData = Boolean(
        !requiredSkills?.length &&
          !optionalSkills?.length &&
          !otherRequirements?.length &&
          !education?.length &&
          !certificates?.length &&
          !languages?.length
      );
      return { skillData: data, isEmptyData };
    } catch (error) {
      console.error('error in getting skill data', error);
      return {
        skillData: {
          otherRequirements: [],
          education: [],
          certificates: [],
          languages: [],
          requiredSkills: [],
          optionalSkills: [],
          compatibility_score: 0,
          workerSkills: [],
          jobPositionId: '',
        },
        isEmptyData: true,
      };
    }
  }, [
    compatibilityScore,
    isAuthenticated,
    jobPositionApiData?.certificates,
    jobPositionApiData?.education,
    jobPositionApiData?.languages,
    jobPositionApiData?.optional_skills,
    jobPositionApiData?.other_requirements,
    jobPositionApiData?.required_skills,
    jobPositionId,
    workerSkills,
  ]);

  return { skillData, isEmptyData };
};
