import { useCallback, useMemo } from 'react';
import 'twin.macro';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import { useAuth } from '@restworld/feature-authentication';
import { CommonModalWrapper } from '@restworld/ui-ds';

import { EntryComponentNew, CvErrorsFieldReInput } from './worker-entry-point.components';

import WorkerCVLoadingUI from './worker-cvloading-ui';
import { CoverLetterEntry } from '../cover-letter-application';
import {
  isCVUploadProcessCompletedAtom,
  spontaneousApplyDataAtom,
  uploadCVLoadingAtom,
  workerEntryPointErrorsAtom,
  workerIdAtom,
} from './worker-entry-point.atoms';
import { entryPointModalVisibleAtom } from './worker-entry-point-modal';
import { jobPositionApplyStatusAtom } from '../job-position/job-position.atoms';

export const EntryPointModalBody = () => {
  const [, toggleEntryPoint] = useAtom(entryPointModalVisibleAtom);
  const closeEntryPoint = useCallback(() => toggleEntryPoint(false), [toggleEntryPoint]);
  const [jobPositionApplyStatus] = useAtom(jobPositionApplyStatusAtom);

  const uploadCVLoading = useAtomValue(uploadCVLoadingAtom);
  const isCVUploadProcessCompleted = useAtomValue(isCVUploadProcessCompletedAtom);
  const errors = useAtomValue(workerEntryPointErrorsAtom);
  const workerId = useAtomValue(workerIdAtom);
  const spontaneousApplyData = useAtomValue(spontaneousApplyDataAtom);

  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const { t } = useTranslation();
  const appliedForAJobEl = useMemo(
    () => (
      <CommonModalWrapper
        mainTitle={t('common:application_delivered')}
        subTitle={t('common:confirm_if_everything_updated')}
        primaryButtonProps={{
          title: t('common:check_your_profile'),
          onClick() {
            router.push('/profilo');
          },
        }}
        secondaryButtonProps={{
          title: t('common:close'),
          onClick() {
            closeEntryPoint();
          },
        }}
        icon={<CheckCircleIcon className="h-10 w-10 text-secondary-500" />}
      />
    ),
    [t, closeEntryPoint, router]
  );

  const modalBody = useMemo(() => {
    if (errors?.statusCode && errors?.statusCode >= 400 && errors?.statusCode <= 409) return <CvErrorsFieldReInput />;
    else if (spontaneousApplyData?.isSpontaneousEntry && spontaneousApplyData?.showCoverLetter) {
      return spontaneousApplyData?.didUserSpontaneousApply ? (
        appliedForAJobEl
      ) : (
        <CoverLetterEntry
          workerId={workerId}
          restaurantID={spontaneousApplyData?.restaurantID}
          closeEntryPoint={closeEntryPoint}
          id={isAuthenticated ? 'authenticated-spontaneous-application' : 'unauthenticated-spontaneous-application'}
        />
      );
    } else if (uploadCVLoading || isCVUploadProcessCompleted)
      return <WorkerCVLoadingUI workerId={workerId} isCompleted={isCVUploadProcessCompleted} />;
    else if (isAuthenticated && jobPositionApplyStatus) return appliedForAJobEl;
    return <EntryComponentNew />;
  }, [
    appliedForAJobEl,
    closeEntryPoint,
    errors?.statusCode,
    isAuthenticated,
    isCVUploadProcessCompleted,
    jobPositionApplyStatus,
    spontaneousApplyData?.didUserSpontaneousApply,
    spontaneousApplyData?.isSpontaneousEntry,
    spontaneousApplyData?.restaurantID,
    spontaneousApplyData?.showCoverLetter,
    uploadCVLoading,
    workerId,
  ]);
  return modalBody;
};
