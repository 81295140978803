/* eslint-disable react/jsx-no-useless-fragment */
import { memo, useCallback, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { useResponsiveBreakPoints } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';
import { Variable } from '@restworld/utility-types';
import { Title, Wrapper, CheckboxGroup, SecondaryList, JobShareModal, ListModal } from '@restworld/ui-ds';

// import { useRecordJobShareEvent } from '../../hooks/use-record-job-share-event';
import OtherRequirements from '../job-other-requirements';

export const SkillsBlock = memo(
  ({
    requiredSkills = [],
    optionalSkills,
    otherRequirements,
    education,
    certificates,
    languages,
    workerSkills,
    children: applicationEntryPointButtonAsChild,
    ambassadorRefCode,
    jobPositionId,
  }: SkillsBlockProps) => {
    const { isDesktop } = useResponsiveBreakPoints();
    const { t } = useTranslation('job-position-page');
    // const { recordJobShareEvent } = useRecordJobShareEvent();
    return (
      <Wrapper>
        <Title
          content={t('job_skills.title')}
          icon="ClipboardDocumentListIcon"
          variant="h4-large"
          fontWeight="semibold"
          textColor="black"
        />
        <NecessarySkills requiredSkills={[...requiredSkills]} workerSkills={workerSkills} />
        <OptionalSkills optionalSkills={optionalSkills} />
        <OtherRequirementsEl
          otherRequirements={otherRequirements}
          education={education}
          certificates={certificates}
          languages={languages}
        />
        {!isDesktop ? (
          <ButtonWrapper>
            <JobShareModal
              ambassadorRefCode={ambassadorRefCode}
              jobPositionId={jobPositionId}
              buttonTitle="Condividi"
              icon="ShareIcon"
              fullwidth
              // onClick={recordJobShareEvent}
            />
            {applicationEntryPointButtonAsChild}
          </ButtonWrapper>
        ) : null}
      </Wrapper>
    );
  }
);
export default SkillsBlock;
const NecessarySkills = ({ requiredSkills, workerSkills }: NecessarySkillProps) => {
  const { isAuthenticated } = useAuth();
  const filterCount = useListFilterCount();
  const renderListEl = (showFullList: boolean) =>
    requiredSkills?.length ? (
      <CheckboxGroup
        options={[...requiredSkills].filter((_, i) => (!showFullList ? i < filterCount : true))}
        value={isAuthenticated ? workerSkills : []}
        isWrapList={!showFullList}
      />
    ) : null;
  return (
    <ListModal isShowMoreButtonVisible={requiredSkills?.length > filterCount} modalEl={renderListEl(true)}>
      {renderListEl(false)}
    </ListModal>
  );
};
const OptionalSkills = ({ optionalSkills }: { optionalSkills: string[] }) => {
  const isEmptyOptionalSkills = Boolean(!optionalSkills?.length);
  const filterCount = useListFilterCount();
  const getOptionalList = useCallback(
    (showFullList: boolean) =>
      !isEmptyOptionalSkills ? (
        <>
          <SecondaryList
            itemList={optionalSkills
              ?.filter((_, i) => (!showFullList ? i < filterCount : true))
              .map((v) => ({ key: v, label: v, icon: 'ClipboardDocumentCheckIcon' }))}
            orientation="vertical"
            textColor="gray"
            isWrapList={!showFullList}
            fontSize="base"
          />
        </>
      ) : null,
    [filterCount, isEmptyOptionalSkills, optionalSkills]
  );

  const optionalEl = optionalSkills?.length ? (
    <ListModal isShowMoreButtonVisible={optionalSkills?.length > filterCount} modalEl={getOptionalList(true)}>
      <Title
        variant="h6-large"
        content="Competenze opzionali, se non le hai ti verrano insegnate"
        fontWeight="semibold"
      />
      {getOptionalList(false)}
    </ListModal>
  ) : null;
  return optionalEl;
};
const OtherRequirementsEl = ({ otherRequirements, education, certificates, languages }: OtherRequirementsProps) => {
  const isEmptyOtherRequirements = useMemo(
    () => Boolean(!otherRequirements?.length && !education?.length && !certificates?.length && !languages?.length),
    [certificates?.length, education?.length, languages?.length, otherRequirements?.length]
  );
  return !isEmptyOtherRequirements ? (
    <OtherRequirements
      otherRequirements={otherRequirements}
      education={education}
      certificates={certificates}
      languages={languages}
    />
  ) : null;
};
const useListFilterCount = () => {
  const { isDesktop } = useResponsiveBreakPoints();
  return isDesktop ? 12 : 5;
};
const ButtonWrapper = styled.div`
  ${tw`flex gap-4 text-lg justify-center items-center lg:(w-full max-w-[21rem] flex-row-reverse)`}
`;

export interface NecessarySkillProps {
  requiredSkills: Variable[];
  workerSkills: number[];
}
export interface OtherRequirementsProps {
  otherRequirements: string[];
  education: string[];
  certificates: string[];
  languages: {
    key: string;
    label: string;
    level: string;
  }[];
}
export interface SkillsBlockProps extends NecessarySkillProps, OtherRequirementsProps {
  optionalSkills: string[];
  compatibility_score?: number;
  children?: React.ReactNode; // applicationEntryPointButtonAsChild
  ambassadorRefCode?: string;
  jobPositionId?: string;
}
