globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var reviewsMap = [{
  author_name: 'Nome Cognome',
  rating: 3,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}, {
  author_name: 'Nome Cognome',
  rating: 1,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}, {
  author_name: 'Nome Cognome',
  rating: 4,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}, {
  author_name: 'Nome Cognome',
  rating: 3,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}, {
  author_name: 'Nome Cognome',
  rating: 5,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}, {
  author_name: 'Nome Cognome',
  rating: 2,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}, {
  author_name: 'Nome Cognome',
  rating: 2,
  time: new Date('2020-08-27T08:24:37.642Z'),
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
}];
export var serviceListMock = ["Tavoli all\u2019aperto", "Consegna a domicilio", "da asporto", "Consumazione sul posto"];
export var openingHoursMock = [{
  close: {
    day: 1,
    time: '1200'
  },
  open: {
    day: 1,
    time: '0900'
  }
}, {
  close: {
    day: 1,
    time: '2200'
  },
  open: {
    day: 1,
    time: '1500'
  }
}, {
  close: {
    day: 2,
    time: '1700'
  },
  open: {
    day: 2,
    time: '0900'
  }
}, {
  close: {
    day: 3,
    time: '1750'
  },
  open: {
    day: 3,
    time: '0900'
  }
}, {
  close: {
    day: 4,
    time: '1045'
  },
  open: {
    day: 4,
    time: '0745'
  }
}, {
  close: {
    day: 4,
    time: '1945'
  },
  open: {
    day: 4,
    time: '1245'
  }
}, {
  close: {
    day: 5,
    time: '1700'
  },
  open: {
    day: 5,
    time: '0900'
  }
}];
export var restaurantImagesMock = ['https://images.pexels.com/photos/262978/pexels-photo-262978.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/67468/pexels-photo-67468.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/260922/pexels-photo-260922.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/1267320/pexels-photo-1267320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/696218/pexels-photo-696218.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/941861/pexels-photo-941861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/1058277/pexels-photo-1058277.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/1581384/pexels-photo-1581384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/262047/pexels-photo-262047.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/2253643/pexels-photo-2253643.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/784633/pexels-photo-784633.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/761854/pexels-photo-761854.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 'https://images.pexels.com/photos/1307698/pexels-photo-1307698.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'];
export var similarPositionsMock = [{
  publishedAt: new Date('2020-08-27T08:24:37.642Z'),
  city: 'Milano',
  jobTitle: 'Cameriere alle colazioni per hotel di lusso',
  occupation: 'Cameriere',
  salaryMin: 1600,
  salaryMax: 1800,
  salary: '',
  restaurantName: 'Lavazza',
  showRestaurantDetails: true
}, {
  publishedAt: new Date('2020-08-27T08:24:37.642Z'),
  city: 'Milano',
  jobTitle: 'Cameriere alle colazioni per hotel di lusso',
  occupation: 'Camoo',
  salaryMin: 2000,
  salaryMax: 2200,
  salary: '',
  restaurantName: 'Starbuck',
  showRestaurantDetails: true
}, {
  publishedAt: new Date('2020-08-27T08:24:37.642Z'),
  city: 'Turino',
  jobTitle: 'Cameriere alle colazioni per hotel di lusso',
  occupation: 'Camera',
  salaryMin: 2000,
  salaryMax: 3200,
  salary: '',
  restaurantName: 'Starbuck',
  showRestaurantDetails: true
}, {
  publishedAt: new Date('2020-08-27T08:24:37.642Z'),
  city: 'Turino',
  jobTitle: 'Cameriere alle colazioni per hotel di lusso',
  occupation: 'Camera',
  salaryMin: 2000,
  salaryMax: 3200,
  salary: '',
  restaurantName: 'Starbuck',
  showRestaurantDetails: true
}, {
  publishedAt: new Date('2020-08-27T08:24:37.642Z'),
  city: 'Turino',
  jobTitle: 'Cameriere alle colazioni per hotel di lusso',
  occupation: 'Camera',
  salaryMin: 2000,
  salaryMax: 3200,
  salary: '',
  restaurantName: 'Starbuck',
  showRestaurantDetails: true
}];