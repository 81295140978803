import { useState } from 'react';
import Image from 'next/image';

import { customImageLoader } from '@restworld/utils-common';

import tw, { TwStyle, styled } from 'twin.macro';

export default function BlurImage({
  alt,
  src,
  customStyles,
  fill,
  dimensions,
}: {
  src: string;
  alt: string;
  customStyles?: TwStyle;
  fill?: boolean;
  dimensions?: { width: number; height: number };
}) {
  const [isLoading, setLoading] = useState(true);

  return (
    <StyledImage
      src={src}
      alt={alt}
      fill={fill}
      priority
      onLoadingComplete={() => setLoading(false)}
      isLoading={isLoading}
      customStyles={customStyles}
      // blurDataURL="https://placehold.co/1024x1024"
      {...dimensions}
      loader={customImageLoader}
    />
  );
}
const StyledImage = styled(Image)<{
  isLoading?: boolean;
  customStyles?: TwStyle;
}>(({ isLoading, customStyles }) => [tw`object-cover`, isLoading ? tw`bg-gray-500` : tw``, customStyles]);
