import { useCallback } from 'react';
import tw from 'twin.macro';
import { useAtomValue, useSetAtom } from 'jotai';

import { Title, Button, Rating } from '@restworld/ui-ds';

import { companyProfileAtom, fullRatingAtom } from '../../job-position/job-position.atoms';

export const SingleRating = () => {
  const setFullRating = useSetAtom(fullRatingAtom);
  const { reviewRating, reviewNumbers } = useAtomValue(companyProfileAtom);

  const clickHandler = useCallback(() => {
    if (reviewNumbers) setFullRating(true);
  }, [reviewNumbers, setFullRating]);

  return reviewNumbers ? (
    <Wrapper>
      <RatingWrapper>
        <Rating rating={reviewRating || 0} />
        <Title content={reviewRating?.toString() ?? ''} />
        <Title
          content={`(${reviewNumbers} review${reviewNumbers > 1 ? 's' : ''})`}
          textColor="gray"
          fontWeight="light"
        />
      </RatingWrapper>
      <Button icon="ChevronRightIcon" variant="ghost" onClick={clickHandler} />
    </Wrapper>
  ) : null;
};

const Wrapper = tw.div`flex justify-between`;
const RatingWrapper = tw.div`flex gap-1`;
