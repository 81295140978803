import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

import { useJobPositionsService } from '@restworld/data-services';

import {
  jobCoordinateParamsAtom,
  jobLocationCoordinatesAtom,
  jobLocationCoordinatesLoadingAtom,
  coordinatesKeyAtom,
} from '../job-explorer-map.atoms';

export const useJobPositionCoordinatesQuery = () => {
  const [enabled, setEnabled] = useState(false);
  const jobPositionsService = useJobPositionsService();
  const queryKey = useAtomValue(coordinatesKeyAtom);
  const coordinatesParams = useAtomValue(jobCoordinateParamsAtom);
  const setJobLocationCoordinatesLoading = useSetAtom(jobLocationCoordinatesLoadingAtom);
  const setJobLocationCoordinates = useSetAtom(jobLocationCoordinatesAtom);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEnabled(true);
    }, 750);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      setJobLocationCoordinatesLoading(true);
      const data = (await jobPositionsService.getJobLocationCoordinates(coordinatesParams, { signal })).data;
      setJobLocationCoordinates(data ?? []);
      setJobLocationCoordinatesLoading(false);
      return data ?? [];
    },
    placeholderData: (previousData) => previousData,
    enabled,
    staleTime: 0,
  });
};
