globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
//  Trigger another update
export * from './hooks';
export * from './types';
export * from './constants';
export * from './mocks';
export * from './utils';
export * from './context';
export * from './svg-icons';
export * from './helpers';
export * from './illustrations';