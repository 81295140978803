import { memo } from 'react';
import { useAtomValue } from 'jotai';
import { currentUrl, isSignupModalOpen } from '../../context/auth-flow';
import { SignupForm, SignupHeader } from './signup-form';

export interface SignupComponentModalProps {
  children?: React.ReactNode;
  redirectToPath: (path: string, query?: { [key: string]: string | boolean }) => void;
  onClose?: () => void;
  currentUrl?: string;
}

export const SignupComponentModal = memo(() => {
  const open = useAtomValue(isSignupModalOpen);
  const activeUrl = useAtomValue(currentUrl);
  if (open) {
    return (
      <>
        <SignupHeader {...{ isModal: true }} />
        <SignupForm {...{ isModal: true, activeUrl }} />
      </>
    );
  }
  return undefined;
});
