globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
export var useNotificationFrequencyOptions = function useNotificationFrequencyOptions() {
  var _useTranslation = useTranslation('common'),
      t = _useTranslation.t;

  return useMemo(function () {
    return [{
      id: 'every_job_position',
      key: 'every_job_position',
      label: t('as_soon_as_there_is_a_job_position')
    }, {
      id: 'weekly',
      key: 'weekly',
      label: t('every_week')
    }, {
      id: 'biweekly',
      key: 'biweekly',
      label: t('every2_weeks')
    }, {
      id: 'monthly',
      key: 'monthly',
      label: t('every_month')
    }];
  }, [t]);
};