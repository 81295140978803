import { ReactNode } from 'react';
import 'twin.macro';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { Title } from '@restworld/ui-ds';

import {
  ContractType,
  JobType,
  DistanceFrom,
  SalaryRange,
  SeasonalJobPosition,
  YearsOfExperience,
  SalaryRating,
  CareerRating,
  DomainInterestQualificationField,
  // ShiftsSection,
} from '.';

export const ExplorerFormContent = ({
  handleFilterChange,
  children,
}: {
  handleFilterChange?: (values?: FieldValues) => Promise<void>;
  children?: ReactNode;
}) => {
  const { handleSubmit } = useFormContext();
  const { t } = useTranslation('job-explorer-page');
  return (
    <form tw="relative" onSubmit={handleSubmit(handleFilterChange)}>
      <div tw="flex flex-col gap-4 px-[40px] h-[calc(100svh - 11rem)] overflow-y-auto pb-4">
        <DomainInterestQualificationField />
        <hr />
        <ContractType />
        <hr />
        <JobType />
        <hr />
        <DistanceFrom />
        <hr />
        <SalaryRange />
        <hr />
        <SeasonalJobPosition />
        <hr />
        <YearsOfExperience />
        <hr />
        <div tw="flex flex-col gap-4 pb-28 lg:pb-0">
          <Title content={t('what_restworld_makes')} fontSize="large" fontWeight="semibold" />
          <SalaryRating />
          <CareerRating />
        </div>
      </div>
      {children}
    </form>
  );
};
