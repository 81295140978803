import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var JWTReducer = function JWTReducer(state, action) {
  var type = action.type,
      _action$payload = action.payload,
      isAuthenticated = _action$payload.isAuthenticated,
      user = _action$payload.user,
      jwt = _action$payload.jwt;

  switch (type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: isAuthenticated,
        isInitialized: true,
        user: user,
        isReady: true,
        jwt: jwt,
        logoutRedirect: false
      };

    case 'LOGIN':
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: isAuthenticated,
        user: user,
        isReady: true,
        jwt: jwt
      });

    case 'VERIFY_TOKEN':
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: isAuthenticated,
        user: user,
        isReady: true,
        jwt: jwt
      });

    case 'LOGOUT':
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: false,
        user: null,
        isReady: true,
        jwt: null,
        logoutRedirect: true
      });

    case 'REGISTER':
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: isAuthenticated,
        user: user,
        isReady: true,
        jwt: jwt
      });

    case 'RESET_PASSWORD':
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: isAuthenticated,
        isReady: true,
        user: user,
        jwt: jwt
      });

    default:
      return state;
  }
};