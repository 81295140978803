import { Button, CircularProgressBar, Paragraph } from '@restworld/ui-ds';
import { memo, useEffect } from 'react';
import { EmailSentIcon, useCountDownTimer } from '@restworld/utils-common';
import { AuthTitle } from '../common/auth-title';
import { AuthSubtitle } from '../common/auth-subtitle';
import 'twin.macro';
import { useEmailLoginForm } from './hooks/use-email-login-form';
import { useAtomValue } from 'jotai';
import { emailVerificationDetails } from '../../context/auth-flow';
import { HeaderWrapper } from '../common/head-wrapper';
import { useTranslation } from 'next-i18next';

export type LoginLinkSentModalProps = {
  isModal?: boolean;
  onAcknowledgingEmailReception: () => void;
};

export const LoginLinkSentForm = memo(({ onAcknowledgingEmailReception, isModal }: LoginLinkSentModalProps) => {
  const emailDetails = useAtomValue(emailVerificationDetails);

  const minutes = Math.floor(emailDetails.tokenValidityTime / 60);

  const {
    hasExpired: waitTimerExpired,
    label: waitTimerLabel,
    resetTimer: resetWaitTimer,
  } = useCountDownTimer({
    timeInSeconds: emailDetails.waitTime,
  });
  const {
    label: tokenTimerLabel,
    percentElapsed,
    hasExpired: tokenHasExpired,
    resetTimer: resetCountDownTimer,
  } = useCountDownTimer({ timeInSeconds: emailDetails.tokenValidityTime });

  const { loading, onSubmit, remainingTimes } = useEmailLoginForm({ isModal });
  const { t } = useTranslation('auth-flow');

  useEffect(() => {
    if (remainingTimes > 0) {
      resetWaitTimer();
    }
    if (remainingTimes >= 0) {
      resetCountDownTimer();
    }
  }, [remainingTimes, resetCountDownTimer, resetWaitTimer]);

  return (
    <div tw="flex flex-col items-center w-full ">
      <div tw="flex flex-col items-center w-full my-10">
        <CircularProgressBar label={tokenTimerLabel} progress={percentElapsed} />
        <h6 tw="text-base text-gray-500 leading-6 text-center mt-4">
          {t('email_login_email_link_sent_link_timeout_text_1')} {minutes}{' '}
          {t('email_login_email_link_sent_link_timeout_text_2')}
        </h6>
      </div>
      <div tw="flex flex-col items-center w-full gap-4">
        <Button fullwidth={true} onClick={() => onAcknowledgingEmailReception()}>
          <span tw="text-black">{t('email_login_email_link_sent_link_email_received_text')}</span>
        </Button>
        <div tw="flex flex-col items-center w-full gap-2">
          <Button
            fullwidth={true}
            onClick={() => onSubmit({ email: emailDetails.email })}
            disabled={!waitTimerExpired || remainingTimes === 0}
            variant="secondary-outline"
            loading={loading}
          >
            <span tw="text-black">
              {!tokenHasExpired &&
                `${t('email_login_email_link_sent_link_wait_to_resend_text_1')}  ${
                  !waitTimerExpired
                    ? `${t('email_login_email_link_sent_link_wait_to_resend_text_2')} ${waitTimerLabel}`
                    : ''
                }`}
              {tokenHasExpired && t('email_login_email_link_sent_link_not_received_email_text')}
            </span>
          </Button>
          {remainingTimes < 3 && (
            <Paragraph color="gray-500">
              {remainingTimes} {t('email_login_email_link_sent_resent_count')}
            </Paragraph>
          )}
        </div>
      </div>
    </div>
  );
});

export const LoginLinkSentHeader = ({ isModal = true }: { isModal?: boolean }) => {
  const { t } = useTranslation('auth-flow');
  return (
    <div tw="flex flex-col items-center w-full mt-5">
      <EmailSentIcon />
      <HeaderWrapper tw="mt-2 gap-2" isModal={isModal}>
        <AuthTitle data-cy="login-link-sent-heading">{t('email_login_email_link_sent_header')}</AuthTitle>
        <AuthSubtitle>{t('email_login_email_link_sent_subheader')}</AuthSubtitle>
      </HeaderWrapper>
    </div>
  );
};
