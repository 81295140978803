import { Button, EmailInput, isValidEmail } from '@restworld/ui-ds';
import { useEffect } from 'react';
import { FacebookButton } from '../common/facebook-button';
import { GoogleButton } from '../common/google-button';
import { Separator } from '../common/separator';
import { useEmailLoginForm } from './hooks/use-email-login-form';
import { AuthTitle } from '../common/auth-title';
import { AuthSubtitle } from '../common/auth-subtitle';
import { Controller } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import { HeaderWrapper } from '../common/head-wrapper';
import { useEmailLoginUtils } from './hooks/use-email-login-utils';
import { useTranslation } from 'next-i18next';

export type LoginByEmailFormProps = {
  isModal?: boolean;
  activeUrl?: string;
  email?: string;
};

export const LoginByEmailForm = ({ activeUrl, isModal = true, email }: LoginByEmailFormProps) => {
  const { loading, onSubmit, handleSubmit, control, errors, setError, setValue } = useEmailLoginForm({
    currentUrl: activeUrl,
    isModal,
  });
  const { existingAccount, onClose, navigateToPhoneNumberLogin, navigateToSignup } = useEmailLoginUtils({ isModal });
  const { t } = useTranslation('auth-flow');
  const { t: commonT } = useTranslation('common');
  useEffect(() => {
    if (existingAccount.email) {
      setValue('email', existingAccount.email);
      setError('email', { message: t('email_login_enter_email_email_already_exist_error') });
    }
  }, [existingAccount.email, setError, setValue, t]);
  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email, setValue]);
  return (
    <>
      <StyledFormWrapper isModal={isModal}>
        <form tw="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div tw="flex flex-col gap-4 w-full">
            <Controller
              name="email"
              control={control}
              rules={{ required: t('email_login_enter_email_insert_valid_email'), validate: isValidEmail }}
              render={({ field }) => (
                <EmailInput
                  label={
                    <span>
                      {commonT('email')}
                      <span tw="text-red-600">*</span>
                    </span>
                  }
                  onChange={field.onChange}
                  variant={errors['email'] ? 'error' : 'primary'}
                  supportText={errors['email']?.message}
                  value={field.value}
                  testId="login-email-input"
                  supportTextTestId="login-email-error"
                />
              )}
            />
          </div>
          <div tw="flex flex-col items-center w-full gap-4 mt-8">
            <Button type="submit" fullwidth={true} loading={loading} icon="ResetIcon" data-cy="login-button">
              <span className="text-black">{t('email_login_enter_email_submit_action_text')}</span>
            </Button>
            <StyledLink
              tw="text-sm leading-5.25 text-center font-medium"
              onClick={() => navigateToPhoneNumberLogin()}
              data-cy="login-with-phone-button"
            >
              {t('email_login_enter_email_redirect_to_phone_number_login')}
            </StyledLink>
            <Separator />
            <div tw="flex flex-col w-full gap-2">
              <GoogleButton {...{ isModal, closeModal: onClose }} />
              <FacebookButton {...{ isModal, closeModal: onClose }} />
            </div>
          </div>
        </form>
      </StyledFormWrapper>

      <div tw="flex flex-row w-full justify-center mt-6 gap-1.75 text-sm leading-5.25 font-medium">
        <p>{t('email_login_enter_email_signup_prompt_text')}</p>
        <StyledLink onClick={() => navigateToSignup()}>
          {t('email_login_enter_email_redirect_to_signup_text')}
        </StyledLink>
      </div>
    </>
  );
};

export const LoginByEmailHeader = ({ isModal = true }: { isModal?: boolean }) => {
  const { t } = useTranslation('auth-flow');
  const { existingAccount } = useEmailLoginUtils({ isModal });
  return (
    <HeaderWrapper isModal={isModal}>
      <AuthTitle isModal={isModal} data-cy="login-by-email-form-heading">
        {existingAccount.email ? (
          t('email_login_enter_email_header_signup_failed')
        ) : isModal ? (
          t('email_login_enter_email_modal_header')
        ) : (
          <span>
            {t('email_login_enter_email_page_header_text_1')}{' '}
            <span tw="text-primary-600">{t('email_login_enter_email_page_header_text_2')}</span>
          </span>
        )}
      </AuthTitle>
      <AuthSubtitle isModal={isModal}>
        {existingAccount.email
          ? t('email_login_enter_email_subheader_signup_failed')
          : isModal
          ? t('email_login_enter_email_modal_subheader')
          : ''}
        {/* : t('email_login_enter_email_page_subheader') */}
      </AuthSubtitle>
    </HeaderWrapper>
  );
};

export const MagicLinkUsedHeader = ({ isModal = true }: { isModal?: boolean }) => {
  const { t } = useTranslation('auth-flow');
  return (
    <HeaderWrapper tw="flex flex-col items-center gap-1.75 w-full" isModal={isModal}>
      <AuthTitle isModal={isModal}>{t('email_login_magic_link_used_header')}</AuthTitle>
      <AuthSubtitle isModal={isModal}>{t('email_login_magic_link_used_subheader')}</AuthSubtitle>
    </HeaderWrapper>
  );
};

export const MagicLinkExpired = ({ isModal = true }: { isModal?: boolean }) => {
  const { t } = useTranslation('auth-flow');
  return (
    <HeaderWrapper tw="flex flex-col items-center gap-1.75 w-full" isModal={isModal}>
      <AuthTitle isModal={isModal}> {t('email_login_magic_link_expired_header')}</AuthTitle>
      <AuthSubtitle isModal={isModal}>{t('email_login_magic_link_expired_subheader')}</AuthSubtitle>
    </HeaderWrapper>
  );
};

const StyledLink = styled.p(() => [tw`text-primary-900 cursor-pointer`]);

const StyledFormWrapper = styled.div(({ isModal = true }: { isModal?: boolean }) => [
  tw`flex flex-col w-full`,
  isModal && tw`box-border bg-primary-50 rounded-3.75 p-4 mt-4`,
]);
