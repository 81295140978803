import { FunctionComponent } from 'react';
import { useTranslation } from 'next-i18next';
import 'twin.macro';

const JobAdInformativeText: FunctionComponent = () => {
  const { t } = useTranslation('job-position-page');
  return <p tw="font-normal text-gray-500">{t('job_ad_informative_text')}</p>;
};

// const JobAdInformativeTextDesktop: FunctionComponent = () => (
//   <span tw="hidden md:block">
//     <JobAdInformativeText />
//   </span>
// );

// const JobAdInformativeTextMobile: FunctionComponent = () => (
//   <span tw="md:hidden">
//     <JobAdInformativeText />
//   </span>
// );

export { JobAdInformativeText };
