import * as React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { Button } from '@restworld/ui-ds';
import { useAuth } from '@restworld/feature-authentication';
import { useResponsiveBreakPoints } from '@restworld/utils-common';

interface ISaveResearchButtonProps {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const SaveResearchButton: React.FunctionComponent<ISaveResearchButtonProps> = ({ onClick }) => {
  const { user } = useAuth();
  const { isDesktop } = useResponsiveBreakPoints();
  const { t } = useTranslation('common');
  return (
    <Button
      icon="BellAlertIcon"
      title={!isDesktop && user?.type === 'ambassador' ? '' : t('save')}
      variant="black-outline"
      onClick={onClick}
      withLabelUnderneathIcon={user?.type === 'ambassador'}
      twStyle={tw`max-h-[48px]`}
      testId="save-research-button"
    />
  );
};

export default SaveResearchButton;
