import { useAtomValue } from 'jotai';

import { JobSimilarPositionsType } from '@restworld/utils-common';
import { JobPosition } from '@restworld/utility-types';
import { JobExpireInformation } from '@restworld/ui-ds';

import { jobActiveStatusAtom } from '../job-position/job-position.atoms';

export const JobStatusInfo = ({
  jobPositionApiData,
  similarJobPositions,
}: {
  jobPositionApiData: Partial<JobPosition>;
  similarJobPositions: JobSimilarPositionsType[];
}) => {
  const jobActiveStatus = useAtomValue(jobActiveStatusAtom);

  const restaurantSlug = jobPositionApiData?.restaurant?.slug || jobPositionApiData?.restaurant?.id;
  const showRestaurantDetails = jobPositionApiData?.restaurant?.show_details || false;

  if (!jobActiveStatus) {
    return (
      <JobExpireInformation
        similarJobPositions={similarJobPositions}
        restaurantSlug={restaurantSlug}
        showRestaurantDetails={showRestaurantDetails as boolean}
      />
    );
  }
  return null;
};
