import { MouseEvent, useCallback, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue } from 'jotai';

import { ModalWrapperCloseIcon, useModal, useResponsiveBreakPoints } from '@restworld/utils-common';
import { ImageGallery, Button, Title, BaseModal, ButtonProps } from '@restworld/ui-ds';

import {
  CompanyInvolvementCard,
  CompanyStatistics,
  CompanyServiceDetails,
  CompanyRating,
  RestaurantWorkingHours,
  RestaurantWorkingHoursProps,
  RestaurantHours,
} from './job.company.components';
import { companyProfileAtom, fullRatingAtom, fullViewAtom } from '../job-position/job-position.atoms';

type TCompanyProfileContent = {
  buttonVariant?: ButtonProps['variant'];
  buttonTitle?: string;
  buttonIcon?: 'BuildingOfficePurpleIcon' | 'OfficeBuildingIcon';
};

const CompanyProfileDrawer = ({ buttonTitle, buttonVariant = 'custom', buttonIcon }: TCompanyProfileContent) => {
  const { images, restaurantName } = useAtomValue(companyProfileAtom);
  const [fullView, setFullView] = useAtom(fullViewAtom);
  const [fullRating, setFullRating] = useAtom(fullRatingAtom);
  const { open, close, show } = useModal();
  const { isDesktop } = useResponsiveBreakPoints();
  const { t } = useTranslation('common');

  const imageClickHandler = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setFullView(true);
    },
    [setFullView]
  );

  const clickHandler = useCallback(() => {
    setFullView(false);
    setFullRating(false);
  }, [setFullRating, setFullView]);

  const renderEl = useMemo(() => {
    if (fullRating) return <CompanyRating type="list" />;
    if (fullView) return <ImageGallery type="full" images={images} />;
    return (
      <div tw="flex flex-col gap-5">
        <CompanyInvolvementCard />
        {images?.length ? <ImageGallery type="half" images={images} onClick={imageClickHandler} /> : null}
        <CompanyStatistics />
        <CompanyRatingDetailsWrapper>
          <CompanyRating type="single" />
          <CompanyServiceDetails />
        </CompanyRatingDetailsWrapper>
      </div>
    );
  }, [fullRating, fullView, imageClickHandler, images]);

  return (
    <>
      <ButtonWrapper>
        <Button
          onClick={show ? close : open}
          variant={buttonVariant}
          title={buttonTitle ?? t('company')}
          icon={buttonIcon}
          suffixIcon={!buttonTitle?.includes('Scopri di') ? 'ChevronRightIcon' : undefined}
        />
      </ButtonWrapper>

      <BaseModal
        open={show}
        onClose={close}
        modalStyles={[tw`max-w-[100vw] lg:(max-w-lg min-w-[28rem] max-h-full) `]}
        showMobileGestures={true}
        variant="right"
        title={
          isDesktop ? (
            <Title content={t('company')} icon="BuildingOfficePurpleIcon" fontSize="extraLarge" isCenter />
          ) : undefined
        }
        closeIcon={<ModalWrapperCloseIcon />}
      >
        <ModalWrapperHeader
          title={<Title content={t('company')} icon="BuildingOfficePurpleIcon" fontSize="extraLarge" isCenter />}
          hide={!isDesktop}
        />
        <StyledBody>
          <div tw="flex">
            {(fullView || fullRating) && <Button icon="ChevronLeftIcon" onClick={clickHandler} variant="ghost" />}
            <div tw="w-full">
              {restaurantName && <Title content={restaurantName} fontSize="extraLarge" isCenter fontWeight="medium" />}
            </div>
          </div>
          <div tw="box-border flex flex-col lg:max-w-[100%]">{renderEl}</div>
        </StyledBody>
      </BaseModal>
    </>
  );
};

const ModalWrapperHeader = ({ title, hide = true }: { title: React.ReactNode; hide: boolean }) => {
  return hide ? (
    <div tw="flex  flex-row lg:justify-start  justify-center lg:gap-12 lg:mb-6 mb-3 items-center w-full">{title}</div>
  ) : null;
};

const CompanyRatingDetailsWrapper = tw.div`flex flex-col gap-5`;
const ButtonWrapper = styled.div`
  ${tw`w-full text-center [> button]:w-full`}
`;

const StyledBody = tw.div`flex flex-col gap-4 pb-[36px] `;

export { CompanyProfileDrawer, RestaurantWorkingHours, RestaurantHours };
export type { RestaurantWorkingHoursProps };
