import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
var tagManagerArgs = {
  gtmId: process.env['NEXT_PUBLIC_GA'] || ''
};

var init = function init() {
  return TagManager.initialize(tagManagerArgs);
};

var sendGAEvent = function sendGAEvent(optionsOrName) {
  return TagManager.initialize(_objectSpread(_objectSpread({}, tagManagerArgs), {}, {
    events: {
      sendUserInfo: optionsOrName
    }
  }));
};

var useGoogleAnalytics = function useGoogleAnalytics() {
  useEffect(function () {
    init();
  }, []);
};

export { useGoogleAnalytics, sendGAEvent };