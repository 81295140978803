import { useWorkerJobPositionsService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';
import { JobApplicationStatusKey } from '@restworld/utility-types';
import { useCallback, useState } from 'react';
export type StatusUpdatePayload = {
  restaurantId?: string;
  jobPositionId?: string;
  updatedStatus: JobApplicationStatusKey;
};

export function useUpdateWorkerStatus() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const workerJobPositionService = useWorkerJobPositionsService();
  const handleJobApplicationStatusUpdate = useCallback(
    async ({ restaurantId, jobPositionId, updatedStatus }: StatusUpdatePayload) => {
      setIsLoading(true);
      try {
        await workerJobPositionService.updateJobApplication({
          workerId: user?.id,
          params: {
            status_key: updatedStatus,
          },
          restaurantId,
          jobPositionId,
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Error updating job application for status key', error);
        setIsLoading(false);
      }
    },
    [user?.id, workerJobPositionService]
  );
  return { isLoading, handleJobApplicationStatusUpdate };
}
