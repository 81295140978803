globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { format, differenceInYears, differenceInHours, differenceInMonths, formatDistanceToNow, parseISO } from 'date-fns';
import it from 'date-fns/locale/it';
export var dateFormat = "dd MMM yyyy";
export var formatDate = function formatDate(date) {
  return format(new Date(date), dateFormat, {
    locale: it
  });
};
export var toDateFormat = function toDateFormat(date, newDateFormat) {
  return format(new Date(date), newDateFormat || dateFormat, {
    locale: it
  });
};
export var genericDateFormat = 'yyyy-MM-dd';
export var getYearsElapsed = function getYearsElapsed(date) {
  var today = new Date();
  var birthDate = new Date(date);
  return differenceInYears(today, birthDate);
};
export var getMonthsElapsed = function getMonthsElapsed(date) {
  var today = new Date();
  var comparison = new Date(date);
  return differenceInMonths(today, comparison);
};
export var getHoursElapsed = function getHoursElapsed(date) {
  var today = new Date();
  var comparison = new Date(date);
  return differenceInHours(today, comparison);
};
export var getTimeElapsed = function getTimeElapsed(date) {
  return formatDistanceToNow(new Date(date), {
    locale: it
  });
};
export var formatDateTo = function formatDateTo(date, dateFormatString) {
  return format(new Date(date), dateFormatString, {
    locale: it
  });
};
/**
 * Formats an ISO date string into 'dd/MM/yy' format.
 *
 * @param {string} [dateStr] - The ISO date string to format.
 * @returns {string} - The formatted date, or an empty string if input is invalid.
 */

export function formatDateToDayMonthYear(dateStr) {
  if (!dateStr) return '';
  var date = parseISO(dateStr);
  return format(date, 'dd/MM/yy');
}