import { FieldValues } from 'react-hook-form';
import { atom } from 'jotai';

import { FilterContextValuesType, URLDataType } from './job-explorer.types';

type FiltersType = {
  moreFilters?: FieldValues;
  isResetFilter?: boolean;
  filterContextValues?: FilterContextValuesType;
  searchBarUrlParams?: URLDataType;
};

const defaultValues: FiltersType = {
  moreFilters: {},
  filterContextValues: {},
  isResetFilter: false,
  searchBarUrlParams: {
    isSearchBarUrlParamsAvailable: false,
  },
};

export const moreFiltersAtom = atom<FiltersType['moreFilters']>(defaultValues.moreFilters);
moreFiltersAtom.debugLabel = 'moreFiltersAtom';

export const isResetFilterAtom = atom<FiltersType['isResetFilter']>(defaultValues.isResetFilter);

export const filterContextValuesAtom = atom<FiltersType['filterContextValues']>(defaultValues.filterContextValues);
filterContextValuesAtom.debugLabel = 'filterContextValuesAtom';

export const searchBarUrlParamsAtom = atom<FiltersType['searchBarUrlParams']>(defaultValues.searchBarUrlParams);
searchBarUrlParamsAtom.debugLabel = 'searchBarUrlParamsAtom';
