import 'twin.macro';
import { useAtomValue } from 'jotai';
import dynamic from 'next/dynamic';

import { JobExplorerMap } from './job-explorer-map/job-explorer-map';
import { ListHeadTitle } from './job-explorer-content';
import JobExplorerSidebar from './job-explorer-sidebar';
import ExplorerFilters from './job-explorer-filters';
import { hasMapViewAtom } from './job-explorer-list.atoms';

const JobExplorerMapDrawer = dynamic(() => import('./job-explorer-mobile-drawer'), { ssr: false });

export const MobileDrawerElement = ({ ambassadorCode }: { ambassadorCode?: string }) => {
  const hasMapView = useAtomValue(hasMapViewAtom);
  const explorerFilters = <ExplorerFilters testId="explorer-filters-mobile" />;
  return (
    <div tw="lg:hidden h-full" id="mobile-drawer-element">
      {hasMapView ? (
        <div data-cy="explorer-map-with-list" tw="flex flex-col gap-4">
          <div tw="px-4">{explorerFilters}</div>
          <JobExplorerMap testId="job-explorer-map-mobile" key="map-with-list" isDesktop={false} />
        </div>
      ) : (
        <div tw="flex flex-col gap-4 px-4">
          {explorerFilters}
          <ListHeadTitle />
          <JobExplorerSidebar ambassadorCode={ambassadorCode} testId="explorer-sidebar-mobile" />
        </div>
      )}
      <JobExplorerMapDrawer />
    </div>
  );
};

export default MobileDrawerElement;
