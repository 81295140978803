globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var defaultSEO = {
  title: 'Restworld',
  titleTemplate: '%s | Restworld',
  description: 'Restworld',
  twitter: {
    cardType: 'summary_large_image'
  },
  openGraph: {
    type: 'website',
    images: [{
      url: '/og_restworld.png'
    }]
  }
};