import { Controller, useFormContext } from 'react-hook-form';
import tw, { theme } from 'twin.macro';
import { ToggleSwitch } from 'flowbite-react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { useAuth } from '@restworld/feature-authentication';
import { InputSlider, Title } from '@restworld/ui-ds';

import {
  StepWrapper,
  SaveResearchFormFields,
  toggleAddressEditViewAtom,
  recordJobExplorerModalAtom,
} from '../job-explorer-save-research-v2';
import AddressEditView from './address-edit-view';
import { SaveResearchMap } from './save-research-map';
import SaveResearchAddressInput from './research-address-input';

export const AddressViewAndSelection = () => {
  const [addressEditView, toggleAddressEditView] = useAtom(toggleAddressEditViewAtom);

  return addressEditView ? <AddressEditView callback={() => toggleAddressEditView(false)} /> : <MainAddressView />;
};

export default AddressViewAndSelection;

const MainAddressView = () => {
  return (
    <StepWrapper>
      <div data-cy="main-address-view" tw="flex flex-col gap-8">
        <AddressTitle />
        <SaveResearchMap />
        <AddressInputSection />
        <SearchRadiusSection />
        <SeasonalFilter />
      </div>
    </StepWrapper>
  );
};

const AddressTitle = () => {
  const { isAuthenticated } = useAuth();
  const recordJobExplorerModal = useAtomValue(recordJobExplorerModalAtom);
  const { t } = useTranslation();

  return (
    <div tw="flex gap-2 flex-col">
      <Title
        content={
          !isAuthenticated || recordJobExplorerModal
            ? t('job-explorer-page:dont_miss_a_single_job_position')
            : t('job-explorer-page:enter_the_location_of_interest_for_the_search')
        }
        fontWeight="semibold"
        fontSize="superLarge"
        twStyle={tw`leading-[28.8px]`}
        testId="address-select-title"
      />
      <Title
        content={t('job-explorer-page:enter_a_specific_address_or_city')}
        fontWeight="normal"
        fontSize="base"
        twStyle={tw`leading-[24px]`}
        textColor="gray-500"
      />
    </div>
  );
};

const AddressInputSection = () => {
  const [, toggleAddressEditView] = useAtom(toggleAddressEditViewAtom);
  const { watch, getValues } = useFormContext<SaveResearchFormFields>();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  return (
    <div tw="flex flex-col gap-2">
      <Title
        content={t('job-explorer-page:reference_location')}
        fontSize="base"
        fontWeight="normal"
        twStyle={tw`leading-[24px]`}
      />
      {isAuthenticated ? (
        <>
          {getValues('address.address') ? (
            <Title content={getValues('address.address')} fontSize="base" textColor="gray-600" icon="HomeIcon" />
          ) : null}
          <a href="#!" tw="text-primary underline text-sm font-semibold" onClick={() => toggleAddressEditView(true)}>
            {t('common:edit')}
          </a>
        </>
      ) : (
        <>
          <SaveResearchAddressInput />
          {!watch('address') && (
            <Title
              content={t('job-explorer-page:will_use_this_information_to_define_the_search_area')}
              fontSize="small"
              fontWeight="normal"
              twStyle={tw`leading-[21px]`}
              textColor="gray-500"
            />
          )}
        </>
      )}
    </div>
  );
};

const SearchRadiusSection = () => {
  const { watch, control } = useFormContext<SaveResearchFormFields>();
  const addressDistance = watch('area_distance');
  const { t } = useTranslation();
  const addressValue = watch('address');
  return (
    <div
      className={`flex flex-col gap-2 ${
        !addressValue ? 'cursor-not-allowed saturate-25 opacity-50 pointer-events-none' : ''
      }`}
    >
      <div tw="flex gap-0 flex-row">
        <Title
          content={t('job-explorer-page:search_radius')}
          fontSize="base"
          fontWeight="normal"
          twStyle={tw`leading-[24px]`}
        />
        <Title content="*" fontSize="base" fontWeight="normal" twStyle={tw`leading-[24px]`} textColor="red" />
      </div>
      {addressValue ? (
        <div tw="flex justify-between items-center">
          <Title
            content={addressValue?.address ? `Da ${addressValue?.address}` : 'Da -'}
            fontSize="small"
            fontWeight="normal"
            twStyle={tw`leading-[21px] w-[15rem] truncate`}
            textColor="gray-600"
          />
          <Title
            content={addressDistance?.toString() + 'km'}
            fontSize="base"
            fontWeight="semibold"
            textColor="bold-purple"
            twStyle={tw`leading-[24px]`}
          />
        </div>
      ) : null}
      <Controller
        name="area_distance"
        control={control}
        render={({ field }) => (
          <InputSlider
            data-cy="save-research-area-distance-slider"
            disabled={!addressValue}
            trackStyle={[
              {
                backgroundColor: theme`colors.primary.800`,
                height: 8,
                borderRadius: 4,
              },
            ]}
            handleStyle={[
              {
                backgroundColor: theme`colors.white`,
                height: 20,
                width: 20,
                boxShadow: theme`boxShadow.DEFAULT`,
                borderColor: theme`colors.gray.200`,
                borderWidth: 2,
                opacity: 1,
              },
            ]}
            railStyle={{
              backgroundColor: theme`colors.gray.200`,
              height: 8,
              borderRadius: 4,
            }}
            {...{ min: 0, max: 100, step: 2 }}
            {...field}
          />
        )}
      />
    </div>
  );
};

export const SeasonalFilter = () => {
  const { control } = useFormContext<SaveResearchFormFields>();
  const { t } = useTranslation('job-explorer-page');
  return (
    <div tw="flex justify-between items-center">
      <Title content={t('work_with_accommodation')} />
      <Controller
        name="seasonal"
        control={control}
        render={({ field }) => (
          <ToggleSwitch
            data-cy="save-research-seasonal-filter"
            color="purple"
            label=""
            checked={Boolean(field.value)}
            {...field}
          />
        )}
      />
    </div>
  );
};
