import * as React from 'react';
import { useState, MouseEvent } from 'react';
import tw, { styled } from 'twin.macro';
import { StarIcon } from '@heroicons/react/24/outline';
import AliceCarousel from 'react-alice-carousel';
import { useAtomValue } from 'jotai';

import { ratingNumberRange } from '@restworld/utils-common';
import { CardReview } from '@restworld/ui-ds';

import { companyProfileAtom } from '../../job-position/job-position.atoms';

export const CompanyRatingList: React.FunctionComponent = () => {
  const { reviewFilterStars, reviewCard } = useCompanyRatingList();
  return (
    <Wrapper>
      {reviewFilterStars}
      {reviewCard}
    </Wrapper>
  );
};
export default CompanyRatingList;

const useCompanyRatingList = () => {
  const { reviews = [] } = useAtomValue(companyProfileAtom);
  const [reviewFilter, setReviewFilter] = useState<number | null>(null);
  const reviewFilterHandler = React.useCallback(
    (e: MouseEvent<HTMLButtonElement>, reviewNumber: number) => {
      e.preventDefault();
      setReviewFilter(reviewNumber !== reviewFilter ? reviewNumber : null);
    },
    [reviewFilter]
  );
  const reviewFilterStars = React.useMemo(() => {
    const items = [...ratingNumberRange].reverse().map((v) => (
      <StarButtonStyled isSelected={reviewFilter === v} key={v} onClick={(e) => reviewFilterHandler(e, v)}>
        <StarIconStyled />
        <RatingNumber>{v}</RatingNumber>
      </StarButtonStyled>
    ));
    return (
      <ReviewFilterWrapper>
        <AliceCarousel
          mouseTracking
          touchTracking
          disableButtonsControls
          disableDotsControls
          items={items}
          controlsStrategy="responsive"
          responsive={{ 0: { items: 4.1, itemsFit: 'fill' } }}
        />
      </ReviewFilterWrapper>
    );
  }, [reviewFilter, reviewFilterHandler]);

  const reviewCard = React.useMemo(
    () => (
      <ReviewListWrapper>
        {reviews
          .filter((v) => (reviewFilter ? reviewFilter === v.rating : true))
          .map((rating, i) => (
            <CardReview key={i} {...rating} />
          ))}
      </ReviewListWrapper>
    ),
    [reviewFilter, reviews]
  );
  return {
    reviewFilterStars,
    reviewCard,
  };
};
const ReviewFilterWrapper = tw.div`flex flex-col w-full max-w-screen-xs lg:max-w-[340px]`;
const ReviewListWrapper = tw.div`flex flex-col gap-4 mb-[36px]`;
const RatingNumber = tw.span``;
const StarButtonStyled = styled.button<{ isSelected: boolean }>`
  ${tw`flex border text-gray-400 border-gray-400 gap-2 justify-center rounded-lg w-20 h-10 px-2 items-center`}
  ${({ isSelected }) => isSelected && tw`border-yellow-300`}
`;
const StarIconStyled = tw(StarIcon)`h-4`;
const Wrapper = tw.div`flex box-border overflow-hidden flex-col gap-6`;
