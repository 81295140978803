import { useMemo } from 'react';

import { JobPosition } from '@restworld/utility-types';

import { getSalary } from './use-salary';
export const useJobPositionDetails = ({ jobPositionApiData }: { jobPositionApiData: Partial<JobPosition> }) => {
  const {
    days_required_per_week_number,
    shift_category_var,
    occupation,
    restaurant,
    contract_type_var,
    benefits,
    season_starts_at,
    season_ends_at,
    salary_month_number,
    id,
  } = jobPositionApiData || {};

  const salary = getSalary({ jobPositionApiData });
  const seasonalAccommodation = useMemo(() => {
    if (!benefits) return false;
    return !!benefits.find((benefit) => benefit?.benefit_var.key === 'seasonal_accommodation');
  }, [benefits]);

  const contractTerm = useMemo(() => {
    if (!contract_type_var) return '';

    let contractLabel = contract_type_var.label;
    if (season_starts_at) {
      contractLabel = contractLabel.concat(` da ${season_starts_at}`);
    }
    if (!!season_starts_at && !!season_ends_at) {
      contractLabel = contractLabel.concat(` a ${season_ends_at}`);
    }
    if (!season_starts_at && !!season_ends_at) {
      contractLabel = contractLabel.concat(` fino a ${season_ends_at}`);
    }
    return contractLabel;
  }, [contract_type_var, season_starts_at, season_ends_at]);

  const shiftsSubTitle = days_required_per_week_number
    ? `${days_required_per_week_number} giorni a settimana`
    : `Giorni da definire`;

  const shiftsTitle = shift_category_var?.label || 'Turni';

  const shifts = {
    title: shiftsTitle,
    subTitle: shiftsSubTitle,
  };

  const jobDetailBlockApiProps = {
    jobPositionDetails: {
      companyName: restaurant?.restaurant_name,
      companyLogo: restaurant?.restaurant_logo,
      title: occupation?.label_it.label,
      jobLocation: restaurant?.address,
      jobLocality: restaurant?.locality,
      salary,
      contractTerm,
      shifts,
      seasonalAccommodation,
      salaryMonthNumber: salary_month_number,
      id,
    },
    showRestaurant: jobPositionApiData?.show_restaurant_details,
  };

  return jobDetailBlockApiProps;
};
