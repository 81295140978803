globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var exploreRestaurantsGraphqlQuery = "\n    query explore_restaurants (\n        $limit: Int!,\n        $offset: Int!,\n        $lat: Float,\n        $lon: Float,\n        $radius: Float\n    ) {\n        explore_restaurants (\n            limit: $limit,\n            offset: $offset,\n            lat: $lat,\n            lon: $lon,\n            radius: $radius\n        ) {\n            data {\n                restaurant {\n                    id,\n                    restaurant_name,\n                    address,\n                    restaurant_logo,\n                    slug,\n                    inserted_at,\n                    show_details,\n                    pictures {\n                        url\n                    },\n                    gmaps_info {\n                        info {\n                            rating,\n                            user_ratings_total\n                        }\n                    }\n                }\n            },\n            hits\n        }\n    }\n";