import { useMemo, memo, useCallback } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { useAtom, useAtomValue } from 'jotai';

import { JobPosition } from '@restworld/utility-types';
import { JobPositionCard, JobPositionCardProps } from '@restworld/ui-ds';
import { useAuth } from '@restworld/feature-authentication';
import { useAnalytics } from '@restworld/utils-common';

import { PaginationButton } from './show-more-button';
import { explorerRestaurantListAtom, isRestaurantListAvailableAtom } from '../job-explorer-map';
import DataNotAvailableUI from './data-not-available-ui';
import { isRestaurantsExplorerAtom } from '../job-explorer-map';
import {
  activeJpIdAtom,
  jobLocationPositionListAtom,
  jobPositionTotalCountAtom,
  hasMapViewAtom,
  jobListParamsAtom,
} from '../job-explorer-list.atoms';
import { checkIfJobPositionIsOutsideItaly } from '../../../utils';

export const JobExplorerSidebar = ({ ambassadorCode, testId }: { ambassadorCode?: string; testId?: string }) => {
  const hasMapView = useAtomValue(hasMapViewAtom);
  const jobPositionTotalCount = useAtomValue(jobPositionTotalCountAtom);
  const jobLocationPositionList = useAtomValue(jobLocationPositionListAtom);

  const activeJpId = useAtomValue(activeJpIdAtom);
  const [isRestaurantListAvailable] = useAtom(isRestaurantListAvailableAtom);
  const [isRestaurantExplorer] = useAtom(isRestaurantsExplorerAtom);
  const explorerRestaurantList = useAtomValue(explorerRestaurantListAtom);

  const isJobPositionListAvailable = useMemo(() => jobLocationPositionList?.length > 0, [jobLocationPositionList]);

  const restaurantExplorerSidebarList = useMemo(
    () =>
      isRestaurantListAvailable ? (
        <>
          <JobExplorerListWrapper
            hasMapView={hasMapView}
            isListEmpty={!isRestaurantListAvailable}
            isRestaurantExplorer={isRestaurantExplorer}
            data-cy="restaurant-explorer-list"
          >
            {[...explorerRestaurantList].map(
              ({ id, restaurant_name, restaurant_logo, address, slug, inserted_at, show_details }) => (
                <li tw="w-full cursor-pointer md:max-w-sm" key={id} id={id}>
                  <JobPositionCard
                    {...{
                      restaurantName: restaurant_name,
                      city: address,
                      restaurantLogo: restaurant_logo,
                      redirectUrl: `/locale/${slug}`,
                      publishedAt: inserted_at,
                      isActive: activeJpId === id,
                      ambassadorCode,
                      cardVariant: 'restaurant',
                      showRestaurantDetails: show_details || show_details === true ? true : false,
                      restaurantAddress: address,
                    }}
                  />
                </li>
              )
            )}
          </JobExplorerListWrapper>
          <div tw="flex mx-auto px-1 items-center max-w-lg pb-2">
            <PaginationButton />
          </div>
        </>
      ) : (
        <DataNotAvailableUI />
      ),
    [activeJpId, ambassadorCode, explorerRestaurantList, hasMapView, isRestaurantExplorer, isRestaurantListAvailable]
  );

  const jobPositionExplorerSidebarList = useMemo(() => {
    if (jobPositionTotalCount === 0) return <DataNotAvailableUI />;
    return (
      <>
        <JobExplorerListWrapper
          hasMapView={hasMapView}
          isListEmpty={!isJobPositionListAvailable}
          isRestaurantExplorer={isRestaurantExplorer}
          data-cy="job-position-explorer-list"
        >
          {jobLocationPositionList?.map((jobPosition) => {
            return (
              <li tw="w-full cursor-pointer md:max-w-[22rem] lg:max-w-sm" key={jobPosition?.id} id={jobPosition?.id}>
                <ExplorerCardView
                  {...{
                    data: jobPosition,
                    isActive: activeJpId === jobPosition?.id,
                    ambassadorCode,
                  }}
                />
              </li>
            );
          })}
        </JobExplorerListWrapper>
        <div tw="flex mx-auto px-1 items-center max-w-lg pb-2">
          <PaginationButton />
        </div>
      </>
    );
  }, [
    activeJpId,
    ambassadorCode,
    hasMapView,
    isJobPositionListAvailable,
    isRestaurantExplorer,
    jobLocationPositionList,
    jobPositionTotalCount,
  ]);
  const explorerList = useMemo(() => {
    if (isRestaurantExplorer) return restaurantExplorerSidebarList;
    return jobPositionExplorerSidebarList;
  }, [isRestaurantExplorer, restaurantExplorerSidebarList, jobPositionExplorerSidebarList]);

  return <div data-cy={testId}>{explorerList}</div>;
};
export default JobExplorerSidebar;
export const ExplorerCardView = memo(({ ambassadorCode, ...rest }: JobPositionCardType) => {
  const { jobPositionProps } = useJobPositionCard({ ...rest });
  const auth = useAuth();
  const { trackEvent } = useAnalytics();
  const sortFilterParams = useAtomValue(jobListParamsAtom);

  const isJobPositionOutsideItaly = checkIfJobPositionIsOutsideItaly({
    address: jobPositionProps?.address,
    country: jobPositionProps?.country,
  });
  const recordViewJobPositionEvent = useCallback(
    (jobProps: JobPositionCardProps) => {
      trackEvent('view_job', {
        email: auth?.user?.email,
        role: auth?.user?.type,
        origin: 'job_explorer_list_view',
        id: jobProps?.id,
        job_title: jobProps?.jobTitle,
        occupation: jobProps?.occupation,
        salary_min: jobProps?.salaryMin,
        salary_max: jobProps?.salaryMax,
        job_type: jobProps?.jobType,
        published_at: jobProps?.publishedAt,
        restaurant_name: jobProps?.restaurantName,
        city: jobProps?.city,
        searchParams: sortFilterParams,
      });
    },
    [auth?.user?.email, auth?.user?.type, sortFilterParams, trackEvent]
  );
  return (
    <JobPositionCard
      {...{
        ...jobPositionProps,
        ambassadorCode,
        onLinkClick: () => {
          recordViewJobPositionEvent(jobPositionProps);
        },
        isJobPositionOutsideItaly,
      }}
    />
  );
});
const useJobPositionCard = ({ data, isActive }: JobPositionCardType) => {
  const {
    published_at,
    restaurant: {
      locality = '',
      restaurant_name: restaurantName = '',
      restaurant_logo = '',
      country = '',
      address = '',
      address_lat = 0,
      address_lon = 0,
    } = {},
    job_title,
    max_salary,
    min_salary,
    salary,
    job_type_var,
    occupation,
    semantic_unique_name: jobPositionId,
    id,
    already_applied,
  } = data || {};
  const jobPositionProps = useMemo(
    () => ({
      publishedAt: published_at,
      city: locality,
      jobTitle: job_title,
      jobType: job_type_var?.label,
      occupation: occupation?.label_it.label,
      salaryMin: min_salary,
      salaryMax: max_salary,
      salary: salary?.toString(),
      restaurantName,
      showRestaurantDetails: data?.show_restaurant_details,
      restaurantLogo: restaurant_logo,
      redirectUrl: jobPositionId ? `/posizione/${jobPositionId}` : '',
      key: id,
      isActive,
      hasAlreadyApplied: already_applied,
      country,
      address,
      address_lat,
      address_lon,
    }),
    [
      address,
      already_applied,
      country,
      data?.show_restaurant_details,
      id,
      isActive,
      jobPositionId,
      job_title,
      job_type_var?.label,
      locality,
      max_salary,
      min_salary,
      occupation?.label_it.label,
      published_at,
      restaurantName,
      restaurant_logo,
      salary,
      address_lon,
      address_lat,
    ]
  );
  return { jobPositionProps };
};
const JobExplorerListWrapper = styled.ul<{
  hasMapView?: boolean;
  isListEmpty?: boolean;
  isRestaurantExplorer?: boolean;
}>`
  ${tw`flex flex-col gap-4 h-full items-center md:(flex-row flex-wrap) lg:(flex-col flex-nowrap) pb-2`};
  ${tw`lg:(h-[calc(100vh - 14.9rem)]) overflow-y-auto overflow-x-hidden`}
  ${({ hasMapView }) => (hasMapView ? tw`pr-2` : tw`pr-0`)}
  ${({ hasMapView }) => (!hasMapView ? tw`h-full lg:(h-[calc(100vh - 22.4rem)])` : tw``)}
  ${({ hasMapView }) => (!hasMapView ? tw`lg:(flex-row flex-wrap h-full justify-center items-center)` : tw``)}
  // ${({ isRestaurantExplorer }) => (isRestaurantExplorer ? tw`lg:(h-[calc(100vh - 16.4rem)])` : tw``)}
  ${({ isListEmpty }) => (isListEmpty ? tw`md:h-full` : tw``)}

  /* scroll styles */
  /* For WebKit (Safari, Chrome, Opera) */
  ::-webkit-scrollbar {
    ${tw`w-1`}/* Width of the scrollbar */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme`colors.gray.200`}; /* Color of the thumb */
    ${tw`rounded`}/* Rounded corners */
  }
  /* For Firefox */
  * {
    scrollbar-width: thin; /* "thin" or "auto" */
    scrollbar-color: ${theme`colors.gray.200`};
  }
  /* For MS Edge */
  *::-ms-scrollbar {
    ${tw`w-1`}
  }
  *::-ms-scrollbar-thumb {
    background-color: ${theme`colors.gray.200`}; /* Color of the thumb */
    ${tw`rounded`}/* Rounded corners */
  }
`;
type JobPositionCardType = {
  data: Partial<JobPosition>;
  isActive?: boolean;
  ambassadorCode?: string;
};
