import { useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import {
  ContentType as ModalContentType,
  activeContent as activeModalContent,
  openAuthFlowModal,
  openEmailLoginModal,
  openSignupModal,
  setCurrentUrl,
} from '../context/auth-flow';
import { useAuthContext } from '@restworld/feature-authentication';
import { useAnalytics } from '@restworld/utils-common';

export const useLoginSignupPopup = () => {
  const router = useRouter();
  const onOpenEmailLoginModal = useSetAtom(openEmailLoginModal);
  const onOpenSignupModal = useSetAtom(openSignupModal);
  const onSetCurrentUrl = useSetAtom(setCurrentUrl);
  const setContent = useSetAtom(activeModalContent);
  const openModal = useSetAtom(openAuthFlowModal);
  const { logout } = useAuthContext();
  const currentUrl = router?.asPath;
  // const { trackEvent } = useAnalytics();

  const onStartLogin = useCallback(() => {
    setContent(ModalContentType.EMAIL_LOGIN);
    openModal();
    onOpenEmailLoginModal();
    onSetCurrentUrl(currentUrl);
    // trackEvent('Auth flow', { phase: 'Email login', step: 'Start email login', mode: 'modal' });
    // }, [onOpenEmailLoginModal, onSetCurrentUrl, openModal, currentUrl, setContent, trackEvent]);
  }, [onOpenEmailLoginModal, onSetCurrentUrl, openModal, currentUrl, setContent]);

  const onStartSignup = useCallback(() => {
    setContent(ModalContentType.SIGNUP);
    openModal();
    onOpenSignupModal();
    onSetCurrentUrl(currentUrl);
    // trackEvent('Auth flow', { phase: 'Signup', step: 'Start signup', mode: 'modal' });
    // }, [onOpenSignupModal, onSetCurrentUrl, openModal, currentUrl, setContent, trackEvent]);
  }, [onOpenSignupModal, onSetCurrentUrl, openModal, currentUrl, setContent]);
  const onLogout = () => {
    if (logout) {
      logout();
    }
  };
  return {
    onStartLogin,
    onStartSignup,
    onLogout,
  };
};
