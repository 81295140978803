import * as React from 'react';
import tw from 'twin.macro';
import { useFormContext } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { useWizard } from 'react-use-wizard';
import { TwStyle } from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { Title } from '@restworld/ui-ds';
import { OptionsType, useOccupationsByDomainInterest } from '@restworld/utils-common';

import { domainInterests as domainInterestsStatic } from '../../../job-explorer-filters';
import { ChipCheckboxFormField } from '../../../job-explorer-more-filter/job-explorere-more-filter.components';
import { StepWrapper, SaveResearchFormFields, areaSelectionAtom } from '../job-explorer-save-research-v2';
import { SeasonalFilter } from './address-view-and-selection';
import { domainInterestAtom } from '../../../job-explorer-server.atoms';

export const FilterSelection = () => {
  const domainInterest = useAtomValue(domainInterestAtom);
  const { watch } = useFormContext<SaveResearchFormFields>();
  const selectedDomainInterests: string[] = watch('domain_interest');
  const sortedDmOptions: OptionsType[] = React.useMemo(
    () =>
      domainInterestsStatic.map((item) => ({
        ...item,
        id: domainInterest?.find(({ key }) => key === item.key)?.id,
      })),
    [domainInterest]
  );
  const { activeStep } = useWizard();
  const areaSelection = useAtomValue(areaSelectionAtom);
  const { t } = useTranslation('common');
  return (
    <StepWrapper>
      <div data-cy="save-research-filters" tw="flex flex-col gap-8">
        <div tw="flex flex-col gap-2">
          <Title
            content={t('tell_us_what_you_are_looking_for')}
            fontWeight="semibold"
            fontSize="superLarge"
            twStyle={tw`leading-[28.8px]`}
          />
          <Title
            content={t('enjoy_your_free_time')}
            fontWeight="normal"
            fontSize="base"
            twStyle={tw`leading-[24px]`}
            textColor="gray-500"
          />
        </div>
        {activeStep === 2 && areaSelection === 'whole_italy' && <SeasonalFilter />}
        <ChipCheckboxFormField
          customChipGroupStyles={tw`grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-2`}
          title={<Title content={t('sector')} fontSize="base" fontWeight="medium" twStyle={tw`leading-[24px]`} />}
          options={sortedDmOptions}
          id="domain_interest"
          isSingleSelectMode={false}
          testId="save-research-domain-interest-filter"
        />
        {!selectedDomainInterests?.length && (
          <div tw="flex flex-col gap-2 saturate-25 opacity-50 pointer-events-none cursor-not-allowed">
            <Title content={t('qualification')} fontSize="base" fontWeight="medium" twStyle={tw`leading-[24px]`} />
            <ChipCheckboxFormField
              customChipGroupStyles={tw`grid grid-cols-2 md:grid-cols-3 gap-2`}
              options={[
                {
                  id: '51',
                  key: '51',
                  label: t('room_attendant'),
                },
                {
                  id: '54',
                  key: '54',
                  label: 'Assistant',
                },
              ]}
              id=""
            />
          </div>
        )}
        {!!selectedDomainInterests?.length && (
          <div tw="flex flex-col gap-4">
            <Title content={t('qualification')} fontSize="base" fontWeight="medium" twStyle={tw`leading-[24px]`} />
            {selectedDomainInterests.map((v) => (
              <OccupationSelect key={v} domainInterestId={v} />
            ))}
          </div>
        )}
      </div>
    </StepWrapper>
  );
};

export default FilterSelection;
export const OccupationSelect = ({
  domainInterestId,
  customGridStyles = tw`grid grid-cols-2 gap-2 md:grid-cols-3`,
  formId = 'occupation_id',
}: {
  domainInterestId?: string;
  customGridStyles?: TwStyle;
  formId?: string;
}) => {
  const domainInterest = useAtomValue(domainInterestAtom);
  const sortedDmOptions: OptionsType[] = React.useMemo(
    () =>
      domainInterestsStatic.map((item) => ({
        ...item,
        id: domainInterest?.find(({ key }) => key === item.key)?.id,
      })),
    [domainInterest]
  );
  const { occupationOptions, loading } = useOccupationsByDomainInterest({
    domainInterestId,
    domainInterest,
  });
  return loading ? (
    <div>loading...</div>
  ) : (
    <div tw="flex flex-col gap-2">
      <Title
        content={sortedDmOptions.find((v) => v.id === domainInterestId)?.label || ''}
        fontSize="small"
        fontWeight="normal"
        twStyle={tw`leading-[21px]`}
      />
      <ChipCheckboxFormField
        customChipGroupStyles={customGridStyles}
        options={occupationOptions}
        id={`${formId}.${domainInterestId}`}
        testId="save-research-occupation-filter"
      />
    </div>
  );
};
