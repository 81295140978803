globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

var buildPath = function buildPath() {
  for (var _len = arguments.length, elements = new Array(_len), _key = 0; _key < _len; _key++) {
    elements[_key] = arguments[_key];
  }

  return "/".concat(elements.join('/'));
};

export var SCOPES = {
  globals: 'globals',
  accounts: 'accounts',
  stripe: 'stripe',
  rw_members: 'rw_member',
  ambassadors: 'ambassadors',
  employers: 'employers',
  workers: 'workers',
  worker_job_positions: 'worker_job_positions',
  restaurants: buildPath('employers', 'restaurants').slice(1),
  job_positions: buildPath('employers', 'job_positions').slice(1),
  dashboard: 'dashboard',
  form: 'form',
  taxonomy: 'taxonomy',
  cv_parsing: 'cv_parsing',
  graphql: 'graphql',
  social_auth: 'auth',
  organizations: 'organizations',
  companies: buildPath('organizations', 'companies').slice(1),
  klaaryo: 'klaaryo'
};
export var ENDPOINTS = {
  globals: {
    root: SCOPES.globals,
    variables: buildPath(SCOPES.globals, 'variables'),
    status: buildPath(SCOPES.globals, 'status'),
    restworldRating: buildPath(SCOPES.globals, 'rating'),
    compressImage: buildPath(SCOPES.globals, 'compress_image'),
    shortenUrl: '/shorten_url'
  },
  accounts: {
    root: SCOPES.accounts,
    login: buildPath(SCOPES.accounts, 'login'),
    signUp: buildPath(SCOPES.accounts, 'sign_up'),
    formId: buildPath(SCOPES.form),
    formSessionId: buildPath(SCOPES.form, 'form_session'),
    forgotPassword: buildPath(SCOPES.accounts, 'forgot_password'),
    resetPassword: buildPath(SCOPES.accounts, 'reset_password'),
    passwordlessLogin: buildPath(SCOPES.accounts, 'get_passwordless_login'),
    me: buildPath(SCOPES.accounts, 'me'),
    setupCheckoutSession: buildPath(SCOPES.stripe, 'setup_checkout_session'),
    getIntercomContactId: buildPath(SCOPES.accounts, 'intercom_contact_id'),
    phoneOtpVerification: buildPath(SCOPES.accounts, 'verification_phone_otp'),
    verifyPhoneOtp: buildPath(SCOPES.accounts, 'verify_phone_otp'),
    updateAuthenticationEmail: buildPath(SCOPES.accounts, 'update_user_authentication'),
    verifyEmail: buildPath(SCOPES.accounts, 'verify_email'),
    logout: buildPath(SCOPES.accounts, 'logout'),
    "delete": buildPath(SCOPES.accounts),
    restaurant_exploration_popup_details: buildPath(SCOPES.accounts, 'restaurant_exploration_popup_details'),
    flexibleSlots: buildPath(SCOPES.accounts, 'flexible_slots'),
    activateFlexibleSlot: buildPath(SCOPES.accounts, 'flexible_slots', 'activate'),
    cancelFlexibleSlot: buildPath(SCOPES.accounts, 'flexible_slots', 'cancel'),
    flexibleSlotPaymentSession: buildPath(SCOPES.accounts, 'flexible_slots', 'start_payment_session'),
    employerUserContact: buildPath(SCOPES.accounts, 'employer_user_contact'),
    employerUserContactAccessLink: buildPath(SCOPES.accounts, 'employer_user_contact', 'access_link'),
    secondaryContacts: buildPath(SCOPES.accounts, 'secondary_contacts'),
    checkUserExistence: buildPath(SCOPES.accounts, 'check_user_existence'),
    cancelSlots: buildPath(SCOPES.accounts, 'cancel_slots')
  },
  rwMembers: {
    root: SCOPES.rw_members,
    wallet: buildPath(SCOPES.rw_members, 'wallet', 'csm'),
    activeApplications: buildPath(SCOPES.rw_members, 'active_job_applications'),
    agenda: buildPath(SCOPES.rw_members, 'agenda', 'csm'),
    associatedTasks: buildPath(SCOPES.rw_members, 'tasks', 'csm'),
    workload: buildPath(SCOPES.rw_members, 'workload')
  },
  ambassadors: {
    root: SCOPES.ambassadors,
    me: buildPath(SCOPES.ambassadors, 'me'),
    metrics: buildPath(SCOPES.ambassadors, 'metrics'),
    acceptTermsAndConditions: buildPath(SCOPES.ambassadors, 'accept_t_and_c'),
    jobPositionPerformanceMetrics: buildPath(SCOPES.ambassadors, 'job_position_performance_metrics')
  },
  employers: {
    root: SCOPES.employers,
    graphql: buildPath(SCOPES.graphql, SCOPES.employers),
    picture: buildPath(SCOPES.employers, 'picture'),
    invoices: buildPath(SCOPES.employers, 'invoices'),
    notes: buildPath(SCOPES.employers, 'notes'),
    subscription: buildPath(SCOPES.employers, 'subscription'),
    paymentMethod: buildPath(SCOPES.employers, 'payment_method'),
    assignCustomerSuccessManager: buildPath(SCOPES.employers, 'assign_customer_success_manager'),
    employerContact: buildPath(SCOPES.employers, 'contact'),
    stripeCustomerPortal: buildPath(SCOPES.employers, 'stripe_customer_portal'),
    subscriptionSession: buildPath(SCOPES.employers, 'subscription_session'),
    createCheckoutSession: buildPath(SCOPES.employers, 'create_checkout_session'),
    invoicePdf: buildPath(SCOPES.employers, 'invoice', 'pdf'),
    accessLink: buildPath(SCOPES.employers, 'access_link'),
    subscriptionFeedback: buildPath(SCOPES.employers, 'subscription_feedback'),
    searchCompanies: buildPath(SCOPES.employers, 'search_companies'),
    subscriptionEmail: buildPath(SCOPES.employers, 'subscription', 'email'),
    followups: buildPath(SCOPES.employers, 'followups'),
    employerContacts: buildPath(SCOPES.employers, 'employer_contacts'),
    adPerformanceStatistics: buildPath(SCOPES.employers, '/job_positions/statistics/get_ad_performance'),
    isSubscriptionFeedbacksSubmitted: buildPath(SCOPES.employers, 'is_feedback_submitted_recently'),
    cancelStripeSubscription: buildPath(SCOPES.employers, 'cancel_stripe_subscription'),
    fetchEmployerSubscriptionFeedbackByOrganization: buildPath(SCOPES.employers, 'fetch_employer_subscription_feedback')
  },
  restaurants: {
    root: SCOPES.restaurants,
    localities: buildPath(SCOPES.restaurants, 'locality'),
    picture: buildPath(SCOPES.restaurants, 'picture'),
    gMapPlaces: buildPath(SCOPES.restaurants, 'places_info'),
    popularLocalities: buildPath(SCOPES.employers, 'popular', 'localities'),
    restaurantIds: buildPath(SCOPES.restaurants, 'ids'),
    restaurantLocationCoordinates: buildPath(SCOPES.restaurants, 'explore', 'map'),
    members: buildPath(SCOPES.restaurants, 'employees'),
    employees: buildPath(SCOPES.restaurants, 'employees'),
    occupationTemplates: buildPath(SCOPES.restaurants, 'occupation_templates'),
    violaBadge: buildPath(SCOPES.restaurants, 'show_viola_badge'),
    referentContact: buildPath(SCOPES.restaurants, 'referent_contact'),
    restaurantNames: buildPath(SCOPES.restaurants, 'names'),
    statistics: buildPath(SCOPES.restaurants, 'statistics'),
    restaurantViewLog: buildPath(SCOPES.restaurants, 'log_view'),
    publicRestaurant: buildPath(SCOPES.restaurants, 'public')
  },
  jobPositions: {
    root: SCOPES.job_positions,
    status: buildPath(SCOPES.job_positions, 'status'),
    active_status: buildPath(SCOPES.job_positions, 'active_status'),
    assignCsm: buildPath(SCOPES.job_positions, 'assign_customer_success_manager'),
    makeCsmMain: buildPath(SCOPES.job_positions, 'set_primary_customer_success_manager'),
    removeCsm: buildPath(SCOPES.job_positions, 'remove_customer_success_manager'),
    duplicate: buildPath(SCOPES.job_positions, 'duplicate'),
    stats: buildPath(SCOPES.job_positions, 'stats'),
    task: buildPath(SCOPES.job_positions, 'task'),
    applicationPerformanceMetrics: buildPath(SCOPES.job_positions, 'application_performance'),
    jobPositionActive: buildPath(SCOPES.job_positions, 'active'),
    JPWorker: buildPath(SCOPES.job_positions, 'workers'),
    jobPositionViewLog: buildPath(SCOPES.job_positions, 'log_view'),
    referralJobPositions: buildPath(SCOPES.employers, 'referral_job_positions'),
    similarJobs: buildPath(SCOPES.job_positions, 'similar'),
    favoriteJob: buildPath(SCOPES.job_positions, 'favorite'),
    isJobFavorite: buildPath(SCOPES.job_positions, 'favorited'),
    showGenerationPrompt: buildPath(SCOPES.job_positions, 'show_generation_prompt'),
    chatCompletion: buildPath(SCOPES.job_positions, 'chat_completion'),
    createJobTitle: buildPath(SCOPES.job_positions, 'create_job_title'),
    createJobDescription: buildPath(SCOPES.job_positions, 'create_job_description'),
    createJobContractDescription: buildPath(SCOPES.job_positions, 'create_job_contract_description'),
    regions: buildPath(SCOPES.job_positions, 'regions'),
    JobLocationCoordinates: buildPath(SCOPES.job_positions, 'explore/map'),
    JobLocationPositionList: buildPath(SCOPES.job_positions, 'explore'),
    groupedCount: buildPath(SCOPES.job_positions, 'grouped_count'),
    assignJR: buildPath(SCOPES.job_positions, 'assign_junior_recruiter'),
    requestAssistance: buildPath(SCOPES.job_positions, 'request_assistance')
  },
  workers: {
    root: SCOPES.workers,
    graphql: buildPath(SCOPES.graphql, SCOPES.workers),
    me: buildPath(SCOPES.workers, 'me'),
    boostStatus: buildPath(SCOPES.workers, 'subscription_status'),
    researchStatus: buildPath(SCOPES.workers, 'check_research_status'),
    applicationStatus: buildPath(SCOPES.workers, 'check_application_status'),
    asset: buildPath(SCOPES.workers, 'asset'),
    minifyImage: buildPath(SCOPES.workers, 'minify_image'),
    nativeCV: buildPath(SCOPES.workers, 'native_cv'),
    emailValidity: buildPath(SCOPES.workers, 'check_validity'),
    sendPicture: buildPath(SCOPES.workers, 'picture'),
    scout: buildPath(SCOPES.workers, 'scout'),
    updateResearchStatus: buildPath(SCOPES.workers, 'update_research_status'),
    activeApplications: buildPath(SCOPES.workers, 'active_applications'),
    sendEmail: buildPath(SCOPES.workers, 'send_email'),
    sendAccessEmail: buildPath(SCOPES.workers, 'send_access_email'),
    uploadCv: buildPath(SCOPES.workers, 'upload_cv'),
    uploadCv_2: buildPath(SCOPES.workers, 'cv'),
    deleteCv: buildPath(SCOPES.workers, 'delete_cv'),
    notes: buildPath(SCOPES.workers, 'notes'),
    createWorker: buildPath(SCOPES.workers, 'worker'),
    savedResearch: buildPath(SCOPES.workers, 'saved_research'),
    savedResearches: buildPath(SCOPES.workers, 'saved_researches'),
    similarWorkers: buildPath(SCOPES.workers, 'similar')
  },
  workerJobPositions: {
    root: SCOPES.worker_job_positions,
    graphql: buildPath(SCOPES.graphql, SCOPES.worker_job_positions),
    jobPosition: buildPath(SCOPES.worker_job_positions, 'job_positions'),
    jobApplication: buildPath(SCOPES.worker_job_positions, 'job_application'),
    shortlist: buildPath(SCOPES.worker_job_positions, 'shortlist'),
    addWorkerToShortlist: buildPath(SCOPES.worker_job_positions, 'add_worker_to_shortlist'),
    removeWorkerFromShortlist: buildPath(SCOPES.worker_job_positions, 'remove_worker_from_shortlist'),
    workerStatus: buildPath(SCOPES.worker_job_positions, 'status'),
    updateStatus: buildPath(SCOPES.worker_job_positions, 'shortlist', 'status'),
    shortlists: buildPath(SCOPES.worker_job_positions, 'shortlists'),
    sendShortlist: buildPath(SCOPES.worker_job_positions, 'shortlist', 'send'),
    cleanPendingApplications: buildPath(SCOPES.worker_job_positions, 'job_applications', 'clean_pending'),
    updateWorkerShortlist: buildPath(SCOPES.worker_job_positions, 'worker_shortlist', 'add_note'),
    getCompatibilityScore: buildPath(SCOPES.worker_job_positions, 'affinity_score'),
    workerApply: buildPath(SCOPES.worker_job_positions, 'apply'),
    jobApplyStatus: buildPath(SCOPES.worker_job_positions, 'job_application'),
    listSpontaneousApplications: buildPath(SCOPES.worker_job_positions, 'spontaneous_applications'),
    spontaneousApplication: buildPath(SCOPES.worker_job_positions, 'spontaneous_application'),
    jobApplicationsHistory: buildPath(SCOPES.worker_job_positions, 'job_applications', 'history'),
    jobApplications: buildPath(SCOPES.worker_job_positions, 'job_applications'),
    conversationSteps: buildPath(SCOPES.worker_job_positions, 'job_applications', 'conversations', 'steps'),
    workerPresentations: buildPath(SCOPES.worker_job_positions, 'worker_presentations')
  },
  dashboard: {
    root: SCOPES.dashboard,
    rwMemberStats: buildPath(SCOPES.dashboard, 'rw_members', 'stats'),
    historyActiveJobPositions: buildPath(SCOPES.dashboard, 'rw_members', 'average_active_job_positions'),
    getAssistanceSatisfactionStatistics: buildPath(SCOPES.dashboard, 'rw_members', 'get_assistance_satisfaction_statistics'),
    csmWinRate: buildPath(SCOPES.dashboard, 'rw_members', 'csm_win_rate'),
    averageShortlistsAndWorkersSent: buildPath(SCOPES.dashboard, 'rw_members', 'average_shortlists_and_workers_sent'),
    jobPositionAverageClosingTime: buildPath(SCOPES.dashboard, 'rw_members', 'job_position_average_closing_time')
  },
  taxonomy: {
    root: SCOPES.taxonomy,
    skill: buildPath(SCOPES.taxonomy, 'skill'),
    occupation: buildPath(SCOPES.taxonomy, 'occupation'),
    occupationSkills: buildPath(SCOPES.taxonomy, 'occupation_skills'),
    popularOccupations: buildPath(SCOPES.taxonomy, 'popular', 'occupations')
  },
  cvParsing: {
    root: SCOPES.cv_parsing,
    unanalysedParsedCv: buildPath(SCOPES.cv_parsing, 'unanalysed_parsed_cv'),
    collectErrors: buildPath(SCOPES.cv_parsing, 'collect_errors'),
    setCvAsAnalysed: buildPath(SCOPES.cv_parsing, 'set_cv_as_analysed'),
    correctedCvCount: buildPath(SCOPES.cv_parsing, 'corrected_cv_count')
  },
  socialAuth: {
    root: SCOPES.social_auth,
    google: buildPath(SCOPES.social_auth, 'google'),
    facebook: buildPath(SCOPES.social_auth, 'facebook')
  },
  organizations: {
    root: SCOPES.organizations,
    paymentMethods: buildPath(SCOPES.organizations, 'payment_methods'),
    followups: buildPath(SCOPES.organizations, 'followups'),
    missedOrgApplicationActivities: buildPath(SCOPES.organizations, 'get_missed_org_application_activities'),
    flexibleSlotPrices: buildPath(SCOPES.organizations, 'get_flexible_slot_prices'),
    discrepancies: buildPath(SCOPES.organizations, 'discrepancies'),
    performanceMetrics: buildPath(SCOPES.organizations, 'performance_metrics')
  },
  companies: {
    root: SCOPES.companies,
    subscriptions: buildPath(SCOPES.companies, 'subscriptions'),
    subscriptionEmail: buildPath(SCOPES.companies, 'subscription', 'email'),
    stripeCustomerPortal: buildPath(SCOPES.companies, 'stripe_customer_portal')
  },
  klaaryo: {
    root: SCOPES.klaaryo
  }
};