import { useEffect, useMemo } from 'react';
import tw from 'twin.macro';
import { atom, useAtom } from 'jotai';

import { useCompletedStepData, useStepData } from './worker.cvloading-ui.constant';
import { Description, WorkerCVLoadingUIWrapper } from './worker-cvloading.components';

export const WorkerCVLoadingUI = ({ isCompleted, workerId }: WorkerCVCarouselProps) => {
  const { onSlideChanged, activeIndex } = useCVLoadingSliderActiveIndex();
  const stepData = useStepData();
  const carouselElList = useMemo(
    () =>
      [...stepData].map(({ key, subTitle, title }, i) => (
        <CarouselListWrapper key={key} data-cy={`${key}-${i}`}>
          <Description title={title} subTitle={subTitle} />
        </CarouselListWrapper>
      )),
    [stepData]
  );

  const completedStepData = useCompletedStepData();

  useEffect(() => {
    if (isCompleted) {
      onSlideChanged(2);
      return;
    }
    const interval = setInterval(() => {
      if (activeIndex < stepData.length - 1) {
        onSlideChanged(activeIndex + 1);
      }
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [activeIndex, isCompleted, onSlideChanged, stepData.length]);

  return (
    <WorkerCVLoadingUIWrapper
      testId={`worker-cv-loading-ui-wrapper-${activeIndex}`}
      isCompleted={isCompleted ?? false}
      workerId={workerId}
      activeIndex={activeIndex}
    >
      {isCompleted ? (
        <CarouselListWrapper key={completedStepData.key}>
          <Description title={completedStepData.title} subTitle={completedStepData.subTitle} />
        </CarouselListWrapper>
      ) : (
        carouselElList[activeIndex]
      )}
    </WorkerCVLoadingUIWrapper>
  );
};
export default WorkerCVLoadingUI;
const CarouselListWrapper = tw.div``;
type CarouselProps = {
  isCompleted?: boolean;
};
export interface WorkerCVCarouselProps extends CarouselProps {
  workerId?: string;
}
const slideIndexAtom = atom(0);
export const useCVLoadingSliderActiveIndex = () => {
  const [activeIndex, setActiveIndex] = useAtom(slideIndexAtom);
  const onSlideChanged = (index: number) => setActiveIndex(index);
  return { activeIndex, onSlideChanged };
};
