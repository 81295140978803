import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export function extractApiErrors(error) {
  var _error$data;

  if ((error === null || error === void 0 ? void 0 : error.code) === 422 && error !== null && error !== void 0 && (_error$data = error.data) !== null && _error$data !== void 0 && _error$data.errors) {
    return Object.entries(error.data.errors).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return {
        type: 'custom',
        name: key,
        message: value[0]
      };
    });
  }

  return [];
}