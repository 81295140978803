/* eslint-disable no-console */
import React, { ReactNode } from 'react';
import 'twin.macro';

import { ErrorBoundary, captureException } from '@sentry/nextjs';

import { Title } from '@restworld/ui-ds';

interface ErrorBoundaryProps {
  children: ReactNode;
}
export const ErrorBoundaryWrapper: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <ErrorBoundary
      showDialog
      dialogOptions={{
        title: 'Something went wrong!',
        subtitle: 'Please have patience, developers are looking into it!',
      }}
      onError={(error, componentStack) => {
        console.trace(error, 'page error');
        console.trace(componentStack, 'page error componentStack');
        captureException(`${error}-${componentStack}`);
      }}
      fallback={
        <div tw="fixed top-[50%] left-[50%] -mt-[150px] -ml-[300px] max-w-2xl min-w-104 p-8">
          <Title
            fontSize="text-4xl"
            content="Ooops! È appena avvenuto qualcosa di inaspettato ;( Se il problema persiste, scrivi all'assistenza"
            fontWeight="bold"
          />
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
};
export default ErrorBoundaryWrapper;
