import { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { FormLabelWrapper, GoogleAutoCompleteInput, InputProps, PhoneInput, TextInput } from '@restworld/ui-ds';
import { FormErrorKey, FormErrorType } from '@restworld/utils-common';

export const useFormInput = ({ handleFormChange, missingFields, formErrors, control }: FormInputProps) => {
  const { t } = useTranslation();

  const getHelperText = useCallback(
    (formErrorMessage?: string, id?: FormErrorKey) =>
      formErrorMessage && id ? (
        <span tw="mt-2 text-sm text-red-600 font-normal">
          {id === 'email' && formErrorMessage.includes('already exists') ? (
            <>
              {t('onboarding-page:a_user_already_exists_with_this_email_execute_the')}{' '}
              <a
                href={`/login?redirect=${encodeURIComponent(window.location.pathname)}`}
                target="_blank"
                rel="noopener noreferrer"
                tw="underline"
              >
                login
              </a>{' '}
              {t('onboarding-page:if_the_email_belongs_to_you')}
            </>
          ) : (
            formErrorMessage
          )}
        </span>
      ) : null,
    [t]
  );
  const formInputs = useMemo(
    () =>
      missingFields.length
        ? [
            {
              type: 'email',
              label: 'Email',
              id: 'email' as const,
              placeholder: 'eg. mario@example.com',
            },
            {
              type: 'text',
              label: t('onboarding-page:name'),
              id: 'name' as const,
              placeholder: 'eg. Elisa',
            },
            {
              type: 'text',
              label: t('onboarding-page:surname'),
              id: 'surname' as const,
              placeholder: 'eg. Ajello',
            },
            {
              type: 'text',
              label: t('onboarding-page:address'),
              id: 'address' as const,
              placeholder: 'Ricerca il tuo indirizzo',
            },
            {
              type: 'tel',
              label: t('onboarding-page:phone_number'),
              id: 'phone_number' as const,
              placeholder: 'eg. +393348369451',
            },
          ]
            .filter((v) => missingFields.includes(v.id))
            .map(
              ({
                type,
                label,
                id,
                placeholder,
              }: {
                type: string;
                label: string;
                id: FormErrorKey;
                placeholder: string;
              }) => {
                const formErrorMessage = formErrors?.[id];
                const helperText = getHelperText(formErrorMessage, id);
                return (
                  <TextLabelInputComponent
                    key={id}
                    type={type}
                    label={label}
                    id={id}
                    placeholder={placeholder}
                    handleFormChange={handleFormChange}
                    helperText={helperText}
                    color={formErrors?.[id] ? 'failure' : undefined}
                    rightIcon={formErrors?.[id] ? HandRaisedIcon : undefined}
                    control={control}
                    formError={formErrorMessage}
                    testId={`${id}-input`}
                  />
                );
              }
            )
        : [],
    [t, control, formErrors, getHelperText, handleFormChange, missingFields]
  );
  return formInputs;
};
const TextLabelInputComponent = ({
  rightIcon,
  label,
  handleFormChange,
  id,
  helperText,
  color,
  placeholder,
  control,
  formError,
  testId,
  ...rest
}: TextLabelInputComponentProps) => {
  const inputId = id as FormErrorKey;
  const onChange = useCallback(
    (value: string) => (handleFormChange ? handleFormChange(value, inputId) : null),
    [handleFormChange, inputId]
  );
  return (
    <FormLabelWrapper id={inputId} label={label}>
      {inputId === 'address' ? (
        <GoogleAutoCompleteInput
          formId="address"
          key="address"
          placeholder={placeholder}
          control={control}
          onSuggestionSelect={({ description }) => {
            onChange(description);
          }}
          helperText={formError}
          testId={testId}
        />
      ) : inputId === 'phone_number' ? (
        <PhoneInput
          priorityCountries={['IT', 'FR', 'ES']}
          menuDialogStyles={[tw`min-w-full`]}
          variant={formError ? 'error' : 'primary'}
          supportText={formError}
          onChange={onChange}
          testIds={{ input: testId }}
        />
      ) : (
        <TextInput
          onChange={onChange}
          placeholder={placeholder}
          color={color}
          helperText={helperText}
          rightIcon={rightIcon}
          id={inputId}
          control={control}
          hasLeftPadding={false}
          testId={testId}
          {...rest}
        />
      )}
    </FormLabelWrapper>
  );
};
type InputChangeProps = {
  handleFormChange?: (value: string, key: FormErrorKey) => void;
  // formData: FormErrorType;
};
interface FormInputProps extends InputChangeProps {
  missingFields: string[];
  formErrors: FormErrorType | null;
  control?: Control<FieldValues>;
}
interface TextLabelInputComponentProps extends InputProps, InputChangeProps {
  label: string;
  formError?: string;
}
