import { useMemo, useCallback } from 'react';
import tw from 'twin.macro';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useAtom } from 'jotai';

import { FileType, sliceContent } from '@restworld/utils-common';
import { Title, Button, UploadCard } from '@restworld/ui-ds';

import { LoginPrivacyRedirectSubmitButton } from './privacy-terms';
import { useEntryPointCheckListContext } from './entry-component.context';
import { TitleWithBackBtn } from '.';
import { doesSizeExceedsAtom, uploadFilesAtom, workerEntryPointErrorsAtom } from '../../worker-entry-point.atoms';

export const UploadCVUI = () => {
  const [uploadFiles, setUploadFiles] = useAtom(uploadFilesAtom);
  const [, setSizeExceeds] = useAtom(doesSizeExceedsAtom);

  const { isCVNotUploaded, setIsCVNotUploaded } = useEntryPointCheckListContext();
  const { t } = useTranslation('onboarding-page');

  const handleUploadFiles = useCallback(
    (value: FileType) => {
      setUploadFiles(value);
      if (value?.[0] && value?.[0].size / 1000000 > 20) {
        setSizeExceeds(true);
      }
      setIsCVNotUploaded(false);
    },
    [setIsCVNotUploaded, setSizeExceeds, setUploadFiles]
  );

  return (
    <UploadEntryWrapper>
      <Wrapper>
        <TitleWithBackBtn title={t('upload_your_cv_to_apply')} testId="upload-your-cv-title" />
        <Title
          isCenter
          icon="BoltIcon"
          content={t('it_will_take_less_than45_seconds')}
          textColor="gray"
          fontSize="small"
          fontWeight="light"
        />
      </Wrapper>
      {!uploadFiles?.[0] ? (
        <UploadCard
          isError={isCVNotUploaded}
          setUploadFiles={handleUploadFiles}
          fileType={['.pdf', '.doc', '.docx', '.jpg']}
          testId="entry-point-upload-card"
        />
      ) : (
        <UploadedCVUI testId="entry-point-uploaded-cv-view" />
      )}
      <LoginPrivacyRedirectSubmitButton type="upload-cv" />
    </UploadEntryWrapper>
  );
};
const UploadEntryWrapper = tw.div`flex flex-col gap-6`;
const Wrapper = tw.div`flex flex-col gap-4`;
const ErrorInfoText = tw.div`p-2 text-xs text-red-700`;
export const UploadedCVUI = ({ testId }: { testId?: string }) => {
  const [uploadFiles, setUploadFiles] = useAtom(uploadFilesAtom);
  const [doesSizeExceeds, setSizeExceeds] = useAtom(doesSizeExceedsAtom);
  const [errors, setErrors] = useAtom(workerEntryPointErrorsAtom);

  const handleDelete = useCallback(() => {
    setUploadFiles([]);
    setErrors({});
    setSizeExceeds(false);
  }, [setErrors, setSizeExceeds, setUploadFiles]);

  const couldNotParse = useMemo(() => !!Object.keys(errors).length, [errors]);

  const { t } = useTranslation('onboarding-page');
  return (
    <>
      <div data-cy={testId} tw="bg-primary-50 flex gap-4 items-center justify-between rounded-lg w-full p-3">
        <div tw="flex gap-4 items-center">
          <div tw="rounded-lg border bg-white p-3">
            <DocumentTextIcon className="h-6 w-6 text-primary-800" />
          </div>
          {uploadFiles?.[0] ? (
            <div tw="flex flex-col gap-2">
              <Title
                content={uploadFiles?.[0]?.name && sliceContent(uploadFiles?.[0]?.name, 20)}
                fontWeight="semibold"
                fontSize="small"
                textColor="black"
                testId="upload-file-name-text"
              />
              <Title
                content={`${(uploadFiles?.[0]?.size / 1000000).toFixed(2)}MB`}
                fontWeight="medium"
                fontSize="xSmall"
                textColor="gray"
                testId="upload-file-size-text"
              />
            </div>
          ) : null}
        </div>
        <Button icon="RedTrashIcon" variant="ghost" onClick={handleDelete} testId="upload-file-delete-button" />
      </div>
      {doesSizeExceeds && (
        <ErrorInfoText data-cy="upload-cv-size-exceed-warning">
          {t('the_cv_exceeds20mb_compress_it_or_choose_another_one')}{' '}
        </ErrorInfoText>
      )}
      {errors?.statusCode === 415 ? (
        <ErrorInfoText data-cy="upload-cv-status-code-415-warning">{t('common:upload_pdf_cv')} </ErrorInfoText>
      ) : (
        couldNotParse && (
          <ErrorInfoText data-cy="upload-cv-could-not-parse-error">
            {t('we_were_unable_to_read_your_cv,we_suggest_you_upload_another_one')}{' '}
            <span role="img" aria-labelledby="wink">
              😉
            </span>
          </ErrorInfoText>
        )
      )}
    </>
  );
};
