import { memo } from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { useResponsiveBreakPoints, ManOnComputerIllustration } from '@restworld/utils-common';
import { Title } from '@restworld/ui-ds';

import { LoginPrivacyRedirectSubmitButton } from './privacy-terms';
import { TitleWithBackBtn } from '.';

export const ApplicationWizardUI = memo(() => {
  const { isDesktop } = useResponsiveBreakPoints();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWithBackBtn title={t('onboarding-page:apply_via_questionnaire')} />
      <Title
        icon="BoltIcon"
        isCenter
        textColor="gray"
        fontSize="base"
        fontWeight="light"
        content={<>{t('onboarding-page:it_will_take_about5_minutes')}</>}
      />
      <ManOnComputerIllustration
        tw="self-center"
        {...(isDesktop ? { width: 340, height: 315 } : { width: 280, height: 265 })}
      />
      <LoginPrivacyRedirectSubmitButton type="application-wizard" />
    </Wrapper>
  );
});
const Wrapper = tw.div`flex flex-col gap-4`;
