import { useAccountsService } from '@restworld/data-services';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';
import {
  ContentType as ModalContentType,
  closeSignupModal,
  nonExistingAccount,
  openEmailLoginModal,
  openEmailTokenSentModal,
  setAccountExistingEmail,
  setActiveContent as setActiveModalContent,
  updateEmailVerificationDetails,
  setCurrentUrl,
  setExistingPhoneNumber,
  openPhoneNumberLoginModal,
  useAlreadyInvitedCollaboratorModal,
  alreadyInvitedCollaboratorEmailAtom,
} from '../../../context/auth-flow';
import { useRouter } from 'next/router';
import { useAnalytics } from '@restworld/utils-common';

export type UseSignupFormProps = {
  redirectToPath?: (path: string, query?: { [key: string]: string | boolean }) => void;
  currentUrl?: string;
  isModal?: boolean;
  email?: string;
};

export interface SignupInput {
  email: string;
  phoneNumber: string;
  acceptedTerms: boolean;
}

export function useSignupForm({ currentUrl, isModal, email }: UseSignupFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm<SignupInput>({
    defaultValues: {
      email: email || '',
      phoneNumber: '',
      acceptedTerms: false,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const onCloseSignupModal = useSetAtom(closeSignupModal);
  const onOpenEmailLoginModal = useSetAtom(openEmailLoginModal);
  const onSetEmailExists = useSetAtom(setAccountExistingEmail);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const openVerificationModal = useSetAtom(openEmailTokenSentModal);
  const updateVerificationDetails = useSetAtom(updateEmailVerificationDetails);
  const onSetNonExistingAccount = useSetAtom(nonExistingAccount);
  const onSetCurrentUrl = useSetAtom(setCurrentUrl);
  const onSetExistingPhoneNumber = useSetAtom(setExistingPhoneNumber);
  const onOpenPhoneLoginModal = useSetAtom(openPhoneNumberLoginModal);
  const setAlreadyInvitedCollaboratorEmail = useSetAtom(alreadyInvitedCollaboratorEmailAtom);

  const { openModal: openInvitedCollaboratorModal } = useAlreadyInvitedCollaboratorModal();
  const router = useRouter();

  const acc = useAccountsService();
  const { trackEvent } = useAnalytics();

  const handleSignup = useCallback(
    async (data: SignupInput) => {
      setLoading(true);
      try {
        await acc.createAccount({ email: data.email, phone_number: data.phoneNumber, redirectURL: currentUrl });
        trackEvent('create_account', { email: data.email, phone_number: data.phoneNumber, terms_and_conditions: true });
        setLoading(false);
        onSetNonExistingAccount({ email: null, phoneNumber: null });
        updateVerificationDetails({
          waitTime: 10,
          tokenValidityTime: 900,
          email: data.email,
        });
        setActiveContent(ModalContentType.EMAIL_SENT);
        if (isModal) {
          onCloseSignupModal();
          openVerificationModal();
        }
      } catch (error) {
        setLoading(false);
        if (parseInt(error.code) === 409) {
          const conflictField = error?.data?.conflict_field;
          onSetNonExistingAccount({ email: null, phoneNumber: null });
          const isEmailError = conflictField === 'email';
          const isPhoneNumberError = conflictField === 'phone_number';
          const isInvitedEmployerCollaborator = error?.data?.error === 'invited_employer_user_contact';

          if (isInvitedEmployerCollaborator) {
            setAlreadyInvitedCollaboratorEmail(data.email);
            openInvitedCollaboratorModal();
            return;
          }
          if (isEmailError) {
            onSetEmailExists(data.email);
            setActiveContent(ModalContentType.EMAIL_LOGIN);
          } else if (isPhoneNumberError) {
            onSetExistingPhoneNumber(data.phoneNumber);
            setActiveContent(ModalContentType.PHONE_NUMBER_LOGIN);
          }
          if (isModal) {
            onCloseSignupModal();
            if (currentUrl) {
              onSetCurrentUrl(currentUrl);
            }
            if (isEmailError) {
              onOpenEmailLoginModal();
            } else if (isPhoneNumberError) {
              onOpenPhoneLoginModal();
            }
          } else {
            router.push(`/login${currentUrl ? `?redirect_url=${currentUrl}` : ''}`);
          }
        }
      }
    },
    [
      acc,
      currentUrl,
      trackEvent,
      onSetNonExistingAccount,
      updateVerificationDetails,
      setActiveContent,
      isModal,
      onCloseSignupModal,
      openVerificationModal,
      setAlreadyInvitedCollaboratorEmail,
      openInvitedCollaboratorModal,
      onSetEmailExists,
      onSetExistingPhoneNumber,
      onSetCurrentUrl,
      onOpenEmailLoginModal,
      onOpenPhoneLoginModal,
      router,
    ]
  );

  const onSubmit = async (data: SignupInput) => {
    handleSignup(data);
  };

  return {
    loading,
    onSubmit,
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    errors,
  };
}
