import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import 'twin.macro';

import { JobPosition, Occupation, Variable } from '@restworld/utility-types';
import {
  JobCoordinateParamsType,
  JobLocationCoordinates,
  JobPositionListParamsType,
  PopularLocality,
} from '@restworld/data-services';
import { defaultItalyCoordinates } from '@restworld/utils-common';
import type { MultiSelectOptionType } from '@restworld/utils-common';

export const defaultQuickFilter = 'fixed_term';
export const defaultJobCoordinatesPayload = {};
export const defaultPagination = {
  limit: 9,
  offset: 9,
};
export const defaultExplorerSortFilter = '{"order_by": ["desc:published_at"]}';
export const defaultJobListPayload = {
  lon: defaultItalyCoordinates[0],
  lat: defaultItalyCoordinates[1],
  limit: 9,
  offset: 0,
  radius: 1999352.7375143734,
  order_by: ['desc:published_at'],
};

export type SetCoordinateType = Dispatch<
  SetStateAction<
    | {
        lat: number;
        lon: number;
      }
    | undefined
  >
>;
export type JobExplorerContextType = JobExplorerComponentPropType;
export type Coordinate = {
  lat?: number;
  lon?: number;
};
export type ParamsType = {
  lon?: number;
  lat?: number;
  radius: number;
  limit: number;
  offset: number;
};
export type SetJobLocationPositionListType = React.Dispatch<React.SetStateAction<JobPosition[] | null>>;
export type JobExplorerComponentPropType = {
  domainInterest?: Variable[];
  popularOccupations?: Occupation[];
  popularLocalities?: PopularLocality[];
  jobPositionListFromServer?: {
    data: JobPosition[];
    hits: number;
  };
  jobPositionCoordinatesFromServer?: JobLocationCoordinates[];
  serverListPayload?: JobPositionListParamsType;
  serverCoordinatePayload?: JobCoordinateParamsType;
};
export type PaginationType = {
  limit: number;
  offset: number;
};
export type FilterContextValuesType = Omit<
  JobPositionListParamsType,
  'contract_type' | 'seasonal_job_position' | 'user_location_radius'
>;

export type SelectedLocation = {
  key?: string;
  value?: {
    lat: number;
    lng: number;
    zoom?: number;
  };
  type?: string;
  label?: string;
};
export type URLDataType = {
  locationUrlParams?: SelectedLocation;
  occupationUrlParams?: MultiSelectOptionType[];
  isSearchBarUrlParamsAvailable: boolean;
  jobListUrlParams?: JobPositionListParamsType;
  jobLocationUrlParams?: JobCoordinateParamsType;
  parsedOverallFilter?: Record<string, any>;
  mapFiltersURLParams?: Record<string, any>;
};
