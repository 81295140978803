import { useMemo, useEffect, RefObject } from 'react';
import { theme } from 'twin.macro';
import { Layer, Source, MapRef, SourceProps, LayerProps } from 'react-map-gl';

import { Coordinate } from '../../job-explorer.types';

export const usePulsingDot = ({
  mapRef,
  userCoordinates,
}: {
  mapRef: RefObject<MapRef>;
  userCoordinates?: Coordinate;
}) => {
  const map = mapRef?.current;
  const sourceProps: SourceProps = useMemo(
    () => ({
      id: 'dot-point',
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates:
                userCoordinates?.lon && userCoordinates?.lat ? [userCoordinates?.lon, userCoordinates?.lat] : [], // icon position [lng, lat]
            },
            properties: {},
          },
        ],
      },
    }),
    [userCoordinates]
  );
  const layerProps: LayerProps = useMemo(
    () => ({
      id: 'layer-with-pulsing-dot',
      type: 'symbol',
      source: 'dot-point',
      layout: {
        'icon-image': 'pulsing-dot',
      },
    }),
    []
  );
  const pulsingDot = useMemo(() => constructPulsingDot({ map }), [map]);
  useEffect(() => {
    if (map && userCoordinates?.lat && userCoordinates?.lon) map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
  }, [map, pulsingDot, userCoordinates]);
  return (
    <Source {...sourceProps}>
      <Layer {...layerProps} />
    </Source>
  );
};
export function constructPulsingDot({ size = 100, map }: { size?: number; map: MapRef }) {
  return {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),
    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },
    // Call once before every frame where the icon will be used.
    render: function () {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;
      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;
      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = theme`colors.primary.200`;
      context.fill();
      // Draw the inner circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = theme`colors.primary.800`;
      context.strokeStyle = theme`colors.primary.800`;
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();
      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;
      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      map?.triggerRepaint();
      // Return `true` to let the map know that the image was updated.
      return true;
    },
  };
}
