globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import jwtDecode from 'jwt-decode';
export var isValidToken = function isValidToken(accessToken) {
  if (!accessToken) return false;
  var decoded = jwtDecode(accessToken);
  var currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};