globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { BASE_URL } from '../constants';
export var openInNewTab = function openInNewTab(url) {
  var win = true && window;

  if (win) {
    var newWin = win.open("".concat(BASE_URL).concat(url), '_blank', 'noopener, noreferrer');
    if (newWin) newWin.opener = null;
  }
};