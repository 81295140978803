globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useNavigationContext } from '../context';
export function useIsEmployerApp() {
  var _router$asPath;

  var _useNavigationContext = useNavigationContext(),
      router = _useNavigationContext.router;

  var isEmployerApp = !!(router !== null && router !== void 0 && (_router$asPath = router.asPath) !== null && _router$asPath !== void 0 && _router$asPath.includes('/employer'));
  return {
    isEmployerApp: isEmployerApp
  };
}