globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var tokenKeyName = 'jwt';
export var jwtTokenLocalStorage = typeof localStorage !== 'undefined' ? localStorage.getItem(tokenKeyName) : '';