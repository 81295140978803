globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useState } from 'react';
export var useModal = function useModal() {
  var _useState = useState(false),
      show = _useState[0],
      toggleModal = _useState[1];

  var close = function close() {
    return toggleModal(false);
  };

  var open = function open() {
    return toggleModal(true);
  };

  var returnValue = {
    show: show,
    close: close,
    open: open
  };
  return returnValue;
};