import { useAtomValue, useSetAtom } from 'jotai';
import {
  ContentType as ModalContentType,
  closeAuthFlowModal,
  closeSignupModal,
  nonExistingAccount,
  openEmailLoginModal,
  setActiveContent as setActiveModalContent,
} from '../../../context/auth-flow';
import { useRouter } from 'next/router';
import { useAnalytics } from '@restworld/utils-common';

export type SignupUtilsProps = {
  isModal?: boolean;
};

export function useSignupUtils({ isModal }: SignupUtilsProps) {
  const nonExistingUserAccount = useAtomValue(nonExistingAccount);
  const onCloseSignupModal = useSetAtom(closeSignupModal);
  const openEmailModal = useSetAtom(openEmailLoginModal);
  const closeModal = useSetAtom(closeAuthFlowModal);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const onSetNonExistingAccount = useSetAtom(nonExistingAccount);
  const { trackEvent } = useAnalytics();
  const router = useRouter();

  function onClose() {
    if (isModal) {
      onCloseSignupModal();
      setActiveContent(ModalContentType.NO_CONTENT);
      onSetNonExistingAccount({ email: null, phoneNumber: null });
      closeModal();
      trackEvent('initiate_signin', { login_state: false, user_type: null });
    }
  }

  function navigateToLogin() {
    setActiveContent(ModalContentType.EMAIL_LOGIN);
    if (isModal) {
      onCloseSignupModal();
      openEmailModal();
    } else {
      router.push('/login');
    }
  }

  return {
    nonExistingUserAccount,
    onClose,
    navigateToLogin,
  };
}
