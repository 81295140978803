globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useQueryClient } from '@tanstack/react-query';
export function useQueryClientData(queryKey) {
  var queryClient = useQueryClient();
  var detailsData = queryClient.getQueryData(queryKey);
  return detailsData === null || detailsData === void 0 ? void 0 : detailsData.data;
}
export default useQueryClientData;