import { atom } from 'jotai';

import { MissingCVTypeParams } from '@restworld/utils-common';

type ActiveTabType = 'application-wizard' | 'upload-cv' | '';
type ApplicationType = 'job-apply' | 'spontaneous-apply';

export interface IErrorProps extends Required<MissingCVTypeParams> {
  statusCode?: number;
  user_id?: string;
  code?: string;
}
type SpontaneousApplyDataType = {
  isSpontaneousEntry?: boolean;
  applicationType?: ApplicationType;
  restaurantID?: string;
  didUserSpontaneousApply?: boolean;
  showCoverLetter?: boolean;
  entryType?: ActiveTabType;
};
interface WorkerEntryPointType {
  uploadCVLoading: boolean;
  isCVUploaded: boolean;
  workerId: string;
  errors: Partial<IErrorProps>;
  uploadFiles: FileList | [];
  isCVUploadProcessCompleted: boolean;
  doesSizeExceeds: boolean;
  activeTab?: ActiveTabType;
  showConfirmModal: boolean;
  spontaneousApplyData?: SpontaneousApplyDataType;
  jobApplicationModalAlreadyDisplayed: boolean;
}

export const uploadCVLoadingAtom = atom<WorkerEntryPointType['uploadCVLoading']>(false);
export const isCVUploadedAtom = atom<WorkerEntryPointType['isCVUploaded']>(false);
export const workerIdAtom = atom<WorkerEntryPointType['workerId']>('');
export const workerEntryPointErrorsAtom = atom<WorkerEntryPointType['errors']>({});
workerEntryPointErrorsAtom.debugLabel = 'workerEntryPointErrorsAtom';
export const uploadFilesAtom = atom<WorkerEntryPointType['uploadFiles']>([]);
export const isCVUploadProcessCompletedAtom = atom<WorkerEntryPointType['isCVUploadProcessCompleted']>(false);
export const doesSizeExceedsAtom = atom<WorkerEntryPointType['doesSizeExceeds']>(false);
export const activeTabAtom = atom<WorkerEntryPointType['activeTab']>('');
export const showConfirmModalAtom = atom<WorkerEntryPointType['showConfirmModal']>(false);
export const spontaneousApplyDataAtom = atom<WorkerEntryPointType['spontaneousApplyData']>({});
export const jobApplicationModalAlreadyDisplayedAtom =
  atom<WorkerEntryPointType['jobApplicationModalAlreadyDisplayed']>(false);

export const resetWorkerEntryStates = atom(null, (_, set) => {
  set(uploadCVLoadingAtom, false);
  set(isCVUploadedAtom, false);
  set(workerEntryPointErrorsAtom, {});
  set(uploadFilesAtom, []);
});
export const doesPhoneNumberExistAtom = atom<boolean>(false);
