import { useRouter } from 'next/router';
import { ReactNode } from 'react';

const GuardLoaderComponent = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  if (router.pathname === '/cerco-lavoro' || router.pathname === '/candidati' || router.pathname === '/candidature')
    return null;

  return <>{children}</>;
};

export default GuardLoaderComponent;
