import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { weekDayMap, weekDaySymbol, fromWord, andWord, toWord, fullWeek } from './job-shifts.mocks';
var workDaysSorted = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']; // -->  fn to get aggregated weekday string

var aggregateWorkdayFn = function aggregateWorkdayFn(workDay) {
  var invert = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var newList = _toConsumableArray(workDay).sort(function (a, b) {
    return workDaysSorted.indexOf(a) - workDaysSorted.indexOf(b);
  });

  if (invert) newList = weekDaySymbol.filter(function (v) {
    return !_toConsumableArray(workDay).includes(v);
  });
  var len = newList.length;
  if (!len) return '';
  var firstWeek = newList[0];
  if (len === 1) return "".concat(capitalizeFirstLetter(weekDayMap[firstWeek]));
  var lastDay = newList[len - 1];
  if (len === 2) return "".concat(capitalizeFirstLetter(weekDayMap[firstWeek]), " ").concat(andWord, " ").concat(weekDayMap[lastDay]);
  var weekNumbers = newList.map(function (v) {
    return fullWeek[v];
  });
  if (isConsecutiveNumbers(weekNumbers)) return "".concat(fromWord, " ").concat(weekDayMap[firstWeek], " a ").concat(weekDayMap[lastDay]);
  var aggString = generateConsecutiveString(weekNumbers);
  var leanString = removeUnwantedCommas(aggString);
  return capitalizeFirstLetter(leanString);
}; // --> fn to check if the number is consecutive or not


var isConsecutiveNumbers = function isConsecutiveNumbers(weekNumbers) {
  return Boolean(weekNumbers.reduce(function (prev, curr) {
    return prev ? prev + 1 === curr ? curr : 0 : 0;
  }));
}; // --> fn to reverse object


var reverseObject = function reverseObject(data) {
  return Object.fromEntries(Object.entries(data).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return [value, key];
  }));
}; // --> fn to get a string of consecutive array


var generateConsecutiveString = function generateConsecutiveString(weekNumbers) {
  var consecutiveRange = consecutiveRanges(weekNumbers);
  var reverseWeekNumber = reverseObject(fullWeek);
  var aggregatedString = "".concat(consecutiveRange.map(function (x, i, list) {
    var prefixAndWord = list.length - 1 === i ? andWord : '';

    if (Array.isArray(x)) {
      var initialWeek = weekDayMap[reverseWeekNumber[x[0]]];
      var lastWeek = weekDayMap[reverseWeekNumber[x[x.length - 1]]];
      var isLengthGreaterEqualThree = x.length >= 3;
      var prefixFromWord = isLengthGreaterEqualThree ? fromWord : '';
      var midWord = isLengthGreaterEqualThree ? toWord : andWord;
      return " ".concat(prefixAndWord, " ").concat(prefixFromWord, " ").concat(initialWeek, " ").concat(midWord, " ").concat(lastWeek);
    }

    return " ".concat(prefixAndWord, " ").concat(weekDayMap[reverseWeekNumber[x]]);
  }));
  return aggregatedString.trim();
}; // --> fn to get consecutive array of string


var consecutiveRanges = function consecutiveRanges(a) {
  var length = 1;
  var list = [];

  var iterateNumbersToRange = function iterateNumbersToRange(min, max) {
    return Array.from({
      length: max - min + 1
    }, function (_, i) {
      return min + i;
    });
  };

  if (a.length === 0) return list;

  for (var i = 1; i <= a.length; i++) {
    if (i === a.length || a[i] - a[i - 1] !== 1) {
      if (length === 1) list.push(a[i - length]);else {
        var consecutiveRange = iterateNumbersToRange(Number(a[i - length]), Number(a[i - 1]));
        list.push(consecutiveRange);
      }
      length = 1;
    } else {
      length++;
    }
  }

  return list;
};

var removeUnwantedCommas = function removeUnwantedCommas(str) {
  return str.replace(', e', ' e');
};

var capitalizeFirstLetter = function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
};

export { aggregateWorkdayFn };