import 'twin.macro';
import { ReactElement } from 'react';

import { WorkerEntryPointButton } from '../worker-entry-point';

interface ISpontaneousApplicationProps {
  restaurantID: string;
  label?: string;
  children?: ReactElement;
  fullwidth?: boolean;
}
export const SpontaneousApplication = ({ children, ...rest }: ISpontaneousApplicationProps) => {
  return (
    <WorkerEntryPointButton applicationType="spontaneous-apply" {...rest}>
      {children}
    </WorkerEntryPointButton>
  );
};
export default SpontaneousApplication;
