import * as React from 'react';
import { useTranslation } from 'next-i18next';
import tw from 'twin.macro';

import { BaseModal, Button, ButtonProps } from '@restworld/ui-ds';

export interface IFormModalWrapper {
  loading?: boolean;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
  modalVisible?: boolean;
  onModalClose?: () => void;
  title?: string;
  subTitle?: string;
  submitHandlerProps?: ButtonProps;
  headerIcon?: React.ReactNode;
  onCancelHandlerProps?: ButtonProps;
  showCloseIcon?: boolean;
  disableDrag?: boolean;
}

export const ModalToggleWrapper = ({
  children,
  loading,
  onSubmitHandler,
  onCancelHandler,
  modalVisible,
  onModalClose,
  title,
  subTitle,
  submitHandlerProps,
  headerIcon,
  onCancelHandlerProps,
  showCloseIcon,
  disableDrag,
}: React.PropsWithChildren<IFormModalWrapper>) => {
  const { t } = useTranslation('common');

  const footer = React.useMemo(
    () => (
      <div tw="flex flex-col gap-5 w-full">
        <Button
          loading={loading}
          fullwidth
          onClick={onSubmitHandler}
          variant="normal"
          title={t('confirm')}
          {...submitHandlerProps}
        />
        {(onCancelHandler || onCancelHandlerProps?.onClick) && (
          <Button fullwidth variant="black-outline" onClick={onCancelHandler} {...onCancelHandlerProps}>
            {t('cancel')}
          </Button>
        )}
      </div>
    ),
    [loading, onCancelHandler, onCancelHandlerProps, onSubmitHandler, submitHandlerProps, t]
  );
  const header = React.useMemo(
    () => (
      <div tw="flex flex-col gap-8 items-center px-6">
        {headerIcon ?? ''}
        <h2 tw="text-xl leading-7.5 text-neutral-700 font-semibold text-center">{title}</h2>
        <h5 tw="text-base font-medium text-neutral-200 text-center">{subTitle}</h5>
      </div>
    ),
    [headerIcon, subTitle, title]
  );
  return (
    <BaseModal
      open={modalVisible}
      onClose={onModalClose}
      modalStyles={[tw`md:max-w-md`]}
      footer={footer}
      title={header}
      showCloseIcon={showCloseIcon}
      disableDrag={disableDrag}
    >
      <Form action="#!" method="post" onSubmit={(e) => e.preventDefault()}>
        {children}
      </Form>
    </BaseModal>
  );
};

const Form = tw.form`flex flex-col gap-8 pb-12 pt-4 w-full`;
