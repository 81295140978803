globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './house-check-icon';
export * from './information-icon';
export * from './google-icon';
export * from './facebook-icon';
export * from './reset-icon';
export * from './back-icon';
export * from './center-close-icon';
export * from './right-close-icon';
export * from './drag-handle-icon';
export * from './email-sent-icon';
export * from './headphone-icon';
export * from './modal-wrapper-close-icon';
export * from './modal-wrapper-back-icon';
export * from './umbrella-icon';
export * from './lock-open-icon';
export * from './pencil-modify-icon';
export * from './gender-icon';
export * from './car-icon';
export * from './upload-icon';
export * from './user-academic-cap-icon';
export * from './academic-cap-icon';
export * from './bulb-icon';
export * from './office-building-icon';
export * from './patners-icon';
export * from './restworld-black';
export * from './locale-social-media-icons';
export * from './the-fork-logo';
export * from './the-tripadvisior-logo';
export * from './locale-icon';
export * from './island-travel-umbrella-icon';
export * from './filter-icons';
export * from './apply-more-filters-icon';
export * from './domain-interest-icons';
export * from './map-loader-icon';
export * from './bell-alert-icon';
export * from './green-check-mark-with-white-bg';
export * from './private-mode-protection-circle-icon';
export * from './no-office-building-icon';
export * from './no-location-marker-icon';
export * from './share-icon';
export * from './chain-icon';
export * from './copy-icon';
export * from './clip-icon';
export * from './private-mode-protection-icon';
export * from './loader-icons';
export * from './italy-map-icon';
export * from './marker-pin-icon';
export * from './underline-marker-icon';
export * from './level-indicator-icon';
export * from './spoon-plate-icon';
export * from './sad-face-icon';
export * from './headphone-alt-icon';
export * from './restworld-purple-icon';