import React, { ReactNode, createContext, useContext, useState } from 'react';

// Define the type for the context value
interface EntryPointType {
  isBtnTriggeredWithoutCheck: boolean;
  setBtnTriggeredWithoutCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCVNotUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  isCVNotUploaded: boolean;
}
// Create a new context with the specified type
const AppContext = createContext<EntryPointType | undefined>(undefined);

// Create a custom hook to access the context value
const useEntryPointCheckListContext = (): EntryPointType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useEntryPointCheckListContext must be used within an EntryPointChecklistProvider');
  }
  return context;
};
// Create a provider component to wrap the app and provide the context value
const EntryPointChecklistProvider = ({ children }: { children: ReactNode }) => {
  const [isBtnTriggeredWithoutCheck, setBtnTriggeredWithoutCheck] = useState(false);
  const [isCVNotUploaded, setIsCVNotUploaded] = useState(false);
  const contextValue: EntryPointType = {
    isBtnTriggeredWithoutCheck,
    setBtnTriggeredWithoutCheck,
    setIsCVNotUploaded,
    isCVNotUploaded,
  };
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
export { EntryPointChecklistProvider, useEntryPointCheckListContext };
