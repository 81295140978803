import { Button } from '@restworld/ui-ds';
import { FacebookIcon } from '@restworld/utils-common';
import 'twin.macro';
import { useLogin } from 'react-facebook';

import { useSocialAuthService } from '@restworld/data-services';
import { useAuthContext } from '@restworld/feature-authentication';
import { useTranslation } from 'next-i18next';

export type FacebookButtonProps = {
  isModal?: boolean;
  closeModal?: () => void;
};

export function FacebookButton({ isModal = false, closeModal }: FacebookButtonProps) {
  const authContext = useAuthContext();
  const socialAuthService = useSocialAuthService();
  const { login } = useLogin();
  const { t } = useTranslation('auth-flow');
  async function onSubmit() {
    try {
      const fbResp = await login({
        scope: 'email',
      });
      socialAuthService
        .loginFacebook({ accessToken: fbResp.authResponse.accessToken })
        .then((res) => {
          const jwtToken = res?.data?.jwt || null;
          if (authContext?.verifyToken) {
            authContext.verifyToken(jwtToken);
          }
          if (isModal && closeModal) {
            closeModal();
          }
        })
        .catch((err) => {
          /**
           * Redirect to path
           */
        });
    } catch (error) {
      /**
       * To be implemented
       */
    }
  }

  return (
    <div tw="hidden">
      <Button variant="secondary-outline" tw="hidden" fullwidth={true} onClick={() => onSubmit()}>
        <div tw="flex flex-row items-center gap-2">
          <FacebookIcon />
          <div tw="text-center w-79.25 text-base leading-6 text-black">{t('facebook_login_button_text')}</div>
        </div>
      </Button>
    </div>
  );
}
