import * as React from 'react';
import { useCallback, useMemo } from 'react';
import 'twin.macro';
import omitBy from 'lodash.omitby';
import pickBy from 'lodash.pickby';
import tw, { styled } from 'twin.macro';
import { useRouter } from 'next/router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { OptionsType, formatExplorerOverallFilterData, useResponsiveBreakPoints } from '@restworld/utils-common';
import { JobExplorerQuickFilters, Title } from '@restworld/ui-ds';

import { isRestaurantsExplorerAtom } from './job-explorer-map';
import JobExplorerMoreFilter from './job-explorer-more-filter';
import { activeJpIdAtom, jobListParamsAtom } from './job-explorer-list.atoms';
import { jobCoordinateParamsAtom } from './job-explorer-map.atoms';
import { filterContextValuesAtom, moreFiltersAtom } from './job-explorer-filters.atoms';
import { domainInterestAtom } from './job-explorer-server.atoms';
interface IFiltersProps {
  isExplorerHomePage?: boolean;
  testId?: string;
}
export enum quickFilterIcons {
  sala = 'SalaIcon',
  cucina = 'CucinaIcon',
  bar = 'BarIcon',
  reception = 'ReceptionIcon',
}
export const domainInterests: OptionsType[] = [
  {
    key: 'sala',
    id: '',
    label: 'Sala',
    icon: quickFilterIcons.sala,
  },
  {
    key: 'cucina',
    id: '',
    label: 'Cucina',
    icon: quickFilterIcons.cucina,
  },
  {
    key: 'bar',
    id: '',
    label: 'Bar',
    icon: quickFilterIcons.bar,
  },
  {
    key: 'reception',
    id: '',
    label: 'Hotellerie',
    icon: quickFilterIcons.reception,
  },
];
export const ExplorerFilters: React.FunctionComponent<IFiltersProps> = ({ isExplorerHomePage, testId }) => {
  const { onQuickFilterChange } = useJobExploreFilters();
  const domainInterest = useAtomValue(domainInterestAtom);
  const filterContextValues = useAtomValue(filterContextValuesAtom);
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);

  const activeQuickFilters = useMemo(
    () =>
      isRestaurantsExplorer
        ? []
        : filterContextValues
        ? [...(filterContextValues['domain_interest'] ? filterContextValues['domain_interest'] : [])]
        : [],
    [filterContextValues, isRestaurantsExplorer]
  );

  const quickFilterItems = useMemo(
    () =>
      domainInterests.map((item) => ({
        ...item,
        id: domainInterest?.find(({ key }) => key === item.key)?.id,
      })),
    [domainInterest]
  );

  return (
    <FilterWrapper data-cy={testId} isExplorerHomePage={isExplorerHomePage}>
      <JobExplorerMoreFilter />
      <div tw="h-10 w-[1px] bg-gray-200" />
      <JobExplorerQuickFilters
        onQuickFilterChange={onQuickFilterChange}
        activeQuickFilters={activeQuickFilters}
        isDisabled={isRestaurantsExplorer}
        quickFilterItems={quickFilterItems}
      />
      {isRestaurantsExplorer ? <FilterNotAvailableBlurOverlay /> : null}
    </FilterWrapper>
  );
};
export default ExplorerFilters;

export const useJobExploreFilters = () => {
  const router = useRouter();
  const setMoreFilters = useSetAtom(moreFiltersAtom);
  const setFilterContextValues = useSetAtom(filterContextValuesAtom);
  const setActiveJpId = useSetAtom(activeJpIdAtom);
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const setJobCoordinateParams = useSetAtom(jobCoordinateParamsAtom);

  const onQuickFilterChange = useCallback(
    async (filterValues: string[]) => {
      try {
        setFilterContextValues((prevValues) => {
          const newValues = {
            ...prevValues,
            ...(filterValues.length ? { domain_interest: filterValues } : { domain_interest: undefined }),
            ...(prevValues.occupation_ids && Object.keys(prevValues.occupation_ids).length
              ? {
                  occupation_ids: Object.keys(prevValues.occupation_ids).reduce((acc, key) => {
                    if (filterValues.includes(key)) acc[key] = newValues.occupation_ids[key];
                    return acc;
                  }, {}),
                }
              : {}),
          };

          const formattedParams = formatExplorerOverallFilterData({ values: newValues });
          setJobListParams((prev) => ({
            ...pickBy(prev, (_, key) => ['lat', 'lon', 'radius', 'offset', 'limit', 'order_by'].includes(key)),
            ...formattedParams,
            ...{ offset: 0 },
          }));
          setJobCoordinateParams(formattedParams);
          setMoreFilters(omitBy(newValues, (value) => value === undefined));
          router.push(
            {
              query: !Object.keys(omitBy(newValues, (value) => value === undefined)).length
                ? omitBy(router.query, (_, key) => key === 'overall_filters')
                : {
                    ...router.query,
                    ...(Object.keys(newValues).length ? { overall_filters: JSON.stringify({ ...newValues }) } : {}),
                  },
            },
            null,
            {
              shallow: true,
            }
          );
          return newValues;
        });
        setActiveJpId('');
      } catch (error) {
        console.error(error, 'Error on quick filter change');
      }
    },
    [router, setActiveJpId, setFilterContextValues, setJobCoordinateParams, setJobListParams, setMoreFilters]
  );

  return {
    onQuickFilterChange,
  };
};
const FilterWrapper = styled.div<{ isExplorerHomePage?: boolean }>`
  ${tw`pt-0 lg:pt-0`};
  ${tw`flex flex-row gap-2 justify-start items-center relative`};
  // ${({ isExplorerHomePage }) => (isExplorerHomePage ? tw`lg:(pt-8 border-t border-gray-200)` : tw`pt-0 md:pt-0`)}
`;
const FilterNotAvailableBlurOverlay = () => {
  const { isMobile } = useResponsiveBreakPoints();
  const { t } = useTranslation();
  return (
    <div tw="absolute w-full h-full backdrop-blur-0.75 flex items-center">
      <div tw="px-2 py-1 md:(px-4 py-2) bg-white rounded-2xl shadow max-w-sm mx-auto flex flex-row gap-2">
        <LockClosedIcon className="w-6 h-6 text-primary-800" />
        <div tw="flex flex-col gap-0 md:gap-1">
          <Title
            content={`${t('job-explorer-page:we_are_working_on_this')}`}
            textColor="black"
            fontSize={isMobile ? 'small' : 'base'}
            fontWeight="semibold"
            twStyle={tw`leading-normal`}
          />
        </div>
      </div>
    </div>
  );
};
