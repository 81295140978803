import { BaseModal } from '@restworld/ui-ds';
import { ModalWrapperBackIcon, ModalWrapperCloseIcon } from '@restworld/utils-common';
import tw from 'twin.macro';

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showBackButton?: boolean;
  onBack?: () => void;
}

export const ModalWrapper = ({ open, onClose, children, showBackButton, onBack }: ModalWrapperProps) => {
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      modalStyles={[tw`lg:min-w-[31.75rem] lg:max-w-[31.75rem]`]}
      showBackButton={showBackButton}
      onBack={onBack}
      closeIcon={<ModalWrapperCloseIcon />}
      backIcon={<ModalWrapperBackIcon />}
      showMobileGestures={true}
    >
      {children}
    </BaseModal>
  );
};
