import { useEffect, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import uniqBy from 'lodash.uniqby';

import { useJobPositionsService } from '@restworld/data-services';

import {
  jobListParamsAtom,
  jobLocationPositionListAtom,
  jobLocationPositionListLoadingAtom,
  jobPositionTotalCountAtom,
  jobListHasLimitReachedAtom,
  jobListKeyAtom,
} from '../job-explorer-list.atoms';

export const useJobLocationPositionListQuery = () => {
  const [enabled, setEnabled] = useState(false);
  const jobPositionService = useJobPositionsService();
  const setJobLocationPositionList = useSetAtom(jobLocationPositionListAtom);
  const setJobPositionTotalCount = useSetAtom(jobPositionTotalCountAtom);
  const setJobLocationPositionListLoading = useSetAtom(jobLocationPositionListLoadingAtom);
  const jobListParams = useAtomValue(jobListParamsAtom);
  const queryKey = useAtomValue(jobListKeyAtom);
  const setJobListHasLimitReachedAtom = useSetAtom(jobListHasLimitReachedAtom);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEnabled(true);
    }, 450);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      setJobLocationPositionListLoading(true);
      const data = (await jobPositionService.getJobLocationPositionList(jobListParams, { signal })).data.data
        ?.explore_job_positions;
      setJobLocationPositionList((prev) => {
        if (jobListParams.offset > 0) return uniqBy([...prev, ...(data?.data ?? [])], 'id');
        return [...(data?.data ?? [])];
      });
      setJobPositionTotalCount(data?.hits ?? 0);
      setJobListHasLimitReachedAtom(data?.data.length === 0);
      setJobLocationPositionListLoading(false);
      return data ?? [];
    },
    placeholderData: (previousData) => previousData,
    enabled,
    staleTime: 0,
  });
};
