import { useState, useEffect, useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { useJobPositionsService } from '@restworld/data-services';
import { useToastMessageContext } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';

import { useJobPositionId } from './use-job-position-id';
import { isJobPositionFavoritedAtom } from '../components/job-position/job-position.atoms';

export const useFavoriteJobStatus = () => {
  const jobPositionId = useJobPositionId();
  const jobPositionDataService = useJobPositionsService();
  const setFavoriteJobPosition = useSetAtom(isJobPositionFavoritedAtom);
  const { isAuthenticated } = useAuth();
  const callJobPositionFavorite = useCallback(async () => {
    try {
      const response = await jobPositionDataService.isJobPositionFavorite(jobPositionId);
      setFavoriteJobPosition(response.data);
    } catch (error) {
      setFavoriteJobPosition(false);
      console.error(error, 'error getting favorite job position');
    }
  }, [jobPositionDataService, jobPositionId, setFavoriteJobPosition]);
  useEffect(() => {
    if (jobPositionId && isAuthenticated) callJobPositionFavorite();
  }, [jobPositionId, callJobPositionFavorite, isAuthenticated]);
};
export const useFavoriteJobPosition = () => {
  const setFavoriteJobPosition = useSetAtom(isJobPositionFavoritedAtom);
  const [loading, setLoading] = useState(false);
  const jobPositionId = useJobPositionId();
  const { setToastMessage } = useToastMessageContext();
  const jobPositionDataService = useJobPositionsService();
  const createFavoriteJobPosition = useCallback(
    async (email?: string) => {
      try {
        setLoading(true);
        await jobPositionDataService.createFavoriteJobPosition({ jobPositionId, email });
        if (!email) setFavoriteJobPosition(true);
        setLoading(false);
        setTimeout(() => {
          setToastMessage({
            message: 'Success',
            duration: 2000,
            visible: true,
          });
        }, 300);
      } catch (error) {
        setLoading(false);
        setFavoriteJobPosition(false);
        console.error(error, 'error creating favorite job position');
      }
    },
    [jobPositionDataService, jobPositionId, setFavoriteJobPosition, setToastMessage]
  );
  const deleteFavoriteJobPosition = useCallback(async () => {
    try {
      setLoading(true);
      await jobPositionDataService.deleteFavoriteJobPosition(jobPositionId);
      setLoading(false);
      setFavoriteJobPosition(false);
    } catch (error) {
      setLoading(false);
      console.error(error, 'error deleting favorite job position');
    }
  }, [jobPositionDataService, jobPositionId, setFavoriteJobPosition]);
  return { loading, createFavoriteJobPosition, deleteFavoriteJobPosition };
};
export const useFavoriteCallback = () => {
  const isJobPositionFavorited = useAtomValue(isJobPositionFavoritedAtom);
  const { createFavoriteJobPosition, deleteFavoriteJobPosition, loading } = useFavoriteJobPosition();
  const favoriteClickHandler = useCallback(
    async (email?: string) =>
      !isJobPositionFavorited ? createFavoriteJobPosition(email) : deleteFavoriteJobPosition(),
    [createFavoriteJobPosition, deleteFavoriteJobPosition, isJobPositionFavorited]
  );
  return {
    favoriteClickHandler,
    isJobPositionFavorited,
    loading,
  };
};
