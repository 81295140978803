import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';

import { BaseModal, EmployerCollaboratorFeedbackScreen } from '@restworld/ui-ds';
import { alreadyInvitedCollaboratorEmailAtom, useAlreadyInvitedCollaboratorModal } from '../../context/auth-flow';
import { useSignupUtils } from './hooks/use-signup-utils';

function AlreadyInvitedCollaboratorModal() {
  const router = useRouter();
  const { t } = useTranslation('auth-flow');
  const { closeModal: closeInvitedModal, isOpen: isInvitedModalOpen } = useAlreadyInvitedCollaboratorModal();
  const { onClose: onCloseSignupModal } = useSignupUtils({ isModal: true });
  const alreadyInvitedCollaboratorEmail = useAtomValue(alreadyInvitedCollaboratorEmailAtom);

  const goToLogin = useCallback(() => {
    router.push(`/login?email=${alreadyInvitedCollaboratorEmail}`);
    onCloseSignupModal();
    closeInvitedModal();
  }, [alreadyInvitedCollaboratorEmail, closeInvitedModal, onCloseSignupModal, router]);

  return (
    <BaseModal open={isInvitedModalOpen} onClose={closeInvitedModal}>
      <EmployerCollaboratorFeedbackScreen
        title={t('someone_already_invited_you_to_collaborate_in_restworld')}
        cta={{ title: t('go_to_login'), onClick: goToLogin }}
        content={t('try_to_login_or_check_your_inbox_for_the_invitation_email')}
      />
    </BaseModal>
  );
}
export default AlreadyInvitedCollaboratorModal;
