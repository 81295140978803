import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from '@restworld/feature-authentication';
import {
  ModalWrapperBackIcon,
  ModalWrapperCloseIcon,
  customImageLoader,
  getAssetSrc,
  getHoursElapsed,
  useResponsiveBreakPoints,
} from '@restworld/utils-common';
import Image from 'next/image';
import tw from 'twin.macro';
import { BaseModal, Button, Checkbox, Spinner, Title } from '@restworld/ui-ds';
import { useAccountsService } from '@restworld/data-services';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const LeadUserOnboardingPrompt = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [neverShowAgain, setNeverShowAgain] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isDesktop } = useResponsiveBreakPoints();
  const { isAuthenticated, user, initializeAuthentication } = useAuthContext();
  const isLeadUser = user?.type === 'lead';
  const promptLastShownAt = user?.lead_onboarding_read_at;
  const isActive = user?.lead_onboarding_popup_show;
  const router = useRouter();
  const accountService = useAccountsService();

  const handleUpdateLeadUserOnboarding = useCallback(async () => {
    try {
      await accountService.updateUser({
        lead_onboarding_popup_show: !neverShowAgain,
        lead_onboarding_read_at: new Date().toISOString(),
      });
      await initializeAuthentication();
    } catch (error) {
      console.error(error, 'Error updating lead onboarding read at');
    }
  }, [accountService, initializeAuthentication, neverShowAgain]);

  useEffect(() => {
    const moreThan24Hours = promptLastShownAt ? getHoursElapsed(promptLastShownAt) > 24 : undefined;
    if (isAuthenticated && isLeadUser && ((isActive && moreThan24Hours) || moreThan24Hours === undefined)) {
      setOpen(true);
      setNeverShowAgain(!isActive);
    } else {
      setOpen(false);
    }
  }, [isAuthenticated, isLeadUser, isActive, promptLastShownAt]);

  const handleCloseModal = useCallback(async () => {
    await handleUpdateLeadUserOnboarding();
    setOpen(false);
  }, [handleUpdateLeadUserOnboarding]);

  const onClickRedirect = useCallback(
    async ({ path }: { path: string }) => {
      setIsLoading(true);
      await handleUpdateLeadUserOnboarding();
      setIsLoading(false);
      router.push(path);
    },
    [handleUpdateLeadUserOnboarding, router]
  );

  if (isLoading) {
    return (
      <ScreenSpinnerWrapper>
        <Spinner size="lg" color="primary" />
      </ScreenSpinnerWrapper>
    );
  }

  return (
    <BaseModal
      open={open}
      onClose={handleCloseModal}
      modalStyles={[tw`lg:min-w-[31.75rem] lg:max-w-[31.75rem] lg:max-h-[calc(100vh - 4rem)]`]}
      closeIcon={<ModalWrapperCloseIcon />}
      backIcon={<ModalWrapperBackIcon />}
      showMobileGestures={true}
    >
      <div tw="flex flex-col items-center pt-5">
        <div tw="w-full flex lg:flex-col flex-col-reverse items-center lg:gap-10 gap-8">
          <Image
            src={getAssetSrc('/utils-assets/lead-user.svg')}
            alt={'Company logo'}
            width={isDesktop ? 438 : 241}
            height={isDesktop ? 254 : 197}
            loader={customImageLoader}
          />
          <div tw="w-full flex flex-col items-center lg:gap-10 gap-4">
            <Title variant="h2-large" textColor="black" twStyle={tw`lg:font-bold font-semibold`}>
              {t('job-explorer-page:here_you_are_one_of_us')}
            </Title>
            <div tw="w-full flex flex-col items-start gap-4">
              <Title variant="h6-large" textColor="gray-500">
                {t('job-explorer-page:welcome_to_the_restworld_community')}
              </Title>
              <Title variant="h6-large" textColor="gray-500">
                {t('job-explorer-page:now_you_can_save_apply_share_jobs')}
              </Title>
              <Title variant="h6-large" textColor="gray-500">
                {t('job-explorer-page:let_us_revolutionise_job_search_in_restaurant_industry')} 💪
              </Title>
            </div>
          </div>
        </div>
        <div tw="w-full flex flex-col items-start lg:my-10 mt-8 mb-6">
          <Checkbox
            onChange={(event) => {
              setNeverShowAgain(event.target.checked);
            }}
            variant="box"
            title={'Non mostrare più questo messaggio'}
            isChecked={neverShowAgain}
          />
        </div>
        <div tw="w-full lg:grid lg:grid-cols-2 flex flex-col-reverse  gap-2">
          <Button variant="secondary-outline" fullwidth onClick={() => onClickRedirect({ path: '/cerco-lavoro' })}>
            {t('job-explorer-page:explore_positions')}
          </Button>
          <Button variant="normal" fullwidth onClick={() => onClickRedirect({ path: '/candidati' })}>
            {t('job-explorer-page:complete_your_profile')}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
const ScreenSpinnerWrapper = tw.div`w-screen h-screen flex items-center justify-center`;
