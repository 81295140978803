globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './use-responsive-breakpoints';
export * from './use-mounted';
export * from './use-scroll';
export * from './use-browser-info';
export * from './use-smartlook';
export * from './use-address-additional-fields';
export * from './use-global-options-context';
export * from './use-variable-options-reducer';
export * from './use-status-options-reducer';
export * from './use-google-analytics';
export * from './on-router-back';
export * from './use-facebook-pixel';
export * from './use-modal';
export * from './use-gmaps-script';
export * from './use-form-with-schema';
export * from './use-cancel-token';
export * from './use-map-bounding-radius';
export * from './use-image-blob';
export * from './use-shorten-url';
export * from './use-ambassador-data';
export * from './use-avatar-image';
export * from './use-store-images';
export * from './data-service-hooks';
export * from './wizard';
export * from './use-occupation-by-domain-interest';
export * from './use-intersection-observer';
export * from './use-kv-query';
export * from './use-query-client-data';
export * from './use-kv';
export * from './use-query-client-state';
export * from './use-count-down-timer';
export * from './use-is-employer-app';
export * from './use-is-dev-server';