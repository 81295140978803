import tw, { styled } from 'twin.macro';

export function Separator() {
  return (
    <div tw="flex flex-row items-center gap-3.75 w-full">
      <StyledLine />
      <span tw="text-base text-black leading-none">o</span>
      <StyledLine />
    </div>
  );
}

const StyledLine = styled.div(() => [tw`flex flex-1 border-t  border-gray-400`]);
