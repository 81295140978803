import { atom } from 'jotai';

import { Status, Worker } from '@restworld/utility-types';
import { selectAtom } from 'jotai/utils';
import { FieldOptionsType } from '../worker-application-wizard';

export const workerDataLoadingAtom = atom<boolean>(false);

export const openWorkerDataLoader = atom(null, (_get, set) => {
  set(workerDataLoadingAtom, true);
});

export const closeWorkerDataLoader = atom(null, (_get, set) => {
  set(workerDataLoadingAtom, false);
});

export const workerAtom = atom<{ data: Worker | null }>({ data: null });
workerAtom.debugLabel = 'workerAtom';

export const getWorkerAtom = atom((get) => get(workerAtom));

export const setWorkerAtom = atom(null, (_get, set, newValue: Worker | null) => {
  set(workerAtom, (prev) => ({ ...prev, data: newValue }));
});

export const workerPersonalDetailsAtom = selectAtom(workerAtom, (worker) => ({
  user_id: worker?.data?.user_id,
  name: worker?.data?.name,
  surname: worker?.data?.surname,
  email: worker?.data?.user?.email,
  phone_number: worker?.data?.user?.phone_number,
  sex: worker?.data?.sex_var?.label,
  birthdate: worker?.data?.birthdate,
  address: worker?.data?.address,
  communication_channel_preferences: worker?.data?.communication_channel_preferences,
  other_communication_channel_preference: worker?.data?.other_communication_channel_preference,
}));

export const workerTransportationDetailsAtom = selectAtom(workerAtom, (worker) => ({
  driving_licences: worker?.data?.driving_licenses,
  transportation_options: worker?.data?.transportation_options,
  displacement_availability_var: worker?.data?.displacement_availability_var,
  displacement_preferences: worker?.data?.displacement_preferences,
}));

export const workerPresentationTextAtom = selectAtom(workerAtom, (worker) => ({
  presentation_text: worker?.data?.presentation,
  motivation_text: worker?.data?.motivation_text,
}));

export const workerProfileAtom = selectAtom(workerAtom, (worker) => ({
  user_id: worker?.data?.user_id,
  name: worker?.data?.name,
  surname: worker?.data?.surname,
  email: worker?.data?.user?.email,
  research_info: worker?.data?.research_info,
  research_status: worker?.data?.research_status,
  research_status_last_updated: worker?.data?.research_status_last_updated,
  picture: worker?.data?.main_picture,
  cv: worker?.data?.cv,
  public_cv_username: worker?.data?.public_cv_username,
}));

export const workerMediaAtom = selectAtom(workerAtom, (worker) => ({
  picture: worker?.data?.main_picture,
}));
export const resetWorkerAtom = atom(null, (_get, set) => {
  set(workerAtom, { data: null });
});

export const workerFieldOptionsAtom = atom<{ data: FieldOptionsType | null }>({ data: null });
export const getWorkerFieldOptionsAtom = atom((get) => get(workerFieldOptionsAtom));

export const researchStatusAtom = atom((get) => {
  const worker = get(workerAtom);
  return worker?.data?.research_status;
});

export const setResearchStatusAtom = atom(null, (_get, set, newValue: Status) => {
  set(workerAtom, (prev) => ({ ...prev, data: { ...prev.data, research_status: newValue } }));
});
export const setWorkerCvAtom = atom(null, (_get, set, newValue: string | undefined) => {
  set(workerAtom, (prev) => ({ ...prev, data: { ...prev.data, cv: newValue } }));
});

export const setWorkerFieldOptionsAtom = atom(null, (_get, set, newValue: FieldOptionsType) =>
  set(workerFieldOptionsAtom, (prev) => ({ ...prev, data: newValue }))
);

export const workerPastExperiencesAtom = selectAtom(workerAtom, (worker) => worker?.data?.past_experiences);

export const workerLanguagesAtom = selectAtom(workerAtom, (worker) => worker?.data?.languages);

export const workerTrainingsAtom = selectAtom(workerAtom, (worker) => worker?.data?.education_em);

export const workerCertificatesAtom = selectAtom(workerAtom, (worker) => worker?.data?.certificate);

export const workerPreferencesAtom = selectAtom(workerAtom, (worker) => ({
  research_info: worker?.data?.research_info,
  shifts: worker?.data?.shifts,
  workerJobTypes: worker?.data?.job_types,
}));
