import { ReactNode } from 'react';
import 'twin.macro';

import LayoutWrapper from '../../layouts/wrapper-layout';
import { JobPosition } from '@restworld/utility-types';
import { useAuth } from '@restworld/feature-authentication';
import { NextSeoProps } from 'next-seo';
import { BASE_URL, defaultSEO } from '@restworld/utils-common';
import { useRouter } from 'next/router';

export const JobPositionLayout = ({
  children,
  jobPositionStatic,
}: {
  children: ReactNode;
  jobPositionStatic?: Partial<JobPosition>;
}) => {
  const { isAuthenticated, user } = useAuth();
  const router = useRouter();
  let seoProps: NextSeoProps | undefined = undefined;
  if (jobPositionStatic) {
    const occupation = jobPositionStatic?.occupation?.label_it?.label;
    const title = jobPositionStatic?.job_title || occupation;
    const description = `Offerta di lavoro come ${occupation} selezionata da Restworld con indicazioni precise sullo stipendio e mansioni.`;
    const isActive = ['jp_screening', 'jp_shortlist_formation', 'jp_shortlist_sent'].includes(
      jobPositionStatic?.status_key
    );
    const metaTitle = jobPositionStatic?.meta_title || title;
    const metaDescription = jobPositionStatic?.meta_description || description;
    const images = [];
    if (jobPositionStatic?.meta_image_url) {
      images.push({
        url: jobPositionStatic.meta_image_url,
        width: 1200,
        height: 630,
        alt: metaTitle,
      });
    }
    const currentUrl = `${BASE_URL}${router.asPath}`;
    seoProps = {
      title,
      description,
      noindex: !isActive,
      openGraph: {
        siteName: defaultSEO.title,
        url: currentUrl,
        title: metaTitle,
        description: metaDescription,
        type: 'article',
        images: images.length > 0 ? images : undefined,
      },
    };
  }

  return (
    <LayoutWrapper
      hasFooter
      hasNavbar
      showSearchBarInNavbar={isAuthenticated ? user?.type !== 'employer' : true}
      title={jobPositionStatic?.job_title ?? 'Restworld | Offerta lavorativa'}
      seoProps={seoProps}
      testId="job-position-page"
    >
      {children}
    </LayoutWrapper>
  );
};
export default JobPositionLayout;
