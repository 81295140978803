import * as React from 'react';
import { useMemo, memo } from 'react';
import 'twin.macro';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { BASE_URL, sliceContent, useAmbassadorData, useAnalytics } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';

import { ExplorerMapPopupCard, ExplorerMapPopupCardProps } from './explorer-map-popup-card';
import { getSalary } from '../../../hooks';
import {
  isRestaurantsExplorerAtom,
  explorerRestaurantSelectedMarkerAtom,
} from '../job-explorer-restaurant-explorer.atoms';
import {
  getPopupDetailsAtom,
  mapPopupLoadingAtom,
  setPopupDetailsAtom,
  setPopupLocationCoordinatesAtom,
} from '../job-explorer-map.atoms';
import { jobListParamsAtom } from '../job-explorer-list.atoms';
import { checkIfJobPositionIsOutsideItaly } from '../../../utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPopupInfoElProps {}

export const PopupInfoEl: React.FunctionComponent<IPopupInfoElProps> = () => {
  const popupDetails = useAtomValue(getPopupDetailsAtom);
  const setPopupDetails = useSetAtom(setPopupDetailsAtom);
  const setPopupLocationCoordinates = useSetAtom(setPopupLocationCoordinatesAtom);
  const popupLoading = useAtomValue(mapPopupLoadingAtom);
  const ambData = useAmbassadorData();
  const auth = useAuth();
  const { trackEvent } = useAnalytics();
  const sortFilterParams = useAtomValue(jobListParamsAtom);
  const { locale } = useRouter();
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  const [explorerRestaurantSelectedMarker, setExplorerRestaurantSelectedMarker] = useAtom(
    explorerRestaurantSelectedMarkerAtom
  );

  const { shift_category_var, job_title, restaurant, semantic_unique_name, show_restaurant_details } =
    popupDetails || {};

  const salary = useMemo(() => getSalary({ jobPositionApiData: popupDetails }), [popupDetails]);

  const otherInfos = useMemo(() => {
    const shiftsTitle = shift_category_var?.label || '';
    const otherInfos: string[] = [];
    if (shiftsTitle) otherInfos.push(shiftsTitle);
    if (salary) otherInfos.push(salary);
    return otherInfos;
  }, [salary, shift_category_var?.label]);

  const images = useMemo(
    () => (restaurant?.pictures?.length ? restaurant?.pictures.map((v) => v.url) : []),
    [restaurant?.pictures]
  );

  const restaurantLogo = useMemo(
    () =>
      restaurant?.restaurant_logo
        ? restaurant?.restaurant_logo
        : restaurant?.restaurant_name
        ? `https://placehold.co/1440x990/white/black?text=${sliceContent(restaurant?.restaurant_name, 5)}`
        : `https://placehold.co/1440x990/white/black?text=N/A`,
    [restaurant?.restaurant_logo, restaurant?.restaurant_name]
  );

  const bgImage = useMemo(() => (images?.length ? images[0] : null), [images]);

  const explorerMapCardProps: ExplorerMapPopupCardProps = useMemo(
    () => ({
      ...(isRestaurantsExplorer
        ? { companyTitle: explorerRestaurantSelectedMarker?.restaurant_name }
        : { companyTitle: restaurant?.restaurant_name ?? '-' }),
      ...(!isRestaurantsExplorer ? { jpTitle: job_title ?? '-' } : {}),
      companyLocation: isRestaurantsExplorer
        ? explorerRestaurantSelectedMarker?.address ?? '-'
        : !show_restaurant_details
        ? restaurant?.locality ?? '-'
        : restaurant?.address ?? '-',
      ...(!isRestaurantsExplorer ? { otherInfos } : {}),
      onCardClose(e) {
        e.preventDefault();
        setPopupLocationCoordinates(null);
        if (isRestaurantsExplorer) setExplorerRestaurantSelectedMarker(null);
        else setPopupDetails({});
      },
      ...(!isRestaurantsExplorer
        ? { bgImage }
        : {
            bgImage: explorerRestaurantSelectedMarker?.pictures?.[0]?.url,
          }),
      ...(isRestaurantsExplorer
        ? { restaurantLogo: explorerRestaurantSelectedMarker?.restaurant_logo }
        : { restaurantLogo }),
      loading: popupLoading,
      ...(isRestaurantsExplorer ? {} : { showRestaurantDetails: show_restaurant_details }),
      isRestaurantsExplorer,
      ...(isRestaurantsExplorer && explorerRestaurantSelectedMarker?.gmaps_info?.info
        ? {
            googleMapsInfo: {
              rating: explorerRestaurantSelectedMarker?.gmaps_info.info.rating,
              reviews: explorerRestaurantSelectedMarker?.gmaps_info.info.user_ratings_total,
            },
          }
        : {}),
    }),
    [
      isRestaurantsExplorer,
      explorerRestaurantSelectedMarker?.restaurant_name,
      explorerRestaurantSelectedMarker?.address,
      explorerRestaurantSelectedMarker?.pictures,
      explorerRestaurantSelectedMarker?.restaurant_logo,
      explorerRestaurantSelectedMarker?.gmaps_info?.info,
      restaurant?.restaurant_name,
      restaurant?.locality,
      restaurant?.address,
      job_title,
      show_restaurant_details,
      otherInfos,
      bgImage,
      restaurantLogo,
      popupLoading,
      setPopupLocationCoordinates,
      setExplorerRestaurantSelectedMarker,
      setPopupDetails,
    ]
  );
  const recordViewJobPositionEvent = React.useCallback(() => {
    trackEvent('view_job', {
      email: auth?.user?.email,
      role: auth?.user?.type,
      origin: 'job_explorer_map',
      id: popupDetails?.id,
      job_title: popupDetails?.job_title,
      occupation: popupDetails?.occupation?.label_it?.label,
      salary_min: popupDetails?.min_salary,
      salary_max: popupDetails?.max_salary,
      job_type: popupDetails?.job_type_var?.label,
      published_at: popupDetails?.published_at,
      restaurant_name: popupDetails?.restaurant?.restaurant_name,
      city: popupDetails?.restaurant?.locality,
      searchParams: sortFilterParams,
    });
  }, [
    auth?.user?.email,
    auth?.user?.type,
    popupDetails?.id,
    popupDetails?.job_title,
    popupDetails?.job_type_var?.label,
    popupDetails?.max_salary,
    popupDetails?.min_salary,
    popupDetails?.occupation?.label_it?.label,
    popupDetails?.published_at,
    popupDetails?.restaurant?.locality,
    popupDetails?.restaurant?.restaurant_name,
    sortFilterParams,
    trackEvent,
  ]);

  const redirectUrl = useMemo(() => {
    if (isRestaurantsExplorer && explorerRestaurantSelectedMarker?.slug)
      return `${BASE_URL}/${locale}/locale/${explorerRestaurantSelectedMarker?.slug}`;
    if (semantic_unique_name) {
      const baseUrl = `${BASE_URL}/${locale}/posizione/${semantic_unique_name}`;
      return ambData?.ambassador_code
        ? `${baseUrl}?utm_source=referral&utm_medium=ambassador&utm_campaign=worker_acquisition&ref_code=${ambData?.ambassador_code}`
        : baseUrl;
    }
    return '';
  }, [
    ambData?.ambassador_code,
    explorerRestaurantSelectedMarker?.slug,
    isRestaurantsExplorer,
    locale,
    semantic_unique_name,
  ]);

  const redirectCallback = React.useCallback(() => {
    if (redirectUrl) window.open(redirectUrl, '_blank', 'noopener,noreferrer');
  }, [redirectUrl]);

  const onJobPositionPopupElClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (semantic_unique_name) recordViewJobPositionEvent();
      redirectCallback();
    },
    [recordViewJobPositionEvent, redirectCallback, semantic_unique_name]
  );
  const isJobPositionOutsideItaly = checkIfJobPositionIsOutsideItaly({
    address: popupDetails?.restaurant?.address,
    country: popupDetails?.restaurant?.country,
  });
  const jobPositionPopupEl = useMemo(
    () => (
      <div tw="cursor-pointer p-6 lg:p-0 z-10 md:z-[20]" onMouseDown={onJobPositionPopupElClick}>
        <ExplorerMapPopupCard {...explorerMapCardProps} isJobPositionOutsideItaly={isJobPositionOutsideItaly} />
      </div>
    ),
    [onJobPositionPopupElClick, explorerMapCardProps, isJobPositionOutsideItaly]
  );
  return jobPositionPopupEl;
};

export default memo(PopupInfoEl);
