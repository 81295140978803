globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './compute-range';
export * from './assets';
export * from './format-date';
export * from './slice-string-content';
export * from './open-in-new-tab';
export * from './ordinal-suffix';
export * from './custom-img-loader';
export * from './to-fixed';
export * from './scroll-to-view';
export * from './format-quick-filter-payload';
export * from './shared-config';
export * from './html-strip';
export * from './date-utils';
export * from './copy-to-clipboard';
export * from './email-validation-rules';
export * from './extract-api-errors';
export * from './format-google-opening-hours';
export * from './url-object-to-uri-string';