import { memo } from 'react';
import 'twin.macro';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  ContentType as ModalContentType,
  closeAuthFlowModal,
  closeEmailTokenSentModal,
  isEmailTokenSentModalOpen,
  setActiveContent as setActiveModalContent,
  updateEmailVerificationDetails,
} from '../../context/auth-flow';
import { LoginLinkSentForm, LoginLinkSentHeader } from './login-link-sent-form';

export const LoginLinkSentModal = memo(() => {
  const open = useAtomValue(isEmailTokenSentModalOpen);
  const closeEmailSentModal = useSetAtom(closeEmailTokenSentModal);
  const resetDetails = useSetAtom(updateEmailVerificationDetails);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const closeModal = useSetAtom(closeAuthFlowModal);

  function onClose() {
    resetDetails({
      email: '',
      waitTime: 0,
      tokenValidityTime: 0,
    });
    closeEmailSentModal();
    setActiveContent(ModalContentType.NO_CONTENT);
    closeModal();
  }

  if (open) {
    return (
      <>
        <LoginLinkSentHeader {...{ isModal: true }} />
        <LoginLinkSentForm {...{ isModal: true, onAcknowledgingEmailReception: onClose }} />
      </>
    );
  }
  return undefined;
});
