import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';

import { Period, Variable } from '@restworld/utility-types';
import { Title } from '@restworld/ui-ds';
import { formatGoogleOpeningHours } from '@restworld/utils-common';

import { companyProfileAtom } from '../../job-position/job-position.atoms';

export const CompanyServiceDetails: React.FunctionComponent = () => {
  const { clientTypes, restaurantTypes, openingHours = [] } = useAtomValue(companyProfileAtom);

  return <RestaurantWorkingHours {...{ clientTypes, restaurantTypes, openingHours }} />;
};

export type RestaurantWorkingHoursProps = {
  openingHours: Period[];
  clientTypes?: Variable[];
  restaurantTypes?: Variable[];
};

export const RestaurantWorkingHours = ({ openingHours, clientTypes, restaurantTypes }: RestaurantWorkingHoursProps) => {
  const { t } = useTranslation('common');
  return (
    <>
      {restaurantTypes?.length ? (
        <DataLabelComponent title={t('local_topology')} icon="BuildingOfficePurpleIcon" data={restaurantTypes} />
      ) : null}
      {clientTypes?.length ? (
        <DataLabelComponent title={t('customer_type')} icon="UserPurpleIcon" data={clientTypes} />
      ) : null}
      <RestaurantHours {...{ openingHours }} />
      {/* <ServiceOptions /> */}
    </>
  );
};

export default CompanyServiceDetails;
// --> components
const Wrapper = tw.div`flex flex-col gap-2`;
// --> reuseable component
const DataLabelComponent = ({
  title,
  data,
  icon,
}: {
  title: string;
  data: Variable[];
  icon: 'BuildingOfficePurpleIcon' | 'UserPurpleIcon' | 'ClockPurpleIcon';
}) => (
  <Wrapper>
    <Title content={title} icon={icon} fontSize="large" />
    <Title
      content={`${data.map(({ label }: { label: string }) => ` ${label}`)}`}
      fontWeight="normal"
      textColor="gray"
    />
  </Wrapper>
);

// ---> restaurant public hours
export const RestaurantHours = ({ openingHours }: Pick<RestaurantWorkingHoursProps, 'openingHours'>) => {
  const { t } = useTranslation('common');
  return openingHours.length ? (
    <Wrapper>
      <Title content={t('public_opening_hours')} icon="ClockPurpleIcon" fontSize="large" />
      <FormattedGoogleRestaurantHours openingHours={openingHours} />
    </Wrapper>
  ) : null;
};

const FormattedGoogleRestaurantHours = ({ openingHours }: Pick<RestaurantWorkingHoursProps, 'openingHours'>) => {
  const { t } = useTranslation('common');
  const workingHours = React.useMemo(() => formatGoogleOpeningHours(openingHours), [openingHours]);

  return React.useMemo(
    () => (
      <>
        {workingHours.map((hours, index) => {
          const [weekday, interval] = hours.split(': ');
          return (
            <RestaurantHoursListWrapper key={index}>
              <RestaurantHoursItem>
                <Title>{t(weekday)}</Title>
                <Title fontWeight="light" textColor="gray">
                  {interval === 'Closed' ? t('closed') : interval}
                </Title>
              </RestaurantHoursItem>
            </RestaurantHoursListWrapper>
          );
        })}
      </>
    ),
    [t, workingHours]
  );
};
const RestaurantHoursListWrapper = tw.ul`flex flex-col gap-2 w-full`;
const RestaurantHoursItem = styled.li`
  ${tw`flex justify-between`};
  > div:last-child {
    span {
      ${tw`tracking-normal`}
    }
  }
`;
