globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var navLinks = {
  worker: {
    primaryNav: [{
      key: 'applications',
      url: '/candidature',
      label: 'Le tue candidature'
    }, {
      key: 'job-offers',
      url: '/worker/offerte-di-lavoro',
      label: 'Offerte di lavoro'
    }, {
      key: 'academy',
      url: '/worker/academy',
      label: 'Academy'
    }, {
      key: 'talk-to-us',
      url: '!#',
      label: 'Parla con noi'
    }],
    secondaryNav: [{
      key: 'modify-password',
      url: '/account/edit',
      label: 'Modifica password'
    }, {
      key: 'get-back-to-home',
      url: '/',
      label: 'Torna al sito web'
    }, {
      key: 'logout',
      url: '!#',
      label: 'Logout'
    }]
  },
  "public": {
    primaryNav: [{
      key: 'worker-platform',
      url: '/worker',
      label: 'Cerco lavoro'
    }, {
      key: 'employer-platform',
      url: '/employer',
      label: 'Cerco staff'
    }, {
      key: 'social-responsibility',
      url: '/responsabilita-sociale',
      label: 'Responsabilità sociale'
    }, {
      key: 'who-we-are',
      url: '/chi-siamo',
      label: 'Chi siamo'
    }, {
      key: 'become a partner',
      url: '/diventa-partner',
      label: 'Diventa partner',
      isMobile: true
    }, {
      key: 'faq',
      url: '/faq',
      label: 'FAQ',
      isMobile: true
    }],
    secondaryNav: []
  }
};