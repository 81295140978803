/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import tw from 'twin.macro';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { BaseModal, ButtonV2, Title } from '@restworld/ui-ds';
import { Worker } from '@restworld/utility-types';
import { useWorkersService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';
import { useKv, useVariableOptions } from '@restworld/utils-common';

import { workerAtom } from '../components/profile/global-state';

const isGlobalJobPositionPreferencesModalVisible = atom<boolean>(false);

const useHasWorkerInterestedInAboardJobsConfirmPopupShownMutation = () => {
  const { setKV } = useKv();
  const { user } = useAuth();

  return useMutation({
    mutationFn: async (workerInterestedAboardJobsConfirmation: boolean) =>
      setKV({
        key: user?.id,
        value: JSON.stringify({
          workerInterestedAboardJobsConfirmation,
        }),
        type: 'worker_interested_aboard_jobs_confirmation',
      }),
    onError: (error) => {
      console.error('Error updating saved research', error);
    },
  });
};

const useWorkersWorkingAboardPreferenceMutation = () => {
  const workerService = useWorkersService();
  const { user } = useAuth();

  return useMutation({
    mutationFn: async ({ params }: { params: Partial<Worker> }) =>
      await workerService.updateWorker({
        workerId: user?.id,
        params,
      }),
    onError: (error) => {
      console.error('Error updating worker', error);
    },
  });
};
const useGlobalJobPositionPreferencesModal = () => {
  const { user } = useAuth();
  const router = useRouter();
  const [isWorkerInterestedInGlobalOffers, setIsWorkerInterested] = useState(false);
  const [isModalVisible, toggleModalVisibility] = useAtom(isGlobalJobPositionPreferencesModalVisible);
  const { mutate: showPopupMutation } = useHasWorkerInterestedInAboardJobsConfirmPopupShownMutation();
  const { mutate: updateWorkerPreferences, isSuccess, isPending } = useWorkersWorkingAboardPreferenceMutation();
  const { fetchVariable, variableOptions } = useVariableOptions();
  const workerData = useAtomValue(workerAtom).data;
  const { getKV } = useKv();

  const displacementPreferencesAboardOption = useMemo(
    () => variableOptions?.displacement_preference?.find((d) => d.key === 'displacement_abroad'),
    [variableOptions?.displacement_preference]
  );

  const isSettingsPage = router.pathname === '/impostazioni';
  const isProfilePage = router.pathname === '/profilo';
  const isJobPositionPage = router.pathname.includes('/posizione/');

  const getConfirmationFlag = useCallback(async () => {
    const flagData = (await getKV(user?.id))?.value;
    if (flagData) {
      const parsedFlagData = JSON.parse(JSON.parse(flagData).workerInterestedAboardJobsConfirmation);
      if (
        !parsedFlagData &&
        (isProfilePage || isSettingsPage || isJobPositionPage) &&
        !workerData?.displacement_preferences?.length
      ) {
        setTimeout(() => {
          toggleModalVisibility(true);
        }, 12000);
      }
    }
  }, [
    getKV,
    user?.id,
    isProfilePage,
    isSettingsPage,
    isJobPositionPage,
    workerData?.displacement_preferences?.length,
    toggleModalVisibility,
  ]);

  useEffect(() => {
    if (user?.id) getConfirmationFlag();
  }, [getConfirmationFlag, user?.id]);

  const onInterestedSection = useCallback(() => {
    const updatedPreferences = workerData?.displacement_preferences
      ? [
          ...workerData.displacement_preferences
            .filter((v) => v.displacement_preference_var.key !== 'displacement_abroad')
            .map((v) => ({
              displacement_preference_var: v.displacement_preference_var,
            })),
          {
            displacement_preference_var: displacementPreferencesAboardOption,
          },
        ]
      : [
          {
            displacement_preference_var: displacementPreferencesAboardOption,
          },
        ];

    updateWorkerPreferences({ params: { displacement_preferences: updatedPreferences } });
    showPopupMutation(true);
  }, [
    displacementPreferencesAboardOption,
    showPopupMutation,
    updateWorkerPreferences,
    workerData?.displacement_preferences,
  ]);

  const onClose = useCallback(() => {
    toggleModalVisibility(false);
    setTimeout(() => {
      setIsWorkerInterested(false);
    }, 250);
  }, [toggleModalVisibility]);

  const onHandleWorkerNotInterested = useCallback(() => {
    showPopupMutation(true);
    toggleModalVisibility(false);
  }, [toggleModalVisibility, showPopupMutation]);

  useEffect(() => {
    fetchVariable(['displacement_preference']);
  }, [fetchVariable]);

  useEffect(() => {
    if (isSuccess) setIsWorkerInterested(true);
  }, [isSuccess]);

  return {
    isModalVisible,
    isWorkerInterestedInGlobalOffers,
    isPending,
    onInterestedSection,
    onClose,
    onHandleWorkerNotInterested,
  };
};

export const GlobalJobPositionPreferencesModal = () => {
  const {
    isModalVisible,
    isWorkerInterestedInGlobalOffers,
    isPending,
    onInterestedSection,
    onClose,
    onHandleWorkerNotInterested,
  } = useGlobalJobPositionPreferencesModal();
  const { t } = useTranslation('common');
  const modalTitle = (
    <Title
      content={isWorkerInterestedInGlobalOffers ? t('profile_updated') : t('interested_in_foreign_offers')}
      fontWeight="bold"
      fontSize="superLarge"
      textColor="neutral-700"
      isCenter
    />
  );

  const modalFooter = isWorkerInterestedInGlobalOffers ? (
    <div tw="w-full flex justify-center">
      <ButtonV2 variant="primary" onClick={onClose}>
        {t('close')}
      </ButtonV2>
    </div>
  ) : (
    <div tw="w-full flex flex-col gap-4 lg:(flex-row justify-between items-center)">
      <ButtonV2 variant="tertiary" onClick={onHandleWorkerNotInterested}>
        {t('i_am_not_interested')}
      </ButtonV2>
      <ButtonV2 loading={isPending} variant="primary" onClick={onInterestedSection}>
        {t('yes_interested')}
      </ButtonV2>
    </div>
  );

  return (
    <BaseModal
      open={isModalVisible}
      onClose={() => {
        /* */
      }}
      title={modalTitle}
      footer={modalFooter}
      modalStyles={[tw`w-full lg:w-[42%] pb-4`]}
      showCloseIcon={false}
      disableDrag
    >
      <Title fontWeight="semibold" fontSize="base" textColor="neutral-200">
        {isWorkerInterestedInGlobalOffers ? (
          <Title fontWeight="semibold" fontSize="base" textColor="neutral-200">
            {t('thanks_for_response')}{' '}
            <Link href="/profilo" tw="underline underline-offset-4">
              {t('restworld_profile')}
            </Link>
          </Title>
        ) : (
          t('global_opportunities')
        )}
      </Title>
    </BaseModal>
  );
};
