import { useCallback, useEffect, useMemo, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import {
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  ViewStateChangeEvent,
  MapLayerMouseEvent,
  GeolocateResultEvent,
} from 'react-map-gl';
import type { GeoJSONSource } from 'react-map-gl';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import { pickBy } from 'lodash';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { MapWrapper, Spinner, Title } from '@restworld/ui-ds';
import { useMapBoundingRadius } from '@restworld/utils-common';

import { clusterLayer } from './map-layers';
import { useUserCoordinates } from '../job-explorer.hooks';
import { useOccupationPins, usePulsingDot } from './job-explorer-map.hooks';
import { ExplorerTypeToggler } from './explorer-type-toggler';
import {
  isRestaurantsExplorerAtom,
  explorerRestaurantZoomLevelAtom,
  explorerRestaurantListPayloadAtom,
} from '../job-explorer-restaurant-explorer.atoms';
import SeasonalRecommendationMapIconFilter from './seasonal-recommdation-mapicon-filter';
import { useExplorerLoading } from '../job-explorer-content';
import { WorkerHomeLocation } from './worker-home-location';
import {
  setUserCoordinatesAtom,
  fullScreenTogglerAtom,
  getZoomLevelAtom,
  getUserCoordinatesAtom,
  getCenterPointAtom,
  getToggleFullScreenAtom,
  getMapRefAtom,
  setZoomLevelAtom,
  setBoundingRadiusAtom,
  setCenterPointAtom,
} from '../job-explorer-map.atoms';
import { hasMapViewAtom, jobListParamsAtom } from '../job-explorer-list.atoms';
import { searchBarUrlParamsAtom } from '../job-explorer-filters.atoms';
import { serverListPayloadAtom } from '../job-explorer-server.atoms';
import { localityAddressAtom } from '../address-search-bar';

export const JobExplorerMap = ({ isDesktop, testId }: { isDesktop: boolean; testId?: string }) => {
  const [isMapTouch, setIsMapTouch] = useState(false);
  const router = useRouter();
  const hasMapView = useAtomValue(hasMapViewAtom);
  const zoomLevel = useAtomValue(getZoomLevelAtom);
  const userCoordinates = useAtomValue(getUserCoordinatesAtom);
  const centerPoint = useAtomValue(getCenterPointAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  const toggleFullScreen = useAtomValue(getToggleFullScreenAtom);
  const setBoundingRadius = useSetAtom(setBoundingRadiusAtom);
  const setCenterPoint = useSetAtom(setCenterPointAtom);
  const setJobListParams = useSetAtom(jobListParamsAtom);

  const setExplorerRestaurantListPayload = useSetAtom(explorerRestaurantListPayloadAtom);
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);

  const pulsingEl = usePulsingDot({ mapRef, userCoordinates });
  const pins = useOccupationPins();
  const { handleZoomEndCb, onClick, onGeolocate } = useJobExplorerMap();

  const initialViewState = useMemo(() => {
    const [longitude, latitude] = centerPoint;
    return {
      longitude,
      latitude,
      zoom: zoomLevel,
    };
  }, [centerPoint, zoomLevel]);

  const interactiveLayerIds = useMemo(() => (clusterLayer.id ? [clusterLayer.id] : []), []);

  const { getRadius } = useMapBoundingRadius({ map: mapRef?.current });

  const shallowChangeURLOnMove = useCallback(
    (evt: ViewStateChangeEvent) => {
      router.push(
        {
          query: {
            ...router.query,
            map_filters: JSON.stringify(
              pickBy(evt.viewState, (_, key) => ['longitude', 'latitude', 'zoom'].includes(key))
            ),
          },
        },
        null,
        {
          shallow: true,
        }
      );
    },
    [router]
  );

  const onMove = useCallback(
    (evt: ViewStateChangeEvent) => {
      const radius = getRadius({ lon: evt.viewState.longitude, lat: evt.viewState.latitude });
      if (isRestaurantsExplorer) {
        setExplorerRestaurantListPayload({
          lon: evt.viewState.longitude,
          lat: evt.viewState.latitude,
          radius,
          offset: 0,
          limit: 9,
        });
        // shallowChangeURLOnMove(evt);
        return;
      }
      setBoundingRadius(radius);
      setJobListParams((p) => {
        const newPayload = {
          ...p,
          ...{ lon: evt.viewState.longitude, lat: evt.viewState.latitude },
          radius,
          offset: 0,
        };
        return { ...newPayload };
      });
      setCenterPoint([evt.viewState.longitude, evt.viewState.latitude]);
      // shallowChangeURLOnMove(evt);
    },
    [
      getRadius,
      isRestaurantsExplorer,
      // shallowChangeURLOnMove,
      setBoundingRadius,
      setCenterPoint,
      setExplorerRestaurantListPayload,
      setJobListParams,
    ]
  );
  const explorerLoading = useExplorerLoading();

  const geoControlEl = useMemo(
    () => (
      <GeolocateControl
        onGeolocate={onGeolocate}
        showUserLocation={false}
        positionOptions={{ enableHighAccuracy: true }}
        style={{
          margin: !isDesktop ? '0px 24px 0px 0px' : '80px 24px 0 0',
          boxShadow: theme`boxShadow.lg`,
          borderRadius: theme`borderRadius.lg`,
        }}
        trackUserLocation
        position={isDesktop ? 'top-right' : 'bottom-right'}
      />
    ),
    [isDesktop, onGeolocate]
  );
  const setLocalityAddress = useSetAtom(localityAddressAtom);

  useEffect(() => {
    mapRef.current?.on('moveend', (evt) => {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            map_filters: JSON.stringify(
              pickBy(evt.viewState, (_, key) => ['longitude', 'latitude', 'zoom'].includes(key))
            ),
          },
        },
        null,
        {
          shallow: true,
        }
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef?.current, router]);

  return (
    <Wrapper data-cy={testId} onMouseEnter={() => setIsMapTouch(true)} toggleFullScreen={toggleFullScreen}>
      <MapWrapper
        cooperativeGestures={false}
        initialViewState={initialViewState}
        interactiveLayerIds={interactiveLayerIds}
        handleZoomEnd={handleZoomEndCb}
        onClick={onClick}
        zoom={zoomLevel}
        onMove={debounce(onMove, 500)}
        mapRef={mapRef}
        onDrag={debounce((evt) => {
          setLocalityAddress({});
          isMapTouch && shallowChangeURLOnMove(evt);
        }, 700)}
        onZoom={debounce((evt) => isMapTouch && shallowChangeURLOnMove(evt), 700)}
      >
        {/* {jobLocationCoordinatesLoading ? (
          <div tw="absolute top-0 left-0 w-full h-full bg-white/80 flex justify-center items-center z-20">
            <MapLoader isLoading={jobLocationCoordinatesLoading} />
          </div>
        ) : null} */}
        {isDesktop && hasMapView && explorerLoading && <MapSpinner />}
        {isDesktop ? <FullScreenCustomControl /> : null}
        {geoControlEl}
        {isDesktop ? (
          <NavigationControl
            showCompass={false}
            visualizePitch
            style={{ boxShadow: theme`boxShadow.lg`, borderRadius: theme`borderRadius.lg` }}
          />
        ) : null}
        <ScaleControl />
        {!isRestaurantsExplorer && <SeasonalRecommendationMapIconFilter />}
        <WorkerHomeLocation />
        {pins}
        {pulsingEl}
        <ExplorerTypeToggler />
      </MapWrapper>
    </Wrapper>
  );
};
const useMapRedirection = () => {
  const serverListPayload = useAtomValue(serverListPayloadAtom);
  const { isSearchBarUrlParamsAvailable, locationUrlParams, jobListUrlParams, mapFiltersURLParams } =
    useAtomValue(searchBarUrlParamsAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  useEffect(() => {
    try {
      if (!isSearchBarUrlParamsAvailable) return;
      if (locationUrlParams?.value?.lat) {
        /* in case of locationUrlParams is exist from search bar */
        mapRef.current?.flyTo({
          duration: 1000,
          center: [locationUrlParams.value?.lng, locationUrlParams.value.lat],
          zoom: locationUrlParams.value?.['zoom'] ?? 5,
        });
      } else if (serverListPayload?.lon) {
        mapRef.current?.flyTo({
          duration: 1000,
          center: [serverListPayload?.lon, serverListPayload?.lat],
          zoom: serverListPayload?.['zoom'] ?? 10,
        });
      } else if (mapFiltersURLParams?.['latitude']) {
        /* in case of map params filter exists */
        mapRef.current?.flyTo({
          duration: 1000,
          center: [mapFiltersURLParams['longitude'], mapFiltersURLParams['latitude']],
          zoom: mapFiltersURLParams?.['zoom'] ?? 5,
        });
      } else if (jobListUrlParams?.['user_location_lon']) {
        mapRef.current?.flyTo({
          duration: 1000,
          center: [+jobListUrlParams?.user_location_lon, +jobListUrlParams?.user_location_lat],
          zoom: 7,
        });
      }
    } catch (error) {
      console.error('Error parsing search bar filter JSON:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSearchBarUrlParamsAvailable,
    jobListUrlParams,
    locationUrlParams?.value,
    mapFiltersURLParams,
    mapRef?.current,
    serverListPayload,
  ]);
};
export default JobExplorerMap;
const useJobExplorerMap = () => {
  const mapRef = useAtomValue(getMapRefAtom);
  const setUserCoordinates = useSetAtom(setUserCoordinatesAtom);
  const setZoomLevel = useSetAtom(setZoomLevelAtom);
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  const setExplorerRestaurantZoomLevel = useSetAtom(explorerRestaurantZoomLevelAtom);
  useUserCoordinates({ map: mapRef?.current });
  useMapRedirection();
  const handleZoomEndCb = useCallback(
    (e: ViewStateChangeEvent) => {
      if (isRestaurantsExplorer) {
        setExplorerRestaurantZoomLevel(e.viewState.zoom);
        return;
      }
      setZoomLevel(e.viewState.zoom);
    },
    [isRestaurantsExplorer, setExplorerRestaurantZoomLevel, setZoomLevel]
  );

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const feature = event?.features?.[0] as any;

      const clusterId = feature?.properties?.['cluster_id'];

      const mapboxSource = mapRef.current?.getSource('job-positions') as GeoJSONSource;

      mapboxSource?.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        const coordinates = feature?.['geometry']?.['coordinates'];
        if (coordinates?.length)
          mapRef.current?.easeTo({
            center: coordinates,
            zoom,
            duration: 500,
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapRef?.current]
  );

  const onGeolocate = useCallback(
    (v: GeolocateResultEvent) => setUserCoordinates({ lat: v.coords.latitude, lon: v.coords.longitude }),
    [setUserCoordinates]
  );
  return {
    handleZoomEndCb,
    onClick,
    onGeolocate,
  };
};
const MapSpinner = () => (
  <div tw="absolute bottom-[28%] lg:bottom-[15%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 w-[88px] h-[48px] flex justify-center items-center bg-white border rounded-lg [border-color: #E5E7EB]">
    <Spinner color="primary" size="sm" />
  </div>
);
export const useOffset = () => useCallback((index: number) => index * 0.00001, []);
const Wrapper = styled.div<{
  toggleFullScreen?: boolean;
}>`
  ${tw`relative w-full h-[calc(100svh - 12rem)] lg:h-[calc(100vh - 11rem)]`}
  ${({ toggleFullScreen }) => (toggleFullScreen ? tw`lg:h-[calc(100vh - 7.5rem)]` : '')}
  .mapboxgl-map {
    ${tw`rounded h-full w-full`};
  }
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin: 24px 24px 0 0;
  }
  .mapboxgl-ctrl-bottom-right {
    ${tw`fixed bottom-[20%] lg:(absolute bottom-0 right-0)`}//TODO: do better
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url(https://worker.restworld.it/utils-assets/map-plus-icon.svg);
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url(https://worker.restworld.it/utils-assets/map-minus-icon.svg);
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
    background-image: url(https://worker.restworld.it/utils-assets/map-geolocate-icon.svg);
  }
  .mapboxgl-ctrl-group button {
    ${tw`w-[40px] h-[40px]`}
  }
`;
const FullScreenCustomControl = () => {
  const mapRef = useAtomValue(getMapRefAtom);
  const toggleFullScreen = useAtomValue(getToggleFullScreenAtom);
  const fullScreenToggler = useSetAtom(fullScreenTogglerAtom);
  const { t } = useTranslation('common');
  return (
    <div
      onClick={() => {
        mapRef.current?.triggerRepaint();
        fullScreenToggler();
      }}
      tw="absolute top-5 right-6 h-[40px] max-w-[6rem] bg-white border border-gray-200 shadow-lg p-2 rounded-lg cursor-pointer hover:bg-gray-200"
    >
      <div tw="flex flex-row gap-2">
        {toggleFullScreen ? <MapUnZoomIcon /> : <MapZoomIcon />}
        <Title isPointer content={toggleFullScreen ? t('reduce') : t('expand')} fontSize="small" fontWeight="medium" />
      </div>
    </div>
  );
};
const MapZoomIcon = ({ stroke = theme`colors.gray.900`, ...rest }: { stroke?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...rest}>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M20 20h-4M4 8V4v4zm0-4h4-4zm0 0l5 5-5-5zm16 4V4v4zm0-4h-4 4zm0 0l-5 5 5-5zM4 16v4-4zm0 4h4-4zm0 0l5-5-5 5zm16 0l-5-5 5 5zm0 0v-4 4z"
    />
  </svg>
);
const MapUnZoomIcon = ({ stroke = theme`colors.gray.900`, ...rest }: { stroke?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 5v4m0 0H5m4 0L4 4m11 1v4m0 0h4m-4 0l5-5M9 19v-4m0 0H5m4 0l-5 5m11-5l5 5m-5-5v4m0-4h4"
    />
  </svg>
);
export type ClusterType =
  | GeoJSON.Feature<GeoJSON.Geometry>
  | GeoJSON.FeatureCollection<GeoJSON.Geometry>
  | GeoJSON.Geometry
  | string;
