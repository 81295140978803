import { useAccountsService } from '@restworld/data-services';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ContentType as ModalContentType,
  closeAuthFlowModal,
  closeOtpVerificationModal,
  setActiveContent as setActiveModalContent,
} from '../../../context/auth-flow';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAuthContext } from '@restworld/feature-authentication';
import { useTranslation } from 'next-i18next';
import { useAnalytics } from '@restworld/utils-common';
import { smsSentCountAuthAtom } from './use-phone-number-login-form';

export type UseOTPFormProps = {
  phoneNumber: string;
  isModal?: boolean;
};

export interface OtpCodeInputForm {
  otpCode: number;
}

export function useOTPForm({ phoneNumber, isModal = true }: UseOTPFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm<OtpCodeInputForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [serverValidationFailed, setServerValidationFailed] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const acc = useAccountsService();
  const setActiveContent = useSetAtom(setActiveModalContent);
  const closeModal = useSetAtom(closeAuthFlowModal);
  const onCloseOtpVerificationModal = useSetAtom(closeOtpVerificationModal);
  const authContext = useAuthContext();
  const { t } = useTranslation('auth-flow');
  const { trackEvent } = useAnalytics();
  const smsSentCount = useAtomValue(smsSentCountAuthAtom);

  async function closeTimeout() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, 1000);
    });
  }
  const handleLogin = useCallback(
    async (data: OtpCodeInputForm) => {
      setLoading(true);
      try {
        const response: AxiosResponse = await acc.verifyOTPCode({ otpCode: data.otpCode.toString(), phoneNumber });
        const jwtToken = response?.data?.jwt || undefined;
        setIsSuccess(true);
        trackEvent('worker_request_otp_for_login', {
          successful_otp_validation: true,
          sms_sent_count: smsSentCount,
          phone_number: phoneNumber,
        });
        if (authContext?.verifyToken) {
          authContext.initializeAuthentication(jwtToken);
          trackEvent('complete_signin', {
            method: 'phone_number',
            phone_number: phoneNumber,
            user_id: authContext?.user?.id,
          });
        }
        await closeTimeout();
        setActiveContent(ModalContentType.NO_CONTENT);
        if (isModal) {
          onCloseOtpVerificationModal();
          closeModal();
        }
        setLoading(false);
        // trackEvent('Auth flow', {
        //   phase: 'Phone number login',
        //   step: 'OTP code validated',
        //   mode: logMode,
        // });
      } catch (error) {
        setLoading(false);
        setValue('otpCode', null);
        setError('otpCode', { message: t('phone_number_login_otp_validation_failed') });
        setServerValidationFailed(true);
        // trackEvent('Auth flow', {
        //   phase: 'Phone number login',
        //   step: 'OTP code validation',
        //   message: 'OTP code validation failed',
        //   mode: logMode,
        //   type: 'error',
        // });
      }
    },
    [
      acc,
      phoneNumber,
      trackEvent,
      smsSentCount,
      authContext,
      setActiveContent,
      isModal,
      onCloseOtpVerificationModal,
      closeModal,
      setValue,
      setError,
      t,
    ]
  );

  const onSubmit = async (data: OtpCodeInputForm) => {
    handleLogin(data);
  };

  return {
    loading,
    onSubmit,
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setValue,
    setError,
    serverValidationFailed,
    setServerValidationFailed,
    isSuccess,
  };
}
