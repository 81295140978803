import { useCallback } from 'react';
import { useAtom } from 'jotai';

import { useSpontaneousCoverLetterEntryContext } from './spontaneous-apply-context';
import { setHasUserAppliedSpontaneousApplicationAtom } from '../../worker-entry-point';
import { useApplyJob } from '../../../hooks/use-apply-job';

export const useSpontaneousApplyCallback = ({
  restaurantID,
  workerId,
}: {
  restaurantID?: string;
  workerId?: string;
}) => {
  const {
    onChangeCoverLetterModalSection: onChangeModalSection,
    coverLetter,
    toggleLoading: setLoading,
  } = useSpontaneousCoverLetterEntryContext();
  const { applyJobPosition } = useApplyJob({
    callUserAuth: false,
  });
  const [, setHasUserAppliedSpontaneousApplication] = useAtom(setHasUserAppliedSpontaneousApplicationAtom);
  const onApplicationApply = useCallback(
    async ({ showSuccess = true }: { showSuccess?: boolean } = {}) => {
      if (workerId) {
        setLoading(true);
        const response = await applyJobPosition({
          workerIdArg: workerId,
          restaurantId: restaurantID,
          coverLetter,
          source: 'restaurant_showcase',
        });
        if (response?.jwt) {
          setHasUserAppliedSpontaneousApplication(true);
          if (showSuccess) onChangeModalSection('application-success-component');
          // if (router?.query?.id && router.query.isSpontaneousEntry === 'true') router.push(`/locale/${router.query.id}`);
        }
        setLoading(false);
        return response;
      }
    },
    [
      applyJobPosition,
      coverLetter,
      onChangeModalSection,
      restaurantID,
      setHasUserAppliedSpontaneousApplication,
      setLoading,
      workerId,
    ]
  );
  return { onApplicationApply };
};
