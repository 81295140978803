var _process$env$NEXT_PUB;

globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback, useMemo } from 'react';
import Smartlook from 'smartlook-client';
var smartLookKey = (_process$env$NEXT_PUB = process.env['NEXT_PUBLIC_SMARTLOOK_KEY']) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '';
export var useSmartlook = function useSmartlook() {
  // const { user, isAuthenticated } = useAuth();
  var isServer = useMemo(function () {
    return false;
  }, []); // useEffect(() => {
  //   if (!isAuthenticated || !user || !Smartlook.initialized()) return;
  //   Smartlook.identify(user.id, {});
  // }, [isAuthenticated, user]);

  var initialize = useCallback(function () {
    if (!smartLookKey || isServer || Smartlook.initialized()) return;
    Smartlook.init(smartLookKey, {
      region: 'eu'
    }); // isAuthenticated && !!user?.id && Smartlook.identify(user.id, {});
  }, [isServer]);
  return {
    initialize: initialize
  };
};