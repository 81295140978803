globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var customImageLoader = function customImageLoader(_ref) {
  var src = _ref.src,
      width = _ref.width;
  return "".concat(src, "?w=").concat(width);
};