import { ReactNode, useCallback, useMemo, useState, createContext, useContext } from 'react';

import { ModalSectionType } from '@restworld/ui-ds';

interface SpontaneousCoverLetterEntryContextType {
  applicationType: {
    isStarted: boolean;
    isButtonClicked: boolean;
    hasApplied: boolean;
  };
  setApplicationType: React.Dispatch<
    React.SetStateAction<{
      isStarted: boolean;
      isButtonClicked: boolean;
      hasApplied: boolean;
    }>
  >;
  coverLetterModalSection: ModalSectionType;
  isExitConfirmation: boolean;
  onChangeCoverLetterModalSection: React.Dispatch<React.SetStateAction<ModalSectionType>>;
  toggleExitConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  toggleLoading: React.Dispatch<React.SetStateAction<boolean>>;
  coverLetter: string;
  setCoverLetterInput: React.Dispatch<React.SetStateAction<string>>;
}

const useSpontaneousCoverLetterEntryApplicationStatus = () => {
  const [applicationType, setApplicationType] = useState<SpontaneousCoverLetterEntryContextType['applicationType']>({
    isStarted: false,
    isButtonClicked: false,
    hasApplied: false,
  });
  const [coverLetterModalSection, setCoverLetterModalSection] = useState<ModalSectionType>(
    'spontaneous-apply-confirmation'
  );
  const [isExitConfirmation, setIsExitConfirmation] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [coverLetter, setCoverLetter] = useState<string>('');

  const toggleLoading = useCallback((flag: boolean) => setLoading(flag), []);
  const onChangeCoverLetterModalSection = useCallback(
    (value: ModalSectionType) => setCoverLetterModalSection(value),
    []
  );
  const toggleExitConfirmation = useCallback((flag: boolean) => setIsExitConfirmation(flag), []);
  const setCoverLetterInput = useCallback((v: string) => setCoverLetter(v), []);

  return useMemo(
    () =>
      ({
        applicationType,
        setApplicationType,
        coverLetterModalSection,
        isExitConfirmation,
        onChangeCoverLetterModalSection,
        toggleExitConfirmation,
        loading,
        toggleLoading,
        coverLetter,
        setCoverLetterInput,
      } as SpontaneousCoverLetterEntryContextType),
    [
      applicationType,
      coverLetter,
      coverLetterModalSection,
      isExitConfirmation,
      loading,
      onChangeCoverLetterModalSection,
      setCoverLetterInput,
      toggleExitConfirmation,
      toggleLoading,
    ]
  );
};

const SpontaneousCoverLetterEntryContext = createContext<SpontaneousCoverLetterEntryContextType | null>(null);
const SpontaneousCoverLetterEntryContextProvider = ({ children }: { children: ReactNode }) => (
  <SpontaneousCoverLetterEntryContext.Provider value={useSpontaneousCoverLetterEntryApplicationStatus()}>
    {children}
  </SpontaneousCoverLetterEntryContext.Provider>
);

const useSpontaneousCoverLetterEntryContext = () => {
  const context = useContext(SpontaneousCoverLetterEntryContext);
  if (!context) {
    throw new Error(
      'useSpontaneousCoverLetterEntryContext must be used within a SpontaneousCoverLetterEntryContextProvider'
    );
  }
  return context;
};

export { useSpontaneousCoverLetterEntryContext, SpontaneousCoverLetterEntryContextProvider };
