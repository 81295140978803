import tw, { styled } from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { useResponsiveBreakPoints } from '@restworld/utils-common';
import { Wrapper, Title, Timeline, JobShareModal } from '@restworld/ui-ds';

// import { useRecordJobShareEvent } from '../../../../hooks/use-record-job-share-event';
import { JobApplicationEntryPoint } from '../../';
import { useJobPositionId } from '../../../../hooks';

/* eslint-disable-next-line */
export interface JobApplicationStepsProps {
  ambassadorRefCode?: string;
}

export function JobApplicationSteps({ ambassadorRefCode }: JobApplicationStepsProps) {
  const { isDesktop } = useResponsiveBreakPoints();
  const jobPositionId = useJobPositionId();
  const { t: jobPositionTranslate } = useTranslation('job-position-page');
  // const { recordJobShareEvent } = useRecordJobShareEvent();

  const timeLineSteps = useMemo(
    () => [
      {
        key: 1,
        title: jobPositionTranslate('job_timeline.timeline_step_1.title'),
        subtitle: jobPositionTranslate('job_timeline.timeline_step_1.description'),
      },
      {
        key: 2,
        title: jobPositionTranslate('job_timeline.timeline_step_2.title'),
        subtitle: jobPositionTranslate('job_timeline.timeline_step_2.description'),
      },
      {
        key: 3,
        title: jobPositionTranslate('job_timeline.timeline_step_3.title'),
        subtitle: jobPositionTranslate('job_timeline.timeline_step_3.description'),
      },
      {
        key: 4,
        title: jobPositionTranslate('job_timeline.timeline_step_4.title'),
        subtitle: jobPositionTranslate('job_timeline.timeline_step_4.description'),
      },
      {
        key: 5,
        title: jobPositionTranslate('job_timeline.timeline_step_5.title'),
        subtitle: jobPositionTranslate('job_timeline.timeline_step_5.description'),
        isFlagged: true,
      },
    ],
    [jobPositionTranslate]
  );
  return (
    <Wrapper>
      <Title
        content={jobPositionTranslate('job_timeline.title')}
        icon="InformationCircleIcon"
        variant="h4-large"
        fontWeight="semibold"
        textColor="black"
      />
      <Timeline timeLineSteps={timeLineSteps} />
      {!isDesktop && (
        <ButtonWrapper>
          <JobShareModal
            jobPositionId={jobPositionId}
            ambassadorRefCode={ambassadorRefCode}
            icon="ShareIcon"
            buttonTitle="Condividi"
            fullwidth
            // onClick={recordJobShareEvent}
          />
          <JobApplicationEntryPoint />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
}
export default JobApplicationSteps;
const ButtonWrapper = styled.div`
  ${tw`flex gap-4 text-lg justify-center items-center lg:(w-full max-w-[21rem] flex-row-reverse)`}
  > button:last-child {
    ${tw`lg:w-4/5`}
  }
`;
