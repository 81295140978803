import { useCallback, useMemo } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { Button } from '@restworld/ui-ds';

import {
  isRestaurantsExplorerAtom,
  explorerRestaurantListPayloadAtom,
  restaurantHasLimitReachedAtom,
  explorerRestaurantListLoadingAtom,
} from '../job-explorer-restaurant-explorer.atoms';
import {
  hasMapViewAtom,
  jobListParamsAtom,
  jobLocationPositionListLoadingAtom,
  jobListHasLimitReachedAtom,
} from '../job-explorer-list.atoms';

export const PaginationButton = () => {
  const hasMapView = useAtomValue(hasMapViewAtom);
  const [, setJobListParams] = useAtom(jobListParamsAtom);
  const setExplorerRestaurantListPayload = useSetAtom(explorerRestaurantListPayloadAtom);
  const jobLocationPositionListLoading = useAtomValue(jobLocationPositionListLoadingAtom);
  const explorerRestaurantListLoading = useAtomValue(explorerRestaurantListLoadingAtom);
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  const hasFetchRestaurantListLimitReached = useAtomValue(restaurantHasLimitReachedAtom);
  const jobListHasLimitReached = useAtomValue(jobListHasLimitReachedAtom);

  const onClickHandler = useCallback(() => {
    if (isRestaurantsExplorer) {
      setExplorerRestaurantListPayload((p) => ({ ...p, offset: p.offset + 9 }));
      return;
    }
    setJobListParams((p) => ({ ...p, offset: p.offset + 9 }));
  }, [isRestaurantsExplorer, setExplorerRestaurantListPayload, setJobListParams]);

  const disabled = useMemo(
    () =>
      isRestaurantsExplorer
        ? hasFetchRestaurantListLimitReached || explorerRestaurantListLoading
        : jobLocationPositionListLoading || jobListHasLimitReached,
    [
      hasFetchRestaurantListLimitReached,
      jobListHasLimitReached,
      explorerRestaurantListLoading,
      isRestaurantsExplorer,
      jobLocationPositionListLoading,
    ]
  );

  const { t } = useTranslation('common');

  return (
    <Button
      onClick={onClickHandler}
      title={isRestaurantsExplorer ? t('show_more_restaurants') : t('show_more_positions')}
      variant="secondary-outline"
      disabled={disabled}
      twStyle={hasMapView ? tw`mt-4` : tw``}
      fullwidth
      testId="explorer-pagination-button"
    />
  );
};
