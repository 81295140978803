import { atom } from 'jotai';

import { JobPosition } from '@restworld/utility-types';
import { JobLocationCoordinates, JobPositionListParamsType } from '@restworld/data-services';
import { defaultItalyCoordinates } from '@restworld/utils-common';

import { defaultExplorerSortFilter, defaultJobListPayload } from './job-explorer.types';

type JobExplorerViewType = {
  list: JobPosition[];
  coordinates: JobLocationCoordinates[];
  loader: boolean;
  params: JobPositionListParamsType;
  centerPoint?: [number, number];
  zoomLevel?: number;
  sortFilter?: string;
  totalListCount?: number;
  activeId: string;
  elapsedApiTime?: number;
  jobListHasLimitReached?: boolean;
};

/**
 * Default values for the Job Explorer view.
 */
const defaultJobExplorerViewValues: JobExplorerViewType = {
  list: [],
  coordinates: [],
  loader: false,
  params: defaultJobListPayload,
  centerPoint: defaultItalyCoordinates,
  sortFilter: defaultExplorerSortFilter,
  zoomLevel: 5,
  activeId: '',
  elapsedApiTime: 0,
  jobListHasLimitReached: false,
};

export const jobLocationPositionListAtom = atom<JobPosition[]>(defaultJobExplorerViewValues.list);
jobLocationPositionListAtom.debugLabel = 'jobLocationPositionListAtom';
export const jobPositionTotalCountAtom = atom<number>(defaultJobExplorerViewValues.totalListCount);
jobPositionTotalCountAtom.debugLabel = 'jobPositionTotalCountAtom';
export const jobLocationPositionListLoadingAtom = atom<boolean>(defaultJobExplorerViewValues.loader);

export const activeJpIdAtom = atom<string>(defaultJobExplorerViewValues.activeId);
activeJpIdAtom.debugLabel = 'activeJpIdAtom';
export const jobListParamsAtom = atom<JobPositionListParamsType>(defaultJobExplorerViewValues.params);
jobListParamsAtom.debugLabel = 'jobListParamsAtom';
export const jobCenterPointAtom = atom<[number, number] | undefined>(defaultJobExplorerViewValues.centerPoint);
export const jobZoomLevelAtom = atom<number | undefined>(defaultJobExplorerViewValues.zoomLevel);
export const jobSortFilterAtom = atom<string | undefined>(defaultJobExplorerViewValues.sortFilter);
export const jobListHasLimitReachedAtom = atom<boolean>(false);
export const hasMapViewAtom = atom<boolean>(true);
export const apiCallElapsedTimeAtom = atom<JobExplorerViewType['elapsedApiTime']>(
  defaultJobExplorerViewValues.elapsedApiTime
);
apiCallElapsedTimeAtom.debugLabel = 'apiCallElapsedTimeAtom';
export const jobListKeyAtom = atom((get) => ['jobLocationPositionList', get(jobListParamsAtom)]);
jobListKeyAtom.debugLabel = 'jobListKeyAtom';
