import Image from 'next/image';
import { MouseEvent, memo, useMemo } from 'react';
import tw, { styled } from 'twin.macro';

import { Badge, GoogleRatingCard, SkeletonLoading, Title } from '@restworld/ui-ds';
import { customImageLoader, useResponsiveBreakPoints } from '@restworld/utils-common';

import BlurImage from '../../../utility-components/blur-image';
import { useTranslation } from 'next-i18next';

export interface ExplorerMapPopupCardProps {
  bgImage?: string;
  jpTitle?: string;
  companyTitle: string;
  otherInfos?: string[];
  onCardClose: (e: MouseEvent<HTMLAnchorElement>) => void;
  companyLocation?: string;
  restaurantLogo?: string;
  loading?: boolean;
  showRestaurantDetails?: boolean;
  isRestaurantsExplorer?: boolean;
  googleMapsInfo?: {
    rating?: number;
    reviews?: number;
  };
  isJobPositionOutsideItaly?: boolean;
}
export const ExplorerMapPopupCard = memo(
  ({
    bgImage,
    companyTitle,
    jpTitle,
    otherInfos,
    onCardClose,
    companyLocation,
    restaurantLogo,
    loading,
    showRestaurantDetails,
    isRestaurantsExplorer,
    googleMapsInfo,
    isJobPositionOutsideItaly,
  }: ExplorerMapPopupCardProps) => {
    const { t } = useTranslation();
    const { isDesktop } = useResponsiveBreakPoints();
    const googleEle = useMemo(
      () =>
        isRestaurantsExplorer && googleMapsInfo?.rating ? (
          <GoogleRatingCard
            googleLogoSize={{
              height: 24,
              width: 72,
            }}
            rating={googleMapsInfo.rating}
            backgroundColor="white"
            description={isDesktop ? `${googleMapsInfo.reviews} ${t('common:reviews')}` : ''}
            wrapperStyle={tw`rounded-none p-2 mx-0 w-full flex flex-row gap-4 bg-transparent`}
            ratingIconSize="xs"
            ratingLabelSize={isDesktop ? 'sm' : 'xs'}
            showGoogleIcon={isDesktop}
          />
        ) : null,
      [t, googleMapsInfo?.rating, googleMapsInfo?.reviews, isDesktop, isRestaurantsExplorer]
    );
    const companyLocationEle = useMemo(
      () =>
        isRestaurantsExplorer ? (
          <Title isPointer textColor="gray" fontSize="small" fontWeight="normal" icon="MapPinIconGray">
            <span tw="line-clamp-2">{companyLocation}</span>
          </Title>
        ) : companyLocation ? (
          <div tw="hidden md:block">
            <Title
              isPointer
              textColor="gray"
              fontSize="small"
              fontWeight="normal"
              icon={isJobPositionOutsideItaly ? 'WorldIcon' : 'MapPinIconGray'}
            >
              <span tw="line-clamp-2">{companyLocation}</span>
            </Title>
          </div>
        ) : null,
      [companyLocation, isJobPositionOutsideItaly, isRestaurantsExplorer]
    );
    const otherInfosEle = useMemo(
      () =>
        otherInfos?.length ? (
          <div tw="flex gap-2 flex-wrap">
            {otherInfos.map((v) => (
              <Badge key={v} variant="secondary">
                <Title isPointer fontWeight="normal" fontSize="small">
                  {v}
                </Title>
              </Badge>
            ))}
          </div>
        ) : null,
      [otherInfos]
    );
    const companyDescEle = useMemo(
      () => <label tw="text-base line-clamp-2 text-black font-semibold cursor-pointer">{jpTitle}</label>,
      [jpTitle]
    );
    const crossMarkEl = useMemo(
      () => (
        <StyledCrossMarkWrapper
          position={!bgImage ? 'alignRight' : isDesktop ? 'alignRight' : 'alignLeft'}
          href="#!"
          onMouseDown={(e) => {
            e.stopPropagation();
            onCardClose(e);
          }}
        >
          <CrossMark />
        </StyledCrossMarkWrapper>
      ),
      [bgImage, isDesktop, onCardClose]
    );
    const renderComponent = useMemo(() => {
      if (loading)
        return (
          <div tw="relative max-w-xs w-full md:w-44 h-full rounded-2xl bg-white">
            <SkeletonLoading animation variant="map-details-card" />
          </div>
        );
      if (showRestaurantDetails || isRestaurantsExplorer) {
        return (
          <>
            <MobileCardWrapper bgImage={bgImage}>
              {bgImage ? (
                <BlurImage
                  src={bgImage}
                  customStyles={tw`rounded-2xl h-[12.5rem] object-cover`}
                  alt="background-color"
                  dimensions={{
                    width: 144,
                    height: 160,
                  }}
                />
              ) : null}
              {bgImage ? crossMarkEl : null}
              <CardWrapper bgImage={bgImage}>
                <CardBody bgImage={bgImage}>
                  {!bgImage ? crossMarkEl : null}
                  {isRestaurantsExplorer ? (
                    <Title isPointer textColor="black" fontWeight="semibold" twStyle={tw`leading-5.25`}>
                      {companyTitle}
                    </Title>
                  ) : (
                    <div>
                      <Title isPointer textColor="gray" icon="BuildingOfficeGrayIcon">
                        {companyTitle}
                      </Title>
                    </div>
                  )}
                  {companyDescEle}
                  {companyLocationEle}
                  {otherInfosEle}
                  {googleEle}
                </CardBody>
              </CardWrapper>
            </MobileCardWrapper>
            <DesktopCardWrapper bgImage={bgImage}>
              <DesktopBackgroundWrapper>
                {bgImage ? (
                  <BlurImage
                    src={bgImage}
                    customStyles={tw`max-h-40 bg-no-repeat bg-cover w-full rounded-t-2xl hidden md:block object-cover`}
                    alt="background-color"
                    dimensions={{
                      width: 144,
                      height: 160,
                    }}
                  />
                ) : null}
                {bgImage ? crossMarkEl : null}
                {restaurantLogo ? (
                  <Image
                    src={restaurantLogo}
                    alt="logo"
                    tw="absolute border border-gray-200 rounded bottom-2 left-2 shadow bg-white"
                    width={56}
                    height={56}
                    loader={customImageLoader}
                    // blurDataURL="https://placehold.co/100x100"
                  />
                ) : null}
              </DesktopBackgroundWrapper>
              <CardBody bgImage={bgImage}>
                {!bgImage ? crossMarkEl : null}
                {isRestaurantsExplorer ? (
                  <Title isPointer textColor="black" fontWeight="semibold" twStyle={tw`leading-5.25`}>
                    {companyTitle}
                  </Title>
                ) : null}
                {companyDescEle}
                {companyLocationEle}
                {otherInfosEle}
                {googleEle}
              </CardBody>
            </DesktopCardWrapper>
          </>
        );
      }
      return (
        <div tw="bg-white rounded-2xl flex flex-col gap-2 p-4 cursor-pointer">
          {crossMarkEl}
          {companyDescEle}
          {companyLocation ? (
            <Title
              isPointer
              textColor="gray"
              fontSize="small"
              fontWeight="normal"
              icon={isJobPositionOutsideItaly ? 'WorldIcon' : 'MapPinIconGray'}
            >
              <span tw="line-clamp-2">{companyLocation}</span>
            </Title>
          ) : null}
          {otherInfosEle}
        </div>
      );
    }, [
      bgImage,
      companyDescEle,
      companyLocation,
      companyLocationEle,
      companyTitle,
      crossMarkEl,
      googleEle,
      isJobPositionOutsideItaly,
      isRestaurantsExplorer,
      loading,
      otherInfosEle,
      restaurantLogo,
      showRestaurantDetails,
    ]);
    return <Card>{renderComponent}</Card>;
  }
);

export default ExplorerMapPopupCard;

const CrossMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <ellipse cx="12.608" cy="12" fill="#B8B8B8" fillOpacity="0.5" rx="11.786" ry="12" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.123 7.435a.779.779 0 011.111 0l3.373 3.434 3.374-3.434a.786.786 0 01.558-.245.773.773 0 01.563.235.8.8 0 01.23.572.812.812 0 01-.24.569L13.719 12l3.373 3.435a.808.808 0 01-.01 1.121.779.779 0 01-1.101.01l-3.374-3.434-3.373 3.434a.778.778 0 01-1.101-.01.807.807 0 01-.01-1.121L11.496 12 8.123 8.566a.807.807 0 010-1.131z"
      clipRule="evenodd"
    />
  </svg>
);

const Card = styled.div`
  ${tw`relative w-full cursor-pointer`};
  ${tw`w-full h-full`};
  ${tw`min-w-[23rem] min-h-[7.5rem]`}
`;

const CardBody = styled.div<{
  bgImage?: string;
}>`
  ${tw`flex flex-col gap-2 p-4 h-full bg-white md:(relative w-full h-fit)`}
  ${({ bgImage }) => (!bgImage ? tw`rounded-2xl md:rounded-2xl` : tw`rounded-r-2xl md:(rounded-b-2xl rounded-tr-none)`)}
`;

const CardWrapper = styled.div<{
  bgImage?: string;
}>(({ bgImage }) => [tw`absolute right-0 top-0 h-full w-[65%]`, bgImage ? tw`w-[65%]` : tw`w-full`]);

const StyledCrossMarkWrapper = styled.a<{
  position?: 'alignLeft' | 'alignRight' | 'justifyRight';
}>(({ position }) => [
  tw`w-fit z-10`,
  position
    ? {
        alignLeft: tw`absolute top-2 right-0 left-2 mr-auto`,
        alignRight: tw`absolute top-2 right-2 left-0 ml-auto`,
        justifyRight: tw`flex justify-end ml-auto w-full`,
      }[position]
    : tw``,
]);

const DesktopCardWrapper = styled.div<{
  bgImage?: string;
}>(({ bgImage }) => [
  tw`hidden md:block relative w-full shadow-lg border-gray-200 rounded-none rounded-t-2xl`,
  !bgImage ? tw`h-fit` : tw`h-40`,
]);

const DesktopBackgroundWrapper = styled.div<{
  bgImage?: string;
}>(({ bgImage }) => [
  tw`cursor-pointer relative rounded-t-2xl border-white`,
  !bgImage ? tw`border-none` : tw`border-t`,
]);

const MobileCardWrapper = styled.div<{
  bgImage?: string;
}>(({ bgImage }) => [tw`md:hidden relative max-w-lg min-h-[12.5rem]`, !bgImage ? tw`min-h-[8rem]` : tw``]);
