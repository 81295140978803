globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useMemo } from 'react';
import { useEmployerUserContactData } from './use-employer-user-info-query';
import { COLLABORATOR_ROLE_KEYS } from '../components';
export function useIsEmployerAnAdmin() {
  var employerUserContactData = useEmployerUserContactData();
  return useMemo(function () {
    return (employerUserContactData === null || employerUserContactData === void 0 ? void 0 : employerUserContactData.role_rw_key) === COLLABORATOR_ROLE_KEYS.ADMIN;
  }, [employerUserContactData === null || employerUserContactData === void 0 ? void 0 : employerUserContactData.role_rw_key]);
}