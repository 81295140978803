import { useEffect, useMemo, useState } from 'react';
import { Marker, Source, Layer } from 'react-map-gl';
import * as turf from '@turf/turf';
import tw, { styled, theme } from 'twin.macro';
import { useFormContext } from 'react-hook-form';
import { HomeIcon } from '@heroicons/react/24/outline';
import { useAtomValue } from 'jotai';

import { MapWrapper } from '@restworld/ui-ds';
import { defaultItalyCoordinates } from '@restworld/utils-common';

import { SaveResearchFormFields, saveResearchMapRefAtom } from '../job-explorer-save-research-v2';

const defaultZoom = 10;
const defaultKM = 3;
export const SaveResearchMap = () => {
  const mapRef = useAtomValue(saveResearchMapRefAtom);

  const { getValues } = useFormContext<SaveResearchFormFields>();
  const [centerPoint, setCenterPoint] = useState<[number, number]>(defaultItalyCoordinates);
  const [markerPoint, setMarkerPoint] = useState<[number, number]>();
  const [zoomLevel, handleZoomEnd] = useState(defaultZoom);

  const address = getValues('address');
  const areaDistance = getValues('area_distance') ?? defaultKM;

  const initialViewState = useMemo(
    () => ({
      longitude: centerPoint?.[0],
      latitude: centerPoint?.[1],
      zoom: 5,
    }),
    [centerPoint]
  );

  const buffered = useMemo(() => {
    if (!markerPoint) return;
    try {
      const pointer = turf.point(markerPoint);
      return turf.buffer(pointer, areaDistance, { units: 'kilometers' });
    } catch (error) {
      console.error(error, `error on creating circular buffer`);
    }
  }, [areaDistance, markerPoint]);

  useEffect(() => {
    if (address?.address) {
      try {
        if (address.address.split(' ').length === 1) handleZoomEnd(5);
        else handleZoomEnd(defaultZoom);
        if (address.address_lon) {
          setMarkerPoint([address.address_lon, address.address_lat]);
          setCenterPoint([address.address_lon, address.address_lat]);
        }
      } catch (error) {
        console.error(error, `error centering and zooming`);
      }
    }
  }, [address]);

  useEffect(() => {
    mapRef.current?.flyTo({
      center: centerPoint,
      zoom: zoomLevel,
      duration: 500,
    });
  }, [centerPoint, mapRef, zoomLevel]);

  return (
    <Wrapper data-cy="save-research-map" id="map-wrapper">
      <MapWrapper
        cooperativeGestures={false}
        initialViewState={initialViewState}
        zoom={zoomLevel}
        mapRef={mapRef}
        touchZoomRotate
        coordinates={centerPoint}
      >
        {markerPoint && (
          <Marker longitude={markerPoint?.[0]} latitude={markerPoint?.[1]} tw="relative">
            <HomePin>
              <HomeIcon className="h-[18px] w-[18px] color-black" />
            </HomePin>
          </Marker>
        )}
        <Source id="circular-radius" type="geojson" data={buffered}>
          <Layer
            id="point-90-hi"
            type="fill"
            paint={{
              'fill-color': theme`colors.primary.700`,
              'fill-opacity': 0.15,
            }}
          />
        </Source>
      </MapWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  ${tw`relative w-full`}
  ${tw`h-[150px]`}
  .mapboxgl-map {
    ${tw`rounded-[4px]`}
  }
`;
const HomePin = styled.div`
  ${tw`flex justify-center items-center bg-white w-[35px] h-[35px] rounded-full`};
  // for arrow pointer
  &::after {
    ${tw`content-[''] absolute -bottom-[10px] left-1/2 ml-[0px] border-[6px] border-solid`};
    border-color: ${theme`colors.white`} transparent transparent transparent;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    transform: translateX(-50%);
  }
`;
