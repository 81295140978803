import { useAtomValue } from 'jotai';

import { JobApplicationStatusKey } from '@restworld/utility-types';

import { /* useJobApplicationPerformance, */ useJobApplyStatus, useJobPositionDetails } from '../../../hooks';
import { JobApplicationEntryPoint } from '../';
import { JobApplicationInterest } from '../../job-applications/components/job-application-interest';
import JobDetailBlock from '../../job-detail-block';
import { jobApplicationAtom, jobPositionApiDataAtom } from '../job-position.atoms';

export const JobInfoCard = ({ ambassadorRefCode, testId }: { ambassadorRefCode?: string; testId?: string }) => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const jobApplication = useAtomValue(jobApplicationAtom);

  const jobPositionDetails = useJobPositionDetails({ jobPositionApiData });
  const { handleFetchJobPositionApplyStatus } = useJobApplyStatus();

  const restaurantId = jobPositionApiData?.restaurant?.id;
  const jopPositionId = jobPositionApiData?.id;
  const jobStatusKey = jobApplication?.status?.key as JobApplicationStatusKey;
  const hasJobInterestEl = restaurantId && jopPositionId && jobStatusKey === 'worker_jp_proposed';
  const jobPublishedAt = jobPositionApiData?.published_at;

  const jobInterestEl = hasJobInterestEl ? (
    <JobApplicationInterest
      restaurantId={restaurantId}
      jobPositionId={jopPositionId}
      variant="jp-page"
      onSuccessfulUpdate={() => {
        handleFetchJobPositionApplyStatus();
      }}
    />
  ) : null;

  return (
    <JobDetailBlock
      showRestaurant={jobPositionDetails?.showRestaurant}
      jobPositionDetails={jobPositionDetails?.jobPositionDetails}
      ambassadorRefCode={ambassadorRefCode}
      jobInterestEl={jobInterestEl}
      hideJobApplicationEntryPoint={hasJobInterestEl}
      publishedAt={jobPublishedAt}
    >
      <JobApplicationEntryPoint testId={testId} />
    </JobDetailBlock>
  );
};
