const path = require('path');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'it',
    locales: ['it', 'en'],
    localeDetection: false,
  },
  // debug: process.env.NODE_ENV === 'development',
  localePath: path.join(
    process.cwd(),
    typeof window === 'undefined'
      ? !process.cwd().includes('/apps/worker-app')
        ? '/apps/worker-app/public/locales'
        : '/public/locales'
      : '/public/locales'
  ),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  serializeConfig: false,
};
