import { useEffect, useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { useRestaurantsService } from '@restworld/data-services';

import { companyProfileAtom, jobPositionApiDataAtom } from '../components/job-position/job-position.atoms';
import { structCompanyProfile } from '../components/job-company-profile/job-company-profile.hooks';

export const useJobPlacesInformation = () => {
  const setCompanyProfile = useSetAtom(companyProfileAtom);

  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const employerService = useRestaurantsService();

  const getCompanyProfileData = useCallback(async () => {
    try {
      if (jobPositionApiData?.restaurant && jobPositionApiData?.restaurant_id) {
        const response = await employerService.restaurantPlacesInfo(jobPositionApiData?.restaurant_id);
        const restaurantPlacesInfo = response.data;
        const rawData = {
          restaurant: jobPositionApiData.restaurant,
          ...(restaurantPlacesInfo
            ? {
                openingHours: restaurantPlacesInfo.opening_hours?.periods ?? [],
                reviewRating: restaurantPlacesInfo.rating,
                reviews: restaurantPlacesInfo.reviews,
                reviewNumbers: restaurantPlacesInfo.user_ratings_total,
                serviceList: restaurantPlacesInfo.types,
              }
            : {}),
        };
        const data = structCompanyProfile(rawData);
        setCompanyProfile(data);
      }
    } catch (error) {
      console.error(error, 'error fetching restaurant places information');
    }
  }, [employerService, jobPositionApiData?.restaurant, setCompanyProfile, jobPositionApiData?.restaurant_id]);

  useEffect(() => {
    getCompanyProfileData();
  }, [getCompanyProfileData]);
};
