globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useMemo } from 'react';
import { useNavLabelLinks } from '..';
export var useNavigationItemsByUserType = function useNavigationItemsByUserType(type, isAuthenticated) {
  var navLabelLinks = useNavLabelLinks();
  return useMemo(function () {
    switch (type) {
      case 'employer':
        return {
          primaryItems: navLabelLinks['employerType']['primaryNav'],
          secondaryItems: navLabelLinks['employerType']['secondaryNav']
        };

      case 'employer_contact':
        return {
          primaryItems: navLabelLinks['employerType']['primaryNav'],
          secondaryItems: navLabelLinks['employerType']['secondaryNav']
        };

      case 'lead':
        return {
          primaryItems: navLabelLinks['leadType']['primaryNav'],
          secondaryItems: navLabelLinks['leadType']['secondaryNav']
        };

      case 'ambassador':
        return {
          primaryItems: navLabelLinks['ambassadorType']['primaryNav'],
          secondaryItems: navLabelLinks['ambassadorType']['secondaryNav']
        };

      default:
        // worker
        return {
          primaryItems: navLabelLinks[isAuthenticated ? 'loggedIn' : 'nonLoggedIn']['primaryNav'],
          secondaryItems: navLabelLinks[isAuthenticated ? 'loggedIn' : 'nonLoggedIn']['secondaryNav']
        };
    }
  }, [isAuthenticated, navLabelLinks, type]);
};