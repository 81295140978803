globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useContext, useMemo, useEffect } from 'react';
import { GlobalOptionsFetchingContext } from '../context';
export var useGlobalOptionsFetchingContext = function useGlobalOptionsFetchingContext() {
  var context = useContext(GlobalOptionsFetchingContext);
  if (!context) throw new Error('useGlobalOptionsFetchingContext must be used inside the GlobalOptionsFetchingProvider');
  return useMemo(function () {
    return context;
  }, [context]);
};
export var useGlobalFieldOptions = function useGlobalFieldOptions(_ref) {
  var statusPrefix = _ref.statusPrefix,
      variableNames = _ref.variableNames;

  var _useGlobalOptionsFetc = useGlobalOptionsFetchingContext(),
      fetchVariable = _useGlobalOptionsFetc.fetchVariable,
      fetchStatus = _useGlobalOptionsFetc.fetchStatus;

  useEffect(function () {
    if (variableNames) fetchVariable(variableNames);
  }, [variableNames]);
  useEffect(function () {
    if (statusPrefix) fetchStatus(statusPrefix);
  }, [statusPrefix]);
  return null;
};
export default useGlobalOptionsFetchingContext;