import { Button } from '@restworld/ui-ds';
import { memo } from 'react';
import { AuthTitle } from '../common/auth-title';
import { AuthSubtitle } from '../common/auth-subtitle';
import { ModalWrapper } from '../common/modal-wrapper';
import { Separator } from '../common/separator';
import { GoogleButton } from '../common/google-button';
import { FacebookButton } from '../common/facebook-button';
import 'twin.macro';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  ContentType as ModalContentType,
  closeAuthFlowModal,
  closeEmailLoginAssistanceModalAtom,
  isEmailLoginAssistanceModalOpen,
  setActiveContent as setActiveModalContent,
} from '../../context/auth-flow';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'next-i18next';

export const AssistanceMagicLinkModal = memo(() => {
  const { show: showIntercom } = useIntercom();
  const open = useAtomValue(isEmailLoginAssistanceModalOpen);
  const closeAssistanceModal = useSetAtom(closeEmailLoginAssistanceModalAtom);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const closeModal = useSetAtom(closeAuthFlowModal);
  const { t } = useTranslation('auth-flow');
  function onClose() {
    closeAssistanceModal();
    setActiveContent(ModalContentType.NO_CONTENT);
    closeModal();
  }
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <div tw="flex flex-col w-full mt-5 ">
        <form tw="w-full">
          <Header />
          <div tw="flex flex-col items-center my-10">
            <Button
              fullwidth={true}
              onClick={(e) => {
                e.preventDefault();
                showIntercom();
              }}
            >
              {t('email_link_assistance_modal_action_text')}
            </Button>
          </div>

          <div tw="flex flex-col items-center w-full gap-2">
            <Separator />
            <GoogleButton {...{ isModal: true, closeModal: onClose }} />
            <FacebookButton {...{ isModal: true, closeModal: onClose }} />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
});

const Header = () => {
  const { t } = useTranslation('auth-flow');
  return (
    <div tw="flex flex-col items-center gap-2 w-full">
      <AuthTitle>{t('email_link_assistance_modal_header')}</AuthTitle>
      <AuthSubtitle>{t('email_link_assistance_modal_subheader')}</AuthSubtitle>
    </div>
  );
};
