import tw from 'twin.macro';
import { Paragraph, Title, Button } from '@restworld/ui-ds';

export const InformationHeadLine = ({
  showBackButton,
  title,
  subTitle,
  backButtonClickHandler,
}: {
  showBackButton?: boolean;
  title: string;
  subTitle?: string;
  backButtonClickHandler?: () => void;
}) => (
  <Wrapper data-cy="cv-error-info-head-title-wrapper">
    {showBackButton ? (
      <div tw="flex gap-8 w-full">
        <div tw="shrink-0">
          <Button fontColor="black" icon="ArrowLeftIcon" variant="ghost" onClick={backButtonClickHandler} />
        </div>
        <Title isCenter content={title} fontSize="extraLarge" fontWeight="semibold" />
      </div>
    ) : (
      <Title testId="information-headline" content={title} fontSize="extraLarge" fontWeight="semibold" />
    )}
    {subTitle ? <Paragraph content={subTitle} /> : null}
  </Wrapper>
);
const Wrapper = tw.div`flex text-center flex-col gap-4 items-center`;
