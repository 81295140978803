import * as React from 'react';
import { useMemo, useCallback, useEffect } from 'react';
import 'twin.macro';
import dynamic from 'next/dynamic';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { AmbassadorButton, Button, Spinner, useShortUrl } from '@restworld/ui-ds';
import { useResponsiveBreakPoints, useAmbassadorData, useAnalytics } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';

import {
  explorerRestaurantListLoadingAtom,
  explorerRestaurantMapLoadingAtom,
  explorerRestaurantHitsAtom,
  saveResearchModalV2Atom,
} from './job-explorer-map';
import { isRestaurantsExplorerAtom } from './job-explorer-map';
import MobileDrawerElement from './mobile-drawer-element';
import JobExplorerDesktopElement from './job-explorer-desktop-element';
import { SeasonalAccommodationSuccessModal } from './job-explorer-map/seasonal-recommdation-mapicon-filter';
import { WorkerHomeLocationWrapper } from './job-explorer-map/worker-home-location';
import {
  setFullScreenAtom,
  getToggleFullScreenAtom,
  jobLocationCoordinatesLoadingAtom,
} from './job-explorer-map.atoms';
import JobExplorerSaveResearchV2, {
  recordJobExplorerModalAtom,
} from './job-explorer-map/job-explorer-save-research-v2/job-explorer-save-research-v2';
import { SaveResearchButton } from './job-explorer-map/job-explorer-save-research-v2';
import {
  jobLocationPositionListLoadingAtom,
  jobPositionTotalCountAtom,
  hasMapViewAtom,
} from './job-explorer-list.atoms';
import { useJobLocationPositionListQuery, useJobPositionCoordinatesQuery } from './job-explorer.hooks';
import { ExplorerMapState, toggleActiveExplorerTypeAtom } from './job-explorer-map';
import { useRQueryFetchExplorerRestaurantList, useRestaurantLocationCoordinates } from './job-explorer.hooks';
import { jobListParamsAtom } from './job-explorer-list.atoms';

const SortDropdown = dynamic(() => import('./job-explorer-sort-dropdown'), {
  ssr: false,
});

export const JobExplorerContent = () => {
  const { isDesktop } = useResponsiveBreakPoints();

  const ambassadorData = useAmbassadorData();
  const explorerLoading = useExplorerLoading();

  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  const openSaveResearchModal = useSetAtom(saveResearchModalV2Atom);
  const [recordSaveResearchOpenModal, toggleRecordSaveResearchOpenModal] = useAtom(recordJobExplorerModalAtom);

  React.useEffect(() => {
    // upon 10 seconds navigation of the user, open the save research modal
    if (recordSaveResearchOpenModal) return;
    const timeout = setTimeout(() => {
      openSaveResearchModal(true);
      toggleRecordSaveResearchOpenModal(true);
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [openSaveResearchModal, recordSaveResearchOpenModal, toggleRecordSaveResearchOpenModal]);

  useJobLocationPositionListQuery();
  useJobPositionCoordinatesQuery();
  useFetchRestaurantExplorer();

  return (
    <div tw="relative" id="map-and-list" data-cy="explorer-map-and-list">
      <JobExplorerDesktopElement ambassadorCode={ambassadorData?.ambassador_code} />
      {!isDesktop ? <MobileDrawerElement ambassadorCode={ambassadorData?.ambassador_code} /> : null}
      {explorerLoading && <MapSpinner />}
      {/* define your modals here */}
      {!isRestaurantsExplorer && <SeasonalAccommodationSuccessModal />}
      <JobExplorerSaveResearchV2 />
      <WorkerHomeLocationWrapper />
    </div>
  );
};
export default JobExplorerContent;
const JobExplorerSaveResearchButton = () => {
  const { trackEvent } = useAnalytics();
  const openSaveResearchModal = useSetAtom(saveResearchModalV2Atom);
  const params = useAtomValue(jobListParamsAtom);
  const auth = useAuth();
  const recordEvent = useCallback(() => {
    trackEvent('initiate_save_search', {
      email: auth.user?.email,
      role: auth.user?.type,
      ...params,
    });
  }, [auth.user?.email, auth.user?.type, params, trackEvent]);

  return (
    <SaveResearchButton
      onClick={() => {
        recordEvent();
        openSaveResearchModal(true);
      }}
    />
  );
};
export const ListHeadTitle = React.memo(({ hideDropdown }: { hideDropdown?: boolean }) => {
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  return (
    <div tw="flex justify-between items-center gap-4">
      <TotalCountTitle />
      {!hideDropdown && <SortDropdown disabled={isRestaurantsExplorer} />}
    </div>
  );
});
const TotalCountTitle = React.memo(() => {
  const { t } = useTranslation('common');
  const totalJobs = useAtomValue(jobPositionTotalCountAtom);
  const explorerRestaurantHits = useAtomValue(explorerRestaurantHitsAtom);
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  return (
    <span tw="flex-col gap-1">
      <h3 tw="text-base font-normal md:(text-sm font-semibold)">{`${t('found')} ${
        isRestaurantsExplorer ? explorerRestaurantHits : totalJobs ?? 0
      } ${t('results')}`}</h3>
    </span>
  );
});

export const MapListTogglers = ({ onTogglingMapList }: { onTogglingMapList?: () => void }) => {
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  const { user } = useAuth();
  const { loading, shortUrl: ambassadorShortUrl, getShortenURL } = useShortUrl();
  const ambassadorData = useAmbassadorData();
  const setFullScreen = useSetAtom(setFullScreenAtom);
  const toggleFullScreen = useAtomValue(getToggleFullScreenAtom);
  const [hasMapView, mapViewToggler] = useAtom(hasMapViewAtom);
  const { t } = useTranslation('job-explorer-page');
  const title = useMemo(() => (hasMapView ? t('close_the_map') : t('open_the_map')), [hasMapView, t]);

  const mapListToggleBtnCallback = useCallback(() => {
    mapViewToggler((p) => !p);
    if (toggleFullScreen && title === t('close_the_map')) setFullScreen(false);
  }, [mapViewToggler, setFullScreen, t, title, toggleFullScreen]);

  return (
    <>
      <Button
        onClick={() => {
          if (onTogglingMapList) onTogglingMapList();
          else mapListToggleBtnCallback();
        }}
        icon={!hasMapView ? 'MapIcon' : 'XMarkIconWhite'}
        title={title}
        variant="dark"
        twStyle={isRestaurantsExplorer ? tw`w-auto max-h-[48px]` : tw`w-[50%] lg:w-auto max-h-[48px]`}
        testId="map-list-toggler"
      />
      {!isRestaurantsExplorer && <JobExplorerSaveResearchButton />}
      {user?.type === 'ambassador' && (
        <AmbassadorButton
          {...{
            type: ambassadorShortUrl === 'error' ? ('error' as const) : ('success' as const),
            regenerateURL: () => getShortenURL(ambassadorData?.ambassador_code),
            url: ambassadorShortUrl,
            loading,
          }}
        />
      )}
    </>
  );
};
const MapSpinner = () => {
  const hasMapView = useAtomValue(hasMapViewAtom);
  return (
    <MapSpinnerWrapper data-cy="job-explorer-spinner" hasMapView={hasMapView}>
      <Spinner color="primary" size="sm" />
    </MapSpinnerWrapper>
  );
};
const MapSpinnerWrapper = styled.div<{
  hasMapView?: boolean;
}>`
  ${tw`fixed bottom-[22%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 w-[88px] h-[48px] flex justify-center items-center bg-white border rounded-lg [border-color: #E5E7EB]`};
  ${({ hasMapView }) => hasMapView && tw`bottom-[17%] lg:hidden`}
`;
export const useExplorerLoading = () => {
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);

  const [explorerLoading, setExplorerLoading] = React.useState(false);
  const explorerRestaurantListFetchLoading = useAtomValue(explorerRestaurantListLoadingAtom);
  const restaurantLocationCoordinatesFetchLoading = useAtomValue(explorerRestaurantMapLoadingAtom);
  const jobLocationPositionListLoading = useAtomValue(jobLocationPositionListLoadingAtom);
  const jobLocationCoordinatesLoading = useAtomValue(jobLocationCoordinatesLoadingAtom);

  React.useEffect(() => {
    if (
      (!isRestaurantsExplorer && (jobLocationPositionListLoading || jobLocationCoordinatesLoading)) ||
      (isRestaurantsExplorer && (explorerRestaurantListFetchLoading || restaurantLocationCoordinatesFetchLoading))
    ) {
      setExplorerLoading(true);
      setTimeout(() => {
        setExplorerLoading(false);
      }, 1500);
    }
  }, [
    explorerRestaurantListFetchLoading,
    isRestaurantsExplorer,
    jobLocationCoordinatesLoading,
    jobLocationPositionListLoading,
    restaurantLocationCoordinatesFetchLoading,
  ]);

  return explorerLoading;
};
const useFetchRestaurantExplorer = () => {
  const [, setActive] = useAtom(toggleActiveExplorerTypeAtom);

  useEffect(() => {
    const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
    if (urlParams?.get('explorer_type')) setActive(urlParams?.get('explorer_type') as ExplorerMapState);
  }, [setActive]);

  useRQueryFetchExplorerRestaurantList();
  useRestaurantLocationCoordinates();
};
