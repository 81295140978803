import { JobApplication } from '@restworld/utility-types';
import { atom } from 'jotai';

export const workerApplicationDataLoadingAtom = atom<boolean>(false);

export const openWorkerApplicationDataLoading = atom(null, (_get, set) => {
  set(workerApplicationDataLoadingAtom, true);
});

export const closeWorkerApplicationDataLoading = atom(null, (_get, set) => {
  set(workerApplicationDataLoadingAtom, false);
});

export const workerJobApplicationsAtom = atom<JobApplication[]>([]);

export const setWorkerJobApplicationsAtom = atom(null, (_, set, payload: JobApplication[]) => {
  set(workerJobApplicationsAtom, payload);
});
