import { Button, Paragraph } from '@restworld/ui-ds';
import { useCallback, useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useUpdateWorkerStatus } from '../hooks/use-update-worker-status';
import { ProposedJobApplicationModal, ProposedJobApplicationModalProps } from './proposed-job-application-modal';
import { useLoadWorkerApplications } from '../hooks/use-load-worker-applications';
import { useTrackJobApplication } from '../hooks/use-track-job-application';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

type JobApplicationInterestProps = {
  restaurantId: string;
  jobPositionId: string;
  variant?: 'primary' | 'jp-page';
  onSuccessfulUpdate?: () => void;
};

export const JobApplicationInterest = ({
  restaurantId,
  jobPositionId,
  variant = 'primary',
  onSuccessfulUpdate,
}: JobApplicationInterestProps) => {
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [modalVariant, setModalVariant] = useState<ProposedJobApplicationModalProps['variant']>(undefined);
  const { isLoading: acceptOfferLoading, handleJobApplicationStatusUpdate: handleAcceptOfferUpdate } =
    useUpdateWorkerStatus();
  const { isLoading: declineOfferLoading, handleJobApplicationStatusUpdate: handleDeclineOfferUpdate } =
    useUpdateWorkerStatus();
  const { fetchWorkerApplications } = useLoadWorkerApplications({ showLoader: false });
  const { trackJobApplicationStatusUpdated } = useTrackJobApplication();
  const router = useRouter();
  const action = router.query?.action;
  const job_position_id = router.query?.job_position_id;
  const worker_id = router.query?.worker_id;
  const cleanQueryParams = useCallback(() => {
    if (action && job_position_id && worker_id) {
      const queryParams = { ...(router.query ?? {}) };
      delete queryParams.action;
      delete queryParams.job_position_id;
      delete queryParams.worker_id;
      router.push({ query: queryParams }, undefined, { shallow: true });
    }
  }, [action, job_position_id, router, worker_id]);

  const onAcceptOffer = useCallback(() => {
    handleAcceptOfferUpdate({ restaurantId, jobPositionId, updatedStatus: 'worker_jp_interested' })
      .then(() => {
        setModalVariant('positive');
        setShowSubmitModal(true);
        trackJobApplicationStatusUpdated('worker_jp_proposed', 'worker_jp_interested');
        cleanQueryParams();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error updating accept offer', error);
      });
  }, [cleanQueryParams, handleAcceptOfferUpdate, jobPositionId, restaurantId, trackJobApplicationStatusUpdated]);
  const onDeclineOffer = useCallback(() => {
    handleDeclineOfferUpdate({ restaurantId, jobPositionId, updatedStatus: 'worker_jp_not_interested' })
      .then(() => {
        setModalVariant('negative');
        setShowSubmitModal(true);
        trackJobApplicationStatusUpdated('worker_jp_proposed', 'worker_jp_not_interested');
        cleanQueryParams();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error updating decline offer', error);
      });
  }, [cleanQueryParams, handleDeclineOfferUpdate, jobPositionId, restaurantId, trackJobApplicationStatusUpdated]);

  useEffect(() => {
    if (job_position_id && worker_id && action && job_position_id === jobPositionId) {
      if (action === 'interested') {
        onAcceptOffer();
      } else if (action === 'not_interested') {
        onDeclineOffer();
      } else {
        cleanQueryParams();
      }
    }
  }, [action, cleanQueryParams, jobPositionId, job_position_id, onAcceptOffer, onDeclineOffer, worker_id]);

  const onHandleCloseModal = useCallback(() => {
    setShowSubmitModal(false);
    setModalVariant(undefined);
    fetchWorkerApplications();
    if (onSuccessfulUpdate) onSuccessfulUpdate();
  }, [fetchWorkerApplications, onSuccessfulUpdate]);
  const { t } = useTranslation();
  return (
    <>
      <OfferWrapper variant={variant}>
        <Paragraph
          variant="small"
          color={variant === 'jp-page' ? 'white' : 'gray-600'}
          font={variant === 'jp-page' ? 'normal' : 'semibold'}
        >
          {t('job-application-page:are_you_interested')}
        </Paragraph>
        <OfferButtonsWrapper>
          <Button
            fullwidth={true}
            onClick={(event) => {
              event.stopPropagation();
              onAcceptOffer();
            }}
            loading={acceptOfferLoading}
          >
            {t('common:yes')}
          </Button>
          <Button
            variant="secondary-outline"
            fullwidth={true}
            onClick={(event) => {
              event.stopPropagation();
              onDeclineOffer();
            }}
            loading={declineOfferLoading}
          >
            No
          </Button>
        </OfferButtonsWrapper>
      </OfferWrapper>
      <ProposedJobApplicationModal
        {...{
          show: showSubmitModal,
          onClose: () => {
            onHandleCloseModal();
          },
          restaurantId,
          jobPositionId,
          variant: modalVariant,
        }}
      />
    </>
  );
};

const OfferWrapper = styled.div<Pick<JobApplicationInterestProps, 'variant'>>`
  ${tw`flex flex-col gap-2 items-start w-full border-t border-gray-200 pt-2 `}
  ${({ variant }) => (variant === 'jp-page' ? tw`border-none pt-0 items-center` : tw``)}
`;

const OfferButtonsWrapper = tw.div`grid grid-cols-2 lg:gap-4 gap-2 w-full `;
