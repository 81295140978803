import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { CommonModalWrapper, Title } from '@restworld/ui-ds';
import { GreenCheckMarkWithWhiteBg } from '@restworld/utils-common';

import { StepWrapper } from '../job-explorer-save-research-v2';

export const ResearchSaveSuccessMessage = () => {
  const { t } = useTranslation('job-explorer-page');
  return (
    <StepWrapper>
      <CommonModalWrapper
        mainTitle={t('now_you_wont_miss_a_single_opportunity')}
        subTitle={t('avoid_missing_out_on_news_we_recommend')}
        icon={<GreenCheckMarkWithWhiteBg />}
        textAlignment="left"
      />
      <Title
        testId="view-saved-research"
        fontWeight="normal"
        fontSize="xSmall"
        icon="InformationCircleIcon"
        textColor="gray-400"
      >
        {t('view_save_search')}
      </Title>
    </StepWrapper>
  );
};

export default ResearchSaveSuccessMessage;
