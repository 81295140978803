import { useAccountsService } from '@restworld/data-services';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAtom, useSetAtom } from 'jotai';
import {
  ContentType as ModalContentType,
  accountExists,
  closeEmailLoginModal,
  nonExistingAccount,
  openEmailTokenSentModal,
  openSignupModal,
  setActiveContent as setActiveModalContent,
  updateEmailVerificationDetails,
} from '../../../context/auth-flow';
import { useRouter } from 'next/router';
import { useAnalytics } from '@restworld/utils-common';

export type UseLoginFormProps = {
  isModal?: boolean;
  currentUrl?: string;
};

export interface EmailLoginInput {
  email: string;
}

export function useEmailLoginForm({ isModal = true, currentUrl }: UseLoginFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm<EmailLoginInput>();
  const [loading, setLoading] = useState<boolean>(false);
  const [remainingTimes, setRemainingTimes] = useState<number>(3);

  const updateVerificationDetails = useSetAtom(updateEmailVerificationDetails);
  const closeEmailModal = useSetAtom(closeEmailLoginModal);
  const [existingAccount, setExistingAccount] = useAtom(accountExists);
  const onOpenSignupModal = useSetAtom(openSignupModal);
  const openVerificationModal = useSetAtom(openEmailTokenSentModal);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const onSetNonExistingAccount = useSetAtom(nonExistingAccount);
  const router = useRouter();

  const acc = useAccountsService();
  const { trackEvent } = useAnalytics();

  const handleLogin = useCallback(
    async (data: EmailLoginInput) => {
      setLoading(true);
      try {
        trackEvent('start_auth_flow', {
          email: data.email,
        });
        const response: AxiosResponse = await acc.passwordlessLogin(data.email, currentUrl);
        setLoading(false);
        const tokenValidityTime = response?.data?.token_validity_time;
        const waitTime = response?.data?.wait_time;
        updateVerificationDetails({
          waitTime: waitTime ? parseInt(waitTime) : 0,
          tokenValidityTime: tokenValidityTime ? parseInt(tokenValidityTime) : 0,
          email: data.email,
        });
        setActiveContent(ModalContentType.EMAIL_SENT);
        setRemainingTimes((prev) => prev - 1);
        if (isModal) {
          closeEmailModal();
          openVerificationModal();
        }
      } catch (error) {
        setLoading(false);
        const statusCode = parseInt(error.code);
        if (statusCode === 409 || statusCode === 404) {
          if (existingAccount.email) {
            setExistingAccount((prev) => ({ ...prev, email: null }));
          }
          onSetNonExistingAccount((prev) => ({ ...prev, email: data.email }));
          setActiveContent(ModalContentType.SIGNUP);
          if (isModal) {
            closeEmailModal();
            onOpenSignupModal();
          } else {
            router.push('/signup');
          }
        }
      }
    },
    [
      acc,
      closeEmailModal,
      currentUrl,
      existingAccount.email,
      isModal,
      onOpenSignupModal,
      onSetNonExistingAccount,
      openVerificationModal,
      router,
      setActiveContent,
      setExistingAccount,
      trackEvent,
      updateVerificationDetails,
    ]
  );

  const onSubmit = async (data: EmailLoginInput) => {
    handleLogin(data);
  };

  return {
    loading,
    onSubmit,
    register,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    setError,
    setLoading,
    remainingTimes,
  };
}
