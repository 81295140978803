import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var urlObjectToUriString = function urlObjectToUriString(urlObject) {
  var pathname = urlObject.pathname,
      _urlObject$query = urlObject.query,
      query = _urlObject$query === void 0 ? {} : _urlObject$query;
  var convertedPath = Object.entries(query).reduce(function (path, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return path === null || path === void 0 ? void 0 : path.replace("[".concat(key, "]"), value.toString());
  }, pathname);
  var queryString = Object.entries(query).filter(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
        key = _ref4[0];

    return !(pathname !== null && pathname !== void 0 && pathname.includes("[".concat(key, "]")));
  }).map(function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
        k = _ref6[0],
        v = _ref6[1];

    return "".concat(k, "=").concat(encodeURIComponent(v));
  }).join('&');
  return queryString ? "".concat(convertedPath, "?").concat(queryString) : convertedPath;
};