import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICheckMarkIconProps {}

export const CheckMarkIcon: React.FunctionComponent<ICheckMarkIconProps> = ({ ...rest }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34783 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34783 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34783 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34783 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34783 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34783 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34783 20 10 20ZM14.6337 8.38375C14.8614 8.148 14.9874 7.83224 14.9846 7.5045C14.9817 7.17675 14.8503 6.86324 14.6185 6.63148C14.3868 6.39972 14.0732 6.26826 13.7455 6.26541C13.4178 6.26256 13.102 6.38855 12.8663 6.61625L8.75 10.7325L7.13375 9.11625C6.898 8.88855 6.58224 8.76256 6.2545 8.76541C5.92675 8.76826 5.61324 8.89972 5.38148 9.13148C5.14972 9.36324 5.01826 9.67675 5.01541 10.0045C5.01256 10.3322 5.13855 10.648 5.36625 10.8837L7.86625 13.3837C8.10066 13.6181 8.41854 13.7497 8.75 13.7497C9.08146 13.7497 9.39934 13.6181 9.63375 13.3837L14.6337 8.38375Z"
      fill="#693EB5"
    />
  </svg>
);
export default CheckMarkIcon;
