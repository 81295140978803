import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { useResponsiveBreakPoints } from '@restworld/utils-common';
import {
  ApplicationSuccessComponent,
  ApplyConfirmation,
  CoverLetterConfirmation,
  CoverLetterInputArea,
  CoverLetterQuickGuide,
  ModalSectionType,
} from '@restworld/ui-ds';
import { CoverLetterEntryPropsType } from './spontaneous-cover-letter-entry';
import { getRestaurantPopupInfoAtom } from '../worker-entry-point';
import { useJobPositionId } from '../../hooks';

/**
 * Renders a modal for applying to a job position with a cover letter.
 * @param modalSection - The current section of the modal to render.
 * @param onChangeModalSection - Function to change the current section of the modal.
 */
export interface ApplicationCoverLetterProps {
  modalSection: ModalSectionType;
  onChangeModalSection: (x: ModalSectionType) => void;
  onApplicationApply?: () => Promise<void>;
  loading: boolean;
  toggleExitConfirmation: (x: boolean) => void;
  // companyReviewDetails?: CompanyReviewProps;
  coverLetter: string;
  setCoverLetterInput: (v: string) => void;
  id?: CoverLetterEntryPropsType['id'];
  restaurantID?: string;
}
export function ApplicationCoverLetter({
  modalSection,
  onChangeModalSection,
  toggleExitConfirmation,
  onApplicationApply,
  loading,
  coverLetter,
  setCoverLetterInput,
  id,
  restaurantID,
}: ApplicationCoverLetterProps) {
  const jobPositionId = useJobPositionId();
  const [restaurantPopupInfo] = useAtom(getRestaurantPopupInfoAtom);
  const { t } = useTranslation();
  const router = useRouter();
  const { isDesktop } = useResponsiveBreakPoints();
  const renderComponent = useMemo(() => {
    switch (modalSection) {
      case 'spontaneous-apply-confirmation':
        return (
          <ApplyConfirmation
            onProceedWithApplication={() => onChangeModalSection('cover-letter-confirmation')}
            onChangeMind={() => toggleExitConfirmation(true)}
            restaurantID={restaurantID}
            restaurantPopupInfo={restaurantPopupInfo}
          />
        );
      case 'cover-letter-confirmation':
        return (
          <CoverLetterConfirmation
            onWriteCoverLetter={() => onChangeModalSection('cover-letter-input-area')}
            onApplyWithoutCoverLetter={() => {
              if (id === 'unauthenticated-spontaneous-application') {
                onChangeModalSection('application-success-component');
                return;
              }
              if (onApplicationApply) onApplicationApply();
            }}
            loading={loading}
            variant={isDesktop ? 'two' : 'one'}
          />
        );
      case 'cover-letter-input-area':
        return (
          <CoverLetterInputArea
            onApplyWithCoverLetter={onApplicationApply}
            onShowCoverLetterGuide={() => onChangeModalSection('cover-letter-quick-guide')}
            loading={loading}
            coverLetter={coverLetter}
            setCoverLetterInput={setCoverLetterInput}
            showCoverLetterGuide={isDesktop}
          />
        );
      case 'cover-letter-quick-guide':
        return <CoverLetterQuickGuide onClose={() => onChangeModalSection('cover-letter-input-area')} />;
      case 'application-success-component':
        return (
          <ApplicationSuccessComponent
            title={t('onboarding-page:you_might_receive_news')}
            subTitle={t('onboarding-page:if_made_an_impression_you_will_be_contacted')}
            buttonFirstTitle={t('onboarding-page:view_your_applications')}
            buttonSecondTitle={t('onboarding-page:find_more_jobs')}
            onFirstButtonClick={() => router.push(`/candidature/${jobPositionId}`)}
            onSecondButtonClick={() => router.push('/cerco-lavoro')}
            variant={isDesktop ? 'two' : 'one'}
          />
        );
      default:
        return <></>;
    }
  }, [
    modalSection,
    restaurantID,
    restaurantPopupInfo,
    loading,
    isDesktop,
    onApplicationApply,
    coverLetter,
    setCoverLetterInput,
    t,
    onChangeModalSection,
    toggleExitConfirmation,
    id,
    router,
    jobPositionId,
  ]);
  return renderComponent;
}
export default ApplicationCoverLetter;
export * from './spontaneous-cover-letter-entry';
