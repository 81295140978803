import tw from 'twin.macro';

import { Spinner } from '@restworld/ui-ds';

export const Loader = () => (
  <ScreenSpinnerWrapper>
    <Spinner color="primary" size="lg" />
  </ScreenSpinnerWrapper>
);
const ScreenSpinnerWrapper = tw.div`w-screen h-screen flex items-center justify-center`;
