globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useRouter } from 'next/router';
import { useEffect } from 'react';
export function Redirect(_ref) {
  var to = _ref.to,
      _ref$refresh = _ref.refresh,
      refresh = _ref$refresh === void 0 ? false : _ref$refresh;
  var router = useRouter();
  var isReady = router.isReady;
  useEffect(function () {
    if (isReady && !!to && !refresh) {
      router.push(to);
    }

    if (refresh) {
      var _window, _window$location;

      (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.replace(to);
    }
  }, [to, refresh, router, isReady]);
  return null;
}