import { useEffect, useState, useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'next-i18next';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useWorkersService } from '@restworld/data-services';
import {
  FormErrorType,
  MissingCVTypeParams,
  useAddressAdditionalFields,
  useAnalytics,
  useFormWithSchema,
} from '@restworld/utils-common';
import { Button } from '@restworld/ui-ds';

import { initialErrorData } from '../worker-entry-point.constant';
import { useFormInput } from '../worker-entry-point.hooks';
import { ExistingEmailInput } from './existing-email-input';
import { InformationHeadLine } from './error-info-headline';
import {
  doesPhoneNumberExistAtom,
  isCVUploadProcessCompletedAtom,
  uploadFilesAtom,
  workerEntryPointErrorsAtom,
  workerIdAtom,
} from '../worker-entry-point.atoms';
import { existingPhoneNumber as existingPhoneNumberAtom } from '../../../context/auth-flow';
import PhoneNumberConflict from './phone-number-conflict';

export const CvErrorsFieldReInput = () => {
  const { t } = useTranslation();
  const errors = useAtomValue(workerEntryPointErrorsAtom);
  const missingFields = useMemo(
    () => (errors ? Object.keys(errors).filter((v) => !['user_id', 'statusCode', 'id'].includes(v)) : []),
    [errors]
  );

  const [doesPhoneNumberExist] = useAtom(doesPhoneNumberExistAtom);
  const requiredTxt = t('onboarding-page:this_field_is_required');
  const commonTextValidation = useCallback(
    (key: 'address' | 'name' | 'surname') =>
      missingFields.includes(key) ? Yup.string().required(requiredTxt).min(1) : notRequired,
    [missingFields, requiredTxt]
  );
  const Schema = useMemo(
    () =>
      Yup.object().shape({
        email: missingFields.includes('email')
          ? Yup.string()
              .required(requiredTxt)
              .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'E-mail non valida')
              .test('gmail-domain', t('onboarding-page:invalid_gmail_address'), (value) => {
                if (!value) return false;
                const domain = value.split('@')[1];
                return !invalidGmailMatch.includes(domain);
              })
          : notRequired,
        phone_number:
          missingFields.includes('phone_number') || doesPhoneNumberExist
            ? Yup.string().matches(phoneRegExp, t('onboarding-page:the_phone_number_is_invalid')).required(requiredTxt)
            : notRequired,
        address: commonTextValidation('address'),
        name: commonTextValidation('name'),
        surname: commonTextValidation('surname'),
      }),
    [commonTextValidation, doesPhoneNumberExist, missingFields, requiredTxt, t]
  );
  const methods = useFormWithSchema(Schema);
  const { formErrors, invalidFormErrors, handleFormChange, control, onSubmit, handleSubmit, loading } =
    useFormValidation({ methods });
  const formInputs = useFormInput({ handleFormChange, missingFields, formErrors, control });
  const [, setDoesPhoneNumberExist] = useAtom(doesPhoneNumberExistAtom);
  const setExistingPhoneNumber = useSetAtom(existingPhoneNumberAtom);

  useEffect(() => {
    if (errors?.phone_number?.includes('already exists')) {
      setDoesPhoneNumberExist(true);
      setExistingPhoneNumber({ phoneNumber: errors?.phone_number.split(' ')[0] });
    }
  }, [errors?.phone_number, setDoesPhoneNumberExist, setExistingPhoneNumber]);

  const formEl = useMemo(() => {
    if (errors?.phone_number?.includes('already exists')) return <PhoneNumberConflict formInputs={formInputs} />;
    if (errors?.statusCode === 409)
      return <ExistingEmailInput formInputs={formInputs} invalidFormErrors={invalidFormErrors} />;
    if (errors?.statusCode === 404)
      return (
        <ErrorWrapper>
          <InformationHeadLine
            title={t('common:something_went_wrong')}
            subTitle={t('common:not_able_to_process_try_again_later')}
          />
        </ErrorWrapper>
      );
    return (
      <ErrorWrapper data-cy="cv-errors-wrapper">
        <InformationHeadLine title={t('common:unable_to_read')} subTitle={t('common:check_your_CV_if_it_is_in_line')} />
        <FormWrapper data-cy="cv-errors-inputs">{formInputs}</FormWrapper>
        <InvalidFormErrors invalidFormErrors={invalidFormErrors} />
        <Button
          loading={loading}
          variant="normal"
          type="submit"
          title={t('common:save_and_continue')}
          fullwidth
          testId="save_and_continue"
        />
      </ErrorWrapper>
    );
  }, [errors?.phone_number, errors?.statusCode, formInputs, invalidFormErrors, t, loading]);
  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {formEl}
      </Form>
    </FormProvider>
  );
};
export const missingFieldFormLoadingAtom = atom<boolean>(false);
const useFormValidation = ({
  methods,
}: {
  methods?: UseFormReturn<{
    address?: string;
    email?: string;
    name?: string;
    surname?: string;
    phone_number?: string;
  }>;
}) => {
  const [loading, setLoading] = useAtom(missingFieldFormLoadingAtom);
  const [formData, setFormData] = useState({ ...initialErrorData });
  const [formErrors, setFormErrors] = useState<FormErrorType | Record<string, never>>({});
  const [invalidFormErrors, setInvalidFormErrors] = useState<FieldValues>();
  const setWorkerId = useSetAtom(workerIdAtom);
  const setCVUploadedProcessComplete = useSetAtom(isCVUploadProcessCompletedAtom);
  const [errors, setErrors] = useAtom(workerEntryPointErrorsAtom);
  const [, setDoesPhoneNumberExist] = useAtom(doesPhoneNumberExistAtom);

  const workerService = useWorkersService();
  const { trackEvent } = useAnalytics();

  const {
    handleSubmit,
    formState: { errors: useFormHookErrors },
    control,
    setValue,
  } = methods;

  const setExistingPhoneNumber = useSetAtom(existingPhoneNumberAtom);

  const addressFields = useAddressAdditionalFields({ addressString: formData['address'] });

  const sendMissingCVData = useCallback(
    async (data: MissingCVTypeParams) => {
      setLoading(true);
      setInvalidFormErrors(undefined);
      await workerService
        .sendMissingCVData({
          ...data,
          phone_number: data.phone_number,
          id: errors?.['id'],
        })
        .then((res) => {
          setWorkerId(res.data?.id);
          setCVUploadedProcessComplete(true);
          setDoesPhoneNumberExist(false);
          setErrors(null);
          return res;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err, 'error sending Missing CV Data');
          setFormErrors(err.data);
          if (err['code'] === 409) setErrors({ ...err.data, statusCode: 409 });
          if (err.data?.phone_number?.includes('already exists')) {
            setDoesPhoneNumberExist(true);
            setExistingPhoneNumber({ phoneNumber: err.data.phone_number.split(' ')[0] });
          }
          if (err['code'] === 422) setInvalidFormErrors(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      errors,
      setCVUploadedProcessComplete,
      setDoesPhoneNumberExist,
      setErrors,
      setExistingPhoneNumber,
      setLoading,
      setWorkerId,
      workerService,
    ]
  );

  const handleFormChange = useCallback(
    (value: string, key: keyof typeof formData) => {
      setFormErrors((prev) => ({ ...prev, [key]: undefined }));
      setFormData((prev) => {
        const newData = { ...prev, [key]: value };
        return newData;
      });
      setValue(key, value);
    },
    [setValue]
  );

  const onSubmit: SubmitHandler<FormErrorType> = useCallback(
    (data) => {
      trackEvent('add_details_cv', {
        email: data.email,
        phone_number: data.phone_number,
      });
      sendMissingCVData({
        ...data,
        ...(addressFields ? { address: Object.keys(addressFields).length ? addressFields : undefined } : {}),
      });
    },
    [addressFields, sendMissingCVData, trackEvent]
  );

  const formErrorRestructured = useMemo(
    () =>
      Object.keys(useFormHookErrors).reduce(
        (acc, prev) => ({
          ...acc,
          [prev]: useFormHookErrors[prev]?.message,
        }),
        {}
      ),
    [useFormHookErrors]
  );

  useEffect(() => {
    setFormData({ ...initialErrorData });
    setFormErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(formErrorRestructured).length) setFormErrors(formErrorRestructured);
    return () => {
      setFormErrors({});
    };
  }, [formErrorRestructured]);

  return {
    handleSubmit,
    handleFormChange,
    onSubmit,
    control,
    formErrors,
    loading,
    invalidFormErrors,
  };
};
export const InvalidFormErrors = ({ invalidFormErrors }: { invalidFormErrors?: FieldValues }) => {
  const { t } = useTranslation();
  const setErrors = useSetAtom(workerEntryPointErrorsAtom);
  const setUploadFiles = useSetAtom(uploadFilesAtom);

  return invalidFormErrors?.['errors']?.['cv'] ? (
    <span tw="flex gap-1 text-red-400 font-medium">
      <p>cv {invalidFormErrors?.['errors']['cv']},</p>
      <a
        tw="underline"
        href="#!"
        onClick={() => {
          setErrors(null);
          setUploadFiles(null);
        }}
      >
        {t('onboarding-page:go_back_and_reload')}
      </a>
    </span>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};
const FormWrapper = tw.div`flex flex-col gap-4`;
export const Form = tw.form`flex flex-col gap-24 w-full`;
export const ErrorWrapper = tw.div`flex flex-col gap-4`;
const phoneRegExp = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
const notRequired = Yup.string().notRequired();
const invalidGmailMatch = ['gmai.com', 'gmil.com', 'gamil.com', 'gmil.com', 'gail.com', 'gmal.com', 'gmall.com'];
