import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

// Atom for signup modal state (open or closed)
export const isSignupModalOpen = atom<boolean>(false);

// Atom to explicitly open the signup modal
export const openSignupModal = atom(null, (_get, set) => {
  set(isSignupModalOpen, true);
});

// Atom to explicitly close the signup modal
export const closeSignupModal = atom(null, (_get, set) => {
  set(isSignupModalOpen, false);
});

//Atom to set account exists when signup is errored
export const accountExists = atom<{ email: string | null }>({ email: null });

// Atom to set an email account exists
export const setAccountExistingEmail = atom(null, (_get, set, newEmail: string | null) => {
  set(accountExists, { email: newEmail });
});

// Atom for email login modal state
export const isEmailLoginModalOpen = atom<boolean>(false);

// Atom for opening email login modal
export const openEmailLoginModal = atom(null, (_get, set) => {
  set(isEmailLoginModalOpen, true);
});

// Atom to explicitly close the email login modal
export const closeEmailLoginModal = atom(null, (_get, set) => {
  set(isEmailLoginModalOpen, false);
});

export interface EmailVerificationDetails {
  waitTime: number;
  tokenValidityTime: number;
  email: string;
}

// Initialize the atom with default values
export const emailVerificationDetails = atom<EmailVerificationDetails>({
  waitTime: 0, // Default wait time
  tokenValidityTime: 0, // Default token validity time
  email: '', // Default email
});

// Create a writable atom to update email verification details
export const updateEmailVerificationDetails = atom(null, (get, set, newValue: Partial<EmailVerificationDetails>) => {
  const currentDetails = get(emailVerificationDetails);
  const updatedDetails = { ...currentDetails, ...newValue };
  set(emailVerificationDetails, updatedDetails);
});

// To manage the state of email verification sent modal
export const isEmailTokenSentModalOpen = atom<boolean>(false);

// Atom to explicitly open the email token sent modal
export const openEmailTokenSentModal = atom(null, (_get, set) => {
  set(isEmailTokenSentModalOpen, true);
});

// Atom to explicitly close the email token sent modal
export const closeEmailTokenSentModal = atom(null, (_get, set) => {
  set(isEmailTokenSentModalOpen, false);
});

// Atom to manage the state of email token not received modal
export const isEmailTokenNotReceivedModalOpen = atom<boolean>(false);

// Atom to explicitly open the "Email Token Not Received" modal
export const openEmailTokenNotReceivedModal = atom(null, (_get, set) => {
  set(isEmailTokenNotReceivedModalOpen, true);
});

// Atom to explicitly close the "Email Token Not Received" modal
export const closeEmailTokenNotReceivedModal = atom(null, (_get, set) => {
  set(isEmailTokenNotReceivedModalOpen, false);
});

// Atom to mantain currentURL
export const currentUrl = atom<string>('');

// Atom to set the current URL
export const setCurrentUrl = atom(null, (_get, set, newValue: string) => {
  set(currentUrl, newValue);
});

// A modal for mantaining the email login assistance modal
export const isEmailLoginAssistanceModalOpen = atom<boolean>(false);

// Atom to explicitly open the email login assistance modal
export const openEmailLoginAssistanceModalAtom = atom(null, (_get, set) => {
  set(isEmailLoginAssistanceModalOpen, true);
});

// Atom to explicitly close the email login assistance modal
export const closeEmailLoginAssistanceModalAtom = atom(null, (_get, set) => {
  set(isEmailLoginAssistanceModalOpen, false);
});

// Atom to open and close authflow modal
export const isAuthFlowModalOpen = atom<boolean>(false);

export const openAuthFlowModal = atom(null, (get, set) => {
  set(isAuthFlowModalOpen, true);
});

// Atom to explicitly close the authentication flow modal
export const closeAuthFlowModal = atom(null, (get, set) => {
  set(isAuthFlowModalOpen, false);
});

export enum ContentType {
  EMAIL_LOGIN = 'EMAIL_LOGIN',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_LOGIN_ASSISTANCE = 'EMAIL_LOGIN_ASSISTANCE',
  SIGNUP = 'SIGNUP',
  PHONE_NUMBER_LOGIN = 'PHONE_NUMBER_LOGIN',
  OTP_VERIFICATION = 'OTP_VERIFICATION',
  OTP_VERIFICATION_ASSISTANCE = 'OTP_VERIFICATION_ASSISTANCE',
  NO_CONTENT = 'NO_CONTENT',
  MAGIC_LINK_EXPIRED = 'MAGIC_LINK_EXPIRED',
  MAGIC_LINK_USED = 'MAGIC_LINK_USED',
}

// Atom to mantain active modal content
export const activeContent = atom<ContentType>(ContentType.NO_CONTENT);

export const setActiveContent = atom(null, (_get, set, newValue: ContentType) => {
  set(activeContent, newValue);
});

// Atom to mantain the state of phone number login modal
export const isPhoneNumberLoginModalOpen = atom(false);

// Atom to explicitly open the phone number login modal
export const openPhoneNumberLoginModal = atom(null, (_get, set) => {
  set(isPhoneNumberLoginModalOpen, true);
});

// Atom to explicitly close the phone number login modal
export const closePhoneNumberLoginModal = atom(null, (_get, set) => {
  set(isPhoneNumberLoginModalOpen, false);
});

export interface PhoneNumberVerificationDetails {
  waitTime: number;
  tokenValidityTime: number;
  phoneNumber: string;
}
// Atom to mantain the state of phone number validation details
export const phoneNumberValidationDetails = atom<PhoneNumberVerificationDetails>({
  waitTime: 0,
  tokenValidityTime: 0,
  phoneNumber: '',
});

// Atom to update phone number validation details
export const updatePhoneNumberValidationDetails = atom(
  null,
  (get, set, newValue: Partial<PhoneNumberVerificationDetails>) => {
    const currentDetails = get(phoneNumberValidationDetails);
    const updatedDetails = { ...currentDetails, ...newValue };
    set(phoneNumberValidationDetails, updatedDetails);
  }
);

// Atom to mantain the state of the otp verification modal
export const isOtpVerificationModalOpen = atom(false);

// Atom to explicitly open the otp verification modal
export const openOtpVerificationModal = atom(null, (_get, set) => {
  set(isOtpVerificationModalOpen, true);
});

// Atom to explicitly close the otp verification modal
export const closeOtpVerificationModal = atom(null, (_get, set) => {
  set(isOtpVerificationModalOpen, false);
});

// Atom to mantain the state of the otp verification assistance modal
export const isOtpVerificationAssistanceModalOpen = atom(false);

// Atom to explicitly open the otp verification assistance modal
export const openOtpVerificationAssistanceModal = atom(null, (_get, set) => {
  set(isOtpVerificationAssistanceModalOpen, true);
});

// Atom to explicitly close the otp verification assistance modal
export const closeOtpVerificationAssistanceModal = atom(null, (_get, set) => {
  set(isOtpVerificationAssistanceModalOpen, false);
});

// Atom to explicity to store the non existing account information
export const nonExistingAccount = atom<{ email: string | null; phoneNumber: string | null }>({
  email: null,
  phoneNumber: null,
});

// Atom to set the non existing account information
export const setNonExistingAccount = atom(
  null,
  (_get, set, newValue: { email: string | null; phoneNumber: string }) => {
    set(nonExistingAccount, newValue);
  }
);

// Atom to set existing phone number
export const existingPhoneNumber = atom<{ phoneNumber: string | null }>({ phoneNumber: null });

// Atom to set existing phone number
export const setExistingPhoneNumber = atom(null, (_get, set, newValue: string | null) => {
  set(existingPhoneNumber, { phoneNumber: newValue });
});

// Atom to set signupFailed due to existing phone number
export const signupPhoneNumberFailed = atom<boolean>((get) => {
  const data = get(existingPhoneNumber);
  return !!data.phoneNumber;
});

export const alreadyInvitedCollaboratorModalOpenAtom = atom(false);
alreadyInvitedCollaboratorModalOpenAtom.debugLabel = 'alreadyInvitedCollaboratorModalOpenAtom';

export function useAlreadyInvitedCollaboratorModal() {
  const [isOpen, setIsOpen] = useAtom(alreadyInvitedCollaboratorModalOpenAtom);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return { isOpen, openModal, closeModal };
}

export const alreadyInvitedCollaboratorEmailAtom = atom('');
alreadyInvitedCollaboratorEmailAtom.debugLabel = 'alreadyInvitedCollaboratorEmailAtom';
