globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './job-offer';
export * from './toast-message-context';
export * from './job-similar-position-props';
export * from './svg-type';
export * from './multi-select-options-type';
export * from './worker-entry-point';