import { createGlobalStyle } from 'styled-components';
import { GlobalStyles as BaseStyles } from 'twin.macro';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    font-size: 14px;
    scroll-behavior: smooth;
    font-family: 'Inter', sans-serif;
  }
  @media all and (min-width: 768px) {
    html,
    body {
      font-size: 16px;
    }
  }
  .alice-carousel__stage-item {
    min-width: 77px !important;
    margin-right: 8px;
  }

  .dialog-open {
    overflow: visible !important;
  }
  .react-date-picker__calendar {
    inset: auto auto -120px 0px !important;
  }
  .mapboxgl-map {
    font: unset !important;
  }
`;

const GlobalStylesComponent = () => {
  return (
    <>
      <GlobalStyles />
      <BaseStyles />
    </>
  );
};

export default GlobalStylesComponent;
