import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { JobShifts } from '@restworld/ui-ds';

import { jobPositionApiDataAtom } from '../job-position.atoms';
import { JobApplicationEntryPoint } from '../';

export const Shifts = () => {
  const { jobShiftsArgs, isEmptyEl } = useShifts();
  return !isEmptyEl ? (
    <>
      <JobShifts {...jobShiftsArgs}>
        <JobApplicationEntryPoint />
      </JobShifts>
      <hr tw="text-gray-300" />
    </>
  ) : null;
};
const useShifts = () => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const jobShiftsArgs = useMemo(
    () => ({
      jobType: jobPositionApiData?.job_type_var?.label || null,
      daysRequiredPerWeek: jobPositionApiData?.days_required_per_week,
      daysRequiredPerWeekNumber: jobPositionApiData?.days_required_per_week_number,
      shifts: jobPositionApiData?.shifts,
      shiftNotes: jobPositionApiData?.shift_notes,
      // customerServiceHelper: jobPositionApiData?.assigned_to_user?.name,
    }),
    [
      // jobPositionApiData?.assigned_to_user?.name,
      jobPositionApiData?.days_required_per_week,
      jobPositionApiData?.days_required_per_week_number,
      jobPositionApiData?.job_type_var?.label,
      jobPositionApiData?.shift_notes,
      jobPositionApiData?.shifts,
    ]
  );
  const isEmptyEl = useMemo(
    () =>
      Boolean(
        !jobPositionApiData?.days_required_per_week?.length &&
          !jobPositionApiData?.days_required_per_week_number &&
          !jobPositionApiData?.shifts?.length
      ),
    [
      jobPositionApiData?.days_required_per_week?.length,
      jobPositionApiData?.days_required_per_week_number,
      jobPositionApiData?.shifts?.length,
    ]
  );
  return { jobShiftsArgs, isEmptyEl };
};
