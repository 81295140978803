globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { tokenKeyName } from '../constants';
export function setSession(accessToken) {
  if (accessToken) localStorage.setItem(tokenKeyName, accessToken);else localStorage.removeItem(tokenKeyName);
}