import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var similarJobPositionMock = {
  publishedAt: new Date('2020-08-27T08:24:37.642Z'),
  city: 'Milano',
  jobTitle: 'Cameriere alle colazioni per hotel di lusso',
  occupation: 'Cameriere',
  salaryMin: 1600,
  salaryMax: 1800,
  salary: '',
  restaurantName: 'Lavazza',
  showRestaurantDetails: true,
  jobType: 'Full time'
};
var jobPositionDetailsMock = {
  companyLogo: '/utils-assets/lavazza-logo.svg',
  companyName: 'Lavazza',
  title: 'Cameriere di sala',
  jobLocation: 'Piazza S. Fedele, 2, 20121 Milano MI',
  salary: '€1600 lordi',
  contractTerm: 'Tempo determinato',
  shifts: {
    title: 'Turni',
    subTitle: 'Orario spezzato'
  },
  seasonalAccommodation: true,
  salaryMonthNumber: 12
};

var defaultSecondaryCardArgs = _objectSpread({}, similarJobPositionMock);

var defaultPrimaryCardArgs = {
  jobPositionDetails: _objectSpread({}, jobPositionDetailsMock),
  showRestaurant: true
};
var reviewMock = {
  author_name: 'Nome Cognome',
  rating: 1,
  time: '1540510197',
  text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, explicabo ad. Praesentium debitis voluptatem cum aperiam a! Laudantium error sit commodi illum eligendi exercitationem aspernatur blanditiis officiis! Corrupti, deserunt nulla.'
};
export { defaultSecondaryCardArgs, defaultPrimaryCardArgs, reviewMock };