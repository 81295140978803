import Image from 'next/image';
import { getAssetSrc } from '@restworld/utils-common';
import { Button, LinkBase, Paragraph, Title } from '@restworld/ui-ds';
import { useTranslation } from 'next-i18next';
import tw from 'twin.macro';
import { JobApplicationEntryPoint } from '../job-application-entry-point';

export const WhyTrustUs = () => {
  const { t } = useTranslation('job-position-page');
  return (
    <>
      <StyledWrapper>
        <div tw="flex flex-row gap-2 items-center mb-2">
          <Image
            width={32}
            height={32}
            src={getAssetSrc('/utils-assets/small-white-logo.svg')}
            alt="restworld"
            priority
          />
          <Title standardVariant="h4" textColor="white" content={t('why_trust_us.header')} />
        </div>
        <Paragraph color="white" standardVariant="body-sm" content={t('why_trust_us.description')} />
        <div tw="flex flex-col gap-1 items-start my-2">
          <Paragraph
            color="white"
            font="semibold"
            standardVariant="body-sm"
            content={t('why_trust_us.our_offers.header')}
          />
          <ul tw="list-disc text-white list-inside pl-2.5">
            <li>
              <Paragraph
                standardVariant="body-sm"
                color="white"
                content={t('why_trust_us.our_offers.bullet_1')}
                twStyle={tw`inline-block`}
              />
            </li>
            <li>
              <Paragraph
                standardVariant="body-sm"
                color="white"
                content={t('why_trust_us.our_offers.bullet_2')}
                twStyle={tw`inline-block`}
              />
            </li>
            <li>
              <Paragraph
                standardVariant="body-sm"
                color="white"
                content={t('why_trust_us.our_offers.bullet_3')}
                twStyle={tw`inline-block`}
              />
            </li>
          </ul>
        </div>
        <div tw="flex flex-col gap-1 items-start mb-2">
          <div tw="flex flex-row gap-2 pl-0 items-center justify-start">
            <Image
              width={64}
              height={64}
              src={getAssetSrc('/utils-assets/restworld-team.svg')}
              alt="restworld"
              priority
            />
            <Title
              textColor="white"
              standardVariant="h5"
              content={t('why_trust_us.team_motivation')}
              fontWeight="semibold"
            />
          </div>

          <Paragraph color="white" standardVariant="body-sm" content={t('why_trust_us.recruiter_motivation')} />
        </div>
        <Title textColor="white" standardVariant="h5" content={t('why_trust_us.contact_text')} fontWeight="semibold" />
        <div tw="lg:grid  lg:grid-cols-2  flex flex-col items-center gap-2  w-full mt-2">
          <JobApplicationEntryPoint />
          <LinkBase link="https://help.restworld.it/it/articles/5330756-che-cos-e-restworld" tw="w-full">
            <Button variant="white-ghost" isUnderLined={true} fontColor="white" fullwidth={true}>
              {t('why_trust_us.contact_link')}
            </Button>
          </LinkBase>
        </div>
      </StyledWrapper>
      <hr tw="text-gray-300 lg:block hidden" />
    </>
  );
};

const StyledWrapper = tw.div`bg-primary-700 p-4 rounded-3.75 w-full flex flex-col items-start gap-0 mt-0`;
