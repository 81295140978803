import React, { useMemo, useEffect, useCallback } from 'react';
import tw from 'twin.macro';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { JobApplicationStatusKey } from '@restworld/utility-types';
import { useAuth } from '@restworld/feature-authentication';
import { useAnalytics, WorkerEntryPointProps } from '@restworld/utils-common';
import { Button, ButtonProps, LinkBase, Paragraph, Popover, Trigger } from '@restworld/ui-ds';

import JobBoardPixels from './worker-entry-point.components/job-board-pixels';
import {
  UploadCVUI,
  EntryPointChecklistProvider,
  useEntryPointCheckListContext,
  UploadedCVUI,
} from './worker-entry-point.components';
import {
  hasUserAppliedSpontaneousApplicationAtom,
  useShowSpontaneousApplication,
} from './use-show-spontaneous-application';
import { useJobExpireInfo, useJobPositionId } from '../../hooks';
import {
  isCVUploadProcessCompletedAtom,
  jobApplicationModalAlreadyDisplayedAtom,
  showConfirmModalAtom,
  spontaneousApplyDataAtom,
  workerIdAtom,
} from './worker-entry-point.atoms';
import { entryPointModalVisibleAtom } from './worker-entry-point-modal';
import { useRouter } from 'next/router';
import {
  isJobApplicationDiscardedAtom,
  jobApplicationAtom,
  jobPositionApiDataAtom,
  jobPositionApplyStatusAtom,
} from '../job-position/job-position.atoms';
import {
  phoneNumberVerificationModalVisibleAtom,
  useShouldShowPhoneNumberVerificationModal,
} from '../common-phone-number-otp-validation-modal/update-phone-number-modal';

export const WorkerEntryPointButton = ({
  children,
  fullwidth,
  applicationType = 'job-apply',
  restaurantID,
  label,
  testId,
}: WorkerEntryPointProps) => {
  const jobPositionId = useJobPositionId();
  const [showEntryPoint, toggleEntryPoint] = useAtom(entryPointModalVisibleAtom);
  const openEntryPoint = useCallback(() => toggleEntryPoint(true), [toggleEntryPoint]);
  const setWorkerId = useSetAtom(workerIdAtom);

  const [isCVUploadProcessCompleted] = useAtom(isCVUploadProcessCompletedAtom);
  const [, confirmModalToggler] = useAtom(showConfirmModalAtom);
  const [, setSpontaneousApplyData] = useAtom(spontaneousApplyDataAtom);
  const setJobApplicationModalAlreadyDisplayed = useSetAtom(jobApplicationModalAlreadyDisplayedAtom);
  const didUserSpontaneousApply = useAtomValue(hasUserAppliedSpontaneousApplicationAtom);
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const [jobPositionApplyStatus] = useAtom(jobPositionApplyStatusAtom);
  const jobApplication = useAtomValue(jobApplicationAtom);

  const { trackEvent, fbTrackWorkerStartsApplication } = useAnalytics();

  const restaurantId = jobPositionApiData?.restaurant?.id;
  const jopPositionId = jobPositionApiData?.id;
  const jobStatusKey = jobApplication?.status?.key as JobApplicationStatusKey;
  const isProposedJobApplication = restaurantId && jopPositionId && jobStatusKey === 'worker_jp_proposed';
  const { user, isAuthenticated } = useAuth();
  const { shouldShowPhoneNumberVerificationModal } = useShouldShowPhoneNumberVerificationModal();

  const onTrackApplyJobEvent = useCallback(() => {
    trackEvent('initiate_apply_job', {
      job_position_id: jobPositionApiData?.id,
      job_title: jobPositionApiData?.job_title,
      restaurant_name: jobPositionApiData?.restaurant?.restaurant_name,
      category: jobPositionApiData?.occupation?.label_it?.label,
      logged_in: isAuthenticated,
    });
    const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
    if (jobPositionApiData?.hirematic_flag && isProd) {
      window?.ppln('track', 'conversionRegistered', { step: 'start' });
    }
    fbTrackWorkerStartsApplication();
  }, [
    isAuthenticated,
    jobPositionApiData?.id,
    jobPositionApiData?.job_title,
    jobPositionApiData?.occupation?.label_it?.label,
    jobPositionApiData?.restaurant?.restaurant_name,
    jobPositionApiData?.hirematic_flag,
    fbTrackWorkerStartsApplication,
    trackEvent,
  ]);
  const router = useRouter();
  const { t } = useTranslation();
  const isJobApplicationDiscarded = useAtomValue(isJobApplicationDiscardedAtom);
  const setPhoneNumberVerificationModalVisible = useSetAtom(phoneNumberVerificationModalVisibleAtom);

  const { isJpInActive, isLoading: jobApplicationStatusLoading } = useJobExpireInfo();
  const btnTitle = useMemo(
    () => (jobPositionApplyStatus ? t('onboarding-page:monitor_the_progress') : t('common:apply_now')),
    [jobPositionApplyStatus, t]
  );
  const handleClick = useCallback(async () => {
    if (user?.type === 'worker' && !jobPositionApplyStatus && shouldShowPhoneNumberVerificationModal) {
      setPhoneNumberVerificationModalVisible(true);
      return;
    }
    if ((didUserSpontaneousApply && applicationType === 'spontaneous-apply') || jobPositionApplyStatus) {
      router?.push(`/candidature/${jobPositionId}`);
      return;
    }
    if (user?.type !== 'lead' && applicationType === 'job-apply' && isAuthenticated && !jobPositionApplyStatus) {
      confirmModalToggler(true);
    }
    openEntryPoint();
    onTrackApplyJobEvent();
  }, [
    applicationType,
    confirmModalToggler,
    didUserSpontaneousApply,
    isAuthenticated,
    jobPositionApplyStatus,
    jobPositionId,
    onTrackApplyJobEvent,
    openEntryPoint,
    router,
    setPhoneNumberVerificationModalVisible,
    shouldShowPhoneNumberVerificationModal,
    user?.type,
  ]);

  const buttonProps = useMemo(
    () =>
      ({
        key: 'apply-now',
        title: btnTitle,
        onClick: handleClick,
        fullwidth,
        disabled: user?.type === 'ambassador' || user?.type === 'employer' || isProposedJobApplication,
        icon: 'BoltIcon',
        testId: `${testId}-cta-btn`,
      } as ButtonProps),
    [btnTitle, fullwidth, handleClick, isProposedJobApplication, testId, user?.type]
  );

  const { showSpontaneousApplicationLoading } = useShowSpontaneousApplication({
    restaurantID,
    isSpontaneousEntry: applicationType === 'spontaneous-apply',
  });

  useEffect(() => {
    if (applicationType === 'spontaneous-apply') {
      setSpontaneousApplyData({
        isSpontaneousEntry: true,
        applicationType,
        restaurantID,
        didUserSpontaneousApply,
        showCoverLetter: isAuthenticated,
      });
    }
  }, [applicationType, didUserSpontaneousApply, isAuthenticated, restaurantID, setSpontaneousApplyData]);

  useEffect(() => {
    setJobApplicationModalAlreadyDisplayed(showEntryPoint);
  }, [setJobApplicationModalAlreadyDisplayed, showEntryPoint]);

  useEffect(() => {
    if (user?.id && isAuthenticated) setWorkerId(user?.id);
  }, [isAuthenticated, setWorkerId, user?.id]);

  const renderButtonChildren = useMemo(() => {
    if (children)
      return React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: handleClick,
        })
      );
    if (isJobApplicationDiscarded)
      return (
        <LinkBase link={`/candidature/${jobPositionId}`} newTab={false} twStyle={tw`w-full`}>
          <Button
            title={t('job-position-page:discover_how_it_went')}
            icon="FaceFrownIcon"
            fontColor="purple-500"
            fullwidth
          />
        </LinkBase>
      );
    if (applicationType === 'spontaneous-apply')
      return (
        <Button
          {...buttonProps}
          title={didUserSpontaneousApply ? t('common:show_application') : label || t('common:spontaneous_application')}
          variant="normal"
          loading={showSpontaneousApplicationLoading}
        />
      );

    if (user?.type === 'ambassador' || user?.type === 'employer' || user?.type === 'employer_contact')
      return (
        <Popover
          trigger={Trigger.HOVER}
          panelTwStyle={tw`md:min-w-[16rem]`}
          haveDefaultButtonStyle={false}
          buttonTwStyle={tw`focus-visible:(border-none ring-0 shadow-none outline-none)`}
        >
          <Popover.Button>
            <Button {...{ ...buttonProps, as: 'a' }} />
          </Popover.Button>
          <Popover.Panel>
            <div tw="text-sm">
              {`${t('onboarding-page:to_apply_you_must_be_authenticated_as_a_worker_and_not_as')} ${
                user?.type === 'ambassador' ? 'ambassador' : 'employer'
              }`}
              <span role="img" aria-label="cry-face">
                {' '}
                😢
              </span>
            </div>
          </Popover.Panel>
        </Popover>
      );

    if (jobPositionApplyStatus) return <Button {...{ ...buttonProps, loading: jobApplicationStatusLoading }} />;

    if (!isJpInActive && !jobPositionApplyStatus)
      return (
        <Popover
          trigger={Trigger.CLICK}
          panelTwStyle={tw`min-w-[16rem]`}
          haveDefaultButtonStyle={false}
          fullWidth={true}
        >
          <Popover.Button>
            <Button disabled fullwidth>
              {btnTitle}
            </Button>
          </Popover.Button>
          <Popover.Panel>
            <Paragraph variant="body" color="gray-500" twStyle={tw`text-black`}>
              {t('onboarding-page:no_longer_accepting_applications_for_this_offer')}
              <span role="img" aria-label="cry">
                &#128557;
              </span>
            </Paragraph>
          </Popover.Panel>
        </Popover>
      );

    return <Button {...{ ...buttonProps, loading: jobApplicationStatusLoading }} testId={`${testId}-cta-btn`} />;
  }, [
    children,
    isJobApplicationDiscarded,
    jobPositionId,
    applicationType,
    buttonProps,
    didUserSpontaneousApply,
    t,
    label,
    showSpontaneousApplicationLoading,
    user?.type,
    jobPositionApplyStatus,
    jobApplicationStatusLoading,
    isJpInActive,
    btnTitle,
    testId,
    handleClick,
  ]);

  return (
    <>
      {renderButtonChildren}
      {isCVUploadProcessCompleted ? <JobBoardPixels /> : null}
    </>
  );
};

export { UploadCVUI, EntryPointChecklistProvider, useEntryPointCheckListContext, UploadedCVUI };
