import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { useMounted } from '@restworld/utils-common';

import { jobListParamsAtom } from '../job-explorer-list.atoms';
import { jobCoordinateParamsAtom } from '../job-explorer-map.atoms';
import { filterContextValuesAtom, moreFiltersAtom } from '../job-explorer-filters.atoms';
import { serverListPayloadAtom, serverCoordinatePayloadAtom } from '../job-explorer-server.atoms';

export const useExplorerInitialApiCalls = () => {
  const isMounted = useMounted();
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const setJobCoordinateParams = useSetAtom(jobCoordinateParamsAtom);
  const serverListPayload = useAtomValue(serverListPayloadAtom);
  const serverCoordinatePayload = useAtomValue(serverCoordinatePayloadAtom);
  const setMoreFilters = useSetAtom(moreFiltersAtom);
  const setFilterContextValues = useSetAtom(filterContextValuesAtom);
  useEffect(() => {
    if (!isMounted) return;
    if (
      serverListPayload?.['contract_type']?.length &&
      serverListPayload['contract_type'][0] === 'seasonal_accommodation'
    ) {
      setFilterContextValues((p) => ({
        ...p,
        ...(p?.['contract_type']
          ? { contract_type: [...p['contract_type'], ...['seasonal_accommodation']] }
          : { contract_type: ['seasonal_accommodation'] }),
      }));
      setMoreFilters((p) => ({
        ...p,
        ...(p?.['contract_type']
          ? { contract_type: [...p['contract_type'], ...['seasonal_accommodation']] }
          : { contract_type: ['seasonal_accommodation'] }),
      }));
    } else if (serverListPayload) {
      setFilterContextValues((p) => ({
        ...p,
        ...(Object.keys(serverListPayload).includes('domain_interest') ? serverListPayload : {}),
      }));
      setMoreFilters((p) => ({
        ...p,
        ...(Object.keys(serverListPayload).includes('domain_interest') ? serverListPayload : {}),
      }));
    }
  }, [isMounted, serverListPayload, setFilterContextValues, setMoreFilters]);

  useEffect(() => {
    if (!isMounted) return;
    if (!serverListPayload) return;
    const combinedPayload = {
      ...(serverListPayload ?? {}),
    };
    const newPayload = Object.keys(combinedPayload).reduce((acc, curr) => {
      const param = combinedPayload[curr];
      if (curr === 'contract_type' && param?.length)
        return { ...acc, contract_type: [...combinedPayload[curr], ...param] };
      return { ...acc, [curr]: combinedPayload[curr] };
    }, {});
    setTimeout(() => {
      setJobListParams((p) => {
        return { ...p, ...newPayload };
      });
    }, 250);
  }, [isMounted, serverListPayload, setJobListParams]);

  useEffect(() => {
    if (!isMounted) return;
    if (!serverCoordinatePayload) return;
    setJobCoordinateParams((p) => {
      return {
        ...p,
        ...{
          ...(serverCoordinatePayload ?? {}),
        },
      };
    });
  }, [isMounted, serverCoordinatePayload, setJobCoordinateParams]);
};
