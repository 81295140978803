import { JobApplicationStatusKey } from '@restworld/utility-types';
import { useAnalytics } from '@restworld/utils-common';
import { useCallback } from 'react';

export function useTrackJobApplication() {
  const { trackEvent } = useAnalytics();
  const trackJobApplicationOpened = useCallback(
    (status: JobApplicationStatusKey) => {
      // trackEvent('Job application', {
      //   phase: 'Opened',
      //   status,
      // });
    },
    // [trackEvent]
    []
  );

  const trackJobApplicationStatusUpdated = useCallback(
    (oldStatus: JobApplicationStatusKey, newStatus: JobApplicationStatusKey) => {
      // trackEvent('Job application', {
      //   phase: 'Status Updated',
      //   oldStatus,
      //   newStatus,
      // });
    },
    // [trackEvent]
    []
  );

  return {
    trackJobApplicationOpened,
    trackJobApplicationStatusUpdated,
  };
}
