import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { BaseModal, Button, Checkbox, CommonModalWrapper, Title } from '@restworld/ui-ds';
import { ManWithMagnifierIllustration } from '@restworld/utils-common';
import { useAccountsService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';

import {
  toggleActiveExplorerTypeAtom,
  activeExplorerTypeAtom,
  explorerRestaurantListPayloadAtom,
  ExplorerMapState,
  restaurantInfoTogglerAtom,
  restaurantInfoModalTogglerAtom,
  getRestaurantInfoModalTogglerAtom,
  getRestaurantInfoTogglerAtom,
  setRestaurantExplorationPopupDetailsAtom,
  getRestaurantExplorationPopupDetailsAtom,
  getRestaurantExplorationPopupDiffHoursAtom,
  getPersistenceRestaurantExplorationPopupShowAtom,
  setPersistenceRestaurantExplorationPopupShowAtom,
  explorerRestaurantZoomLevelAtom,
} from '../job-explorer-restaurant-explorer.atoms';
import { getMapRefAtom } from '../job-explorer-map.atoms';

export const ExplorerTypeToggler = () => {
  const router = useRouter();
  const mapRef = useAtomValue(getMapRefAtom);
  const { isAuthenticated } = useAuth();
  const [active] = useAtom(activeExplorerTypeAtom);
  const [, setActive] = useAtom(toggleActiveExplorerTypeAtom);
  const explorerRestaurantListPayload = useAtomValue(explorerRestaurantListPayloadAtom);
  const explorerRestaurantZoomLevel = useAtomValue(explorerRestaurantZoomLevelAtom);
  const [restaurantInfoToggle] = useAtom(getRestaurantInfoTogglerAtom);
  const [, restaurantInfoModalToggler] = useAtom(restaurantInfoModalTogglerAtom);
  const [persistenceRestaurantExplorationPopupShow] = useAtom(getPersistenceRestaurantExplorationPopupShowAtom);

  const [popupReadAtDiffHours] = useAtom(getRestaurantExplorationPopupDiffHoursAtom);

  const onClickHandler = useCallback(
    ({ value }: { value: ExplorerMapState }) => {
      setActive(value);
      if (value === 'restaurants') {
        if (isAuthenticated) {
          if (popupReadAtDiffHours === 0 || (popupReadAtDiffHours !== -24 && popupReadAtDiffHours >= 24))
            restaurantInfoModalToggler(restaurantInfoToggle);
        }
        if (!isAuthenticated) restaurantInfoModalToggler(persistenceRestaurantExplorationPopupShow === 'true');
        mapRef.current?.flyTo({
          center: [explorerRestaurantListPayload?.lon, explorerRestaurantListPayload?.lat],
          zoom: explorerRestaurantZoomLevel,
          duration: 500,
        });
      }
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, explorer_type: value },
        },
        null,
        {
          shallow: true,
        }
      );
    },
    [
      setActive,
      router,
      isAuthenticated,
      persistenceRestaurantExplorationPopupShow,
      restaurantInfoModalToggler,
      mapRef,
      explorerRestaurantListPayload?.lon,
      explorerRestaurantListPayload?.lat,
      explorerRestaurantZoomLevel,
      popupReadAtDiffHours,
      restaurantInfoToggle,
    ]
  );

  const { t } = useTranslation('common');

  const toggleItems = useMemo(
    () => [
      {
        label: t('job_offers'),
        value: 'job_offers' as const,
      },
      {
        label: t('restaurants'),
        value: 'restaurants' as const,
        isBeta: true,
      },
    ],
    [t]
  );

  const toggler = useMemo(
    () =>
      toggleItems.map(({ label, value, isBeta }) => (
        <Item onClick={() => onClickHandler({ value })} key={value} isActive={active === value}>
          <Title
            content={label}
            fontWeight="medium"
            fontSize="small"
            twStyle={tw`leading-5.25`}
            textColor={active === value ? 'white' : 'black'}
          />
          {isBeta ? (
            <Beta>
              <Title content="Beta" fontSize="xSmall" fontWeight="normal" twStyle={tw`leading-4.5`} />
            </Beta>
          ) : null}
        </Item>
      )),
    [active, onClickHandler, toggleItems]
  );

  return (
    <Wrapper>
      <ToggleWrapper>{toggler}</ToggleWrapper>
    </Wrapper>
  );
};
const Item = styled.li<{ isActive: boolean }>`
  ${tw`flex items-center justify-center rounded bg-white px-2 py-1 cursor-pointer`}
  ${({ isActive }) => isActive && tw`bg-primary-800`}
`;
const Wrapper = tw.div`top-4 left-4 absolute z-10 h-[40px]`;
const Beta = tw.div`bg-primary-100 p-1 rounded-[0.125rem] ml-1`;
const ToggleWrapper = tw.ul`flex flex-row gap-2 bg-white rounded-lg shadow-lg p-1 border border-gray-200 max-h-[40px]`;

export const RestaurantInfoModal = () => {
  const { t } = useTranslation('job-explorer-page');
  const [hasModalShown, setHasModalShown] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const { isAuthenticated, isReady } = useAuth();
  const [, restaurantInfoModalToggler] = useAtom(restaurantInfoModalTogglerAtom);
  const [, restaurantInfoToggler] = useAtom(restaurantInfoTogglerAtom);
  const restaurantInfoModalToggle = useAtom(getRestaurantInfoModalTogglerAtom)[0];
  const restaurantInfoToggle = useAtom(getRestaurantInfoTogglerAtom)[0];
  const popupReadAtDiffHours = useAtom(getRestaurantExplorationPopupDiffHoursAtom)[0];

  const restaurantQueryType = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('explorer_type') === 'restaurants';
  }, []);
  const [persistenceRestaurantExplorationPopupShow] = useAtom(getPersistenceRestaurantExplorationPopupShowAtom);

  const [, setPersistenceRestaurantExplorationPopupShow] = useAtom(setPersistenceRestaurantExplorationPopupShowAtom);
  const { updateRestaurantExplorationPopupDetails, getRestaurantExplorationPopupDetails } =
    useRestaurantExplorationPopupDetails();

  useEffect(() => {
    getRestaurantExplorationPopupDetails();
  }, [getRestaurantExplorationPopupDetails]);

  // show modal if user is authenticated and restaurantQueryType is true and restaurantInfoToggle is true and popupReadAtDiffHours is 0 or >= 24 and popupReadAtDiffHours is not -24
  useEffect(() => {
    if (
      isReady &&
      isAuthenticated &&
      restaurantQueryType &&
      restaurantInfoToggle &&
      popupReadAtDiffHours !== -24 &&
      (popupReadAtDiffHours === 0 || popupReadAtDiffHours >= 24) &&
      !hasModalShown
    ) {
      restaurantInfoModalToggler(true);
      setHasModalShown(true);
    }
  }, [
    hasModalShown,
    isAuthenticated,
    isReady,
    popupReadAtDiffHours,
    restaurantInfoModalToggler,
    restaurantInfoToggle,
    restaurantQueryType,
  ]);

  useEffect(() => {
    if (isReady && !isAuthenticated && restaurantQueryType && persistenceRestaurantExplorationPopupShow === 'true') {
      setPersistenceRestaurantExplorationPopupShow('true');
      restaurantInfoModalToggler(true);
    }
  }, [
    isAuthenticated,
    isReady,
    persistenceRestaurantExplorationPopupShow,
    restaurantInfoModalToggler,
    restaurantQueryType,
    setPersistenceRestaurantExplorationPopupShow,
  ]);
  return (
    <BaseModal
      open={restaurantInfoModalToggle}
      onClose={() => {
        updateRestaurantExplorationPopupDetails({
          restaurantExplorationPopupReadAt: new Date().toISOString(),
        });
        restaurantInfoModalToggler(false);
      }}
      modalStyles={[tw`md:max-w-lg`]}
      showMobileGestures
    >
      <CommonModalWrapper
        mainTitle={t('find_the_perfect_place_for_your_career')}
        subTitle={t('explore_the_list_of_registered_venues')}
      >
        <div tw="flex flex-col gap-8">
          <ManWithMagnifierIllustration />
          <div tw="flex flex-col gap-4">
            <Checkbox
              variant="box"
              isChecked={isChecked}
              title={t('do_not_show_this_message_again')}
              onChange={(evt) => {
                restaurantInfoToggler(!evt.target.checked);
                setChecked(evt.target.checked);
                if (!isAuthenticated) {
                  setPersistenceRestaurantExplorationPopupShow(JSON.stringify(!evt.target.checked));
                  return;
                }
                updateRestaurantExplorationPopupDetails({
                  restaurantExplorationPopupShow: !evt.target.checked,
                  restaurantExplorationPopupReadAt: new Date().toISOString(),
                });
              }}
            />
            <Button
              title={t('let_the_exploration_begin')}
              onClick={() => {
                restaurantInfoModalToggler(false);
                updateRestaurantExplorationPopupDetails({
                  restaurantExplorationPopupReadAt: new Date().toISOString(),
                });
              }}
              variant="normal"
              fullwidth
            />
          </div>
        </div>
      </CommonModalWrapper>
    </BaseModal>
  );
};
export default memo(RestaurantInfoModal);
export const useRestaurantExplorationPopupDetails = () => {
  const [loading, setLoading] = useState(false);

  const { isAuthenticated } = useAuth();
  const [, restaurantInfoToggler] = useAtom(restaurantInfoTogglerAtom);
  const [, setRestaurantExplorationPopupDetails] = useAtom(setRestaurantExplorationPopupDetailsAtom);
  const [restaurantExplorationPopupDetails] = useAtom(getRestaurantExplorationPopupDetailsAtom);
  const accountServices = useAccountsService();

  const getRestaurantExplorationPopupDetails = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      setLoading(true);
      const response = await accountServices.restaurant_exploration_popup_details();
      restaurantInfoToggler(response?.data?.restaurant_exploration_popup_show);
      setRestaurantExplorationPopupDetails(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error, 'Error getting restaurant exploration popup details');
    }
  }, [isAuthenticated, accountServices, restaurantInfoToggler, setRestaurantExplorationPopupDetails]);

  const updateRestaurantExplorationPopupDetails = useCallback(
    async ({
      restaurantExplorationPopupShow,
      restaurantExplorationPopupReadAt,
    }: {
      restaurantExplorationPopupShow?: boolean;
      restaurantExplorationPopupReadAt?: string;
    }) => {
      if (!isAuthenticated) return;
      const payload = {
        ...(typeof restaurantExplorationPopupShow !== 'undefined'
          ? { restaurant_exploration_popup_show: restaurantExplorationPopupShow }
          : {}),
        ...(typeof restaurantExplorationPopupReadAt !== 'undefined'
          ? { restaurant_exploration_popup_read_at: restaurantExplorationPopupReadAt }
          : {}),
      };
      try {
        setLoading(true);
        await accountServices.updateUser(payload);
        setRestaurantExplorationPopupDetails({
          ...restaurantExplorationPopupDetails,
          ...payload,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error, 'Error updating restaurant exploration popup details');
      }
    },
    [accountServices, isAuthenticated, restaurantExplorationPopupDetails, setRestaurantExplorationPopupDetails]
  );

  return {
    getRestaurantExplorationPopupDetails,
    loading,
    updateRestaurantExplorationPopupDetails,
  };
};
