import { useWorkerJobPositionsService } from '@restworld/data-services';
import { useCallback } from 'react';
import {
  closeWorkerApplicationDataLoading,
  openWorkerApplicationDataLoading,
  setWorkerJobApplicationsAtom,
} from '../global-state';
import { useSetAtom } from 'jotai';

export type UseLoadWorkerApplicationsProps = {
  showLoader?: boolean;
};

export function useLoadWorkerApplications({ showLoader = true }: UseLoadWorkerApplicationsProps) {
  const workerJobPositionService = useWorkerJobPositionsService();
  const openLoader = useSetAtom(openWorkerApplicationDataLoading);
  const closeLoader = useSetAtom(closeWorkerApplicationDataLoading);
  const setWorkerData = useSetAtom(setWorkerJobApplicationsAtom);

  const fetchWorkerApplications = useCallback(async () => {
    if (showLoader) {
      openLoader();
    }

    try {
      const response = await workerJobPositionService.indexJobApplications({ limit: 100, offset: 0 });
      setWorkerData(response.data.data);
      if (showLoader) {
        closeLoader();
      }
    } catch (error) {
      if (showLoader) {
        closeLoader();
      }
      console.error(error, 'error fetching worker applications');
    }
  }, [closeLoader, openLoader, setWorkerData, showLoader, workerJobPositionService]);
  return { fetchWorkerApplications };
}
