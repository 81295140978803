import { useState, useCallback } from 'react';

import { useJobPositionsService as jobPositionServices } from '@restworld/data-services';
import { JobPosition } from '@restworld/utility-types';

export const useJobPositionApiData = (data?: { jobPositionId?: string }) => {
  const [loading, loader] = useState(false);
  const [refetchJobPosition, setJobPositionApiData] = useState<JobPosition | null>(null);

  const jobPositionService = jobPositionServices();

  const getJobPositionApiCall = useCallback(
    async (jobPositionArg?: string) => {
      const id = jobPositionArg ?? data?.jobPositionId;
      if (!id) return;
      try {
        loader(true);
        const response = await jobPositionService.showJobPosition(id);
        setJobPositionApiData(response.data);
        loader(false);
        return response.data;
      } catch (error) {
        loader(false);
        console.error(error, `error fetching jp data`);
        return null;
      }
    },
    [data?.jobPositionId, jobPositionService]
  );
  return {
    refetchJobPosition,
    getJobPositionApiCall,
    loading,
  };
};
