import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';

import { useWorkerJobPositionsService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';

import { useJobPositionId } from './use-job-position-id';
import { jobApplicationAtom, jobPositionApplyStatusAtom } from '../components/job-position/job-position.atoms';

export const useJobApplyStatus = () => {
  const jobPositionId = useJobPositionId();
  const { user } = useAuth();
  const workerJobPositionsService = useWorkerJobPositionsService();

  const [jobPositionApplyStatus, setJobPositionApplyStatus] = useAtom(jobPositionApplyStatusAtom);
  const [jobApplication, setJobApplication] = useAtom(jobApplicationAtom);

  const handleFetchJobPositionApplyStatus = useCallback(async () => {
    try {
      if (user?.id && jobPositionId) {
        const response = await workerJobPositionsService.jobApplyStatus({
          worker_id: user?.id,
          job_position_id: jobPositionId,
        });
        const hasAlreadyApplied = response.data && response.data?.status?.key !== 'worker_jp_proposed';
        setJobPositionApplyStatus(hasAlreadyApplied);
        setJobApplication(response.data);
      }
    } catch (error) {
      console.error(error, 'Error on getting job position apply status');
    }
  }, [jobPositionId, setJobApplication, setJobPositionApplyStatus, user?.id, workerJobPositionsService]);
  useEffect(() => {
    handleFetchJobPositionApplyStatus();
  }, [handleFetchJobPositionApplyStatus]);
  return {
    jobPositionApplyStatus,
    jobApplication,
    handleFetchJobPositionApplyStatus,
  };
};
