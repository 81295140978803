import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { similarPositionsMock, restaurantImagesMock } from '@restworld/utils-common';

var restaurantPictures = _toConsumableArray(restaurantImagesMock);

var defaultImageArgs = {
  restaurantPictures: restaurantPictures
};

var similarPositions = _toConsumableArray(similarPositionsMock);

var defaultCardArgs = {
  items: _toConsumableArray(similarPositions)
};
export { defaultImageArgs, restaurantPictures, defaultCardArgs, similarPositions };