globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useEffect } from 'react';
export function useIntersectionObserver(_ref) {
  var targetRef = _ref.targetRef,
      options = _ref.options,
      callback = _ref.callback;
  useEffect(function () {
    var target = targetRef.current;
    if (!target) return;
    var observer = new IntersectionObserver(callback, options);
    observer.observe(target);
    return function () {
      if (observer) {
        observer.unobserve(target);
      }
    };
  }, [targetRef, options, callback]);
}