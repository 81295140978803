import { memo } from 'react';
import { useAtomValue } from 'jotai';
import { isOtpVerificationModalOpen } from '../../context/auth-flow';
import { VerifyOtpForm, VerifyOtpHeader } from './verify-otp-form';

export const VerifyOTPModal = memo(() => {
  const open = useAtomValue(isOtpVerificationModalOpen);
  if (open) {
    return (
      <>
        <VerifyOtpHeader {...{ isModal: true }} />
        <VerifyOtpForm {...{ isModal: true }} />
      </>
    );
  }
  return undefined;
});
