import * as React from 'react';
import 'twin.macro';
import { useAtomValue } from 'jotai';

import { JobExplorerSidebar } from './job-explorer-sidebar';
import { JobExplorerMap } from './job-explorer-map';
import ExplorerFilters from './job-explorer-filters';
import { ListHeadTitle, MapListTogglers } from './job-explorer-content';
import { getToggleFullScreenAtom } from './job-explorer-map.atoms';
import { hasMapViewAtom } from './job-explorer-list.atoms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IJobExplorerDesktopElementProps {
  ambassadorCode?: string;
}
export const JobExplorerDesktopElement: React.FunctionComponent<IJobExplorerDesktopElementProps> = ({
  ambassadorCode,
}) => {
  const hasMapView = useAtomValue(hasMapViewAtom);
  const toggleFullScreen = useAtomValue(getToggleFullScreenAtom);

  const mapTogglers = <MapListTogglers />;
  const explorerFilters = <ExplorerFilters testId="explorer-filters-desktop" />;
  const jobExplorerMap = (
    <div tw="relative" data-cy="job-explorer-map">
      <JobExplorerMap key="map-with-list" isDesktop />
      <div tw="hidden lg:(flex gap-4 justify-center items-center absolute bottom-[2%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9])">
        {mapTogglers}
      </div>
    </div>
  );
  const listHeadTitle = <ListHeadTitle />;
  const explorerSidebar = <JobExplorerSidebar ambassadorCode={ambassadorCode} testId="explorer-sidebar-desktop" />;
  const mainEl =
    !toggleFullScreen && hasMapView ? (
      <div tw="hidden flex-col gap-9 items-center relative lg:(w-full flex flex-row items-start) max-w-full overflow-hidden pt-2">
        <div tw="flex flex-col gap-4 min-w-[25rem]">
          {listHeadTitle}
          <div>{explorerSidebar}</div>
        </div>
        <div tw="flex flex-col gap-4 xl:max-w-[calc(100vw-548px)] xl:w-[calc(100vw-548px)] lg:max-w-[calc(100vw-532px)] w-[calc(100vw-532px)]">
          {explorerFilters}
          {jobExplorerMap}
        </div>
      </div>
    ) : !hasMapView ? (
      <div tw="hidden lg:block transition duration-100 ease-in-out pt-2">
        <div tw="flex gap-4 flex-col">
          <div tw="flex gap-4 flex-row">
            {listHeadTitle}
            {explorerFilters}
          </div>
          {explorerSidebar}
          <div tw="flex gap-4 justify-center items-center fixed bottom-[2%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9]">
            {mapTogglers}
          </div>
        </div>
      </div>
    ) : (
      <div tw="hidden lg:block pt-2">{jobExplorerMap}</div>
    );
  return <div data-cy="job-explorer-save-research-desktop">{mainEl}</div>;
};

export default JobExplorerDesktopElement;
