import * as React from 'react';

import { JobApplicationEntryPoint } from '../';
import { useSkills } from '../use-effect-hooks';
import SkillsBlock from '../../job-skills-block';

export const Skills = ({ ambassadorRefCode }: { ambassadorRefCode?: string }) => {
  const { skillData, isEmptyData } = useSkills();

  return !isEmptyData ? (
    <>
      <SkillsBlock {...skillData} ambassadorRefCode={ambassadorRefCode}>
        <JobApplicationEntryPoint />
      </SkillsBlock>
      <hr tw="text-gray-300" />
    </>
  ) : (
    <></>
  );
};
