import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import tw from 'twin.macro';

import { FormLabelWrapper, HelperTextRElement, OTPInput } from '@restworld/ui-ds';
import { formatTimeLabel } from '@restworld/utils-common';

import { IFormModalWrapper, ModalToggleWrapper } from './form-modal-wrapper';
import {
  phoneNumberSentAtom,
  phoneNumberSentFlagAtom,
  phoneNumberVerificationDateNowAtom,
} from './update-phone-number-modal';
import { useEmployerPhoneNumberUpdateMutation, useOTPVerificationMutation } from './use-phone-number-otp-validation';

const countForResendOTPModal = 1 * 60; // 1 minute
// const countForOTPModal = 60;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOTPValidationModalProps {
  onOTPVerificationSuccessFull?: () => void;
}
export const validateOtpInputVisibleAtom = atom(false);
const OTPValidationModal: React.FunctionComponent<IOTPValidationModalProps> = ({ onOTPVerificationSuccessFull }) => {
  const [validateOtpInputVisible, validateOtpInputVisibleToggler] = useAtom(validateOtpInputVisibleAtom);

  const methods = useForm<{
    otpCode?: string;
  }>({
    defaultValues: {
      otpCode: '',
    },
  });

  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = methods;
  const { t } = useTranslation('common');
  const phoneNumberSent = useAtomValue(phoneNumberSentAtom);

  const { countDownLabel, resetTimer } = useCountDownTimerForOTPModal();

  const handleReset = React.useCallback(() => {
    reset({ otpCode: '' });
    validateOtpInputVisibleToggler(false);
  }, [reset, validateOtpInputVisibleToggler]);

  const {
    mutate: resendOTPMutate,
    isPending: resendOTPPending,
    isSuccess: isResendOTPSuccess,
    reset: resetOTPMutationState,
  } = useEmployerPhoneNumberUpdateMutation();

  const {
    mutate,
    isPending,
    isError,
    error: mutationError,
    isSuccess,
  } = useOTPVerificationMutation({ onOTPVerificationSuccessFull });

  const onSubmit: SubmitHandler<{ otpCode: string }> = React.useCallback(
    ({ otpCode }) => {
      mutate({ otpCode: otpCode.toString() });
    },
    [mutate]
  );

  const onResendCode = React.useCallback(() => {
    resendOTPMutate({ phoneNumber: phoneNumberSent });
    resetTimer();
  }, [phoneNumberSent, resendOTPMutate, resetTimer]);

  const modalProps: IFormModalWrapper = React.useMemo(() => {
    return {
      modalVisible: validateOtpInputVisible,
      onModalClose: handleReset,
      title: `${t('we_sent_sms')} ${phoneNumberSent}`,
      subTitle: t('input_verification_code'),
      submitHandlerProps: {
        onClick: handleSubmit(onSubmit),
        title: t('verify_number'),
        loading: isPending,
      },
      onCancelHandler: onResendCode,
      onCancelHandlerProps: {
        title: `${t('resend_code')} ${countDownLabel || ''}`,
        twStyle: tw`hover:(ring-2 ring-neutral-50)`,
        loading: resendOTPPending,
        disabled: !!countDownLabel,
      },
    };
  }, [
    validateOtpInputVisible,
    handleReset,
    t,
    phoneNumberSent,
    handleSubmit,
    onSubmit,
    isPending,
    onResendCode,
    countDownLabel,
    resendOTPPending,
  ]);

  React.useEffect(() => {
    if (isError) {
      setError('otpCode', {
        message: mutationError?.['data']?.['error'] || t('something_went_wrong'),
      });
    }
  }, [isError, mutationError, setError, t]);

  React.useEffect(() => {
    if (isSuccess) handleReset();
  }, [handleReset, isSuccess]);

  React.useEffect(() => {
    if (isResendOTPSuccess) resetOTPMutationState();
  }, [isResendOTPSuccess, resetOTPMutationState]);

  return (
    <ModalToggleWrapper {...modalProps}>
      <FormLabelWrapper label="" isFullWidth id="otpCode">
        <Controller
          name="otpCode"
          control={control}
          rules={{
            required: t('validate_otp_insert_code_prompt'),
            validate: (value) => value?.toString().length === 6,
          }}
          render={({ field }) => (
            <OTPInput
              variant={errors['otpCode'] ? 'error' : 'primary'}
              isSuccess={false}
              {...field}
              value={field?.value?.toString()}
            />
          )}
        />
        <div tw="pt-4 flex justify-center">
          {errors['otpCode'] && <HelperTextRElement helperText={errors['otpCode'].message} />}
        </div>
      </FormLabelWrapper>
    </ModalToggleWrapper>
  );
};

export default OTPValidationModal;
const useCountDownTimerForOTPModal = () => {
  const [countDown, setCountDown] = React.useState(countForResendOTPModal);
  const [resetCountDown, resetCountDownToggler] = React.useState(false);
  const [validateOtpInputVisible] = useAtom(validateOtpInputVisibleAtom);

  const setPhoneNumberSentFlag = useSetAtom(phoneNumberSentFlagAtom);
  const settPhoneNumberSent = useSetAtom(phoneNumberSentAtom);
  const [, setPhoneNumberVerificationDateNow] = useAtom(phoneNumberVerificationDateNowAtom);

  const resetPhoneNumberStateData = React.useCallback(() => {
    setPhoneNumberSentFlag(false);
    settPhoneNumberSent('');
    setPhoneNumberVerificationDateNow(null);
    setCountDown(countForResendOTPModal);
  }, [setPhoneNumberSentFlag, setPhoneNumberVerificationDateNow, settPhoneNumberSent]);

  React.useEffect(() => {
    resetPhoneNumberStateData();
  }, [resetPhoneNumberStateData]);

  React.useEffect(() => {
    if (!validateOtpInputVisible) return;
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev) return prev - 1;
        else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [validateOtpInputVisible, resetCountDown]);

  return {
    countDown,
    countDownLabel: countDown > 0 ? formatTimeLabel(countDown) : null,
    resetTimer: () => {
      setCountDown(countForResendOTPModal);
      resetCountDownToggler(true);
    },
  };
};
