import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback, useMemo } from 'react';
import * as turf from '@turf/turf';
import { defaultItalyCoordinates } from '../constants';
export var useMapBoundingRadius = function useMapBoundingRadius(_ref) {
  var map = _ref.map;
  var center = useMemo(function () {
    return map !== null && map !== void 0 && map.getCenter() ? [map === null || map === void 0 ? void 0 : map.getCenter().lng, map === null || map === void 0 ? void 0 : map.getCenter().lat] : defaultItalyCoordinates;
  }, [map]);
  var getBoundsRect = useCallback(function () {
    var b = map === null || map === void 0 ? void 0 : map.getBounds();

    if (b) {
      var bArray = Object.keys(b).reduce(function (prev, curr) {
        var v = Object.values(b[curr]);
        if (prev.length <= 1) prev = [].concat(_toConsumableArray(prev), _toConsumableArray(v));
        return prev.reverse();
      }, []);
      return bArray;
    }

    return [];
  }, [map]);
  var getRadius = useCallback(function (_ref2) {
    var lon = _ref2.lon,
        lat = _ref2.lat,
        _ref2$isDoubleDistanc = _ref2.isDoubleDistance,
        isDoubleDistance = _ref2$isDoubleDistanc === void 0 ? true : _ref2$isDoubleDistanc;
    if (!getBoundsRect().length) return;
    var from = turf.point([lon, lat]);
    var to = turf.point(getBoundsRect());
    var options = {
      units: 'meters'
    };
    var distance = turf.distance(from, to, options);
    if (isDoubleDistance) return distance + distance;
    return distance;
  }, [getBoundsRect]);
  return {
    getRadius: getRadius,
    center: center
  };
};