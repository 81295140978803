import * as React from 'react';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { useResponsiveBreakPoints, useSmartlook } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';

import { LayoutWrapper } from '../../layouts';
import { JobExplorerContent } from './job-explorer-content';
import { useExplorerInitialApiCalls } from './job-explorer.hooks';
import { useExplorerFilterUrlParams } from './job-explorer.hooks';
import { LeadUserOnboardingPrompt } from './lead-user-onboarding-prompt';
import { useLoadWorkerData } from '../../hooks';
import { hasMapViewAtom } from './job-explorer-list.atoms';

const RestaurantInfoModal = dynamic(() => import('./job-explorer-map/explorer-type-toggler'), {
  ssr: false,
});

export const JobExplorer = () => {
  const { user, isAuthenticated } = useAuth();
  const { initialize } = useSmartlook();
  const { isDesktop } = useResponsiveBreakPoints();
  const hasMapView = useAtomValue(hasMapViewAtom);
  const { fetchWorker } = useLoadWorkerData();
  const { t } = useTranslation('job-explorer-page');

  useExplorerFilterUrlParams();
  useExplorerInitialApiCalls();
  useEffect(() => {
    fetchWorker();
  }, [fetchWorker]);
  useEffect(() => {
    initialize();
  }, [initialize]);
  return (
    <LayoutWrapper
      title={t('search_for_jobs')}
      navbarVariant="worker"
      hasFooter={isDesktop}
      hasNavbar
      showSearchBarInNavbar={isAuthenticated ? user?.type !== 'employer' : true}
      noHorizontalPadding
      noPaddingBottom
      noPaddingTop
      seoProps={{
        title: 'Offerte di lavoro',
        description: 'Offerte di lavoro verificate, di qualità e con stipendi trasparenti',
      }}
    >
      <FilterAndListWrapper data-cy="job-explorer-page" hasMapView={hasMapView}>
        <JobExplorerContent />
      </FilterAndListWrapper>
      <RestaurantInfoModal />
      <LeadUserOnboardingPrompt />
    </LayoutWrapper>
  );
};

const FilterAndListWrapper = styled.div<{ hasMapView?: boolean }>`
  ${tw`pb-[8rem] xl:px-14 lg:px-12  pt-[94px] md:pt-[104px] lg:pt-[96px] lg:pb-0`};
  ${({ hasMapView }) => hasMapView && tw`pb-0 overflow-hidden lg:overflow-auto`};
`;
