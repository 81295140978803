import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import axios from 'axios'; // import * as Sentry from '@sentry/browser';

import ApiCache from './apiCache';

var formatError = function formatError(error) {
  var _error$response, _error$response2;

  return {
    code: (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status,
    message: error.message,
    data: (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.data
  };
};

var apiCache = new ApiCache();
var axiosInstances = {};
export var getAxiosInstance = function getAxiosInstance(base_url, token) {
  var axiosInstance = axios;
  axiosInstance.defaults.baseURL = base_url;
  axiosInstance.interceptors.request.use(function (config) {
    apiCache.handleRequest(config);
    if (token && config.headers) config.headers.set('Authorization', "Bearer ".concat(token));
    return config;
  });
  axiosInstance.interceptors.response.use(function (response) {
    return apiCache.handleResponse(response);
  }, function (error) {
    // Sentry.captureException(error);
    return Promise.reject(error.response ? formatError(_objectSpread({}, error)) : error);
  });
  return axiosInstance;
};
export var dynamicAxios = function dynamicAxios(base_url, token) {
  var key = "".concat(base_url, "_").concat(token || '');
  var existingAxiosInstance = axiosInstances[key];

  if (!existingAxiosInstance) {
    var newAxiosInstance = getAxiosInstance(base_url, token);
    axiosInstances[key] = newAxiosInstance;
    return newAxiosInstance;
  }

  return existingAxiosInstance;
};
export default dynamicAxios;