var _process$env$NEXT_PUB;

globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback, useEffect, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '@restworld/feature-authentication';
import { isDev } from './use-is-dev-server';
var isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
var token = (_process$env$NEXT_PUB = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '';
export default function useMixpanel() {
  var _useAuth = useAuth(),
      user = _useAuth.user,
      isAuthenticated = _useAuth.isAuthenticated;

  var isActive = useMemo(function () {
    return true && isProd && !!mixpanel;
  }, []);

  if (isActive) {
    mixpanel.init(token, {
      debug: isDev,
      api_host: 'https://mixpanel.restworld.it',
      track_pageview: 'url-with-path'
    });
  }

  useEffect(function () {
    if (!isActive || !mixpanel) return;
    mixpanel.set_config({
      persistence: 'localStorage'
    });
  }, [isActive]);
  useEffect(function () {
    if (!isAuthenticated || !user || !isActive) return;
    mixpanel.identify(user.id);
    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
      $first_name: user.name,
      $last_name: user.surname,
      $created: user.inserted_at,
      $avatar: user.picture,
      $type: user.type,
      $phone: user.phone_number
    });
  }, [isAuthenticated, user, isActive]);
  var trackEvent = useCallback(function (eventName, eventProperties) {
    if (!isActive) return;
    mixpanel.track(eventName, eventProperties);
  }, [isActive]);
  return {
    isActive: isActive,
    trackEvent: trackEvent
  };
}