import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { jobActiveStatusAtom } from '../job-position.atoms';

export const useSetActiveStatus = ({ jobActiveStatus }: { jobActiveStatus: boolean }) => {
  const setJobActiveStatus = useSetAtom(jobActiveStatusAtom);

  useEffect(() => {
    setJobActiveStatus(jobActiveStatus);
  }, [jobActiveStatus, setJobActiveStatus]);
};
