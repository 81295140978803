import {
  Button,
  Checkbox,
  EmailInput,
  LinkBase,
  PhoneInput,
  isValidEmail,
  isValidPhoneNumber,
  Paragraph,
} from '@restworld/ui-ds';
import { memo } from 'react';
import tw, { styled } from 'twin.macro';
import { FacebookButton } from '../common/facebook-button';
import { GoogleButton } from '../common/google-button';
import { Separator } from '../common/separator';
import { InformationIcon, config } from '@restworld/utils-common';
import { useSignupForm } from './hooks/use-signup-form';
import { AuthTitle } from '../common/auth-title';
import { AuthSubtitle } from '../common/auth-subtitle';
import { Controller } from 'react-hook-form';
import { useSignupUtils } from './hooks/use-signup-utils';
import { HeaderWrapper } from '../common/head-wrapper';
import { useTranslation } from 'next-i18next';
import AlreadyInvitedCollaboratorModal from './already-invited-collaborator-modal';

export interface SignupFormProps {
  isModal?: boolean;
  activeUrl?: string;
}

export const SignupForm = memo(({ isModal = true, activeUrl }: SignupFormProps) => {
  const { nonExistingUserAccount, navigateToLogin, onClose } = useSignupUtils({ isModal });
  const { loading, onSubmit, handleSubmit, control, errors } = useSignupForm({
    currentUrl: activeUrl,
    isModal: isModal,
    email: nonExistingUserAccount.email,
  });
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('auth-flow');

  return (
    <FormWrapper {...{ isModal }}>
      <StyledFormContainer {...{ isModal }}>
        <form tw="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div tw="flex flex-col gap-4 w-full">
            <Controller
              name="email"
              control={control}
              rules={{ required: t('signup_form_insert_valid_email'), validate: isValidEmail }}
              render={({ field }) => (
                <EmailInput
                  label={
                    <span>
                      {commonT('email')}
                      <span tw="text-red-600">*</span>
                    </span>
                  }
                  onChange={field.onChange}
                  variant={errors['email'] ? 'error' : 'primary'}
                  supportText={errors['email']?.message}
                  value={field.value}
                  inputStyles={[tw`min-h-[44px]`]}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: t('signup_form_insert_valid_phone_number'), validate: isValidPhoneNumber }}
              render={({ field }) => (
                <PhoneInput
                  label={
                    <span>
                      {commonT('phone_number')}
                      <span tw="text-red-600">*</span>
                    </span>
                  }
                  onChange={field.onChange}
                  priorityCountries={['IT']}
                  menuDialogStyles={[tw`min-w-full`]}
                  variant={errors['phoneNumber'] ? 'error' : 'primary'}
                  supportText={errors['phoneNumber']?.message}
                />
              )}
            />

            <div tw="flex flex-row items-start justify-start w-full gap-2">
              <InformationIcon />
              <p tw="text-gray-500 text-base">{t('signup_form_description_text')}</p>
            </div>
          </div>
          <div tw="flex flex-col items-start gap-4 mt-8">
            <Controller
              name="acceptedTerms"
              control={control}
              rules={{
                required: t('signup_form_accept_terms_text'),
                validate: (value) => value === true,
              }}
              render={({ field }) => {
                const isError = errors['acceptedTerms']?.message ? true : false;
                return (
                  <Checkbox
                    {...{
                      variant: 'box',
                      isChecked: field.value,
                      onChange: (event) => {
                        const checked = event.target.checked;
                        field.onChange(checked);
                      },
                      onClick: (_) => {
                        field.onChange(!field.value);
                      },
                      iserror: isError,
                      title: (
                        <Paragraph
                          twStyle={[
                            tw`text-gray-500 font-light leading-6 text-sm w-full inline-block`,
                            isError ? tw`text-red-500` : tw``,
                          ]}
                        >
                          {t('signup_form_view_terms_and_condition_text_1')}
                          <LinkBase link={config.workerTermsUrl}>
                            <StyledLink isError={isError}>
                              {t('signup_form_view_terms_and_condition_text_2')}
                            </StyledLink>
                          </LinkBase>
                          {t('signup_form_view_terms_and_condition_text_3')}
                          <LinkBase link="/privacy-policy">
                            <StyledLink isError={isError}>
                              {t('signup_form_view_terms_and_condition_text_4')}
                            </StyledLink>
                          </LinkBase>
                        </Paragraph>
                      ),
                    }}
                  />
                );
              }}
            />
            <Button fullwidth={true} type="submit" loading={loading}>
              {t('signup_form_signup_button_text')}
            </Button>
            <Separator />
            <div tw="flex flex-col w-full gap-2">
              <GoogleButton {...{ isModal: true, closeModal: onClose }} />
              <FacebookButton {...{ isModal: true, closeModal: onClose }} />
            </div>
          </div>
        </form>
      </StyledFormContainer>
      <div tw="flex flex-row justify-center mt-6 gap-1.75 text-sm leading-5.25 font-medium">
        <p>{t('signup_form_login_redirect_text')}</p>
        <StyledLoginLink onClick={navigateToLogin}>{commonT('login')}</StyledLoginLink>
      </div>
      <AlreadyInvitedCollaboratorModal />
    </FormWrapper>
  );
});

const FormWrapper = styled.div<{ isModal?: boolean }>(({ isModal = true }) => [
  tw`flex flex-col w-full`,
  isModal && tw`mt-5`,
]);
const StyledFormContainer = styled.div(({ isModal = true }: { isModal?: boolean }) => [
  tw`flex flex-col flex-1 w-full`,
  isModal && tw`box-border bg-primary-50 rounded-3.75 p-4 mt-4`,
]);

export const SignupHeader = ({ isModal = true }: { isModal?: boolean }) => {
  const { t } = useTranslation('auth-flow');
  const { nonExistingUserAccount } = useSignupUtils({ isModal });
  return (
    <HeaderWrapper tw="gap-2 mt-2" isModal={isModal}>
      <AuthTitle isModal={isModal} data-cy="sign-up-form-email-not-found-heading">
        {' '}
        {nonExistingUserAccount.email ? (
          t('signup_form_header_email_does_not_exist')
        ) : isModal ? (
          t('signup_form_header_modal_variant')
        ) : (
          <span>
            {t('signup_form_header_page_variant_text_1')}{' '}
            <span tw="text-primary-600">{t('signup_form_header_page_variant_text_2')}</span>
          </span>
        )}
      </AuthTitle>
      <AuthSubtitle isModal={isModal}>
        {isModal ? t('signup_form_subheader_modal_variant') : t('signup_form_subheader_page_variant')}
      </AuthSubtitle>
    </HeaderWrapper>
  );
};

export const StyledCheckbox = styled.input(() => [
  tw`w-4 h-4 text-primary-600 bg-gray-50 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`,
]);

export const StyledLink = styled.span<{ isError?: boolean }>`
  ${({ isError }) => (isError ? tw`text-red-500` : tw`text-gray-500 underline underline-offset-4`)}
`;

const StyledLoginLink = styled.p(() => [tw`text-primary-900 cursor-pointer`]);
