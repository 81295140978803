import { Button, PhoneInput, isValidPhoneNumber } from '@restworld/ui-ds';
import { memo, useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import { Controller } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';

import { useAnalytics } from '@restworld/utils-common';

import { PhoneNumberLoginInput, usePhoneNumberLoginForm } from '../Login/hooks/use-phone-number-login-form';
import { AuthTitle } from '../common/auth-title';
import { AuthSubtitle } from '../common/auth-subtitle';
import { HeaderWrapper } from '../common/head-wrapper';
import { signupPhoneNumberFailed } from '../../../worker-app/context/auth-flow';

export type LoginByyPhoneModalProps = {
  isModal?: boolean;
};

export const LoginByPhoneForm = memo(({ isModal = true }: LoginByyPhoneModalProps) => {
  const { show: showIntercom } = useIntercom();
  const { loading, onSubmit, handleSubmit, control, errors } = usePhoneNumberLoginForm({ isModal });
  const { t } = useTranslation('auth-flow');
  const { t: commonT } = useTranslation('common');
  const { trackEvent } = useAnalytics();

  const onSubmitForm = useCallback(
    (data: PhoneNumberLoginInput) => {
      onSubmit(data);
      trackEvent('worker_request_otp_for_login', { phone_number: data.phoneNumber });
    },
    [onSubmit, trackEvent]
  );
  return (
    <FormWrapper {...{ isModal }}>
      <form tw="w-full" onSubmit={handleSubmit(onSubmitForm)}>
        <div tw="flex flex-col gap-4 w-full my-10">
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true, validate: isValidPhoneNumber }}
            render={({ field }) => (
              <PhoneInput
                label={
                  <span>
                    {commonT('phone_number')}
                    <span tw="text-red-600">*</span>
                  </span>
                }
                onChange={field.onChange}
                priorityCountries={['IT']}
                menuDialogStyles={[tw`min-w-full`]}
                variant={errors['phoneNumber'] ? 'error' : 'primary'}
                supportText={errors['phoneNumber']?.message}
                value={field.value}
                testIds={{
                  input: 'login-phone-input',
                  dropdownBtn: 'login-phone-dropdown',
                  dropdownOptions: 'login-phone-dropdown-options',
                  supportText: 'login-phone-support-text',
                }}
              />
            )}
          />
        </div>
        <div tw="flex flex-col items-center w-full gap-4">
          <Button fullwidth={true} type="submit" loading={loading} testId="login-phone-submit-button">
            <span className="text-black">{t('phone_number_login_enter_phone_number_send_sms')}</span>
          </Button>
          <Button
            fullwidth={true}
            variant="secondary-outline"
            icon="HeadphoneIcon"
            onClick={(e) => {
              e.preventDefault();
              showIntercom();
            }}
            testId="login-phone-support-button"
          >
            <span className="text-black">{t('phone_number_login_enter_phone_number_assistance_text')}</span>
          </Button>
        </div>
      </form>
    </FormWrapper>
  );
});

const FormWrapper = styled.div<{ isModal?: boolean }>(({ isModal = true }) => [
  tw`flex flex-col w-full`,
  isModal && tw`mt-5`,
]);

export const LoginByPhoneHeader = ({ isModal = true }: { isModal?: boolean }) => {
  const { t } = useTranslation('auth-flow');
  const signupFailed = useAtomValue(signupPhoneNumberFailed);
  return (
    <HeaderWrapper tw="gap-2" isModal={isModal}>
      <AuthTitle isModal={isModal} data-cy="login-by-phone-form-heading">
        {!signupFailed
          ? t('phone_number_login_enter_phone_number_header')
          : t('phone_number_login_enter_phone_number_header_signup_failed')}
      </AuthTitle>
      <AuthSubtitle isModal={isModal}>
        {!signupFailed
          ? t('phone_number_login_enter_phone_number_subheader_1')
          : t('phone_number_login_enter_phone_number_subheader_1_signup_failed')}
      </AuthSubtitle>
      <AuthSubtitle isModal={isModal}>
        {!signupFailed
          ? t('phone_number_login_enter_phone_number_subheader_2')
          : t('phone_number_login_enter_phone_number_subheader_2_signup_failed')}
      </AuthSubtitle>
    </HeaderWrapper>
  );
};
