import tw, { styled } from 'twin.macro';
import { useAtomValue } from 'jotai';

import { CompanyProfileDrawer } from '../../job-company-profile';
import { jobPositionApiDataAtom } from '../job-position.atoms';

export const CompanyProfileButton = () => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);

  return jobPositionApiData?.show_restaurant_details ? (
    <Wrapper>
      <CompanyProfileDrawer buttonIcon="BuildingOfficePurpleIcon" />
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  ${tw`block lg:hidden`}
`;
