import { useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

// import { useAnalytics } from '@restworld/utils-common';
import { useWorkersService } from '@restworld/data-services';

import { useJobPositionId } from '../../../hooks';
// import { jobPositionApiDataAtom } from '../../job-position/job-position.atoms';
import {
  doesSizeExceedsAtom,
  isCVUploadProcessCompletedAtom,
  spontaneousApplyDataAtom,
  uploadCVLoadingAtom,
  uploadFilesAtom,
  workerEntryPointErrorsAtom,
  workerIdAtom,
} from '../worker-entry-point.atoms';

export const useEntryPoint = () => {
  const jobPositionId = useJobPositionId();
  // const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const setSizeExceeds = useSetAtom(doesSizeExceedsAtom);
  const uploadCVLoadingToggler = useSetAtom(uploadCVLoadingAtom);
  const setCVUploadedProcessComplete = useSetAtom(isCVUploadProcessCompletedAtom);
  const setWorkerId = useSetAtom(workerIdAtom);
  const setSpontaneousApplyData = useSetAtom(spontaneousApplyDataAtom);
  const uploadFiles = useAtomValue(uploadFilesAtom);
  const setErrors = useSetAtom(workerEntryPointErrorsAtom);

  const workerService = useWorkersService();
  // const { fbTrackWorkerStartsApplication } = useAnalytics();

  const onUploadFileChange = useCallback(async () => {
    if (uploadFiles?.[0]) {
      if (uploadFiles[0].size / 1000000 > 20) {
        setSizeExceeds(true);
        return;
      }
      // fbTrackWorkerStartsApplication();
      try {
        uploadCVLoadingToggler(true);
        const response = await workerService.uploadCV({
          cv: uploadFiles[0],
          workerId: '',
          jobPositionId: jobPositionId,
        });
        setWorkerId(response?.data?.user_id);
        setCVUploadedProcessComplete(true);
        setTimeout(() => {
          setSpontaneousApplyData((p) => ({
            ...p,
            showCoverLetter: true,
          }));
        }, 2000);
      } catch (err: any) {
        if (err?.['message']?.includes('500')) setErrors({ ...err?.data, statusCode: 500 });
        if (err?.['data']) setErrors({ ...err?.data, statusCode: err.code });
        setCVUploadedProcessComplete(false);
        uploadCVLoadingToggler(false);
        if (err?.code === 413) setSizeExceeds(true);
        return err;
      }
    }
  }, [
    uploadFiles,
    // trackEvent,
    // fbTrackWorkerStartsApplication,
    // jobPositionApiData?.semantic_unique_name,
    setSizeExceeds,
    uploadCVLoadingToggler,
    workerService,
    jobPositionId,
    setWorkerId,
    setCVUploadedProcessComplete,
    setSpontaneousApplyData,
    setErrors,
  ]);
  return {
    onUploadFileChange,
  };
};
