import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useNavigationContext } from '../../';
import { useCallback, useEffect, useMemo } from 'react';
import { useWizard as useBaseWizard } from 'react-use-wizard';
export default function useWizardLogic(_ref) {
  var urlControl = _ref.urlControl,
      stepParamName = _ref.stepParamName;
  var wizardValues = useBaseWizard();
  var activeStep = wizardValues.activeStep,
      baseGoToStep = wizardValues.goToStep,
      baseNextStep = wizardValues.nextStep,
      basePreviousStep = wizardValues.previousStep;

  var _useNavigationContext = useNavigationContext(),
      router = _useNavigationContext.router;

  var cleanQuery = useMemo(function () {
    return (router === null || router === void 0 ? void 0 : router.query) && Object.keys(router === null || router === void 0 ? void 0 : router.query).filter(function (k) {
      return k !== 'id';
    }).reduce(function (acc, k) {
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, k, router === null || router === void 0 ? void 0 : router.query[k]));
    }, {});
  }, [router === null || router === void 0 ? void 0 : router.query]);
  var nextStep = useCallback(function () {
    if (!urlControl) return wizardValues.nextStep();
    router === null || router === void 0 ? void 0 : router.push({
      pathname: window.location.pathname,
      query: cleanQuery ? _objectSpread(_objectSpread({}, cleanQuery), {}, _defineProperty({}, stepParamName, (activeStep + 1).toString())) : _defineProperty({}, stepParamName, (activeStep + 1).toString())
    }); // empty promise

    return new Promise(function (resolve) {
      return resolve();
    });
  }, [wizardValues, activeStep, cleanQuery, urlControl, stepParamName, router]);
  var previousStep = useCallback(function () {
    if (!urlControl) return wizardValues.previousStep();
    router.push({
      pathname: window.location.pathname,
      query: cleanQuery ? _objectSpread(_objectSpread({}, cleanQuery), {}, _defineProperty({}, stepParamName, (activeStep - 1).toString())) : _defineProperty({}, stepParamName, (activeStep - 1).toString())
    });
  }, [wizardValues, activeStep, cleanQuery, urlControl, stepParamName, router]);
  var goToStep = useCallback(function (index) {
    if (!urlControl) return wizardValues.goToStep(index);
    router.push({
      pathname: window.location.pathname,
      query: cleanQuery ? _objectSpread(_objectSpread({}, cleanQuery), {}, _defineProperty({}, stepParamName, index.toString())) : _defineProperty({}, stepParamName, index.toString())
    });
  }, [wizardValues, cleanQuery, urlControl, stepParamName, router]);
  var value = useMemo(function () {
    if (!urlControl) return wizardValues;
    return _objectSpread(_objectSpread({}, wizardValues), {}, {
      nextStep: nextStep,
      previousStep: previousStep,
      goToStep: goToStep
    });
  }, [wizardValues, nextStep, previousStep, goToStep, urlControl]); // Side effects to connect internal state with url params

  useEffect(function () {
    // Init side effect
    if (!urlControl) return;
    if (!(router !== null && router !== void 0 && router.isReady)) return;
    if ((router === null || router === void 0 ? void 0 : router.query[stepParamName]) === activeStep.toString()) return;

    if (!(router !== null && router !== void 0 && router.query[stepParamName]) || activeStep === 0) {
      // Step 0
      router === null || router === void 0 ? void 0 : router.push({
        pathname: window.location.pathname,
        query: _objectSpread(_objectSpread({}, cleanQuery), {}, _defineProperty({}, stepParamName, '0'))
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [activeStep, router === null || router === void 0 ? void 0 : router.isReady, urlControl]); // This side effect is actually modifying the internal state of the Wizard

  useEffect(function () {
    if (!urlControl) return;
    if (!(router !== null && router !== void 0 && router.isReady)) return;
    if (!(router !== null && router !== void 0 && router.query[stepParamName])) return;
    var step = Number(router === null || router === void 0 ? void 0 : router.query[stepParamName]);
    if (step === activeStep) return;
    if (step === activeStep + 1) baseNextStep();else if (step === activeStep - 1) basePreviousStep();else baseGoToStep(step);
  }, [router === null || router === void 0 ? void 0 : router.isReady, router === null || router === void 0 ? void 0 : router.query, urlControl, stepParamName, baseGoToStep, baseNextStep, basePreviousStep, activeStep]);
  return {
    value: value
  };
}