globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var defaultItalyCoordinates = [12.5674, 41.8719]; // italy coordinates [lon, lat]

export var defaultItalyAddressMetaData = {
  address: 'Whole Italia',
  address_lon: defaultItalyCoordinates[0],
  address_lat: defaultItalyCoordinates[1],
  administrative_area_level_1: 'Italia',
  administrative_area_level_2: 'Italia',
  country: 'Italia',
  locality: 'Italia',
  postal_code: ''
};