globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useState, useEffect, useCallback, useMemo } from 'react';
export var useResponsiveBreakPoints = function useResponsiveBreakPoints() {
  var win = true && window;
  var mounted = useSkipInitialRender();

  var _useState = useState(win ? win.innerWidth : 0),
      width = _useState[0],
      setWidth = _useState[1];

  var handleWindowSizeChange = useCallback(function () {
    return win ? setWidth(win === null || win === void 0 ? void 0 : win.innerWidth) : null;
  }, [win]);
  useEffect(function () {
    if (!win) return;
    win === null || win === void 0 ? void 0 : win.addEventListener('resize', handleWindowSizeChange);
    return function () {
      win === null || win === void 0 ? void 0 : win.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange, win]);
  return {
    isDesktop: mounted ? win ? width >= 1024 : false : false,
    isLargeDesktop: mounted ? win ? width >= 1280 : false : false,
    isTablet: mounted ? win ? width >= 768 && width < 1024 : false : false,
    isMobile: mounted ? win ? width < 768 : false : false,
    isSmallMobile: mounted ? win ? width < 640 : false : false,
    isExtraSmallMobile: mounted ? win ? width <= 412 : false : false
  };
};

var useSkipInitialRender = function useSkipInitialRender() {
  var _useState2 = useState(false),
      isSkipped = _useState2[0],
      setIsSkipped = _useState2[1];

  useEffect(function () {
    setIsSkipped(true);
    return function () {
      setIsSkipped(false);
    };
  }, []);
  return useMemo(function () {
    return isSkipped;
  }, [isSkipped]);
};