globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback, useState } from 'react';
export var useDatePicker = function useDatePicker(_ref) {
  var initDate = _ref.initDate,
      selectRange = _ref.selectRange;

  var _useState = useState(initDate),
      value = _useState[0],
      setValue = _useState[1];

  var onChange = useCallback(function (date) {
    setValue(date);
  }, []);
  return {
    value: value,
    onChange: onChange,
    selectRange: selectRange
  };
};