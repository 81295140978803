import { ReactNode, memo } from 'react';
import * as React from 'react';
import { Transition } from '@headlessui/react';
import 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { Button, GoogleRestworldRatingUI, Paragraph, Title } from '@restworld/ui-ds';
import { useAnalytics } from '@restworld/utils-common';
import { useGlobalsService } from '@restworld/data-services';

import { EntryPointChecklistProvider, useEntryPointCheckListContext } from './entry-component.context';
import { ApplicationWizardUI } from './application-wizard-ui';
import { UploadCVUI } from './upload-cv-ui';
import { restworldGoogleRatingDataAtom } from '../../../job-position/job-position.atoms';
import { activeTabAtom, uploadFilesAtom } from '../../worker-entry-point.atoms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEntryComponentProps {}
export const EntryComponentNew: React.FunctionComponent<IEntryComponentProps> = (props) => (
  <EntryPointChecklistProvider>
    <MainContent />
  </EntryPointChecklistProvider>
);
export default EntryComponentNew;
const MainContent = memo(() => {
  const activeTab = useAtomValue(activeTabAtom);
  if (activeTab === 'application-wizard') return <ApplicationWizardUI />;
  if (activeTab === 'upload-cv') return <UploadCVUI />;
  return <EntryPointUI />;
});
const EntryPointUI = memo(() => {
  const { t } = useTranslation('onboarding-page');
  const reviews = useReviews();
  return (
    <div tw="flex flex-col gap-9 pt-5">
      <div tw="flex flex-col gap-6">
        <div tw="flex flex-col gap-4">
          <Title
            fontWeight="semibold"
            fontSize="superSuperLarge"
            textColor="black"
            content={t('how_do_you_want_to_apply')}
            testId="entry-point-title"
          />
          <Paragraph content={t('restworlds_commitment_is_to_help_thousands_of_people')} />
        </div>
        <ConfidentProjectionTextList />
      </div>
      <GoogleRestworldRatingUI {...reviews} />
    </div>
  );
});
const useReviews = () => {
  const [restworldGoogleRatingData, setRestworldGoogleRatingData] = useAtom(restworldGoogleRatingDataAtom);

  const [rating, setRating] = React.useState<number>(5);
  const [userRatingsTotal, setUserRatingsTotal] = React.useState<number>();

  const globalsService = useGlobalsService();

  const restworldRatingApi = React.useCallback(() => {
    globalsService
      .fetchRestworldRating()
      .then((res) => {
        setRestworldGoogleRatingData(res.data);
        setRating(res.data.rating);
        setUserRatingsTotal(res.data.user_ratings_total);
      })
      .catch((err) => console.error(err, 'error on getting reviews'));
  }, [globalsService, setRestworldGoogleRatingData]);

  const getRestworldRating = React.useCallback(() => {
    if (restworldGoogleRatingData && Object.keys(restworldGoogleRatingData)?.length) {
      const { rating, user_ratings_total } = restworldGoogleRatingData;
      setRating(rating);
      setUserRatingsTotal(user_ratings_total);
    } else restworldRatingApi();
  }, [restworldGoogleRatingData, restworldRatingApi]);

  React.useEffect(() => getRestworldRating(), [getRestworldRating]);

  return { userRatingsTotal, rating };
};

const ConfidentProjectionTextList = memo(() => {
  const { t } = useTranslation('onboarding-page');
  return (
    <ul tw="flex flex-col gap-4">
      {[
        {
          icon: 'ChatBubbleLeftRightIcon' as const,
          label: t('support_before_and_during_the_application'),
        },
        { icon: 'LockClosedIcon' as const, label: t('personal_data_safe') },
        {
          icon: 'HouseCheckIcon' as const,
          label: t('only_venues_verified_by_our_managers'),
        },
        {
          icon: 'ShieldCheckIcon' as const,
          label: t('no_commercial_communication_with_your_data'),
        },
      ].map((v) => (
        <li key={v.label}>
          <Title key={v.label} content={v.label} icon={v.icon} fontWeight="medium" fontSize="base" />
        </li>
      ))}
    </ul>
  );
});
export const EntryProcessButtons = memo(() => {
  const setActiveTab = useSetAtom(activeTabAtom);
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation('onboarding-page');
  return (
    <div tw="flex flex-col gap-2 items-center w-full">
      <Button
        fullwidth
        variant="normal"
        onClick={() => {
          setActiveTab('upload-cv');
          trackEvent('initiate_upload_cv', {});
        }}
        testId="upload-cv-button"
      >
        {t('upload_a_cv')} <span tw="font-light">(45 sec)</span>
      </Button>
      <Button
        fullwidth
        fontColor="black"
        variant="secondary-outline"
        onClick={() => {
          setActiveTab('application-wizard');
        }}
        testId="application-wizard-button"
      >
        {t('answer_the_questionnaire')} <span tw="font-light">(5 min)</span>
      </Button>
    </div>
  );
});
export const TitleWithBackBtn = memo(({ title, testId }: { title: string; testId?: string }) => {
  const { setIsCVNotUploaded, setBtnTriggeredWithoutCheck } = useEntryPointCheckListContext();
  const setActiveTab = useSetAtom(activeTabAtom);
  const setUploadFiles = useSetAtom(uploadFilesAtom);

  const onClick = React.useCallback(() => {
    setActiveTab('');
    setIsCVNotUploaded(false);
    setBtnTriggeredWithoutCheck(false);
    setUploadFiles(null);
  }, [setActiveTab, setBtnTriggeredWithoutCheck, setIsCVNotUploaded, setUploadFiles]);

  return (
    <>
      <div tw="flex items-start">
        <Button testId="onboarding-modal-title" variant="borderless" icon="ChevronLeftIcon" onClick={onClick} />
      </div>
      <Title isCenter fontSize="superLarge" content={title} fontWeight="semibold" testId={testId} />
    </>
  );
});
export const FadeInOutTransaction = ({ children, show }: { children: ReactNode; show: boolean }) => (
  <Transition
    show={show}
    enter="transition-opacity duration-175"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-250"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {(ref) => <div ref={ref}>{children}</div>}
  </Transition>
);
export * from './entry-component.context';
