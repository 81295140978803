import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useOffset } from '..';
import { jobLocationCoordinatesAtom } from '../../job-explorer-map.atoms';

export const useClustersJSON = () => {
  const jobLocationCoordinates = useAtomValue(jobLocationCoordinatesAtom);
  const addOffset = useOffset();
  const clusters = useMemo(() => {
    const c = jobLocationCoordinates?.reduce(
      (prev, curr, i) => {
        const coordinates = Object.values(curr.coordinates)
          .reverse()
          .map((v) => (typeof v === 'number' ? v + addOffset(i) : v));
        prev = {
          ...prev,
          features: [
            ...prev.features,
            ...[
              {
                type: 'Feature',
                properties: { id: curr.id, label: curr.label },
                geometry: {
                  type: 'Point',
                  coordinates,
                },
              },
            ],
          ],
        };
        return prev;
      },
      {
        type: 'FeatureCollection',
        features: [],
      }
    );
    return c;
  }, [addOffset, jobLocationCoordinates]);
  return clusters;
};
