import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useAtomValue } from 'jotai';

import { useJobPositionsService } from '@restworld/data-services';
import { QUERY_KEYS } from '@restworld/utils-common';

import { useJobPositionId } from './use-job-position-id';
import { jobActiveStatusAtom } from '../components/job-position/job-position.atoms';

export const useJobExpireInfo = () => {
  const jobPositionId = useJobPositionId();
  const jobPositionService = useJobPositionsService();
  const jobActiveStatus = useAtomValue(jobActiveStatusAtom);

  const { data: isJpInActive, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.JOB_POSITIONS.STATUS, jobPositionId],
    queryFn: () => jobPositionService.showJobPositionStatus(jobPositionId),
    select: (data) => data.data,
    enabled: !!jobPositionId,
    initialData: { data: jobActiveStatus } as AxiosResponse,
  });

  return { isJpInActive, isLoading };
};
