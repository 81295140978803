import tw from 'twin.macro';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';

import { getAssetSrc, getDateDifference, getMonthDifference } from '@restworld/utils-common';
import { Card, Title } from '@restworld/ui-ds';

import { companyProfileAtom } from '../../job-position/job-position.atoms';

const monthLimit = 6;
export const CompanyInvolvementCard = () => {
  const { t } = useTranslation();
  const data = useAtomValue(companyProfileAtom);
  const { insertedAt } = data;

  const involvementTitle = useMemo(
    () => insertedAt && `${t('common:collaborate_with_us')} ${getDateDifference(insertedAt)}`,
    [insertedAt, t]
  );

  const isInvolvementMonthsOld = useMemo(() => insertedAt && getMonthDifference(insertedAt) > monthLimit, [insertedAt]);

  return (
    <CardWrapper>
      {isInvolvementMonthsOld ? (
        <Card>
          <Wrapper>
            <Title content={involvementTitle || '-'} fontSize="extraLarge" textColor="white" fontWeight="semibold" />
            <Image src={getAssetSrc('/utils-assets/party-confetti.svg')} alt="" />
          </Wrapper>
        </Card>
      ) : null}
    </CardWrapper>
  );
};
export default CompanyInvolvementCard;
const CardWrapper = tw.div`px-2 w-full `;
const Image = tw.img`h-11 mt-4`;
const Wrapper = tw.div`flex items-center`;
