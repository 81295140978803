import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var formatExplorerOverallFilterData = function formatExplorerOverallFilterData(_ref) {
  var values = _ref.values;
  return Object.keys(values).reduce(function (prev, curr) {
    var modifiedData = {};

    if (values[curr]) {
      var _values$contract_type, _values$domain_intere2, _values$seasonal_job_, _values$job_type, _values$curr, _values$user_location;

      var occupationData = values['occupation_ids'] && Object.keys(values['occupation_ids']).length && Object.keys(values['occupation_ids']).reduce(function (acc, id) {
        var _values$domain_intere;

        if ((_values$domain_intere = values['domain_interest']) !== null && _values$domain_intere !== void 0 && _values$domain_intere.includes(id)) acc[id] = values['occupation_ids'][id];
        return acc;
      }, {});
      var occupationIds = curr === 'occupation_ids' && occupationData && Object.keys(occupationData).length && Object.values(occupationData).flat().filter(Boolean).length ? {
        occupation_ids: Object.values(occupationData).flat().filter(Boolean)
      } : {
        occupation_ids: undefined
      };

      var common = _defineProperty({}, curr, singleMode.includes(curr) ? values[curr][0] : values[curr]);

      var formattedData = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, common), (_values$contract_type = values['contract_type']) !== null && _values$contract_type !== void 0 && _values$contract_type.length ? {
        contract_type: values['contract_type']
      } : {
        contract_type: undefined
      }), (_values$domain_intere2 = values['domain_interest']) !== null && _values$domain_intere2 !== void 0 && _values$domain_intere2.length ? {
        domain_interest: values['domain_interest']
      } : {
        domain_interest: undefined
      }), curr === 'seasonal_job_position' && ((_values$seasonal_job_ = values['seasonal_job_position']) === null || _values$seasonal_job_ === void 0 ? void 0 : _values$seasonal_job_.length) && (values['seasonal_job_position'].includes('is_abroad_job') ? {
        is_abroad_job: true,
        seasonal_job_position: undefined
      } : {
        seasonal_job_position: JSON.parse(values['seasonal_job_position'][0]),
        is_abroad_job: undefined
      })), curr === 'salary_rating' ? {
        salary_rating: values['salary_rating'].map(function (v) {
          return +v;
        })
      } : {}), curr === 'career_rating' ? {
        career_rating: values['career_rating'].map(function (v) {
          return +v;
        })
      } : {}), curr === 'max_salary' && values['max_salary'] ? {
        max_salary: values['max_salary'] === 5000 ? 'infinite' : values['max_salary'].toString()
      } : {
        max_salary: undefined
      }), ((_values$job_type = values['job_type']) === null || _values$job_type === void 0 ? void 0 : _values$job_type.length) > 0 ? {
        job_type: values['job_type']
      } : {
        job_type: undefined
      }), curr === 'required_experience' && (_values$curr = values[curr]) !== null && _values$curr !== void 0 && _values$curr.length ? {
        required_experience: typeof values[curr] === 'string' ? values[curr] : values[curr][0]
      } : {}), curr === 'user_location_radius' && (_values$user_location = values['user_location_radius']) !== null && _values$user_location !== void 0 && _values$user_location.length ? {
        user_location_radius: +values['user_location_radius'][0]
      } : {
        user_location_radius: undefined
      }), occupationIds); // modifiedData = omitBy(formattedData, (value) => value === undefined || value === null || value === '');


      modifiedData = _objectSpread({}, formattedData);
    }

    return _objectSpread(_objectSpread({}, prev), modifiedData);
  }, {});
};
export var singleMode = ['user_location_radius', 'seasonal_job_position', 'required_experience', 'salary_rating', 'career_rating'];
export var multiMode = ['job_type', 'shifts', 'domain_interest', 'occupation_ids'];
export var userLocation = ['user_location_lat', 'user_location_lon'];
export var range = ['salary_range'];
export var applyMoreFilterAttrs = [].concat(singleMode, multiMode, userLocation, range, ['address', 'max_salary', 'min_salary', 'benefits', 'contract_type', 'is_abroad_job']);