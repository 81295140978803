import { PrimitiveAtom, atom } from 'jotai';

import { JobApplicationPerformance, RestWorldRating } from '@restworld/data-services';
import { CompanyProfileComponentPropsType, JobApplication, JobPosition } from '@restworld/utility-types';
import { JobSimilarPositionsType } from '@restworld/utils-common';

interface IJobPosition {
  jobActiveStatus: boolean;
  workerSkills: number[];
  showViolaBadge: boolean | null;
  jobPositionApiData?: Partial<JobPosition> | null;
  restworldGoogleRatingData?: Partial<RestWorldRating>;
  jobPositionApplyStatus: boolean;
  jobApplication?: Partial<JobApplication>;
  jobApplicationPerformanceMetrics?: JobApplicationPerformance;
  isJobPositionFavorited: boolean;
  similarJobPositions: JobSimilarPositionsType[];
  companyProfile: CompanyProfileComponentPropsType;
  compatibilityScore: number;
  fullView: boolean;
  fullRating: boolean;
}

export const jobActiveStatusAtom = atom<IJobPosition['jobActiveStatus']>(false);
export const workerSkillsAtom = atom<IJobPosition['workerSkills']>([]);
export const showViolaBadgeAtom = atom<IJobPosition['showViolaBadge']>(null) as PrimitiveAtom<boolean | null>;
export const jobPositionApiDataAtom = atom<IJobPosition['jobPositionApiData']>({});
export const restworldGoogleRatingDataAtom = atom<IJobPosition['restworldGoogleRatingData']>({});
export const jobPositionApplyStatusAtom = atom<IJobPosition['jobPositionApplyStatus']>(false);
export const jobApplicationAtom = atom<IJobPosition['jobApplication']>({});
export const isJobApplicationDiscardedAtom = atom((get) => {
  const jobApplication = get(jobApplicationAtom);
  return jobApplication?.status_key === 'worker_jp_discarded';
});
export const jobApplicationPerformanceMetricsAtom = atom<IJobPosition['jobApplicationPerformanceMetrics'] | undefined>(
  undefined
);
export const isJobPositionFavoritedAtom = atom<IJobPosition['isJobPositionFavorited']>(false);
export const similarJobPositionsAtom = atom<IJobPosition['similarJobPositions']>([]);
export const companyProfileAtom = atom<IJobPosition['companyProfile']>({});
companyProfileAtom.debugLabel = 'companyProfileAtom';
export const compatibilityScoreAtom = atom<IJobPosition['compatibilityScore']>(-1);
export const fullViewAtom = atom<IJobPosition['fullView']>(false);
export const fullRatingAtom = atom<IJobPosition['fullRating']>(false);
