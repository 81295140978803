import { useState, useCallback, useMemo, memo, ChangeEvent, Dispatch, SetStateAction } from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { TabComponentType, useAnalytics } from '@restworld/utils-common';
import { Title, Button, TermsAndConditions } from '@restworld/ui-ds';

import { useEntryPoint } from '../../worker-entry-point.hooks';
import { useEntryPointCheckListContext } from './entry-component.context';
import { useCVLoadingSliderActiveIndex } from '../../worker-cvloading-ui';
import { activeTabAtom, spontaneousApplyDataAtom, uploadFilesAtom } from '../../worker-entry-point.atoms';
import { jobPositionApiDataAtom } from '../../../job-position/job-position.atoms';
import { entryPointModalVisibleAtom } from '../../worker-entry-point-modal';

export const LoginPrivacyRedirectSubmitButton = ({ type }: { type: TabComponentType }) => {
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  const { onUploadFileChange } = useEntryPoint();
  const uploadFiles = useAtomValue(uploadFilesAtom);
  const [spontaneousApplyData, setSpontaneousApplyData] = useAtom(spontaneousApplyDataAtom);
  // const { fbTrackWorkerStartsApplication } = useAnalytics();
  const { setBtnTriggeredWithoutCheck, setIsCVNotUploaded } = useEntryPointCheckListContext();
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const toggleEntryPoint = useSetAtom(entryPointModalVisibleAtom);

  const query = router?.['query'];
  const { onSlideChanged } = useCVLoadingSliderActiveIndex();
  const onClick = useCallback(() => {
    if (!isPrivacyChecked) setBtnTriggeredWithoutCheck(true);
    if (type === 'upload-cv' && !uploadFiles?.[0]) setIsCVNotUploaded(true);
    if (type === 'upload-cv' && uploadFiles?.[0] && isPrivacyChecked) {
      onSlideChanged(0);
      onUploadFileChange();
      setSpontaneousApplyData((p) => ({ ...p, entryType: type }));
      trackEvent('upload_cv', {
        upload_media: true,
        close_button: false,
      });
    }
    if (type === 'application-wizard' && isPrivacyChecked) {
      trackEvent('initiate_questionnaire', {
        close_button: true,
        terms_and_conditions: true,
      });
      // fbTrackWorkerStartsApplication((query['id'] ?? query['slug'] ?? '') as string);
      setSpontaneousApplyData((p) => ({ ...p, entryType: type }));
      router?.push(
        `/candidati/${query['slug']}${
          spontaneousApplyData?.isSpontaneousEntry
            ? `?isSpontaneousEntry=${spontaneousApplyData?.isSpontaneousEntry}`
            : ''
        }${jobPositionApiData?.manage_using_klaaryo ? `?manage_using_klaaryo=true` : ''}`
      );
      toggleEntryPoint(false);
    }
  }, [
    isPrivacyChecked,
    setBtnTriggeredWithoutCheck,
    type,
    uploadFiles,
    setIsCVNotUploaded,
    onSlideChanged,
    onUploadFileChange,
    setSpontaneousApplyData,
    trackEvent,
    query,
    router,
    spontaneousApplyData?.isSpontaneousEntry,
    jobPositionApiData?.manage_using_klaaryo,
    toggleEntryPoint,
  ]);

  const { t } = useTranslation();
  return (
    <Wrapper>
      <EntryPointTermsAndConditions
        testId="entry-point-terms-and-conditions"
        setIsChecked={setIsPrivacyChecked}
        isChecked={isPrivacyChecked}
        type={type}
      />
      <Button
        title={type === 'upload-cv' ? t('common:upload_cv') : t('onboarding-page:fill_out_the_questionnaire')}
        variant="normal"
        buttonSize="large"
        onClick={onClick}
        fullwidth
        testId={type === 'upload-cv' ? 'upload-cv-button' : 'onboarding-button'}
      />
      <WarningText type={type} />
      <RedirectButtonComponent type={type} />
    </Wrapper>
  );
};
const EntryPointTermsAndConditions = ({
  type,
  isChecked,
  setIsChecked,
  testId,
}: {
  type: TabComponentType;
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  testId?: string;
}) => {
  const { isBtnTriggeredWithoutCheck, setBtnTriggeredWithoutCheck } = useEntryPointCheckListContext();
  const onCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) setBtnTriggeredWithoutCheck(false);
      setIsChecked(event.target.checked);
    },
    [setBtnTriggeredWithoutCheck, setIsChecked]
  );
  return (
    <TermsAndConditions
      key={type}
      onCheckboxChange={onCheckboxChange}
      isChecked={isChecked}
      type={type}
      iserror={isBtnTriggeredWithoutCheck}
      testId={testId}
    />
  );
};
const WarningText = memo(({ type }: { type: TabComponentType }) => {
  const { t } = useTranslation('onboarding-page');
  const { isBtnTriggeredWithoutCheck, isCVNotUploaded } = useEntryPointCheckListContext();
  const textContent = isBtnTriggeredWithoutCheck
    ? type === 'application-wizard'
      ? t('accept_the_terms_and_conditions_to_continue')
      : t('upload_acv_and_accept_the_terms_and_conditions_to_continue')
    : t('upload_acv_to_continue');

  return isBtnTriggeredWithoutCheck || isCVNotUploaded ? (
    <Title
      icon="HandRaiseRedIcon"
      textColor="red"
      content={textContent}
      fontSize="small"
      fontWeight="normal"
      isCenter
      testId="onboarding-warning-text"
    />
  ) : null;
});
const RedirectButtonComponent = memo(({ type }: { type: TabComponentType }) => {
  const { t } = useTranslation('onboarding-page');
  const { setBtnTriggeredWithoutCheck, setIsCVNotUploaded } = useEntryPointCheckListContext();
  const setActiveTab = useSetAtom(activeTabAtom);
  const onTogglerCallback = useCallback(() => {
    setBtnTriggeredWithoutCheck(false);
    setIsCVNotUploaded(false);
    setActiveTab(type === 'upload-cv' ? 'application-wizard' : 'upload-cv');
  }, [setActiveTab, setBtnTriggeredWithoutCheck, setIsCVNotUploaded, type]);
  const togglerButtonTitle = useMemo(
    () => (type === 'upload-cv' ? t('fill_out_the_questionnaire') : t('upload_it')),
    [t, type]
  );
  const titleContent = useMemo(
    () => (type === 'upload-cv' ? t('you_dont_have_cv') : t('do_you_already_have_acv')),
    [t, type]
  );
  return (
    <TogglerWrapper>
      <Title textColor="gray" content={titleContent} fontWeight="light" fontSize="small" />
      <Button title={togglerButtonTitle} variant="ghost" buttonSize="small" onClick={onTogglerCallback} />
    </TogglerWrapper>
  );
});
const TogglerWrapper = tw.span`flex gap-1 mx-auto`;
const Wrapper = tw.div`flex flex-col gap-4`;
