globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useContext } from 'react';
import { AuthContext } from '../context';
export var useAuth = function useAuth() {
  var context = useContext(AuthContext);
  if (!context) throw new Error('Auth context must be use inside AuthProvider');
  return context;
};