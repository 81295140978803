import tw, { styled } from 'twin.macro';

export type ModalSubTitleProps = {
  children: React.ReactNode | string;
};

export const AuthSubtitle = styled.p(({ isModal = true }: { isModal?: boolean }) => [
  tw`text-base font-normal text-gray-600 text-center`,
  !isModal && tw`text-left`,
]);
