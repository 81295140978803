import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { EmailInput, RadioGroup, TermsAndConditions, Title, isValidEmail } from '@restworld/ui-ds';
import { useAuth } from '@restworld/feature-authentication';
import { useNotificationFrequencyOptions } from '@restworld/utils-common';

import { StepWrapper, SaveResearchFormFields } from '../job-explorer-save-research-v2';

export const NotificationUpdateSelection = () => {
  const { user } = useAuth();
  const { control } = useFormContext<SaveResearchFormFields>();
  const { t: jobExplorerTranslate } = useTranslation('job-explorer-page');
  const { t: commonTranslate } = useTranslation('common');

  const notificationFrequencyOptions = useNotificationFrequencyOptions();

  return (
    <StepWrapper>
      <div data-cy="notification-update-selection" tw="flex flex-col gap-8">
        <div tw="flex flex-col gap-2">
          <Title
            content={jobExplorerTranslate('receive_updates_on_offers')}
            fontWeight="semibold"
            fontSize="superLarge"
            twStyle={tw`leading-[28.8px]`}
          />
          <Title
            content={jobExplorerTranslate('enter_email_select_frequency')}
            fontWeight="normal"
            fontSize="base"
            twStyle={tw`leading-[24px]`}
            textColor="gray-500"
          />
        </div>
        <Controller
          name="email"
          control={control}
          rules={{ required: commonTranslate('enter_valid_email'), validate: isValidEmail }}
          render={({ field, fieldState }) => {
            return (
              <div tw="flex flex-col gap-2">
                <EmailInput
                  label={
                    <span>
                      Email<span tw="text-red-600">*</span>
                    </span>
                  }
                  variant={fieldState?.error?.message ? 'error' : 'primary'}
                  placeholder="mario.rossi@gmail.com"
                  disabled={!!user?.email}
                  supportText={fieldState?.error?.message ?? ''}
                  testId="save-research-email-input-field"
                  supportTextTestId="email-input-support-text"
                  {...(fieldState?.error?.message ? { supportText: fieldState?.error?.message } : {})}
                  {...field}
                />
                <Title
                  content={jobExplorerTranslate('no_spam_job_offers')}
                  fontWeight="normal"
                  fontSize="small"
                  twStyle={tw`leading-[21px]`}
                  textColor="gray-500"
                />
              </div>
            );
          }}
        />
        <Controller
          name="frequency"
          control={control}
          rules={{ required: jobExplorerTranslate('select_notification_frequency') }}
          render={({ field }) => (
            <div tw="flex gap-2 flex-col">
              <span tw="text-base font-normal leading-[24px]">
                {commonTranslate('select_an_option')} <span tw="text-red-600">*</span>{' '}
              </span>
              <RadioGroup
                testId="save-research-notification-freq-filter"
                id="notification_frequency"
                options={notificationFrequencyOptions}
                {...field}
              />
            </div>
          )}
        />
        <Controller
          name="terms_and_conditions"
          control={control}
          rules={{
            required: commonTranslate('accept_terms_and_conditions'),
          }}
          render={({ field }) => <TermsAndConditions testId="save-research-tAndC" {...field} isChecked={field.value} />}
        />
      </div>
    </StepWrapper>
  );
};

export default NotificationUpdateSelection;
