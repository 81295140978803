import 'twin.macro';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';

import { useResponsiveBreakPoints } from '@restworld/utils-common';
import { Paragraph, Title, Wrapper, MapComponent, ImageCarousel, IMapProps } from '@restworld/ui-ds';

import { defaultArgType } from './job-location.mocks';
import { CompanyProfileDrawer } from '../job-company-profile';

export type JobLocationMapBlockProps = IMapProps & Pick<JobLocationProps, 'locationDescription'>;

export function JobLocationBlock({
  locationDescription,
  restaurantBriefDescription,
  restaurantPictures,
  showRestaurant,
  children: applicationEntryPointButtonAsChild,
  ...rest
}: JobLocationProps) {
  const { isDesktop } = useResponsiveBreakPoints();
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      {/* map block */}
      <JobLocationMapBlock {...rest} locationDescription={locationDescription} showRestaurant={showRestaurant} />
      {/* location pictures */}
      {restaurantPictures?.length && showRestaurant ? (
        <>
          <Title textColor="black" variant="h6-large" fontWeight="semibold" content={t('restaurant_presentation')} />
          <Paragraph content={restaurantBriefDescription} />
          <ImageCarousel restaurantPictures={restaurantPictures} />
        </>
      ) : null}
      {showRestaurant && (
        <CompanyProfileDrawer
          buttonVariant="secondary-outline"
          buttonTitle={t('find_out_more_about_company')}
          buttonIcon="OfficeBuildingIcon"
        />
      )}
      {!isDesktop && <>{applicationEntryPointButtonAsChild}</>}
    </Wrapper>
  );
}
export const JobLocationMapBlock = ({ showRestaurant, locationDescription, ...rest }: JobLocationMapBlockProps) => {
  const { t } = useTranslation('job-position-page');
  return (
    <div tw="flex flex-col gap-0">
      <Title
        content={t('job_location_title')}
        icon="MapIcon"
        wrapperStyle={tw`mb-4`}
        variant="h4-large"
        fontWeight="semibold"
        textColor="black"
      />
      <MapComponent {...rest} showRestaurant={showRestaurant} />
      <Paragraph content={locationDescription} />
    </div>
  );
};
export default JobLocationBlock;
export type JobLocationProps = defaultArgType;
