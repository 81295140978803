globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var quickFilterItems = [{
  key: 'seasonal_accommodation',
  id: 'seasonal_accommodation',
  label: 'Con alloggio',
  icon: 'StagionaleIcon',
  returnKey: 'benefits'
}, {
  key: 'fixed_term',
  id: 'fixed_term',
  label: 'Determinato',
  icon: 'DeterminatoIcon'
}, {
  key: 'permanent',
  id: 'permanent',
  label: 'Indeterminato',
  icon: 'IndeterminatoIcon'
}, {
  key: 'apprenticeship_internship',
  id: 'apprenticeship_internship',
  label: 'Stage/Tirocinio',
  icon: 'StageTirocinioIcon'
}, {
  key: 'part_time',
  id: 'part_time',
  label: 'Part time',
  icon: 'PartTimeIcon'
}, {
  key: 'full_time',
  id: 'full_time',
  label: 'Full time',
  icon: 'FullTimeIcon'
}];