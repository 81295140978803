import * as React from 'react';
import tw from 'twin.macro';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { atom, useAtom } from 'jotai';
// import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
// import { useRouter } from 'next/router';

import { BaseModal, Button, TimelineV2 } from '@restworld/ui-ds';
import { customImageLoader, getAssetSrc, GreenCheckMarkWithWhiteBg } from '@restworld/utils-common';

// import { saveResearchModalV2Atom } from './job-explorer/job-explorer-restaurant-explorer.atoms';
import { useSaveResearchModalForJobPosition } from './worker-entry-point/worker-entry-point-modal';
// import { useJobPositionId } from '../hooks';
// import { areaSelectionAtom } from './job-explorer/job-explorer-map/job-explorer-save-research-v2';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IWorkerManagedUsingKlaaryoProps {}

export const workerManagedUsingKlaaryoModalFlagAtom = atom(false);
export const workerManagedFooterMapKeyAtom = atom<'cv_applied' | 'default_applied' | 'direct_applied' | 'profile'>(
  'default_applied'
);
const WorkerManagedUsingKlaaryo: React.FunctionComponent<IWorkerManagedUsingKlaaryoProps> = () => {
  const [workerManagedUsingKlaaryoModalFlag, setWorkerManagedUsingKlaaryoModalFlagAtom] = useAtom(
    workerManagedUsingKlaaryoModalFlagAtom
  );
  const { t } = useTranslation('common');
  const { openSaveResearchModal } = useSaveResearchModalForJobPosition();
  // const [, setSaveResearchModal] = useAtom(saveResearchModalV2Atom);
  // const [workerManagedFooterMapKey] = useAtom(workerManagedFooterMapKeyAtom);
  // const router = useRouter();
  // const jobPositionId = useJobPositionId();
  // const setAreaSelection = useSetAtom(areaSelectionAtom);
  // const checkYourProfileBtn = React.useMemo(
  //   () => (
  //     <Button
  //       onClick={() => {
  //         router.push('/profilo');
  //         setWorkerManagedUsingKlaaryoModalFlagAtom(false);
  //       }}
  //       fullwidth
  //     >
  //       {t('check_your_profile')}
  //     </Button>
  //   ),
  //   [router, setWorkerManagedUsingKlaaryoModalFlagAtom, t]
  // );
  // const jobExplorerBtn = React.useMemo(
  //   () => (
  //     <Button
  //       onClick={() => {
  //         router.push('/cerco-lavoro');
  //         setWorkerManagedUsingKlaaryoModalFlagAtom(false);
  //       }}
  //       variant="secondary-outline"
  //       fullwidth
  //     >
  //       {t('browse_other_job_positions')}
  //     </Button>
  //   ),
  //   [router, setWorkerManagedUsingKlaaryoModalFlagAtom, t]
  // );
  // const applicationsBtn = React.useMemo(
  //   () => (
  //     <Button onClick={() => router.push(`/candidature/${jobPositionId}`)} fullwidth>
  //       {t('see_your_applications')}
  //     </Button>
  //   ),
  //   [jobPositionId, router, t]
  // );
  // const defaultFooter = React.useMemo(
  //   () => (
  //     <div tw="flex flex-col gap-2 items-center justify-center w-full pt-4">
  //       {workerManagedFooterMapKey === 'cv_applied' ? (
  //         checkYourProfileBtn
  //       ) : workerManagedFooterMapKey === 'direct_applied' ? (
  //         <>
  //           {applicationsBtn}
  //           {jobExplorerBtn}
  //         </>
  //       ) : workerManagedFooterMapKey === 'profile' ? (
  //         checkYourProfileBtn
  //       ) : (
  //         <>
  //           {checkYourProfileBtn}
  //           {jobExplorerBtn}
  //         </>
  //       )}
  //     </div>
  //   ),
  //   [applicationsBtn, checkYourProfileBtn, jobExplorerBtn, workerManagedFooterMapKey]
  // );
  const defaultFooter = React.useMemo(
    () => (
      <Button
        onClick={() => {
          openSaveResearchModal();
          setWorkerManagedUsingKlaaryoModalFlagAtom(false);
        }}
        fullwidth
      >
        {t('receive_other_offers_similar_to_this')}
      </Button>
    ),
    [openSaveResearchModal, setWorkerManagedUsingKlaaryoModalFlagAtom, t]
  );
  return (
    <BaseModal
      {...{
        open: workerManagedUsingKlaaryoModalFlag,
        onClose: () => setWorkerManagedUsingKlaaryoModalFlagAtom(false),
        modalStyles: [tw`max-w-full w-full md:(max-w-[640px] min-w-104)`],
        showMobileGestures: true,
      }}
      title={
        <div tw="flex flex-col gap-3 justify-center items-center pb-6 px-3 lg:px-[6rem]">
          <GreenCheckMarkWithWhiteBg
            {...{
              height: 24,
              width: 24,
            }}
          />
          <h4 tw="text-center text-neutral-700 text-2xl font-bold">
            {t('your_application_has_been_successfully_sent')}
          </h4>
        </div>
      }
      footer={defaultFooter}
    >
      <div tw="flex flex-col items-center relative px-3 lg:px-[6rem]">
        <TimelineV2
          timeLineSteps={[
            { title: t('application_received'), checked: true, showDownIcon: false, verticalLineStyle: 'solid' },
            {
              customContentElement: (
                <div tw="px-3 py-2 rounded-lg border border-neutral-40 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div tw="text-neutral-700 text-base font-medium">{t('interview_with_our_manager')}</div>
                  <div tw="relative">
                    <Image
                      src={getAssetSrc('/utils-assets/cs-manager.png')}
                      width={56}
                      height={56}
                      alt="cs-manager"
                      loader={customImageLoader}
                    />
                    <Image
                      tw="absolute top-[10px] left-[28px]"
                      src={getAssetSrc('/utils-assets/whatsapp-img.png')}
                      width={50}
                      height={50}
                      alt="whatsapp-icon"
                      loader={customImageLoader}
                    />
                  </div>
                  <div tw="text-gray-500 text-base font-medium">{t('soon_to_be_contacted')}</div>
                </div>
              ),
              title: t('interview_with_our_manager'),
              checked: false,
              showDownIcon: true,
              verticalLineStyle: 'dashed',
            },
            { title: t('send_application'), checked: false, showDownIcon: true, verticalLineStyle: 'dashed' },
            {
              title: t('contact_with_the_company'),
              checked: false,
              showDownIcon: true,
              verticalLineStyle: 'dashed',
            },
          ]}
        />
      </div>
    </BaseModal>
  );
};

export default WorkerManagedUsingKlaaryo;
