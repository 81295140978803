globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
var DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
var SORTED_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

var formatTime = function formatTime(time) {
  if (!time) return null;
  var splitTime = time.match(/.{1,2}/g);
  return splitTime ? splitTime.join(':') : null;
};

var formatTimeInterval = function formatTimeInterval(openTime, closeTime, anotherOpenTime, anotherCloseTime) {
  if (anotherOpenTime && anotherCloseTime) {
    return "".concat(openTime, " - ").concat(closeTime, " | ").concat(anotherOpenTime, " - ").concat(anotherCloseTime);
  }

  return "".concat(openTime, " - ").concat(closeTime);
};

var formatDayPeriods = function formatDayPeriods(periods) {
  return periods.reduce(function (acc, _ref) {
    var open = _ref.open,
        close = _ref.close;
    var day = DAYS[open.day];
    var openTime = open !== null && open !== void 0 && open.time ? formatTime(open.time) : null;
    var closeTime = close !== null && close !== void 0 && close.time ? formatTime(close.time) : null;
    var anotherOpenTime = open !== null && open !== void 0 && open.anotherTime ? formatTime(open.anotherTime) : null;
    var anotherCloseTime = close !== null && close !== void 0 && close.anotherTime ? formatTime(close.anotherTime) : null;
    var timeInterval = formatTimeInterval(openTime, closeTime, anotherOpenTime, anotherCloseTime);
    if (!acc[day]) acc[day] = [];
    acc[day].push(timeInterval);
    return acc;
  }, {});
};

export var formatGoogleOpeningHours = function formatGoogleOpeningHours(periods) {
  if (!(periods !== null && periods !== void 0 && periods.length)) return [];

  try {
    var formattedHours = formatDayPeriods(periods);
    return SORTED_DAYS.map(function (day) {
      var _formattedHours$day;

      var intervals = ((_formattedHours$day = formattedHours[day]) === null || _formattedHours$day === void 0 ? void 0 : _formattedHours$day.join(' | ')) || 'Closed';
      return "".concat(day, ": ").concat(intervals);
    });
  } catch (error) {
    console.error('Error in formatting google opening hours', error);
    return [];
  }
};