import { useCallback, useEffect, useState } from 'react';

import { useJobPositionsService } from '@restworld/data-services';
import { JobPosition } from '@restworld/utility-types';

import { useViolaBadge } from '../../../hooks';

export const useJobPositionClientData = (staticJp: Partial<JobPosition>, id?: string) => {
  const [jobPositionData, setJobPositionData] = useState<Partial<JobPosition>>(staticJp);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const jobPositionService = useJobPositionsService();

  const callJobPositionApi = useCallback(async () => {
    if (!id) return;
    try {
      setIsLoading(true);
      const response = await jobPositionService.showJobPosition(id);
      setJobPositionData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error, `Error while fetching job position data for id: ${id}`);
    }
  }, [id, jobPositionService]);

  useEffect(() => {
    callJobPositionApi();
  }, [callJobPositionApi]);

  useViolaBadge(staticJp?.id, { initOnMount: true });

  return {
    isLoading,
    jobPositionData,
    callJobPositionApi,
  };
};
