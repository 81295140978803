import { useCallback, useEffect } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { useRouter } from 'next/router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import omitBy from 'lodash.omitby';
import { useTranslation } from 'next-i18next';

import { BaseModal, CommonModalWrapper, Title } from '@restworld/ui-ds';
import { filterIconMap } from '@restworld/utils-common';

import {
  getSeasonalAccommodationAtom,
  setSeasonalAccommodationToggleModalAtom,
  setSeasonalAccommodationAppliedAtom,
  isSeasonalAccommodationAppliedAtom,
  saveResearchModalV2Atom,
} from '../job-explorer-restaurant-explorer.atoms';
import { jobListParamsAtom } from '../job-explorer-list.atoms';
import { jobCoordinateParamsAtom } from '../job-explorer-map.atoms';
import { filterContextValuesAtom, moreFiltersAtom } from '../job-explorer-filters.atoms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISeasonalRecommendationMapIconFilterProps {}

const SeasonalIcon = filterIconMap['StagionaleIcon'];

export const SeasonalRecommendationMapIconFilter: React.FunctionComponent<
  ISeasonalRecommendationMapIconFilterProps
> = () => {
  const setMoreFilters = useSetAtom(moreFiltersAtom);
  const setFilterContextValues = useSetAtom(filterContextValuesAtom);
  const router = useRouter();
  const [, setSeasonalAccommodationToggleModal] = useAtom(setSeasonalAccommodationToggleModalAtom);
  const [, setSeasonalAccommodationApplied] = useAtom(setSeasonalAccommodationAppliedAtom);
  const [isSeasonalAccommodationApplied] = useAtom(isSeasonalAccommodationAppliedAtom);
  const jobListParams = useAtomValue(jobListParamsAtom);
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const setJobCoordinateParams = useSetAtom(jobCoordinateParamsAtom);

  const onApplyFilter = useCallback(() => {
    try {
      setJobCoordinateParams((prev) => {
        const contractType = isSeasonalAccommodationApplied
          ? prev?.contract_type?.filter((v) => v !== 'seasonal_accommodation')
          : [...(prev?.contract_type ?? []), 'seasonal_accommodation'];
        const eitherContractType = contractType.length ? contractType : undefined;
        const newPayload = {
          ...prev,
          contract_type: eitherContractType,
        };
        return newPayload;
      });
      setJobListParams((prev) => {
        const contractType = isSeasonalAccommodationApplied
          ? prev?.contract_type?.filter((v) => v !== 'seasonal_accommodation')
          : [...(prev?.contract_type ?? []), 'seasonal_accommodation'];
        const eitherContractType = contractType.length ? contractType : undefined;
        const newPayload = {
          ...prev,
          offset: 0,
          contract_type: eitherContractType,
        };
        setMoreFilters((p) => ({ ...p, contract_type: eitherContractType }));
        setFilterContextValues((p) => ({ ...p, contract_type: eitherContractType }));
        if (!isSeasonalAccommodationApplied) {
          setSeasonalAccommodationApplied(true);
          setSeasonalAccommodationToggleModal(true);
        } else setSeasonalAccommodationApplied(false);
        const query = {
          ...router.query,
          ...(router.query?.['overall_filters'] &&
          typeof router.query['overall_filters'] === 'string' &&
          Object.keys(JSON.parse(router.query['overall_filters'])).length > 0
            ? isSeasonalAccommodationApplied
              ? {
                  overall_filters:
                    Object.keys(
                      omitBy(JSON.parse(router.query['overall_filters']), (_, key) => key === 'contract_type')
                    ).length > 0
                      ? JSON.stringify({
                          ...JSON.parse(router.query['overall_filters']),
                          contract_type: eitherContractType,
                        })
                      : undefined,
                }
              : {
                  overall_filters: JSON.stringify({
                    ...JSON.parse(router.query['overall_filters']),
                    contract_type: eitherContractType,
                  }),
                }
            : {
                overall_filters: JSON.stringify({
                  contract_type: eitherContractType,
                }),
              }),
        };
        const newQuery = omitBy(query, (value) => value === undefined || value === '{}') as Record<string, string>;

        router.push(
          {
            pathname: router.pathname,
            query: newQuery,
          },
          undefined,
          { shallow: true }
        );
        return newPayload;
      });
    } catch (error) {
      console.error(error, 'error in seasonal recommendation map icon filter');
    }
  }, [
    isSeasonalAccommodationApplied,
    router,
    setFilterContextValues,
    setJobCoordinateParams,
    setJobListParams,
    setMoreFilters,
    setSeasonalAccommodationApplied,
    setSeasonalAccommodationToggleModal,
  ]);

  useEffect(() => {
    if (jobListParams?.contract_type?.includes('seasonal_accommodation')) setSeasonalAccommodationApplied(true);
    else setSeasonalAccommodationApplied(false);
  }, [jobListParams?.contract_type, setSeasonalAccommodationApplied]);

  return (
    <SeasonalFilterLinkStyled isActive={isSeasonalAccommodationApplied} onClick={onApplyFilter} href="#!">
      <SeasonalIcon stroke={isSeasonalAccommodationApplied ? theme`colors.white` : theme`colors.black`} size={24} />
    </SeasonalFilterLinkStyled>
  );
};

export default SeasonalRecommendationMapIconFilter;

export const SeasonalAccommodationSuccessModal = () => {
  const [, setSeasonalAccommodationToggleModal] = useAtom(setSeasonalAccommodationToggleModalAtom);
  const [seasonalAccommodation] = useAtom(getSeasonalAccommodationAtom);
  const [, openSaveResearchModal] = useAtom(saveResearchModalV2Atom);
  const { t } = useTranslation('job-explorer-page');
  return (
    <BaseModal
      modalStyles={[tw`min-w-[375px]`]}
      open={seasonalAccommodation.toggleModal}
      onClose={() => setSeasonalAccommodationToggleModal(false)}
      showMobileGestures
      footer={
        <>
          <CommonModalWrapper
            primaryButtonProps={{
              title: t('all_clear'),
              onClick() {
                setSeasonalAccommodationToggleModal(false);
              },
            }}
            textAlignment="left"
          />
          <a
            href="#!"
            tw="w-full"
            onClick={() => {
              setSeasonalAccommodationToggleModal(false);
              openSaveResearchModal(true);
            }}
          >
            <Title
              content={t('help_with_search')}
              textColor="primary-700"
              variant="h6-xsmall"
              twStyle={tw`underline underline-offset-4 leading-[15.4px] pt-6`}
              isCenter
            />
          </a>
        </>
      }
    >
      <CommonModalWrapper
        icon={<SeasonalIcon stroke={theme`colors.primary.700`} size={42} />}
        mainTitle={t('seasonal_accommodation_activated')}
        subTitle={t('looking_for_help_with_search')}
        textAlignment="left"
      />
    </BaseModal>
  );
};
const SeasonalFilterLinkStyled = styled.a<{ isActive?: boolean }>`
  ${tw`absolute top-4 lg:left-[18rem] w-[40px] right-6 lg:right-0 bg-white border border-gray-200 shadow-lg p-2 rounded-lg h-[40px] z-20`}
  ${({ isActive }) => (isActive ? tw`bg-primary-700` : tw`hover:bg-gray-200`)}
`;
