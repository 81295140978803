globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var getAssetSrc = function getAssetSrc(assetPath) {
  return process.env['NEXT_PUBLIC_VERCEL_URL'] ? "https://".concat(process.env['NEXT_PUBLIC_VERCEL_URL']).concat(assetPath) : assetPath;
};