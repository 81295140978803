import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { JobApplicationEntryPoint } from '../';
import JobLocationBlock from '../../job-location';
import { jobPositionApiDataAtom } from '../job-position.atoms';

export const JobLocationDetails = () => {
  const jobLocationArgs = useJobLocation();

  return (
    <JobLocationBlock {...jobLocationArgs}>
      <JobApplicationEntryPoint />
    </JobLocationBlock>
  );
};
const useJobLocation = () => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const restaurant = jobPositionApiData?.restaurant;
  const mapDefaultArgs = useMemo(
    () => ({
      showRestaurant: jobPositionApiData?.show_restaurant_details,
      lat: restaurant?.address_lat,
      lon: restaurant?.address_lon,
      approximateLat: restaurant?.approximate_lat,
      approximateLon: restaurant?.approximate_lon,
    }),
    [
      jobPositionApiData?.show_restaurant_details,
      restaurant?.address_lat,
      restaurant?.address_lon,
      restaurant?.approximate_lat,
      restaurant?.approximate_lon,
    ]
  );
  const jobLocationArgs = useMemo(
    () => ({
      ...mapDefaultArgs,
      locationDescription: restaurant?.location_description,
      restaurantBriefDescription: restaurant?.brief_description,
      restaurantPictures: restaurant?.pictures ? restaurant?.pictures?.map((v) => v.url) : [],
    }),
    [mapDefaultArgs, restaurant?.brief_description, restaurant?.location_description, restaurant?.pictures]
  );
  return jobLocationArgs;
};
