globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
var timeLineSteps = [{
  key: 1,
  title: 'Contatto da parte di Restworld',
  subtitle: 'Ti scriveremo su Whatsapp per confermare il tuo interesse.'
}, {
  key: 2,
  title: "Conoscenza telefonica con l'azienda",
  subtitle: "Una volta confermato, l'azienda ti contatterà telefonicamente."
}, {
  key: 3,
  title: 'Colloquio',
  subtitle: 'Avrai un colloquio con chi si occupa della selezione per il locale.'
}, {
  key: 4,
  title: 'Prova',
  subtitle: 'Periodo di prova che varia in base agli accordi presi in fase di colloquio.'
}, {
  key: 5,
  title: 'Contratto',
  subtitle: 'Ti verrà proposto un contratto di assunzione.',
  isFlagged: true
}];
var defaultTimelineArgs = {
  timeLineSteps: timeLineSteps
};
export { defaultTimelineArgs };