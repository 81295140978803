import { ReactNode, useMemo, useCallback, memo } from 'react';
import tw, { styled } from 'twin.macro';
import { Progress as FlowBiteProgressBar } from 'flowbite-react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useSetAtom } from 'jotai';

import {
  computeRange,
  getAssetSrc,
  openInNewTab,
  PersonWithRestworldCupIllustration,
  CVParsingLoadingIllustrationInitial,
  useAnalytics,
} from '@restworld/utils-common';
import { Button, Title } from '@restworld/ui-ds';

import { useStepData } from '../worker.cvloading-ui.constant';
import { CheckMarkIcon } from './svg';
import { entryPointModalVisibleAtom } from '../../worker-entry-point-modal';
import { workerManagedFooterMapKeyAtom } from '../../../worker-managed-using-klaaryo';

const numberList = [1, 2, 3, 4];
export const WorkerCVLoadingUIWrapper = ({
  children,
  activeIndex,
  isCompleted,
  workerId,
  testId,
}: {
  children: ReactNode;
  activeIndex: number;
  isCompleted: boolean;
  workerId?: string;
  testId?: string;
}) => {
  const { t } = useTranslation();
  const title = useMemo(() => t('common:check_the_data'), [t]);
  const { trackEvent } = useAnalytics();

  const toggleEntryPoint = useSetAtom(entryPointModalVisibleAtom);
  const setWorkerManagedFooterMapKey = useSetAtom(workerManagedFooterMapKeyAtom);

  const handleClick = useCallback(() => {
    if (isCompleted) {
      trackEvent('check_data_cv', { close_button: true });
      openInNewTab(`/signup${workerId ? `?user_id=${workerId}` : ''}`);
      setWorkerManagedFooterMapKey('cv_applied');
      toggleEntryPoint(false);
    }
  }, [isCompleted, setWorkerManagedFooterMapKey, toggleEntryPoint, trackEvent, workerId]);

  const progressTabElList = useMemo(
    () =>
      numberList.map((v, i) => {
        const activeIndexes = [0, ...computeRange(activeIndex + 1)];
        return (
          <ProgressTabWrapper key={v} isActiveIndex={activeIndexes.includes(i)} isProceedingBar={activeIndex + 1 === i}>
            {activeIndexes.includes(i) && (
              <ProgressTab>
                <CheckMarkIcon {...{ height: 24, width: 24 }} />
              </ProgressTab>
            )}
          </ProgressTabWrapper>
        );
      }),
    [activeIndex]
  );

  return (
    <MainWrapper data-cy={testId}>
      <SubWrapper>{progressTabElList}</SubWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {isCompleted ? (
        <div
          data-cy="success-illustration"
          tw="[animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both]"
        >
          <PersonWithRestworldCupIllustration {...{ width: 300, height: 300 }} />
        </div>
      ) : (
        <>
          <div tw="animate-pulse">
            <CVParsingLoadingIllustrationInitial {...{ width: 300, height: 300 }} />
          </div>
          <ProgressBarBlock activeIndex={activeIndex} />
        </>
      )}
      <Button title={title} variant="normal" disabled={!isCompleted} onClick={handleClick} />
    </MainWrapper>
  );
};
export const Description = memo(({ title: mainTitle, subTitle }: { title: string; subTitle: string }) => {
  return (
    <DescriptionWrapper>
      <Title
        testId="cv-upload-complete-title"
        content={mainTitle}
        isCenter
        fontWeight="semibold"
        fontSize="extraLarge"
      />
      <Title content={subTitle} isCenter fontWeight="normal" textColor="gray" />
    </DescriptionWrapper>
  );
});
export const ProgressBarBlock = memo(({ activeIndex }: { activeIndex: number }) => {
  const stepData = useStepData();
  return (
    <ProgressBarMainWrapper>
      <ProgressBarAvatar>
        <Image height={32} width={32} src={getAssetSrc(stepData[activeIndex].progressLabel.icon)} alt="" />
      </ProgressBarAvatar>
      <ProgressBarTitleBarWrapper>
        <Title content={stepData[activeIndex].progressLabel.title} fontSize="base" fontWeight="bold" />
        <ProgressStyled progress={stepData[activeIndex].progressPercent} color="purple" />
        <ProgressBarTitleBar>
          <Title
            content={stepData[activeIndex].progressLabel.remainingTime}
            fontSize="xSmall"
            fontWeight="medium"
            textColor="gray"
          />
          <Title
            content={`${stepData[activeIndex].progressPercent}%`}
            fontSize="xSmall"
            fontWeight="medium"
            textColor="gray"
          />
        </ProgressBarTitleBar>
      </ProgressBarTitleBarWrapper>
    </ProgressBarMainWrapper>
  );
});
export const ProgressStyled = styled(FlowBiteProgressBar)`
  ${tw`h-1`}
  > div:first-child {
    ${tw`h-1 bg-primary-800 shadow`}
  }
`;
const ChildrenWrapper = tw.div`flex flex-col gap-4 w-full`;
const MainWrapper = styled.div`
  ${tw`flex flex-col gap-6 items-center w-full`}
  /**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
    0% {
      ${tw`scale-50`}
    }
    100% {
      ${tw`scale-100`}
    }
  }
  @keyframes scale-up-center {
    0% {
      ${tw`scale-50`}
    }
    100% {
      ${tw`scale-100`}
    }
  }
`;

const SubWrapper = tw.div`flex gap-1 pt-4 w-full justify-center`;
const ProgressTabWrapper = styled.div<{
  isActiveIndex: boolean;
  isProceedingBar: boolean;
}>`
  ${tw`relative h-1.5 w-24 z-0 rounded-sm flex justify-center`};
  ${({ isActiveIndex, isProceedingBar }) =>
    isActiveIndex ? tw`bg-primary-800` : isProceedingBar ? tw`bg-primary-300` : tw`bg-gray-200`}
`;
const ProgressTab = tw.div`absolute mx-auto flex -top-2 z-10`;
const DescriptionWrapper = tw.div`text-center flex flex-col gap-4`;
const ProgressBarMainWrapper = tw.div`rounded-lg flex gap-4 bg-primary-50 w-full p-2 justify-start items-center`;
const ProgressBarAvatar = tw.div`rounded-lg bg-white p-3 flex items-center justify-center`;
const ProgressBarTitleBarWrapper = tw.div`flex flex-col w-full gap-2 rounded`;
const ProgressBarTitleBar = tw.div`flex justify-between`;
