import * as React from 'react';
import tw from 'twin.macro';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { BaseModal } from '@restworld/ui-ds';
import { ModalWrapperCloseIcon, useAnalytics } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';

import {
  activeTabAtom,
  isCVUploadProcessCompletedAtom,
  resetWorkerEntryStates,
  showConfirmModalAtom,
  spontaneousApplyDataAtom,
  uploadCVLoadingAtom,
  uploadFilesAtom,
  workerEntryPointErrorsAtom,
  workerIdAtom,
} from './worker-entry-point.atoms';
import { EntryProcessButtons, JobApplyConfirmation } from './worker-entry-point.components';
import { jobPositionApplyStatusAtom } from '../job-position/job-position.atoms';
import { EntryPointModalBody } from './entry-point-modal-body';
import { useApplyJob, useJobPositionId } from '../../hooks';
import { saveResearchModalV2Atom } from '../job-explorer/job-explorer-restaurant-explorer.atoms';
import { areaSelectionAtom } from '../job-explorer/job-explorer-map/job-explorer-save-research-v2';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IWorkerEntryPointModalProps {}
export const entryPointModalVisibleAtom = atom<boolean>(false);

const WorkerEntryPointModal: React.FunctionComponent<IWorkerEntryPointModalProps> = (_) => {
  const [spontaneousApplyData] = useAtom(spontaneousApplyDataAtom);
  const [showEntryPoint, toggleEntryPoint] = useAtom(entryPointModalVisibleAtom);
  const [isCVUploadProcessCompleted, setCVUploadedProcessComplete] = useAtom(isCVUploadProcessCompletedAtom);
  const [uploadCVLoading, uploadCVLoadingToggler] = useAtom(uploadCVLoadingAtom);
  const { isAuthenticated, initializeAuthentication } = useAuth();
  const activeTab = useAtomValue(activeTabAtom);
  const uploadFiles = useAtomValue(uploadFilesAtom);
  const [showConfirmModal, confirmModalToggler] = useAtom(showConfirmModalAtom);
  const errors = useAtomValue(workerEntryPointErrorsAtom);
  const [jobPositionApplyStatus] = useAtom(jobPositionApplyStatusAtom);
  const closeEntryPoint = React.useCallback(() => toggleEntryPoint(false), [toggleEntryPoint]);

  const { trackEvent } = useAnalytics();

  const handleCVUploadProcess = React.useCallback(() => {
    if (isCVUploadProcessCompleted) uploadCVLoadingToggler(false);
  }, [isCVUploadProcessCompleted, uploadCVLoadingToggler]);

  const handleSpontaneousApply = React.useCallback(() => {
    if (spontaneousApplyData?.didUserSpontaneousApply && localStorage.getItem('jwt'))
      initializeAuthentication(localStorage.getItem('jwt') || '');
  }, [initializeAuthentication, spontaneousApplyData?.didUserSpontaneousApply]);

  const handleActiveTabEvents = React.useCallback(() => {
    if (isCVUploadProcessCompleted) trackEvent('check_data_cv', { close_button: false });
    if (activeTab === 'upload-cv')
      trackEvent('upload_cv', {
        close_button: true,
        upload_media: (uploadFiles?.length && uploadFiles.length > 0) ?? false,
      });
    if (activeTab === 'application-wizard')
      trackEvent('initiate_questionnaire', { close_button: false, terms_and_conditions: false });
  }, [activeTab, isCVUploadProcessCompleted, trackEvent, uploadFiles?.length]);

  const handleOtherOperation = React.useCallback(() => {
    confirmModalToggler(false);
    setCVUploadedProcessComplete(false);

    handleCVUploadProcess();
    handleSpontaneousApply();
    handleActiveTabEvents();
  }, [
    confirmModalToggler,
    handleActiveTabEvents,
    handleCVUploadProcess,
    handleSpontaneousApply,
    setCVUploadedProcessComplete,
  ]);

  const onHandleClose = React.useCallback(() => {
    closeEntryPoint();
    setTimeout(() => {
      handleOtherOperation();
    }, 250);
  }, [closeEntryPoint, handleOtherOperation]);

  const footerEl = React.useMemo(() => {
    if (
      showConfirmModal ||
      (errors?.statusCode && errors?.statusCode >= 400 && errors?.statusCode <= 409) ||
      (spontaneousApplyData?.isSpontaneousEntry && spontaneousApplyData?.showCoverLetter) ||
      uploadCVLoading ||
      isCVUploadProcessCompleted ||
      (isAuthenticated && jobPositionApplyStatus) ||
      activeTab === 'application-wizard' ||
      activeTab === 'upload-cv'
    )
      return undefined;
    return <EntryProcessButtons />;
  }, [
    activeTab,
    errors?.statusCode,
    isAuthenticated,
    isCVUploadProcessCompleted,
    jobPositionApplyStatus,
    showConfirmModal,
    spontaneousApplyData?.isSpontaneousEntry,
    spontaneousApplyData?.showCoverLetter,
    uploadCVLoading,
  ]);

  const isEitherShowCoverOrApplicationWizard = React.useMemo(
    () => spontaneousApplyData?.showCoverLetter || spontaneousApplyData?.entryType === 'application-wizard',
    [spontaneousApplyData?.entryType, spontaneousApplyData?.showCoverLetter]
  );
  useEntryPointModal();

  return (
    <BaseModal
      modalStyles={[
        tw`min-h-[20rem] max-w-full w-full md:(max-w-lg min-w-104)`,
        spontaneousApplyData?.showCoverLetter ? tw`p-6 md:max-w-2xl` : tw``,
        spontaneousApplyData?.entryType === 'application-wizard' ? tw`p-6 md:max-w-fit` : tw``,
      ]}
      open={showEntryPoint}
      onClose={onHandleClose}
      closeIcon={<ModalWrapperCloseIcon />}
      showMobileGestures
      hideHeader={isEitherShowCoverOrApplicationWizard}
      noPadding={isEitherShowCoverOrApplicationWizard}
      footer={footerEl}
    >
      <div tw="mx-auto">
        <ApplicationModalBody />
      </div>
    </BaseModal>
  );
};

export default WorkerEntryPointModal;
const ApplicationModalBody = () => {
  const showConfirmModal = useAtomValue(showConfirmModalAtom);
  return showConfirmModal ? <JobApplyConfirmation /> : <EntryPointModalBody />;
};

export const useEntryPointModal = () => {
  const [, toggleEntryPoint] = useAtom(entryPointModalVisibleAtom);

  const closeEntryPoint = React.useCallback(() => toggleEntryPoint(false), [toggleEntryPoint]);
  const openEntryPoint = React.useCallback(() => toggleEntryPoint(true), [toggleEntryPoint]);

  const [, setJobPositionApplyStatus] = useAtom(jobPositionApplyStatusAtom);

  const router = useRouter();
  const { query: queryParams } = router;
  const openWorkerEntryPoint = queryParams['apply'] as string;

  const workerId = useAtomValue(workerIdAtom);
  const [isCVUploadProcessCompleted, setCVUploadedProcessComplete] = useAtom(isCVUploadProcessCompletedAtom);
  const confirmModalToggler = useSetAtom(showConfirmModalAtom);
  const [spontaneousApplyData, setSpontaneousApplyData] = useAtom(spontaneousApplyDataAtom);
  const setErrors = useSetAtom(uploadFilesAtom);
  const stateResetFn = useSetAtom(resetWorkerEntryStates);

  const jobPositionId = useJobPositionId();
  const { user } = useAuth();
  const { applyJobPosition } = useApplyJob({
    callUserAuth: !spontaneousApplyData?.isSpontaneousEntry,
    referralCode: router?.query?.['scout'],
    onUnAuthenticated: () => {
      stateResetFn();
      setCVUploadedProcessComplete(true);
      if (spontaneousApplyData?.isSpontaneousEntry) setSpontaneousApplyData((p) => ({ ...p, showCoverLetter: true }));
    },
    onAuthenticated: () => {
      if (openEntryPoint) openEntryPoint();
      if (setJobPositionApplyStatus) setJobPositionApplyStatus(true);
      if (user?.type === 'lead') setCVUploadedProcessComplete(true);
    },
    errorCallBack: (e: unknown) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e) setErrors({ statusCode: e?.['code'], code: e?.['code'] } as IErrorProps);
    },
  });

  React.useEffect(() => {
    if (openWorkerEntryPoint === 'true') {
      confirmModalToggler(true);
      if (openEntryPoint) openEntryPoint();
    }
    if (openWorkerEntryPoint === 'false' && closeEntryPoint) closeEntryPoint();
  }, [openWorkerEntryPoint, openEntryPoint, closeEntryPoint, confirmModalToggler]);

  React.useEffect(() => {
    if (workerId && isCVUploadProcessCompleted)
      applyJobPosition({
        workerIdArg: workerId,
        ...(spontaneousApplyData?.isSpontaneousEntry
          ? { restaurantId: spontaneousApplyData.restaurantID }
          : { jobPositionId }),
        source: 'cv',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerId, isCVUploadProcessCompleted, spontaneousApplyData?.isSpontaneousEntry, setSpontaneousApplyData]);
};

export const useSaveResearchModalForJobPosition = () => {
  const [, setSaveResearchModal] = useAtom(saveResearchModalV2Atom);
  // const setAreaSelection = useSetAtom(areaSelectionAtom);
  const router = useRouter();

  const openSaveResearchModal = React.useCallback(() => {
    if (router.pathname.includes('/posizione')) {
      setTimeout(() => {
        setSaveResearchModal(true);
        // setAreaSelection('regional');
      }, 200);
    }
  }, [router.pathname, setSaveResearchModal]);

  return { openSaveResearchModal };
};
