/* eslint-disable no-console */
import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { LinkComponentType, MainContext, NavigationContextProvider } from '@restworld/utils-common';

import { AuthFlowModals } from './auth-flow-modals';
import { GlobalJobPositionPreferencesModal } from './global-job-position-preferences-modal';

export const MainContextWrapper = ({ children }: { children: React.ReactNode }) => (
  <NavigationProviderWrapper>
    <MainContext>
      {children}
      <AuthFlowModals />
      <GlobalJobPositionPreferencesModal />
    </MainContext>
  </NavigationProviderWrapper>
);
const NavigationProviderWrapper = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  return (
    <NavigationContextProvider linkComponent={Link as LinkComponentType} router={router}>
      {children}
    </NavigationContextProvider>
  );
};
