import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { ArrowUpLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import tw from 'twin.macro';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { GoogleAutoCompleteInput } from '@restworld/ui-ds';
import { UnderlineMarkerIcon, defaultItalyCoordinates } from '@restworld/utils-common';

import { getMapRefAtom } from '../job-explorer-map.atoms';

type LocalityData = Partial<{
  type: string;
  key: string;
  value: {
    lng: number;
    lat: number;
  };
  label: string;
}>;
const defaultInputProps = {
  bordercolor: 'gray' as const,
  type: 'text',
  id: '',
  icon: UnderlineMarkerIcon,
};
const locationInputProps = {
  ...defaultInputProps,
};
const commonWidthStyle = `w-[16rem] md:w-[20rem] lg:w-[24rem] xl:w-[28rem] 2xl:w-[32rem]`;
const storage = createJSONStorage(
  // getStringStorage
  () => sessionStorage // or sessionStorage, asyncStorage or alike
);
export const localityAddressAtom = atomWithStorage<LocalityData>('locality_address', {}, storage);

export const AddressSearchBar = () => {
  const { t } = useTranslation();
  const [localityAddress, setLocalityAddress] = useAtom(localityAddressAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  const setLocalityData = useSetAtom(localityAddressAtom);
  const routerProps = useRouter();

  const handleSelect = useCallback(
    ({ description }: { description: string }) => {
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        const locationData = {
          type: 'set_selected_location',
          key: 'selected_location',
          value: { lng, lat },
          label: description,
        };
        setLocalityData(locationData as LocalityData);
        if (routerProps.asPath !== '/cerco-lavoro') {
          routerProps.push({
            pathname: '/cerco-lavoro',
            query: {
              ...routerProps.query,
              map_filters: JSON.stringify({
                longitude: lng,
                latitude: lat,
                zoom: 13,
              }),
            },
          });
        }
        setTimeout(() => {
          mapRef.current?.flyTo({
            center: [lng, lat],
            duration: 1200,
            essential: true,
            zoom: 7,
          });
        }, 250);
      });
    },
    [mapRef, routerProps, setLocalityData]
  );

  const resetInput = useCallback(() => {
    setLocalityAddress({});
  }, [setLocalityAddress]);

  const handleClose = useCallback(() => {
    resetInput();
    mapRef.current?.flyTo({
      center: [defaultItalyCoordinates[0], defaultItalyCoordinates[1]],
      duration: 1200,
      essential: true,
      zoom: 5,
    });
  }, [mapRef, resetInput]);

  useEffect(() => {
    mapRef.current?.on('dragend', () => resetInput());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef?.current, resetInput]);

  useEffect(() => {
    routerProps.events.on('routeChangeStart', (currURL) => {
      if (!currURL.includes('/cerco-lavoro')) resetInput();
    });
  }, [resetInput, routerProps.events]);

  return (
    <div tw="relative">
      {localityAddress?.label ? (
        <div
          className={`flex mx-auto justify-start items-center gap-2 cursor-pointer border shadow-[0px 0px 6px 0px #693EB566] px-2.5 py-4 rounded-2xl ${commonWidthStyle}`}
        >
          <UnderlineMarkerIcon />
          <div onClick={(e) => e.stopPropagation()} tw="flex justify-between items-center w-[86%] md:w-full">
            <span tw=" text-left text-base font-medium truncate w-[80%] md:w-full text-primary-800">
              {localityAddress?.label}
            </span>
            <div tw="cursor-pointer">
              <XMarkIcon onClick={handleClose} className="w-5 h-5 text-black" />
            </div>
          </div>
        </div>
      ) : (
        <GoogleAutoCompleteInput
          title={t('common:search_suggestions')}
          formId="search-address-input"
          key="search-address-input"
          onSuggestionSelect={({ description }) => {
            handleSelect({ description });
          }}
          placeholder={t('common:where_you_are_looking_for')}
          inputProps={{
            bordercolor: 'primary',
            wrapperStyle: tw`${commonWidthStyle}`,
            hasLeftPadding: true,
            autoFocus: false,
            ...locationInputProps,
          }}
          dropdownItemProps={{
            suffixIcon: <ArrowUpLeftIcon className="w-4 h-4 text-gray-500" />,
          }}
        />
      )}
    </div>
  );
};
