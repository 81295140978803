import { useMemo } from 'react';
import 'twin.macro';
import { useAtomValue } from 'jotai';

import { JobContract } from '@restworld/ui-ds';
import { Variable } from '@restworld/utility-types';
import { listIconType } from '@restworld/utils-common';

import { jobPositionApiDataAtom } from '../job-position.atoms';

const ContractBlock = (): JSX.Element | null => {
  const { contractContent, jobBenefitsList } = useJobContract();
  return contractContent?.length ? (
    <>
      <JobContract contractContent={contractContent} jobBenefits={jobBenefitsList} />
      <hr tw="text-gray-300" />
    </>
  ) : null;
};

export const ContractBlockDesktop = () => {
  const contractBlockOutput = ContractBlock();
  if (!contractBlockOutput) return null;
  return (
    <>
      <div tw="hidden lg:block">
        <ContractBlock />
      </div>
    </>
  );
};

export const ContractBlockMobile = () => {
  const contractBlockOutput = ContractBlock();

  if (!contractBlockOutput) return null;
  return (
    <>
      <div tw="lg:hidden">
        <ContractBlock />
      </div>
    </>
  );
};

const useJobContract = () => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);

  const jobBenefitsList = useMemo(
    () =>
      jobPositionApiData?.benefits?.map(({ benefit_var: { label, icon, key } }: { benefit_var: Variable }) => ({
        label,
        icon: icon as listIconType,
        key,
      })),
    [jobPositionApiData?.benefits]
  );
  return {
    jobBenefitsList,
    contractContent: jobPositionApiData?.job_contract_description,
  };
};
