import { CheckCircleIcon, HomeIcon } from '@heroicons/react/24/outline';
import {
  BaseModal,
  Button,
  FormLabelWrapper,
  GoogleAutoCompleteInput,
  HelperTextRElement,
  Paragraph,
  Title,
} from '@restworld/ui-ds';
import { ModalWrapperCloseIcon, useAddressAdditionalFields } from '@restworld/utils-common';
import { setWorkerAtom, workerAtom } from '../../../profile/global-state';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Marker } from 'react-map-gl';
import tw, { styled, theme } from 'twin.macro';
import React from 'react';
import { useWorkersService } from '@restworld/data-services';
import { Address, Worker } from '@restworld/utility-types';
import { useAuth } from '@restworld/feature-authentication';
import { useTranslation } from 'next-i18next';

export type HomeModalContentType = 'view' | 'success' | 'edit';

const showWorkerHomeLocationAtom = atom<boolean>(false);

const setShowWorkerHomeLocationAtom = atom(null, (_, set, show: boolean) => {
  set(showWorkerHomeLocationAtom, show);
});

const workerLocationAtom = atom((get) => {
  const worker = get(workerAtom);
  return {
    latitude: worker?.data?.address_lat,
    longitude: worker?.data?.address_lon,
  };
});

const workerAddressAtom = atom((get) => {
  const worker = get(workerAtom);
  return worker?.data?.address;
});

export type AddressForm = {
  address: Address;
};

export const WorkerHomeLocation = memo(() => {
  const setShow = useSetAtom(setShowWorkerHomeLocationAtom);
  const location = useAtomValue(workerLocationAtom);

  if (!location.latitude || !location.longitude) {
    return null;
  }

  return (
    <Marker
      latitude={location.latitude}
      longitude={location.longitude}
      onClick={(event) => {
        setShow(true);
      }}
      scale={100}
    >
      <StyledMarker>
        <HomeIcon tw="text-black w-6 h-6" />
      </StyledMarker>
    </Marker>
  );
});

export const WorkerHomeLocationWrapper = memo(() => {
  const [contentType, setContentType] = useState<HomeModalContentType>('view');
  const show = useAtomValue(showWorkerHomeLocationAtom);
  const setShow = useSetAtom(setShowWorkerHomeLocationAtom);
  const methods = useForm<AddressForm>();

  const onClose = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setContentType('view');
    }, 300);
  }, [setShow]);

  const onHandleClickPrimaryAction = useCallback(() => {
    if (contentType === 'view') {
      setContentType('edit');
    }
    if (contentType === 'edit') {
      setContentType('success');
    }
    if (contentType === 'success') {
      onClose();
    }
  }, [contentType, onClose]);

  const onHandleClickSecondaryAction = useCallback(() => {
    onClose();
  }, [onClose]);
  const footer = (
    <FooterBlock
      contentType={contentType}
      onClickPrimaryAction={onHandleClickPrimaryAction}
      onClickSecondaryAction={onHandleClickSecondaryAction}
    />
  );

  const titleEl = useMemo(() => <ModalTitle contentType={contentType} />, [contentType]);
  const addressEl = useMemo(() => <AddressBlock contentType={contentType} />, [contentType]);
  const descriptionEl = useMemo(() => <DescriptionTextBlock contentType={contentType} />, [contentType]);
  const addressBlockEl = useMemo(() => {
    if (contentType === 'edit') {
      return <AddressEditBlock />;
    }
    return null;
  }, [contentType]);

  return (
    <FormProvider {...methods}>
      <BaseModal
        {...{
          onClose: onClose,
          showMobileGestures: true,
          open: show,
          modalStyles: [tw`w-full md:(max-w-[26rem] min-w-[22rem]) min-h-[20rem]`],
          closeIcon: <ModalWrapperCloseIcon />,
          title: titleEl,
          footer: footer,
        }}
      >
        <div tw="flex flex-col gap-4 items-center justify-start w-full pt-2 pb-4">
          {descriptionEl}
          {addressEl}
          {addressBlockEl}
        </div>
      </BaseModal>
    </FormProvider>
  );
});

export const StyledMarker = styled.button`
  ${tw`flex flex-col items-center justify-center shadow-lg`}
  ${tw`w-[32px] h-[35px] rounded-full bg-white border border-gray-300`}
  &::after {
    ${tw`content-[''] absolute -bottom-[10px] left-1/2 -ml-[5px] border-[6px] border-solid`};
    border-color: ${theme`colors.white`} transparent transparent transparent;
  }
`;

export const ModalTitle = ({ contentType }: { contentType: HomeModalContentType }) => {
  const { t } = useTranslation('job-explorer-page');
  const titleIcon = useMemo(() => {
    if (contentType === 'success') {
      return <CheckCircleIcon tw="w-8 h-8 text-green-600" />;
    } else if (contentType === 'edit') {
      return null;
    } else {
      return <HomeIcon tw="w-8 h-8 text-primary-800" />;
    }
  }, [contentType]);

  const titleContent = useMemo(() => {
    if (contentType === 'success') {
      return t('updated_home_address');
    } else if (contentType === 'edit') {
      return t('this_is_the_location_of_your_home');
    } else {
      return t('change_home_address');
    }
  }, [contentType, t]);
  return (
    <div tw="flex flex-col gap-2 items-center justify-start w-full">
      {titleIcon}
      <Title fontWeight="semibold" variant="h3" tw="text-black" twStyle={tw`text-center`}>
        {titleContent}
      </Title>
    </div>
  );
};

export const DescriptionTextBlock = ({ contentType }: { contentType: HomeModalContentType }) => {
  const { t } = useTranslation('job-explorer-page');
  const descriptionContent = useMemo(() => {
    if (contentType === 'view') {
      return t('update_your_address_from_your_profile');
    } else if (contentType === 'edit') {
      return t('address_will_update_in_the_profile');
    } else {
      return null;
    }
  }, [t, contentType]);

  return (
    <Paragraph variant="body" align="center">
      {descriptionContent}
    </Paragraph>
  );
};

export const AddressBlock = ({ contentType }: { contentType: HomeModalContentType }) => {
  const { t } = useTranslation('job-explorer-page');
  const address = useAtomValue(workerAddressAtom);
  if (contentType === 'success') return null;
  return (
    <div tw="flex flex-col gap-1 items-start justify-start w-full">
      <Paragraph standardVariant="label-m" font="medium">
        {t('living_address')}
      </Paragraph>
      <Title variant="h6" textColor="gray-600" icon="HomeIconGray" fontWeight="normal">
        {address}
      </Title>
    </div>
  );
};

export const FooterBlock = memo(
  ({
    contentType,
    onClickPrimaryAction,
    onClickSecondaryAction,
  }: {
    contentType: HomeModalContentType;
    onClickPrimaryAction: () => void;
    onClickSecondaryAction: () => void;
  }) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    const workerService = useWorkersService();
    const onSetWorkerData = useSetAtom(setWorkerAtom);
    const auth = useAuth();
    const onSubmit = useCallback(
      async (data: { address: Partial<Worker> }) => {
        const response = await workerService.updateWorker({
          workerId: auth.user?.id,
          params: {
            ...data.address,
          },
        });
        onSetWorkerData({ ...response.data });
        onClickPrimaryAction();
      },
      [auth.user?.id, onClickPrimaryAction, onSetWorkerData, workerService]
    );
    const primaryActionText = useMemo(() => {
      if (contentType === 'view') return t('job-explorer-page:change_home_address');
      else if (contentType === 'edit') return t('job-explorer-page:save_new_address');
      else return t('common:close');
    }, [t, contentType]);

    const secondaryActionText = useMemo(() => {
      if (contentType === 'view') return t('job-explorer-page:all_clear');
      else if (contentType === 'edit') return t('common:cancel');
      else return null;
    }, [t, contentType]);
    return (
      <div tw="flex flex-col w-full gap-2 items-center justify-start">
        {contentType === 'edit' && primaryActionText && (
          <form tw="w-full" onSubmit={methods.handleSubmit(onSubmit)}>
            <Button fullwidth type="submit" loading={methods.formState.isSubmitting}>
              {primaryActionText}
            </Button>
          </form>
        )}
        {primaryActionText && contentType !== 'edit' && (
          <Button fullwidth onClick={onClickPrimaryAction}>
            {primaryActionText}
          </Button>
        )}
        {secondaryActionText && (
          <Button variant="secondary-outline" fullwidth onClick={onClickSecondaryAction}>
            {secondaryActionText}
          </Button>
        )}
      </div>
    );
  }
);

export const AddressEditBlock = memo(() => {
  const [address, setAddress] = useState('');
  const methods = useFormContext();
  const errors = methods.formState.errors;
  const addressFields = useAddressAdditionalFields({ addressString: address });
  const setValue = methods.setValue;
  const { t } = useTranslation();
  React.useEffect(() => {
    if (addressFields && Object.keys(addressFields).length) {
      setValue('address', {
        address: addressFields.address,
        country: addressFields.country,
        address_lat: addressFields.address_lat,
        address_lon: addressFields.address_lon,
        administrative_area_level_1: addressFields.administrative_area_level_1,
        administrative_area_level_2: addressFields.administrative_area_level_2,
        postal_code: addressFields.postal_code,
        locality: addressFields.locality,
      });
    }
  }, [addressFields, setValue]);

  return (
    <FormLabelWrapper id={'address'} label={t('job-explorer-page:new_address')} isFullWidth>
      <GoogleAutoCompleteInput
        formId="address"
        placeholder={t('job-explorer-page:enter_address')}
        required
        onSuggestionSelect={({ description }) => {
          setAddress(description);
        }}
        control={methods.control}
        key="address"
      />
      {Object.keys(errors).includes('address') && (
        <HelperTextRElement helperText={t('job-explorer-page:the_address_is_mandatory')} />
      )}
    </FormLabelWrapper>
  );
});
