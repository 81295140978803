import { useAtom } from 'jotai';
import tw, { styled, theme } from 'twin.macro';

import { OfficeBuildingIcon } from '@restworld/utils-common';

import { isRestaurantsExplorerAtom } from '../job-explorer-restaurant-explorer.atoms';

export const Pin = ({
  label,
  isActive,
  subLabel,
  isApproximate,
}: {
  label: string;
  isActive?: boolean;
  subLabel?: string;
  isApproximate?: boolean;
}) => {
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  return (
    <PinLabelWrapper
      isRestaurantsExplorer={isRestaurantsExplorer}
      hasPrefixIcon={isRestaurantsExplorer}
      isActive={isActive}
      isApproximate={isApproximate}
    >
      {isRestaurantsExplorer ? (
        <OfficeBuildingIcon
          {...{
            size: 20,
            stroke: isActive ? theme`colors.white` : theme`colors.black`,
          }}
        />
      ) : null}
      <PinLabel isActive={isActive}>{label}</PinLabel>
      {!isRestaurantsExplorer && <PinSubLabel>{subLabel}</PinSubLabel>}
    </PinLabelWrapper>
  );
};

const PinLabelWrapper = styled.div<{
  isActive?: boolean;
  hasPrefixIcon?: boolean;
  isRestaurantsExplorer?: boolean;
  isApproximate?: boolean;
}>`
  ${tw`relative z-[10]`}
  ${tw`max-w-[6.875rem]`}
  ${tw`bg-white border-0 border-gray-200 shadow-lg rounded-[8px] text-center p-1 cursor-pointer`}
  ${({ isActive }) => (isActive ? tw`bg-primary-800 shadow shadow-primary-800/30` : tw``)}
  ${({ hasPrefixIcon }) => (hasPrefixIcon ? tw`flex flex-row items-center justify-center gap-1` : tw``)}
  ${({ isRestaurantsExplorer }) => (isRestaurantsExplorer ? tw`rounded-full px-2.5 py-1.5 max-w-xs` : tw``)}
  ${({ isActive }) => (isActive ? tw`transition-all duration-300` : tw``)}
  // for arrow under the pin
  &::after {
    ${tw`content-[''] absolute -bottom-5 left-1/2 -ml-2.5 border-[10px] border-solid`};
    border-color: ${({ isActive }) => (isActive ? theme`colors.primary.800` : theme`colors.white`)} transparent
      transparent transparent;
  }
  // for approximate don't show arrow under the pin
  ${({ isApproximate }) => (isApproximate ? tw`after:hidden` : tw``)}
`;
const PinLabel = styled.div(({ isActive }: { isActive?: boolean }) => [
  tw`text-base md:text-sm font-semibold leading-5.25`,
  isActive ? tw`!text-white` : tw`text-black`,
]);
const PinSubLabel = tw.div`text-sm md:text-xs font-normal leading-4.5 rounded-[4px] py-[2px] px-[4px] bg-primary-100 text-black`;
