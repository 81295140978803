import { useEffect } from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useAtomValue, useSetAtom } from 'jotai';

import { ModalWrapperCloseIcon, useModal } from '@restworld/utils-common';
import { Title, Button, BaseModal, LinkBase } from '@restworld/ui-ds';

import {
  isJobApplicationDiscardedAtom,
  jobPositionApiDataAtom,
  jobPositionApplyStatusAtom,
} from '../job-position/job-position.atoms';
import { jobApplicationModalAlreadyDisplayedAtom } from '../worker-entry-point/worker-entry-point.atoms';
import { workerManagedUsingKlaaryoModalFlagAtom } from '../worker-managed-using-klaaryo';
import { useJobPositionId } from '../../hooks';
import { useSaveResearchModalForJobPosition } from '../worker-entry-point/worker-entry-point-modal';

export function JobAlreadyApplied() {
  const { show, open, close } = useModal();
  const jobPositionApplyStatus = useAtomValue(jobPositionApplyStatusAtom);
  const jobApplicationModalAlreadyDisplayed = useAtomValue(jobApplicationModalAlreadyDisplayedAtom);
  const isJobApplicationDiscarded = useAtomValue(isJobApplicationDiscardedAtom);

  const { t } = useTranslation();
  const { manage_using_klaaryo } = useAtomValue(jobPositionApiDataAtom);
  const setWorkerManagedUsingKlaaryoModalFlagAtom = useSetAtom(workerManagedUsingKlaaryoModalFlagAtom);

  useEffect(() => {
    if (jobPositionApplyStatus && !jobApplicationModalAlreadyDisplayed) {
      if (manage_using_klaaryo && !isJobApplicationDiscarded) {
        setWorkerManagedUsingKlaaryoModalFlagAtom(true);
        return;
      }
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionApplyStatus, jobApplicationModalAlreadyDisplayed]);

  return (
    <>
      <BaseModal
        {...{
          onClose: close,
          showMobileGestures: true,
          open: show,
          modalStyles: [tw`w-full md:(max-w-[26rem] min-w-[22rem])`],
          closeIcon: <ModalWrapperCloseIcon />,
          title: <ModalTitle />,
          footer: <ModalFooter close={close} />,
        }}
      >
        <Wrapper tw="pt-4 pb-8">
          {isJobApplicationDiscarded ? (
            <Title variant="h6-large" textColor="neutral-200" isCenter>
              {t('job-position-page:application_not_suitable_for_this_offer')}
            </Title>
          ) : (
            <Title variant="h6-large" textColor="gray-500" isCenter>
              {t('common:confirm_if_everything_updated')}
            </Title>
          )}
        </Wrapper>
      </BaseModal>
    </>
  );
}
export default JobAlreadyApplied;

const ModalTitle = () => {
  const { t } = useTranslation();

  const isJobApplicationDiscarded = useAtomValue(isJobApplicationDiscardedAtom);

  return isJobApplicationDiscarded ? (
    <div tw="flex flex-col gap-2 items-center justify-center">
      <InformationCircleIcon tw="w-5 h-5 text-purple-700" />
      <Title
        isCenter
        fontSize="superLarge"
        content={t('job-position-page:application_was_not_eligible_for_this_offer')}
        textColor="neutral-700"
        fontWeight="bold"
        twStyle={tw`leading-[29.05px] px-3`}
      />
    </div>
  ) : (
    <div tw="flex flex-col gap-2 items-center justify-center">
      <CheckCircleIcon tw="w-13 h-13 text-secondary-900" />
      <Title
        isCenter
        fontSize="large"
        content={t('common:application_delivered')}
        textColor="black"
        fontWeight="semibold"
        variant="h3-large"
        twStyle={tw`leading-7`}
      />
    </div>
  );
};
const ModalFooter = ({ close }: { close?: () => void }) => {
  const { t } = useTranslation();
  const { openSaveResearchModal } = useSaveResearchModalForJobPosition();

  const isJobApplicationDiscarded = useAtomValue(isJobApplicationDiscardedAtom);
  const jobPositionId = useJobPositionId();

  return isJobApplicationDiscarded ? (
    <div tw="flex flex-col items-center w-full gap-2">
      <LinkBase link={`/cerco-lavoro`} newTab={false} twStyle={tw`w-full`}>
        <Button fullwidth title={t('job-position-page:see_other_job_positions')} variant="normal" />
      </LinkBase>
      <LinkBase link={`/candidature/${jobPositionId}`} newTab={false} twStyle={tw`w-full`}>
        <Button
          fullwidth
          title={t('job-position-page:discover_how_it_went')}
          variant="secondary-outline"
          icon="FaceFrownIcon"
          fontColor="purple-500"
        />
      </LinkBase>
    </div>
  ) : (
    <div tw="flex flex-col items-center w-full gap-2">
      <Button
        onClick={() => {
          openSaveResearchModal();
          close();
        }}
        fullwidth
      >
        {t('common:receive_other_offers_similar_to_this')}
      </Button>
      {/* <LinkBase link={'/profilo'} newTab={false} twStyle={tw`w-full`}>
        <Button fullwidth title={t('common:check_your_profile')} variant="normal" />
      </LinkBase>
      <Button fullwidth title={t('common:close')} variant="secondary-outline" onClick={close} /> */}
    </div>
  );
};
const Wrapper = tw.div`flex flex-col items-center`;
