globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { differenceInMonths, formatDistanceToNow, fromUnixTime } from 'date-fns';
import formatDistance from 'date-fns/formatDistance';
import it from 'date-fns/locale/it';
export var getDateDifference = function getDateDifference(dateAt) {
  if (!dateAt) return '';
  var timestamp = new Date(dateAt);
  var distance = formatDistance(Date.now(), timestamp, {
    addSuffix: false,
    locale: it
  });
  return distance;
};
export var getMonthDifference = function getMonthDifference(dateAt) {
  var monthDiff = differenceInMonths(new Date(), new Date(dateAt));
  return monthDiff;
};
export var getTimeDifference = function getTimeDifference(dateAt) {
  var timeDiff = formatDistanceToNow(fromUnixTime(Number(dateAt)), {
    locale: it
  });
  return timeDiff + ' fa';
};
export var weekDayMapObj = {
  0: 'Domenica',
  1: 'Lunedì',
  2: 'Martedì',
  3: 'Mercoledì',
  4: 'Giovedì',
  5: 'Venenerdì',
  6: 'Sabato'
};
export var formatTime = function formatTime(time) {
  // time format --> 1700
  var timeString = '';
  var splitTime = time.match(/.{1,2}/g); // --> splits string after 2 characters

  if (splitTime && splitTime.length) timeString = "".concat(splitTime.join(':'));
  return timeString; // result --> 17:00
};
export function getDurationSinceDate(dateStr) {
  var currentDate = new Date();
  var targetDate = new Date(!dateStr.includes('Z') ? dateStr + 'Z' : dateStr); // Add Z to ensure date is parsed as UTC  (ISO 8601 format)
  // Get time in UTC for both dates to avoid local timezone conversion issues

  var currentDateUTC = Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
  var targetDateUTC = Date.UTC(targetDate.getUTCFullYear(), targetDate.getUTCMonth(), targetDate.getUTCDate(), targetDate.getUTCHours(), targetDate.getUTCMinutes(), targetDate.getUTCSeconds());
  var totalMilliseconds = currentDateUTC - targetDateUTC;
  var totalMinutes = Math.floor(totalMilliseconds / (1000 * 60));
  if (totalMinutes < 60) return {
    duration: totalMinutes,
    unit: 'minutes'
  };
  var hours = Math.floor(totalMinutes / 60);
  if (hours < 24) return {
    duration: hours,
    unit: 'hours'
  };
  var days = Math.floor(hours / 24);
  if (days < 30) return {
    duration: days,
    unit: 'days'
  };
  var months = Math.floor(days / 30);
  return {
    duration: months,
    unit: 'months'
  };
}