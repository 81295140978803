import { useMemo, useState } from 'react';
import 'twin.macro';
import { FieldValues } from 'react-hook-form';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';

import { openInNewTab } from '@restworld/utils-common';
import { TextInput, Button, Wrapper, FormLabelWrapper } from '@restworld/ui-ds';

import { InformationHeadLine } from './error-info-headline';
import { ErrorWrapper, InvalidFormErrors, missingFieldFormLoadingAtom } from './cv-errors-field-reinput';
import { workerEntryPointErrorsAtom } from '../worker-entry-point.atoms';

export const ExistingEmailInput = ({
  formInputs,
  invalidFormErrors,
}: {
  formInputs?: JSX.Element[];
  invalidFormErrors?: FieldValues;
}) => {
  const errors = useAtomValue(workerEntryPointErrorsAtom);
  const loading = useAtomValue(missingFieldFormLoadingAtom);
  const existingEmail = errors?.email?.split(' ')[0];

  const missingFields = useMemo(
    () => (errors ? Object.keys(errors).filter((v) => !['user_id', 'statusCode', 'id'].includes(v)) : []),
    [errors]
  );
  const [isUpdateEmail, toggleUpdateEmail] = useState(false);
  const { t } = useTranslation();
  return (
    <ErrorWrapper>
      <InformationHeadLine
        title={
          isUpdateEmail
            ? missingFields.length > 1
              ? t('onboarding-page:update_some_information')
              : t('onboarding-page:update_emails_address')
            : t('onboarding-page:email_already_used_how_do_you_want_to_proceed')
        }
        showBackButton={isUpdateEmail}
        backButtonClickHandler={() => toggleUpdateEmail(false)}
      />
      {isUpdateEmail ? (
        <Wrapper>{formInputs}</Wrapper>
      ) : (
        <FormLabelWrapper label="Email" id="readOnly-email">
          <TextInput
            type="email"
            id="readOnly-email"
            placeholder={existingEmail ?? 'mario@example.com'}
            rightIcon={LockClosedIcon}
            required
            readOnly
            hasLeftPadding={false}
          />
        </FormLabelWrapper>
      )}
      <Wrapper>
        <InvalidFormErrors invalidFormErrors={invalidFormErrors} />
        {isUpdateEmail ? (
          <Button
            variant="normal"
            type="submit"
            loading={loading}
            title={
              missingFields.length > 1 ? t('common:update') : t('onboarding-page:use_this_email_for_the_new_profile')
            }
            fullwidth
          />
        ) : (
          <>
            <Button
              onClick={() => openInNewTab(`/login${existingEmail ? `?email=${existingEmail}` : ''}`)}
              variant="normal"
              type="button"
              title={t('onboarding-page:log_in_with_this_email')}
              fullwidth
            />
            <Button
              variant="secondary-outline"
              title={t('onboarding-page:update_email_and_create_a_new_profile')}
              onClick={() => toggleUpdateEmail(true)}
              fullwidth
            />
          </>
        )}
      </Wrapper>
    </ErrorWrapper>
  );
};
