import React from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
  ContentType as ModalContentType,
  activeContent as activeModalContent,
  closeAuthFlowModal,
  closeEmailLoginAssistanceModalAtom,
  closeEmailLoginModal,
  closeEmailTokenSentModal,
  closeSignupModal,
  isAuthFlowModalOpen,
  setAccountExistingEmail,
  setActiveContent as setActiveModalContent,
  updateEmailVerificationDetails,
  closePhoneNumberLoginModal,
  closeOtpVerificationModal,
  closeOtpVerificationAssistanceModal,
  openPhoneNumberLoginModal,
  setExistingPhoneNumber,
} from './auth-flow';
import { ModalWrapper } from '../components/common/modal-wrapper';
import { SignupComponentModal } from '../components/signup/signup-component-modal';
import { LoginByEmailComponentModal } from '../components/Login/login-by-email-modal';
import { LoginLinkSentModal } from '../components/Login/login-link-sent-modal';
import { AssistanceMagicLinkModal } from '../components/Login/assistance-magic-link-modal';
import { LoginByPhoneModal } from '../components/Login/login-by-phone-modal';
import { VerifyOTPModal } from '../components/Login/verify-otp-modal';
import { AssistanceOTPCodeModal } from '../components/Login/assistance-otp-code-modal';

export const AuthFlowModals = () => {
  const open = useAtomValue(isAuthFlowModalOpen);
  const [, closeModal] = useAtom(closeAuthFlowModal);
  const contentType = useAtomValue(activeModalContent);
  const closeEmailModal = useSetAtom(closeEmailLoginModal);
  const setExistingEmail = useSetAtom(setAccountExistingEmail);
  const closeEmailSentModal = useSetAtom(closeEmailTokenSentModal);
  const updateEmailDetails = useSetAtom(updateEmailVerificationDetails);
  const onCloseSignupModal = useSetAtom(closeSignupModal);
  const closeAssistanceModal = useSetAtom(closeEmailLoginAssistanceModalAtom);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const closeOtpModal = useSetAtom(closeOtpVerificationModal);
  const closePhoneNumberModal = useSetAtom(closePhoneNumberLoginModal);
  const closeOtpAssistanceModal = useSetAtom(closeOtpVerificationAssistanceModal);
  const onOpenPhoneNumberLoginModal = useSetAtom(openPhoneNumberLoginModal);
  const onSetExistingPhoneNumber = useSetAtom(setExistingPhoneNumber);

  function onClose() {
    switch (contentType) {
      case ModalContentType.SIGNUP:
        onCloseSignupModal();
        break;

      case ModalContentType.EMAIL_LOGIN:
        closeEmailModal();
        setExistingEmail(null);
        break;

      case ModalContentType.EMAIL_SENT:
        updateEmailDetails({
          email: '',
          waitTime: 0,
          tokenValidityTime: 0,
        });
        closeEmailSentModal();
        break;

      case ModalContentType.EMAIL_LOGIN_ASSISTANCE:
        closeAssistanceModal();
        break;

      case ModalContentType.PHONE_NUMBER_LOGIN:
        closePhoneNumberModal();
        onSetExistingPhoneNumber(null);
        break;

      case ModalContentType.OTP_VERIFICATION:
        closeOtpModal();
        break;

      case ModalContentType.OTP_VERIFICATION_ASSISTANCE:
        closeOtpAssistanceModal();
        break;

      default:
      /**Do nothing */
    }
    setActiveContent(ModalContentType.NO_CONTENT);
    closeModal();
  }

  function isBackButtonShown(): boolean {
    switch (contentType) {
      case ModalContentType.OTP_VERIFICATION:
        return true;
      default:
        return false;
    }
  }

  function onBack() {
    switch (contentType) {
      case ModalContentType.OTP_VERIFICATION:
        closeOtpModal();
        setActiveContent(ModalContentType.PHONE_NUMBER_LOGIN);
        onOpenPhoneNumberLoginModal();
        break;
      default:
      /**Do nothing */
    }
  }

  return (
    <React.Fragment>
      {open ? (
        <ModalWrapper {...{ open, onClose, showBackButton: isBackButtonShown(), onBack }}>
          <React.Fragment>
            <SignupComponentModal />
            <LoginByEmailComponentModal />
            <LoginLinkSentModal />
            <AssistanceMagicLinkModal />
            <LoginByPhoneModal />
            <VerifyOTPModal />
            <AssistanceOTPCodeModal />
          </React.Fragment>
        </ModalWrapper>
      ) : null}
    </React.Fragment>
  );
};
