globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useEffect } from 'react';
import { useScript } from 'usehooks-ts';
export var googleMapKey = process.env['STORYBOOK_GMAPS_API'] || process.env['NEXT_PUBLIC_GMAPS_API'];
export var useGmapsScript = function useGmapsScript(_ref) {
  var libraries = _ref.libraries;
  var script = "https://maps.googleapis.com/maps/api/js?key=".concat(googleMapKey, "&libraries=").concat(libraries);
  var gmapsScript = useScript(script);
  useEffect(function () {
    if (gmapsScript === 'error') {
      // eslint-disable-next-line no-console
      console.error('Error loading GMAPS Script');
    }
  }, [gmapsScript]);
  return gmapsScript === 'ready';
};