import tw from 'twin.macro';
import { Controller, useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { Button, Checkbox, GoogleAutoCompleteInput, Title } from '@restworld/ui-ds';
import { AddressFieldType, useWorkersService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';
import { useAddressAdditionalFields } from '@restworld/utils-common';

import { SaveResearchFormFields } from '../job-explorer-save-research-v2';

interface IAddressEditViewProps {
  callback?: () => void;
}

export const AddressEditView = ({ callback }: IAddressEditViewProps) => {
  const { control, getValues, setValue } = useFormContext<SaveResearchFormFields>();
  const workerService = useWorkersService();

  const { user } = useAuth();
  // const [modifyUserLocality, setModifyUserLocality] = useState(false)
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<string | ''>('');
  const [addressFieldsTempState, setAddressFields] = useState<AddressFieldType | null>(null);

  const addressFields = useAddressAdditionalFields({ addressString: address });

  const handleModifyAddress = useCallback(async () => {
    if (getValues('modify_user_locality')) {
      try {
        setValue('address', addressFieldsTempState);
        setValue('address_lon', addressFieldsTempState?.address_lon);
        setValue('address_lat', addressFieldsTempState?.address_lat);
        setAddressFields(null);
        setAddress('');
        setLoading(true);
        workerService.updateWorker({
          workerId: user?.id,
          params: {
            ...addressFieldsTempState,
          },
        });
        callback();
      } catch (error) {
        console.error(error, `Error updating worker address`);
      } finally {
        setValue('modify_user_locality', false);
        setLoading(false);
      }
    } else {
      setValue('address', addressFieldsTempState);
      setValue('address_lon', addressFieldsTempState?.address_lon);
      setValue('address_lat', addressFieldsTempState?.address_lat);
      setAddressFields(null);
      setAddress(null);
      callback();
    }
  }, [addressFieldsTempState, callback, getValues, setValue, user?.id, workerService]);

  useEffect(() => {
    if (addressFields && Object.keys(addressFields).length) setAddressFields(addressFields);
  }, [addressFields, setValue]);
  const { t } = useTranslation();
  return (
    <div tw="relative">
      <div tw="flex flex-col gap-8 pb-6 max-h-[calc(100svh - 16rem)] md:max-w-sm md:(min-h-[calc(100svh - 24rem)]) overflow-y-auto px-2">
        <div tw="flex flex-col gap-2">
          <Title
            content={t('job-explorer-page:change_search_area')}
            fontWeight="semibold"
            fontSize="superLarge"
            twStyle={tw`leading-[28.8px]`}
          />
          <Title
            content={t('job-explorer-page:the_saved_search_area_address_matches_your_last_search')}
            fontWeight="normal"
            fontSize="base"
            twStyle={tw`leading-[24px]`}
          />
        </div>
        <div tw="flex gap-6 flex-col">
          <div tw="flex flex-col gap-1">
            <Title content={t('job-explorer-page:reference_location')} fontSize="base" />
            <Title content={getValues('address.address')} fontSize="base" textColor="gray-600" icon="HomeIcon" />
          </div>
          <div tw="flex flex-col gap-2">
            <Title
              content={t('job-explorer-page:new_reference_location')}
              fontSize="base"
              fontWeight="normal"
              twStyle={tw`leading-[24px]`}
            />
            <GoogleAutoCompleteInput
              formId="research-address-input"
              key="research-address-input"
              placeholder={t('job-explorer-page:enter_address_or_location')}
              formValue={address}
              onSuggestionSelect={({ description }) => {
                setAddress(description);
              }}
              control={control}
              autoFocus
            />
            <Title
              content={t('job-explorer-page:will_use_this_information_to_define_the_search_area')}
              fontSize="small"
              fontWeight="normal"
              twStyle={tw`leading-[21px]`}
              textColor="gray-500"
            />
          </div>
          <Controller
            name="modify_user_locality"
            control={control}
            render={({ field }) => (
              <div tw="md:pt-20">
                <Checkbox
                  title={
                    <Title
                      content={t('job-explorer-page:use_the_new_address_in_your_profile')}
                      fontWeight="normal"
                      fontSize="small"
                      twStyle={tw`leading-[21px]`}
                    />
                  }
                  variant="box"
                  isChecked={Boolean(field.value)}
                  value={field.value}
                  disabled={!addressFieldsTempState}
                  {...field}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div tw="flex gap-2 flex-col w-full fixed bottom-2 px-6 left-0 md:(relative px-0)">
        <Button
          variant="normal"
          title={t('common:save_changes')}
          fullwidth
          onClick={handleModifyAddress}
          loading={loading}
        />
        <Button
          variant="secondary-outline"
          title={t('common:cancel')}
          fullwidth
          onClick={() => {
            setValue('modify_user_locality', false);
            setAddressFields(null);
            setAddress(null);
            callback();
          }}
        />
      </div>
    </div>
  );
};

export default AddressEditView;
