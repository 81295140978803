import { useState, useEffect, useMemo, useCallback, ChangeEvent } from 'react';
import 'twin.macro';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import tw from 'twin.macro';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { OptionsType, useAddressAdditionalFields } from '@restworld/utils-common';
import { Button, CheckboxGroup, GoogleAutoCompleteInput, InputSlider, Title } from '@restworld/ui-ds';

import ChipCheckboxFormField from './chip-checkbox-form-field';
import { jobTypeItems, radiusItems } from './form-fields.constants';
import { domainInterests } from '../../job-explorer-filters';
import { FilterContextValuesType } from '../../job-explorer.types';
import { OccupationSelect } from '../../job-explorer-map/job-explorer-save-research-v2/job-explorer-save-research-v2.components';
import { domainInterestAtom } from '../../job-explorer-server.atoms';

export const ContractType = () => {
  const { t } = useTranslation('common');
  const quickFilterItemsCopy = useMemo(
    () =>
      [
        {
          key: 'seasonal_accommodation',
          id: 'seasonal_accommodation',
          label: t('seasonal_accommodation'),
          icon: 'StagionaleIcon',
          returnKey: 'benefits',
        },
        {
          key: 'fixed_term',
          id: 'fixed_term',
          label: t('fixed_term'),
          icon: 'DeterminatoIcon',
        },
        {
          key: 'permanent',
          id: 'permanent',
          label: t('permanent'),
          icon: 'IndeterminatoIcon',
        },
        {
          key: 'apprenticeship_internship',
          id: 'apprenticeship_internship',
          label: t('apprenticeship_or_internship'),
          icon: 'StageTirocinioIcon',
        },
        {
          key: 'part_time',
          id: 'part_time',
          label: 'Part time',
          icon: 'PartTimeIcon',
        },
        {
          key: 'full_time',
          id: 'full_time',
          label: 'Full time',
          icon: 'FullTimeIcon',
        },
      ].filter((v) => v.key !== 'part_time' && v.key !== 'full_time'),
    [t]
  );
  return (
    <ChipCheckboxFormField
      title={t('type_of_contract')}
      options={quickFilterItemsCopy}
      id="contract_type"
      customChipGroupStyles={tw`grid grid-cols-2 gap-2`}
    />
  );
};
export const JobType = () => {
  const id = 'job_type';
  const { control } = useFormContext();
  const { t } = useTranslation('common');
  const renderCallback = useCallback(({ field }: { field: ControllerRenderProps<FieldValues, 'job_type'> }) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const fieldValue = field.value ? [...field.value] : [];
      const value = e.target.id;
      const newData = fieldValue.includes(value) ? fieldValue.filter((v) => v !== value) : [...fieldValue, value];
      field.onChange(newData);
    };
    return (
      <CheckboxGroup
        variant="box"
        isOrientation="horizontal"
        mode="clickable"
        options={jobTypeItems}
        wrapperStyles={tw`md:px-0`}
        {...{
          ...field,
          onChange,
        }}
      />
    );
  }, []);
  return (
    <div tw="flex gap-4 flex-col">
      <Title content={t('total_amount_of_hours')} fontSize="large" fontWeight="semibold" />
      <Controller name={id} control={control} rules={{ required: false }} render={renderCallback} />
    </div>
  );
};
export const ShiftsSection = () => {
  const { t } = useTranslation('common');

  return (
    <ChipCheckboxFormField
      key="working-time-slot"
      title={t('working_time_slot')}
      options={[
        {
          key: 'morning',
          label: t('morning'),
          id: 'morning',
        },
        {
          key: 'afternoon',
          label: t('afternoon'),
          id: 'afternoon',
        },
        {
          key: 'evening',
          label: t('evening'),
          id: 'evening',
        },
        {
          key: 'split_shift',
          label: t('split_shift'),
          id: 'split_shift',
        },
        {
          key: 'full_time',
          label: t('full_time'),
          id: 'full_time',
        },
      ]}
      id="shifts"
    />
  );
};
export const DistanceFrom = () => {
  const { t } = useTranslation('common');
  return (
    <div tw="flex gap-4 flex-col">
      <Title content={t('distance_from')} fontSize="large" fontWeight="semibold" />
      <UserDefinedLocation />
      <Radius />
    </div>
  );
};
const UserDefinedLocation = () => {
  const { setValue, getValues, control } = useFormContext();
  const [address, setAddress] = useState<string>('');
  const [toggleAddressInput, addressInputToggler] = useState(false);
  const { address_lat, address_lon } = useAddressAdditionalFields({ addressString: address });
  const addressString: string = getValues('address');

  const onChange = useCallback(
    (description: string) => {
      addressInputToggler(true);
      setAddress(description);
      setValue('address', description);
    },
    [setValue]
  );

  useEffect(() => {
    const lonId = 'user_location_lon';
    if (address_lon) setValue(lonId, address_lon);
  }, [address_lon, setValue]);
  useEffect(() => {
    const latId = 'user_location_lat';
    if (address_lat) setValue(latId, address_lat);
  }, [address_lat, setValue]);
  useEffect(() => {
    if (addressString) addressInputToggler(true);
  }, [addressString]);

  return toggleAddressInput ? (
    <div tw="flex gap-2">
      <Title content={addressString} fontWeight="normal" fontSize="base" />
      <Button
        icon="PencilSquareIcon"
        borderLess
        variant="ghost"
        onClick={() => {
          addressInputToggler(false);
        }}
      />
    </div>
  ) : (
    <div tw="relative">
      <GoogleAutoCompleteInput
        formId="filter-address-input"
        key="filter-address-input"
        placeholder="Via Roma, 12, Verona, VR, Italia"
        onSuggestionSelect={({ description }) => {
          onChange(description);
        }}
        autoFocus={Boolean(!toggleAddressInput && address)}
        control={control}
      />
      {address ? (
        <div tw="absolute right-2 top-[0.6rem]">
          <Button icon="XMarkIcon" borderLess variant="ghost" onClick={() => addressInputToggler(true)} />
        </div>
      ) : null}
    </div>
  );
};
const Radius = () => <ChipCheckboxFormField options={radiusItems} id="user_location_radius" isSingleSelectMode />;
export const SalaryRange = () => {
  const id = 'salary_range';
  const { control, setValue, watch } = useFormContext();
  const { t } = useTranslation('common');
  // const { trackEvent } = useAnalytics();
  const minSalary = watch('min_salary') ?? 0;
  const maxSalary = watch('max_salary') ?? 0;
  const rangeText = useMemo(() => `€${minSalary} - €${maxSalary}`, [maxSalary, minSalary]);
  const renderCallback = useCallback(
    ({ field }: { field: ControllerRenderProps<FieldValues, 'salary_range'> }) => (
      <InputSlider
        {...{
          ...field,
          onChange: (data) => {
            field.onChange(data);
            setValue('min_salary', data[0]);
            setValue('max_salary', data[1]);
          },
          min: 500,
          max: 5000,
          allowCross: false,
          range: true,
          step: 100,
          pushable: true,
        }}
      />
    ),
    [setValue]
  );
  return (
    <div tw="flex gap-4 flex-col">
      <Title content={t('monthly_earnings')} fontSize="large" fontWeight="semibold" />
      <Title content={rangeText} textColor="bold-purple" fontSize="large" />
      <Controller name={id} control={control} rules={{ required: false }} render={renderCallback} />
    </div>
  );
};

export const SeasonalJobPosition = () => {
  const { t } = useTranslation();
  return (
    <ChipCheckboxFormField
      title={t('transfer')}
      options={[
        {
          key: 'seasonal_only',
          id: 'true',
          label: t('job-explorer-page:seasonal_only'),
          icon: 'IslandTravelUmbrellaIcon',
        },
        {
          key: 'for_the_life',
          id: 'false',
          label: t('job-explorer-page:for_the_life'),
          icon: 'IndeterminatoIcon',
        },
        {
          key: 'is_abroad_job',
          id: 'is_abroad_job',
          label: t('job-explorer-page:aboard'),
          icon: 'GlobeEuropeAfricaIcon',
        },
      ]}
      id="seasonal_job_position"
      isSingleSelectMode
    />
  );
};
export const YearsOfExperience = () => {
  const { t } = useTranslation('common');
  return (
    <ChipCheckboxFormField
      title={t('years_of_experience.title')}
      options={[
        {
          key: '0_to_1',
          id: '0_to_1',
          label: t('years_of_experience.zero_to_one_years'),
        },
        {
          key: '1_to_3',
          id: '1_to_3',
          label: t('years_of_experience.one_to_three_years'),
        },
        {
          key: '3_to_5',
          id: '3_to_5',
          label: t('years_of_experience.three_to_five_years'),
        },
        {
          key: '5_plus',
          id: '5_plus',
          label: t('years_of_experience.five_plus_years'),
        },
      ]}
      id="required_experience"
      isSingleSelectMode
    />
  );
};
export const DomainInterestQualificationField = () => {
  const domainInterest = useAtomValue(domainInterestAtom);
  const { watch } = useFormContext<FilterContextValuesType>();
  const selectedDomainInterests = watch('domain_interest');
  const { t } = useTranslation('common');
  const options: OptionsType[] = useMemo(
    () =>
      domainInterests.map((item) => ({
        ...item,
        id: domainInterest?.find(({ key }) => key === item.key)?.id,
      })),
    [domainInterest]
  );
  return (
    <div tw="flex flex-col gap-4">
      <ChipCheckboxFormField
        customChipGroupStyles={tw`grid grid-cols-2 gap-2`}
        title={t('sector')}
        options={options}
        id="domain_interest"
        componentType="explorer_filter"
        testId="explorer-filter-domain-interest"
      />
      {!selectedDomainInterests?.length && (
        <div tw="flex flex-col gap-2 saturate-25 opacity-50 pointer-events-none cursor-not-allowed">
          <ChipCheckboxFormField
            title={t('qualification')}
            customChipGroupStyles={tw`grid grid-cols-2 md:grid-cols-3 gap-2`}
            options={[
              {
                id: '51',
                key: '51',
                label: t('room_attendant'),
              },
              {
                id: '54',
                key: '54',
                label: 'Assistant',
              },
            ]}
            id=""
          />
        </div>
      )}
      {!!selectedDomainInterests?.length && (
        <div tw="flex flex-col gap-4">
          <Title content={t('qualification')} fontSize="large" fontWeight="semibold" />
          {selectedDomainInterests.map((v) => (
            <OccupationSelect
              key={v}
              domainInterestId={v}
              customGridStyles={tw`grid grid-cols-2 gap-2`}
              formId="occupation_ids"
            />
          ))}
        </div>
      )}
    </div>
  );
};
export const SalaryRating = () => {
  const { t } = useTranslation('common');
  return (
    <ChipCheckboxFormField
      title={t('salary')}
      options={[1, 2, 3, 4, 5].map((x) => ({ label: x.toString(), key: x.toString(), id: x.toString() }))}
      id="salary_rating"
    />
  );
};
export const CareerRating = () => {
  const { t } = useTranslation('common');
  return (
    <ChipCheckboxFormField
      title={t('career_possibilities')}
      options={[1, 2, 3, 4, 5].map((x) => ({ label: x.toString(), key: x.toString(), id: x.toString() }))}
      id="career_rating"
    />
  );
};
