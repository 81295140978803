globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export * from './exit-confirmation';
export * from './apply-confirmation';
export * from './cover-letter-confirmation';
export * from './cover-letter-input-area';
export * from './cover-letter-quick-guide';
export * from './application-success';
export * from './common-modal-wrapper';