import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';

import { ratingCoordinateProps, ratingIconProps } from '@restworld/utils-common';
import { useResponsiveBreakPoints } from '@restworld/utils-common';
import { ListTertiary, Title, Wrapper } from '@restworld/ui-ds';

import { JobApplicationEntryPoint } from '../';
import { jobPositionApiDataAtom } from '../job-position.atoms';

export const PerformanceBlock = () => {
  const { jobPerformanceItemList } = usePerformanceBlock();
  return jobPerformanceItemList.length ? (
    <>
      <JobPerformanceBlock jobPerformanceItemList={jobPerformanceItemList} />
      <hr tw="text-gray-300" />
    </>
  ) : null;
};
interface IJobPerformanceBlockProps {
  jobPerformanceItemList: tertiaryItemListProps[];
  customerServiceHelper?: string;
  // careerPossibilityDescription?: string;
}
export const JobPerformanceBlock: React.FunctionComponent<IJobPerformanceBlockProps> = ({ jobPerformanceItemList }) => {
  const { t: translateCommon } = useTranslation('common');
  const { isDesktop } = useResponsiveBreakPoints();
  return (
    <Wrapper>
      <Title
        icon="HandThumbUpIcon"
        content={translateCommon('our_advice')}
        variant="h4-large"
        fontWeight="semibold"
        textColor="black"
      />
      <ListTertiary jobPerformanceItemList={jobPerformanceItemList} />
      {!isDesktop && (
        <>
          <JobApplicationEntryPoint />
        </>
      )}
    </Wrapper>
  );
};
const usePerformanceBlock = () => {
  const { t: commonTranslate } = useTranslation('common');
  const { t: jobPositionTranslate } = useTranslation('job-position-page');
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);

  const customerServiceHelper = React.useMemo(
    () => jobPositionApiData?.assigned_to_user?.name,
    [jobPositionApiData?.assigned_to_user?.name]
  );
  const formatSalaryRating = React.useCallback(
    (rating: 1 | 2 | 3 | 4 | 5) => {
      switch (rating) {
        case 1:
          return jobPositionTranslate('job_low_pay');
        case 2:
          return jobPositionTranslate('job_below_average_pay');
        case 3:
          return jobPositionTranslate('job_average_pay');
        case 4:
          return jobPositionTranslate('job_above_average_pay');
        case 5:
          return jobPositionTranslate('job_high_average_pay');
        default:
          return '';
      }
    },
    [jobPositionTranslate]
  );

  const formatCareerRating = React.useCallback(
    (rating: 1 | 2 | 3 | 4 | 5) => {
      switch (rating) {
        case 1:
          return jobPositionTranslate('job_gives_low_career_dev');
        case 2:
          return jobPositionTranslate('job_gives_below_average_career_dev');
        case 3:
          return jobPositionTranslate('job_gives_average_career_dev');
        case 4:
          return jobPositionTranslate('job_gives_above_average_career_dev');
        case 5:
          return jobPositionTranslate('job_gives_high_career_dev');
        default:
          return '';
      }
    },
    [jobPositionTranslate]
  );

  const formatWorklifeBalanceRating = React.useCallback(
    (rating: 1 | 2 | 3 | 4 | 5) => {
      switch (rating) {
        case 1:
          return jobPositionTranslate('job_gives_low_worklife_balance');
        case 2:
          return jobPositionTranslate('job_gives_below_average_worklife_balance');
        case 3:
          return jobPositionTranslate('job_gives_average_worklife_balance');
        case 4:
          return jobPositionTranslate('job_gives_above_average_worklife_balance');
        case 5:
          return jobPositionTranslate('job_gives_high_worklife_balance');
        default:
          return '';
      }
    },
    [jobPositionTranslate]
  );

  const jobPerformanceItemList: tertiaryItemListProps[] = React.useMemo(
    () => [
      ...(jobPositionApiData?.csm_insights && customerServiceHelper
        ? [
            {
              key: 'recruiter-profile',
              icon: jobPositionApiData?.assigned_to_user?.icon_url || '',
              rating: 1 as const,
              title: `Cosa ne pensa ${customerServiceHelper?.trim()}, Senior Recruiter di Restworld`,
              subTitle: jobPositionApiData.csm_insights,
            },
          ]
        : []),
      ...(jobPositionApiData?.salary_rating
        ? [
            {
              key: 'pay',
              icon: 'rating-currency-icon',
              rating: jobPositionApiData?.salary_rating as ratingCoordinateProps,
              title: commonTranslate('salary'),
              subTitle: formatSalaryRating(jobPositionApiData.salary_rating),
            },
          ]
        : []),
      ...(jobPositionApiData?.career_rating
        ? [
            {
              key: 'career-possibilities',
              icon: 'rating-graph-icon',
              rating: jobPositionApiData?.career_rating as ratingCoordinateProps,
              title: jobPositionTranslate('job_career_possibilities.title'),
              subTitle:
                jobPositionApiData?.career_development_description ||
                formatCareerRating(jobPositionApiData.career_rating),
              hasChild: true,
            },
          ]
        : []),
      ...(jobPositionApiData?.worklife_balance_rating
        ? [
            {
              key: 'worklife-balance',
              icon: 'rating-clock-icon',
              rating: jobPositionApiData?.worklife_balance_rating as ratingCoordinateProps,
              title: jobPositionTranslate('job_worklife_balance_title'),
              subTitle: formatWorklifeBalanceRating(jobPositionApiData.worklife_balance_rating),
            },
          ]
        : []),
    ],
    [
      commonTranslate,
      customerServiceHelper,
      formatCareerRating,
      formatSalaryRating,
      formatWorklifeBalanceRating,
      jobPositionApiData?.assigned_to_user?.icon_url,
      jobPositionApiData?.career_development_description,
      jobPositionApiData?.career_rating,
      jobPositionApiData?.csm_insights,
      jobPositionApiData?.salary_rating,
      jobPositionApiData?.worklife_balance_rating,
      jobPositionTranslate,
    ]
  );
  return {
    jobPerformanceItemList,
    customerServiceHelper,
    // careerPossibilityDescription: jobPositionApiData.career_development_description,
  };
};
interface tertiaryItemListProps {
  key: string;
  subTitle?: string;
  title: string;
  hasChild?: boolean;
  icon: ratingIconProps | string;
  rating: ratingCoordinateProps;
}
