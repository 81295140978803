import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import { useRestaurantsService } from '@restworld/data-services';

import { showViolaBadgeAtom } from '../components/job-position/job-position.atoms';

type Options = {
  initOnMount?: boolean;
};

export const useViolaBadge = (jobPositionId?: string, options?: Options) => {
  const restaurantService = useRestaurantsService();
  const setShowViolaBadge = useSetAtom(showViolaBadgeAtom);

  const fetchShowViolaBadge = useCallback(
    async (localId?: string) => {
      const id = localId || jobPositionId;
      if (!id) return;

      try {
        const response = await restaurantService.showViolaBadge(id);
        setShowViolaBadge(response.data || false);
      } catch (error) {
        console.error(error, `Error while fetching show viola badge data for id: ${id}`);
      }
    },
    [jobPositionId, restaurantService, setShowViolaBadge]
  );

  useEffect(() => {
    options?.initOnMount && fetchShowViolaBadge();
  }, [fetchShowViolaBadge, options?.initOnMount]);

  return { fetchShowViolaBadge };
};
