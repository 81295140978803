import { useEffect } from 'react';

import ApplicationCoverLetter from '..';
import SpontaneousApplyCoverLetterLayout from './spontaneous-apply-layout';
import {
  SpontaneousCoverLetterEntryContextProvider,
  useSpontaneousCoverLetterEntryContext,
} from './spontaneous-apply-context';
import { useSpontaneousApplyCallback } from './use-spontaneous-apply-callback';
// import { CompanyReviewProps } from '../../../';

export type CoverLetterEntryPropsType = {
  closeEntryPoint?: () => void;
  workerId?: string;
  restaurantID?: string;
  // companyReviewDetails?: CompanyReviewProps;
  id?: 'authenticated-spontaneous-application' | 'unauthenticated-spontaneous-application';
};

export const CoverLetterEntry = (props: CoverLetterEntryPropsType) => (
  <SpontaneousCoverLetterEntryContextProvider>
    <SpontaneousCoverLetterEntry {...props} />
  </SpontaneousCoverLetterEntryContextProvider>
);

const SpontaneousCoverLetterEntry = ({
  closeEntryPoint,
  restaurantID,
  workerId,
  // companyReviewDetails,
  id,
}: CoverLetterEntryPropsType) => {
  const {
    coverLetterModalSection,
    onChangeCoverLetterModalSection,
    loading,
    toggleExitConfirmation,
    coverLetter,
    setCoverLetterInput,
  } = useSpontaneousCoverLetterEntryContext();

  const { onApplicationApply } = useSpontaneousApplyCallback({
    restaurantID,
    workerId,
  });
  useEffect(() => {
    if (id === 'unauthenticated-spontaneous-application') {
      onChangeCoverLetterModalSection('cover-letter-confirmation');
      toggleExitConfirmation(false);
    }
  }, [id, onChangeCoverLetterModalSection, toggleExitConfirmation]);
  return (
    <SpontaneousApplyCoverLetterLayout closeModal={closeEntryPoint}>
      <ApplicationCoverLetter
        modalSection={coverLetterModalSection}
        onChangeModalSection={onChangeCoverLetterModalSection}
        loading={loading}
        toggleExitConfirmation={toggleExitConfirmation}
        coverLetter={coverLetter}
        setCoverLetterInput={setCoverLetterInput}
        onApplicationApply={onApplicationApply}
        // companyReviewDetails={companyReviewDetails}
        id={id}
        restaurantID={restaurantID}
      />
    </SpontaneousApplyCoverLetterLayout>
  );
};
