import * as React from 'react';

import { CompanyRatingList } from './company-rating-list';
import { SingleRating } from './single-rating';

interface ICompanyRatingProps {
  type: 'single' | 'list';
}
export const CompanyRating: React.FunctionComponent<ICompanyRatingProps> = ({ type }) =>
  type === 'list' ? <CompanyRatingList /> : <SingleRating />;
export default CompanyRating;
