import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import 'twin.macro';
import { useTranslation } from 'next-i18next';

import { useAddressAdditionalFields } from '@restworld/utils-common';
import { GoogleAutoCompleteInput, Title } from '@restworld/ui-ds';

import { SaveResearchFormFields } from '../job-explorer-save-research-v2';

interface IAddressInputProps {
  showAddressText?: boolean;
}

export const SaveResearchAddressInput = ({ showAddressText }: IAddressInputProps) => {
  const [address, setAddress] = useState<string>();
  const [modifyAddressFlag, toggleModifyAddressFlag] = useState(true);

  const addressFields = useAddressAdditionalFields({ addressString: address });
  const { setValue, getValues, control } = useFormContext<SaveResearchFormFields>();

  const { t } = useTranslation();

  useEffect(() => {
    if (addressFields && Object.keys(addressFields).length) {
      toggleModifyAddressFlag(false);
      setValue('address_lat', addressFields.address_lat);
      setValue('address_lon', addressFields.address_lon);
      setValue('address', {
        address: addressFields.address,
        country: addressFields.country,
        address_lat: addressFields.address_lat,
        address_lon: addressFields.address_lon,
        administrative_area_level_1: addressFields.administrative_area_level_1,
        administrative_area_level_2: addressFields.administrative_area_level_2,
      });
      if (addressFields.postal_code) setValue('postal_code', addressFields.postal_code);
      if (addressFields.locality) setValue('locality', addressFields.locality);
      setValue('street_number', addressFields.street_number);
    }
  }, [addressFields, setValue]);

  useEffect(() => {
    if (getValues('address')?.address) {
      setAddress(getValues('address').address);
    }
  }, [getValues]);

  return showAddressText && !modifyAddressFlag ? (
    <>
      {addressFields?.address ? (
        <Title content={addressFields?.address} fontSize="base" textColor="gray-600" icon="HomeIcon" />
      ) : null}
      <a
        href="#!"
        tw="text-primary underline text-sm font-semibold leading-[15.4px] max-w-fit"
        onClick={() => toggleModifyAddressFlag(true)}
      >
        Modifica
      </a>
    </>
  ) : (
    <GoogleAutoCompleteInput
      formId="research-address-input"
      key="research-address-input"
      placeholder={t('job-explorer-page:enter_address_or_location')}
      onSuggestionSelect={({ description }) => {
        setAddress(description);
      }}
      control={control}
      autoFocus
    />
  );
};

export default SaveResearchAddressInput;
