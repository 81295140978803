globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

var buildKey = function buildKey(service, path) {
  return "".concat(service, "_").concat(path);
};

var SERVICES = {
  JOB_POSITIONS: 'job_position',
  WORKER_JOB_POSITIONS: 'worker_job_positions',
  WORKERS: 'worker',
  RESTAURANTS: 'restaurants',
  ACCOUNTS: 'accounts',
  ORGANISATIONS: 'organisations',
  EMPLOYERS: 'employers'
};
var JOB_POSITIONS = {
  STATUS: buildKey(SERVICES.JOB_POSITIONS, 'status'),
  LIST: buildKey(SERVICES.JOB_POSITIONS, 'list'),
  APPLICATION_PERFORMANCE_METRICS: buildKey(SERVICES.JOB_POSITIONS, 'application_performance_metrics'),
  DETAILS: buildKey(SERVICES.JOB_POSITIONS, 'details')
};
var WORKER_JOB_POSITIONS = {
  SPONTANEOUS_APPLICATION: buildKey(SERVICES.WORKER_JOB_POSITIONS, 'spontaneous_application'),
  APPLICATIONS: buildKey(SERVICES.JOB_POSITIONS, 'applications')
};
var WORKERS = {
  HAS_NO_EXPERIENCE: buildKey(SERVICES.WORKERS, 'has_no_experience')
};
var RESTAURANTS = {
  LIST: buildKey(SERVICES.RESTAURANTS, 'list'),
  LOCATIONS: buildKey(SERVICES.RESTAURANTS, 'locations'),
  NAMES: buildKey(SERVICES.RESTAURANTS, 'names'),
  DETAILS: buildKey(SERVICES.RESTAURANTS, 'details')
};
var ACCOUNTS = {
  LIST_USER_CONTACTS: buildKey(SERVICES.ACCOUNTS, 'list_user_contacts')
};
var ORGANISATIONS = {
  DETAILS: buildKey(SERVICES.ORGANISATIONS, 'details'),
  COMPANY_LIST: buildKey(SERVICES.ORGANISATIONS, 'company-list'),
  STRIPE_PAYMENT_METHODS: buildKey(SERVICES.ORGANISATIONS, 'stripe_payment_methods'),
  STRIPE_CUSTOMER_PORTAL: buildKey(SERVICES.ORGANISATIONS, 'stripe_customer_portal'),
  MISSED_APPLICATION_ACTIVITIES: buildKey(SERVICES.ORGANISATIONS, 'missed_application_activities'),
  PERFORMANCE_METRICS: buildKey(SERVICES.ORGANISATIONS, 'performance_metrics')
};
var EMPLOYERS = {
  COMPANY_SEARCH: buildKey(SERVICES.EMPLOYERS, 'company_search')
};
export var QUERY_KEYS = {
  JOB_POSITIONS: JOB_POSITIONS,
  WORKER_JOB_POSITIONS: WORKER_JOB_POSITIONS,
  WORKERS: WORKERS,
  RESTAURANTS: RESTAURANTS,
  ACCOUNTS: ACCOUNTS,
  ORGANISATIONS: ORGANISATIONS,
  EMPLOYERS: EMPLOYERS
};