import { useAccountsService } from '@restworld/data-services';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  closePhoneNumberLoginModal,
  updatePhoneNumberValidationDetails,
  openSignupModal,
  setActiveContent as setActiveModalContent,
  openOtpVerificationModal,
  ContentType as ModalContentType,
  existingPhoneNumber,
} from '../../../context/auth-flow';

export type UsePhoneNumberLoginFormProps = {
  redirectToPath?: (path: string, query?: { [key: string]: string | boolean }) => void;
  isModal?: boolean;
};

export interface PhoneNumberLoginInput {
  phoneNumber: string;
}

export const smsSentCountAuthAtom = atom(0);
export function usePhoneNumberLoginForm({ isModal }: UsePhoneNumberLoginFormProps) {
  const { phoneNumber } = useAtomValue(existingPhoneNumber);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm<PhoneNumberLoginInput>({ defaultValues: { phoneNumber } });
  const [loading, setLoading] = useState<boolean>(false);
  const [remainingTimes, setRemainingTimes] = useState<number>(3);
  const acc = useAccountsService();
  const setActiveContent = useSetAtom(setActiveModalContent);
  const updateVerificationDetails = useSetAtom(updatePhoneNumberValidationDetails);
  const closePhoneNumberModal = useSetAtom(closePhoneNumberLoginModal);
  const openOtpModal = useSetAtom(openOtpVerificationModal);
  const openNewSignupModal = useSetAtom(openSignupModal);
  const { t } = useTranslation('auth-flow');
  const [, setSMSSentCountAuth] = useAtom(smsSentCountAuthAtom);

  const handleLogin = useCallback(
    async (data: PhoneNumberLoginInput) => {
      setLoading(true);
      try {
        const response = await acc.phoneNumberLogin(data.phoneNumber);
        const tokenValidityTime = response?.data?.token_validity_time;
        const waitTime = response?.data?.wait_time;
        setLoading(false);
        updateVerificationDetails({
          waitTime: waitTime ? parseInt(waitTime) : 0,
          tokenValidityTime: tokenValidityTime ? parseInt(tokenValidityTime) : 0,
          phoneNumber: data.phoneNumber,
        });
        setSMSSentCountAuth((prev) => prev + 1);
        setActiveContent(ModalContentType.OTP_VERIFICATION);
        setRemainingTimes((prev) => prev - 1);
        if (isModal) {
          closePhoneNumberModal();
          openOtpModal();
        }
      } catch (error) {
        setLoading(false);
        if (parseInt(error.code) === 404) {
          setError('phoneNumber', { message: t('phone_number_login_phone_number_not_found') });
        } else {
          setActiveContent(ModalContentType.SIGNUP);
          if (isModal) {
            closePhoneNumberModal();
            openNewSignupModal();
          }
        }
      }
    },
    [
      acc,
      closePhoneNumberModal,
      isModal,
      openNewSignupModal,
      openOtpModal,
      setActiveContent,
      setError,
      setSMSSentCountAuth,
      t,
      updateVerificationDetails,
    ]
  );

  const onSubmit = async (data: PhoneNumberLoginInput) => {
    handleLogin(data);
  };

  return {
    loading,
    onSubmit,
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setValue,
    setError,
    remainingTimes,
  };
}
