import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { BaseModal, Button, LinkBase, Title } from '@restworld/ui-ds';
import { ModalWrapperCloseIcon } from '@restworld/utils-common';
import { useTranslation } from 'next-i18next';
import tw from 'twin.macro';

export type ProposedJobApplicationModalProps = {
  show: boolean;
  onClose: () => void;
  jobPositionId?: string;
  restaurantId?: string;
  variant?: 'positive' | 'negative';
};

export const ProposedJobApplicationModal = ({
  show,
  onClose,
  jobPositionId,
  restaurantId,
  variant = 'positive',
}: ProposedJobApplicationModalProps) => {
  const { t } = useTranslation();
  const modalTitle =
    variant === 'positive'
      ? t('job-application-page:application_being_evaluated')
      : t('job-application-page:the_job_position_is_not_of_interest_to_you');
  const modalBody =
    variant === 'positive'
      ? t('job-application-page:application_being_evaluated_desc')
      : t('job-application-page:removed_the_job_position_from_the_proposals');
  return (
    <BaseModal
      {...{
        onClose: onClose,
        showMobileGestures: true,
        open: show,
        modalStyles: [tw`w-full md:(max-w-[26rem] min-w-[22rem])`],
        closeIcon: <ModalWrapperCloseIcon />,
      }}
    >
      <Wrapper>
        <CheckCircleIcon tw="w-13 h-13 text-secondary-900 mb-4 " />
        <Title
          isCenter
          fontSize="large"
          content={modalTitle}
          textColor="black"
          fontWeight="semibold"
          variant="h3-large"
          twStyle={tw`leading-7 mb-2`}
        />
        <Title variant="h6-large" textColor="gray-500" twStyle={tw`mb-10`} isCenter>
          {modalBody}
        </Title>
        <div tw="flex flex-col items-center w-full gap-4 ">
          {variant === 'positive' && (
            <LinkBase link={`/candidature/${restaurantId || jobPositionId}`} newTab={false} twStyle={tw`w-full`}>
              <Button fullwidth title={t('job-application-page:view_candidate')} variant="normal" />
            </LinkBase>
          )}
          <Button
            fullwidth
            title={t('common:close')}
            variant={variant === 'positive' ? 'secondary-outline' : 'normal'}
            onClick={onClose}
          />
        </div>
      </Wrapper>
    </BaseModal>
  );
};

const Wrapper = tw.div`flex flex-col items-center`;
