import { useWorkersService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';
import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { closeWorkerDataLoader, openWorkerDataLoader, setWorkerAtom } from '../components/profile/global-state';

export function useLoadWorkerData() {
  const workerService = useWorkersService();
  const { user } = useAuth();

  const { workerId, email, phoneNumber } = useMemo(
    () => ({
      workerId: user?.id,
      email: user?.email,
      phoneNumber: user?.phone_number,
    }),
    [user]
  );

  const onSetWorkerData = useSetAtom(setWorkerAtom);
  const openLoader = useSetAtom(openWorkerDataLoader);
  const closeLoader = useSetAtom(closeWorkerDataLoader);

  const fetchWorker = useCallback(async () => {
    if (!workerId) return;
    try {
      openLoader();
      const workerData = (await workerService.fetchWorker(workerId))?.data;
      onSetWorkerData({
        ...workerData,
        user: { ...workerData.user, email: email, phone_number: phoneNumber },
      });
    } catch (error) {
      console.error(`Error on getting worker data`, error);
    } finally {
      closeLoader();
    }
  }, [email, workerId, phoneNumber, closeLoader, onSetWorkerData, openLoader, workerService]);

  return { fetchWorker };
}
