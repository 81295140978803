globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
// Trigger update
import { ServiceProvider } from './lib/contexts/ServiceConfig';
import useServices from './lib/hooks/useServices';
export * from './lib/services/useWorkers';
export * from './lib/services/useWorkerJobPositions';
export * from './lib/services/useRwMembers';
export * from './lib/services/useAmbassadors';
export * from './lib/services/useRestaurants';
export * from './lib/services/useJobPositions';
export * from './lib/services/useGlobals';
export * from './lib/services/useEmployers';
export * from './lib/services/useDashboard';
export * from './lib/services/useAccounts';
export * from './lib/services/useTaxonomy';
export * from './lib/services/useCvParsing';
export * from './lib/services/useSocialAuth';
export * from './lib/services/useOrganizations';
export * from './lib/endpoints';
export * from './lib/utils/axios';
export * from './lib/wrappers';
export { ServiceProvider, useServices };