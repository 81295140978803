import * as React from 'react';
import { useEffect } from 'react';
import { Toast } from '@restworld/ui-ds';
import { useToastMessageContext } from '@restworld/utils-common';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToastMessageProps {}
const ToastMessage: React.FunctionComponent<IToastMessageProps> = () => {
  const { toastMessageOptions, setToastMessage } = useToastMessageContext();
  const { duration } = toastMessageOptions;
  useEffect(() => {
    setTimeout(() => {
      setToastMessage({
        visible: false,
        message: '',
      });
    }, duration);
  }, [duration, setToastMessage, toastMessageOptions.visible]);

  return <Toast {...toastMessageOptions} />;
};

export default ToastMessage;
