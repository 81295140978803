import * as React from 'react';
import { ReactNode, memo, FunctionComponent } from 'react';
import Head from 'next/head';
import { NextSeo, NextSeoProps } from 'next-seo';
import tw, { styled } from 'twin.macro';

import { Footer, DefaultNavigationBar, NavigationVariantType, NavigationBarProps } from '@restworld/ui-ds';
import { defaultSEO, getAssetSrc } from '@restworld/utils-common';

import { useLoginSignupPopup } from '../hooks';
import { AddressSearchBar } from '../components/job-explorer/address-search-bar';

export const LayoutWrapper: FunctionComponent<ILayoutWrapperProps> = ({
  children,
  title,
  hasFooter,
  hasNavbar,
  navbarVariant,
  showSearchBarInNavbar = false,
  hasBorderBottom,
  noHorizontalPadding,
  noPaddingTop,
  noPaddingBottom,
  seoProps,
  testId,
  metaDescription,
}) => {
  const loginSignupProps = useLoginSignupPopup();
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="description" content={metaDescription} />
        <link rel="shortcut icon" href={getAssetSrc('/utils-assets/favicons/favicon.ico')} />
        <link
          rel="icon"
          type="image/png"
          href={getAssetSrc('/utils-assets/favicons/favicon-96x96.png')}
          sizes="96x96"
        />
        <link rel="icon" type="image/svg+xml" href={getAssetSrc('/utils-assets/favicons/favicon.svg')} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={getAssetSrc('/utils-assets/favicons/apple-touch-icon.png')}
        />
        <title>{`Restworld${title ? ' | ' + title : ''}`}</title>
      </Head>
      <NextSeo {...(seoProps ? seoProps : defaultSEO)} />
      {hasNavbar ? (
        <DefaultNavigationBar
          key="DefaultNavigationBar"
          variant={navbarVariant}
          authProps={{ ...loginSignupProps }}
          algoliaSearchBarElement={showSearchBarInNavbar ? <AddressSearchBar key="navbar" /> : null}
          hasBorderBottom={hasBorderBottom}
          testId={testId}
        />
      ) : null}
      <PaddingWrapper
        noHorizontalPadding={noHorizontalPadding}
        noPaddingTop={noPaddingTop}
        noPaddingBottom={noPaddingBottom}
      >
        {children}
      </PaddingWrapper>
      {hasFooter ? <Footer /> : null}
    </>
  );
};

export default memo(LayoutWrapper);
interface ILayoutWrapperProps {
  title: string;
  children: ReactNode;
  hasFooter?: boolean;
  hasNavbar?: boolean;
  navbarVariant?: NavigationVariantType;
  showSearchBarInNavbar?: boolean;
  background?: NavigationBarProps['background'];
  hasBorderBottom?: boolean;
  noHorizontalPadding?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  seoProps?: NextSeoProps;
  testId?: string;
  metaDescription?: string;
}

export const standardHorizontalPadding = tw`2xl:px-[220px] xl:px-[120px] lg:px-12 px-6`;
export const standardVerticalPadding = tw`lg:pt-32 pt-32 lg:pb-16 pb-16`;

export const PaddingWrapper = styled.div<{
  noHorizontalPadding?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
}>`
  ${tw`w-full`}
  ${standardHorizontalPadding}
  ${standardVerticalPadding}
  ${({ noHorizontalPadding }) => noHorizontalPadding && tw`xl:px-0 lg:px-0 px-0`}
  ${({ noPaddingTop }) => noPaddingTop && tw`lg:pt-0 pt-0`}
  ${({ noPaddingBottom }) => noPaddingBottom && tw`lg:pb-0 pb-0`}
`;
