import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

export const useStepData = () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        key: 'step-1',
        title: t('onboarding-page:cv_loading.we_are_reading_your_cv'),
        subTitle: t('onboarding-page:cv_loading.the_analysis_of_your_data_is_important'),
        progressLabel: {
          title: t('onboarding-page:cv_loading.on_you'),
          icon: '/utils-assets/single-user.svg',
          remainingTime: `10 sec. ${t('common:remained')}`,
        },
        progressPercent: 25,
      },
      {
        key: 'step-2',
        title: t('onboarding-page:cv_loading.let_take_a_look_at_your_experiences'),
        subTitle: t('onboarding-page:cv_loading.everything_has_its_preparation_time'),
        progressLabel: {
          title: t('common:experiences'),
          icon: '/utils-assets/briefcase.svg',
          remainingTime: `5 sec. ${t('common:remained')}`,
        },
        progressPercent: 50,
      },
      // {
      //   key: 'step-3',
      //   title: t('onboarding-page:cv_loading.lets_see_what_you_studied'),
      //   subTitle: t('onboarding-page:cv_loading.extracting_the_data_from_your_cv'),
      //   progressLabel: {
      //     title: t('common:training'),
      //     icon: '/utils-assets/graduate-hat.svg',
      //     remainingTime: `1 sec. ${t('common:remained')}`,
      //   },
      //   progressPercent: 75,
      // },
      {
        key: 'step-4',
        title: t('onboarding-page:cv_loading.lets_put_the_icing_on_the_cake'),
        subTitle: t('onboarding-page:cv_loading.enter_all_the_data_by_hand'),
        progressLabel: {
          title: t('common:preferences'),
          icon: '/utils-assets/love-sign.svg',
          remainingTime: `1 sec. ${t('common:remained')}`,
        },
        progressPercent: 75,
      },
    ],
    [t]
  ) as CVParsingProps;
};
export const useCompletedStepData = () => {
  const { t } = useTranslation('onboarding-page');
  return useMemo(
    () => ({
      key: 'complete',
      title: t('cv_loading.your_cv_has_been_analyzed'),
      subTitle: t('cv_loading.check_the_data_collected_and_correct_the_missing_ones'),
      progressLabel: {
        title: '',
        icon: '',
        remainingTime: '',
      },
      progressPercent: 100,
    }),
    [t]
  );
};
export type CVParsingProps = {
  key: string;
  title: string;
  subTitle: string;
  progressLabel: {
    title: string;
    icon: string;
    remainingTime: string;
  };
  progressPercent: number;
}[];
export const defaultArgs = {};
