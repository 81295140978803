import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export const useShowIntercom = () => {
  const { boot, shutdown, show } = useIntercom();
  useEffect(() => {
    // show();
    return () => {
      shutdown();
    };
  }, [boot, show, shutdown]);
};
