import tw, { TwStyle, styled } from 'twin.macro';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';

import { toDateFormat, useScroll } from '@restworld/utils-common';
import { CardPrimary, JobShareModal, Paragraph } from '@restworld/ui-ds';

// import { useRecordJobShareEvent } from '../../hooks/use-record-job-share-event';
import { JobDetailProps } from './job-detail.mocks';
import { CompanyProfileDrawer } from '../job-company-profile';
import { showViolaBadgeAtom } from '../job-position/job-position.atoms';

export const JobDetailBlock = ({
  jobPositionDetails,
  showRestaurant,
  children: applicationEntryPointButtonAsChildren,
  ambassadorRefCode,
  bgColor,
  showShareButton = true,
  jobInterestEl,
  hideJobApplicationEntryPoint = false,
  publishedAt,
  performanceMetrics,
}: JobDetailProps): JSX.Element => {
  const { isScrolled } = useScroll();
  const showViolaBadge = useAtomValue(showViolaBadgeAtom);
  const { t } = useTranslation('common');

  return (
    <StickyJobDetailWrapper id="job-info-el" isScrolled={isScrolled} showRestaurant={showRestaurant} bgColor={bgColor}>
      <Wrapper>
        <CardPrimary
          jobPositionDetails={jobPositionDetails}
          showRestaurant={showRestaurant}
          showViolaBadge={showViolaBadge || false}
        />
        {showRestaurant && (
          <div tw="hidden lg:(block w-full)">
            <CompanyProfileDrawer buttonIcon="BuildingOfficePurpleIcon" />
          </div>
        )}
        {!hideJobApplicationEntryPoint ? (
          showShareButton ? (
            <ButtonWrapper>
              {applicationEntryPointButtonAsChildren}
              <JobShareModal
                icon="ShareIcon"
                buttonTitle=""
                ambassadorRefCode={ambassadorRefCode}
                jobPositionId={jobPositionDetails?.id}
                fontWeight="bold"
                // onClick={recordJobShareEvent}
              />
            </ButtonWrapper>
          ) : (
            applicationEntryPointButtonAsChildren
          )
        ) : null}
        {jobInterestEl ? <div tw="flex flex-row w-full items-start mt-2">{jobInterestEl}</div> : undefined}
        {hideJobApplicationEntryPoint && jobInterestEl ? (
          <JobShareModal
            icon="ShareWhiteIcon"
            buttonTitle="Condividi"
            ambassadorRefCode={ambassadorRefCode}
            jobPositionId={jobPositionDetails?.id}
            variant={'ghost'}
            fontColor="white"
          />
        ) : null}
      </Wrapper>
      {(publishedAt || performanceMetrics) && (
        <FooterWrapper>
          {publishedAt && (
            <JobDetailParagraph icon={<CalendarIcon tw="w-[13.5px] h-[13.5px] text-white" />}>{`${t(
              'published_on'
            )} ${toDateFormat(publishedAt, `dd/MM/yyyy`)}`}</JobDetailParagraph>
          )}
        </FooterWrapper>
      )}
    </StickyJobDetailWrapper>
  );
};
export default JobDetailBlock;

export const StickyJobDetailWrapper = styled.div<{
  isScrolled: boolean;
  showRestaurant: boolean;
  bgColor?: string;
  twStyle?: TwStyle;
}>`
  ${({ bgColor = 'primary' }) =>
    ({
      primary: tw`bg-primary-800`,
      secondary: tw`bg-white`,
      none: tw`bg-transparent`,
    }[bgColor])}

  ${tw`rounded-[20px] w-full h-max lg:(sticky top-[6rem])`}
  ${tw`lg:max-w-sm lg:min-w-[384px]`}
  ${tw`px-6 pb-6 lg:(shadow)`};
  ${({ showRestaurant }) => (showRestaurant ? tw`pt-16` : tw`pt-6`)};
  ${({ twStyle }) => (twStyle ? twStyle : tw``)};
`;

const ButtonWrapper = styled.div`
  ${tw`flex gap-4 text-lg justify-center w-full min-w-full px-[0.5rem] lg:px-0`};
`;

const Wrapper = tw.div`flex flex-col gap-6 items-center border-b border-primary-300 pb-4`;
const FooterWrapper = tw.div`flex flex-col gap-4 items-center justify-start  w-full pt-4`;

const JobDetailParagraph = ({ children, icon }: { children: React.ReactNode; icon: React.ReactNode }) => (
  <div tw="flex flex-row gap-2 items-center justify-start w-fit">
    {icon}
    <Paragraph variant="xsmall" color="white">
      {children}
    </Paragraph>
  </div>
);
