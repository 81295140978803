import { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { useAuth } from '@restworld/feature-authentication';

import { ApplyConfirmation, CardPrimary, CommonModalWrapper, Timeline } from '@restworld/ui-ds';
import { getRestaurantPopupInfoAtom } from '../use-show-spontaneous-application';
import { useApplyJob } from '../../../hooks/use-apply-job';
import { useJobPositionDetails, useJobPositionId } from '../../../hooks';
import { workerIdAtom } from '../worker-entry-point.atoms';
import { entryPointModalVisibleAtom } from '../worker-entry-point-modal';
import { jobPositionApiDataAtom, jobPositionApplyStatusAtom } from '../../job-position/job-position.atoms';
import {
  workerManagedFooterMapKeyAtom,
  workerManagedUsingKlaaryoModalFlagAtom,
} from '../../worker-managed-using-klaaryo';

const useTimeLineSteps = () => {
  const { t } = useTranslation('onboarding-page');

  return useMemo(
    () => [
      {
        title: t('application_received'),
        checked: true,
      },
      {
        key: 2,
        title: t('interview_with_our_manager'),
      },
      {
        key: 3,
        title: t('application_sent_to_the_company'),
      },
      {
        key: 4,
        title: t('contact_with_the_company'),
      },
      {
        key: 5,
        title: t('on_site_interview'),
      },
      {
        key: 6,
        title: t('trial_period'),
      },
      {
        isFlagged: true,
        title: t('assumption'),
      },
    ],
    [t]
  );
};
export const JobApplyConfirmation = () => {
  const [, toggleEntryPoint] = useAtom(entryPointModalVisibleAtom);
  const closeEntryPoint = () => toggleEntryPoint(false);
  const [, setJobPositionApplyStatus] = useAtom(jobPositionApplyStatusAtom);
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  const jobPositionData = useJobPositionDetails({ jobPositionApiData });
  const router = useRouter();
  const [showSuccessView, toggleSuccessView] = useState(false);
  const workerId = useAtomValue(workerIdAtom);
  const jobPositionId = useJobPositionId();
  const { user } = useAuth();
  const setWorkerManagedUsingKlaaryoModalFlag = useSetAtom(workerManagedUsingKlaaryoModalFlagAtom);
  const setWorkerManagedFooterMapKey = useSetAtom(workerManagedFooterMapKeyAtom);

  const manageUsingKlaaryo = jobPositionApiData?.manage_using_klaaryo;

  const { applyJobPosition, jobPositionLoading } = useApplyJob({
    referralCode: router?.query?.['scout'],
    onAuthenticated: () => {
      if (setJobPositionApplyStatus) setJobPositionApplyStatus(true);
      if (manageUsingKlaaryo) {
        setWorkerManagedUsingKlaaryoModalFlag(true);
        setWorkerManagedFooterMapKey('direct_applied');
        closeEntryPoint();
      } else toggleSuccessView(true);
    },
  });
  const { t } = useTranslation('onboarding-page');
  const [restaurantPopupInfo] = useAtom(getRestaurantPopupInfoAtom);
  const timeLineSteps = useTimeLineSteps();
  return showSuccessView ? (
    <CommonModalWrapper
      mainTitle={t('your_application_has_been_sent')}
      subTitle={t('your_application_has_been_sent')}
      primaryButtonProps={{
        title: t('view_your_applications'),
        onClick: () => {
          router.push(`/candidature/${jobPositionId}`);
        },
      }}
      secondaryButtonProps={{
        title: t('find_more_jobs'),
        onClick: () => {
          router.push(`/cerco-lavoro`);
        },
      }}
    >
      <div tw="flex flex-col items-center relative">
        <Timeline timeLineSteps={timeLineSteps} />
      </div>
    </CommonModalWrapper>
  ) : (
    <ApplyConfirmation
      onProceedWithApplication={() =>
        applyJobPosition({ workerIdArg: user?.id || workerId, jobPositionId, source: 'job_position_page' })
      }
      onChangeMind={() => (closeEntryPoint ? closeEntryPoint() : null)}
      proceedButtonTitle={t('apply_now')}
      exitButtonTitle={t('changed_my_mind')}
      mainTitle={t('confirm_that_you_want_to_apply')}
      loading={jobPositionLoading}
      restaurantPopupInfo={restaurantPopupInfo}
    >
      {jobPositionData ? <CardPrimary {...jobPositionData} variant="gradientBase" twStyle={tw`max-w-xs`} /> : null}
    </ApplyConfirmation>
  );
};
