import * as React from 'react';
import { atom, useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import tw from 'twin.macro';

import { FormLabelWrapper, isValidPhoneNumber, PhoneInput } from '@restworld/ui-ds';
import { useAuth } from '@restworld/feature-authentication';

import { IFormModalWrapper, ModalToggleWrapper } from './form-modal-wrapper';
import { useEmployerPhoneNumberUpdateMutation } from './use-phone-number-otp-validation';

interface IUpdatePhoneNumberModalProps {
  headerTitle?: string;
  headerSubTitle?: string;
  preventModalClose?: boolean;
}

export const phoneNumberSentFlagAtom = atomWithStorage<boolean>(
  'phoneNumberSentFlag',
  false,
  createJSONStorage<boolean>(() => localStorage)
);

export const phoneNumberSentAtom = atomWithStorage<string>(
  'phoneNumberSent',
  '',
  createJSONStorage<string>(() => localStorage)
);

export const phoneNumberVerificationDateNowAtom = atomWithStorage<number>(
  'phoneNumberVerificationDateNow',
  null,
  createJSONStorage<number>(() => localStorage)
);

export const phoneNumberVerificationModalVisibleAtom = atom<boolean>(false);

const UpdatePhoneNumberModal: React.FunctionComponent<IUpdatePhoneNumberModalProps> = ({
  headerTitle,
  headerSubTitle,
  preventModalClose,
}) => {
  const [phoneNumberVerificationModalVisible, phoneNumberVerificationModalVisibleToggler] = useAtom(
    phoneNumberVerificationModalVisibleAtom
  );
  const methods = useForm<{
    phoneNumber?: string;
  }>({
    defaultValues: {
      phoneNumber: '',
    },
  });

  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = methods;
  const { user } = useAuth();
  const handleReset = React.useCallback(() => {
    if (preventModalClose) return;
    reset({ phoneNumber: '' });
    phoneNumberVerificationModalVisibleToggler(false);
  }, [phoneNumberVerificationModalVisibleToggler, preventModalClose, reset]);
  const { t } = useTranslation('common');

  const { mutate, isPending, isError, error, isSuccess } = useEmployerPhoneNumberUpdateMutation();
  const onSubmit: SubmitHandler<{ phoneNumber: string }> = React.useCallback((data) => mutate(data), [mutate]);

  const modalProps: IFormModalWrapper = React.useMemo(() => {
    return {
      modalVisible: phoneNumberVerificationModalVisible,
      onModalClose: handleReset,
      title: headerTitle || t('update_phone_number'),
      subTitle: headerSubTitle || t('we_send_a_verification_code'),
      submitHandlerProps: {
        onClick: handleSubmit(onSubmit),
        title: t('send_verification_code'),
        loading: isPending,
      },
      onCancelHandler: handleReset,
      onCancelHandlerProps: {
        disabled: preventModalClose,
      },
      showCloseIcon: !preventModalClose,
      showMobileGestures: !preventModalClose,
      disableDrag: preventModalClose,
    };
  }, [
    handleReset,
    handleSubmit,
    headerSubTitle,
    headerTitle,
    isPending,
    onSubmit,
    phoneNumberVerificationModalVisible,
    preventModalClose,
    t,
  ]);

  React.useEffect(() => {
    if (isError) {
      setError('phoneNumber', {
        message: error?.['data']?.['error'] || t('something_went_wrong'),
      });
    }
  }, [error, isError, setError, t]);

  React.useEffect(() => {
    if (isSuccess) handleReset();
  }, [handleReset, isSuccess]);

  React.useEffect(() => {
    if (user?.phone_number) setValue('phoneNumber', user.phone_number);
  }, [setValue, user?.phone_number]);

  return (
    <ModalToggleWrapper {...modalProps}>
      <FormLabelWrapper label={t('phone_number')} isFullWidth id="phoneNumber">
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            validate: (phoneNumber) => isValidPhoneNumber(phoneNumber) || t('insert_valid_phone_number'),
          }}
          render={({ field }) => (
            <PhoneInput
              priorityCountries={['IT', 'FR', 'ES']}
              inputContainerStyles={[tw`border-neutral-100 border`]}
              variant={errors['phoneNumber'] ? 'error' : 'primary'}
              supportText={errors['phoneNumber']?.message}
              {...field}
            />
          )}
        />
      </FormLabelWrapper>
    </ModalToggleWrapper>
  );
};

export default UpdatePhoneNumberModal;

export const useShouldShowPhoneNumberVerificationModal = () => {
  const { user } = useAuth();
  const phoneNumberValid = user?.phone_number_valid;
  const phoneNumberVerified = user?.phone_number_verified;
  const phoneNumberDoesNotExist = !user?.phone_number;

  const shouldShowPhoneNumberVerificationModal = React.useMemo(() => {
    if (phoneNumberVerified || (phoneNumberValid && !phoneNumberDoesNotExist)) return false;
    return true;
  }, [phoneNumberDoesNotExist, phoneNumberValid, phoneNumberVerified]);

  return {
    phoneNumberValid,
    phoneNumberVerified,
    phoneNumberDoesNotExist,
    shouldShowPhoneNumberVerificationModal,
  };
};
