globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useMemo } from 'react';
export var useBrowserInfo = function useBrowserInfo() {
  var userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : undefined;
  return useMemo(function () {
    if (userAgent) {
      var browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
      } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
      } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
      } else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
      } else {
        browserName = null;
      }

      return browserName;
    }

    return null;
  }, [userAgent]);
};