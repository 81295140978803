globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useContext } from 'react';
import { ServiceConfigContext } from '../contexts/ServiceConfig';

var useServices = function useServices() {
  var context = useContext(ServiceConfigContext);
  if (!context) throw new Error('In order to use a service module, it needs to be wrapped in a ServiceConfigProvider');
  return context;
};

export default useServices;