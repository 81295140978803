import { useCallback, useState } from 'react';
import { useAtomValue } from 'jotai';

import { WorkerApplyPayload, useWorkerJobPositionsService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';
import { useAnalytics, useUTMSource } from '@restworld/utils-common';

import { jobPositionApiDataAtom, showViolaBadgeAtom } from '../components/job-position/job-position.atoms';

type ApplyRequestDataType = {
  jobPositionId?: string;
  referralCode?: string | string[];
  onAuthenticated?: () => void;
  onUnAuthenticated?: () => void;
  errorCallBack?: (e: unknown) => void;
  callUserAuth?: boolean;
  // origin: 'wizard' | 'cv' | 'authenticated' | 'proposal' | 'unknown';
};
type requestPayloadType = {
  workerIdArg: string;
  restaurantId?: string;
  coverLetter?: string;
  jobPositionId?: string;
  source?: string;
};
export const useApplyJob = (requestData?: ApplyRequestDataType) => {
  const { referralCode, onAuthenticated, onUnAuthenticated, errorCallBack, callUserAuth = true } = requestData || {};
  const jp = useAtomValue(jobPositionApiDataAtom);
  const { utmSource, refCode, removeRefCode } = useUTMSource();
  const { initializeAuthentication, isAuthenticated, user } = useAuth();
  const workerJobPositionsService = useWorkerJobPositionsService();
  const { trackEvent, fbTrackWorkerApplied } = useAnalytics();
  const showViolaBadge = useAtomValue(showViolaBadgeAtom);
  const auth = useAuth();

  const [loading, setLoading] = useState(false);

  const getPayload = useCallback(
    ({ workerIdArg, restaurantId, coverLetter, jobPositionId }: requestPayloadType): WorkerApplyPayload => ({
      job_position_id: jobPositionId,
      worker_id: workerIdArg,
      bypass_last_applied: true,
      source: utmSource || 'active_public_offers',
      ambassador_code: refCode || null,
      referral_code: referralCode,
      user_type: user?.type,
      restaurant_id: restaurantId,
      cover_letter: coverLetter,
      is_viola: showViolaBadge || undefined,
    }),
    [utmSource, refCode, referralCode, user?.type, showViolaBadge]
  );
  const applyJobPosition = useCallback(
    async (requestData: requestPayloadType) => {
      try {
        setLoading(true);
        const resp = await workerJobPositionsService.jobApply(getPayload(requestData));
        if (!isAuthenticated) {
          if (onUnAuthenticated) onUnAuthenticated(); // on success callback for unauthenticated user
          if (resp?.data?.jwt && callUserAuth) initializeAuthentication(resp.data.jwt);
        } else {
          if (onAuthenticated) onAuthenticated();
          if (user?.type === 'lead') initializeAuthentication();
        } // on success callback for authenticated user

        if (requestData.jobPositionId && !requestData.restaurantId) {
          trackEvent('apply_job', {
            email: auth.user?.email,
            origin: requestData.source,
            restaurant_name: jp?.restaurant?.restaurant_name,
            city: jp?.restaurant?.locality,
            job_position_id: requestData?.jobPositionId,
            min_salary: jp?.min_salary,
            max_salary: jp?.max_salary,
          });
        }
        const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
        if (jp?.hirematic_flag && isProd) window?.ppln('track', 'conversionRegistered', { step: 'end' });

        trackEvent('worker_successfully_applied', { jobApplication: resp.data.job_application, jobPosition: jp });
        fbTrackWorkerApplied();
        removeRefCode();
        return resp.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (errorCallBack) errorCallBack(e);
        console.error(e, 'error on applying job position');
        return e;
      } finally {
        setLoading(false);
      }
    },
    [
      workerJobPositionsService,
      getPayload,
      isAuthenticated,
      trackEvent,
      jp,
      fbTrackWorkerApplied,
      removeRefCode,
      onUnAuthenticated,
      callUserAuth,
      initializeAuthentication,
      onAuthenticated,
      user?.type,
      auth.user?.email,
      errorCallBack,
    ]
  );
  return {
    applyJobPosition,
    jobPositionLoading: loading,
  };
};
