import { useState, useEffect, useCallback } from 'react';
import { ApplicationPerformanceWorkerMetricsType, useJobPositionsService } from '@restworld/data-services';
import { useJobPositionId } from './use-job-position-id';

export const useJobApplicationPerformance = () => {
  const jobPositionId = useJobPositionId();
  const [jobApplicationPerformance, setJobApplicationPerformance] =
    useState<ApplicationPerformanceWorkerMetricsType | null>(null);
  const [loading, setLoading] = useState(false);
  const jobPositionDataService = useJobPositionsService();
  const getRestaurantPlacesInformation = useCallback(async () => {
    setLoading(true);
    await jobPositionDataService
      .getJobPositionApplicationPerformanceWorkerMetrics(jobPositionId)
      .then((res) => setJobApplicationPerformance(res.data))
      .catch(() => setJobApplicationPerformance(null));
    setLoading(false);
  }, [jobPositionDataService, jobPositionId]);
  useEffect(() => {
    if (jobPositionId) getRestaurantPlacesInformation();
  }, [jobPositionId]);
  return { jobApplicationPerformance, loading };
};
