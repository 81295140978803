import { Fragment, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { listIconType } from '@restworld/utils-common';
import { Title, SecondaryList, Wrapper } from '@restworld/ui-ds';

import { jobRequirementsCategoryType } from './job-other-requirements.constants';
import { otherRequirementOptions, educationOptions, certificateOptions, flagMap } from './job-other-requirements.mocks';
import { OtherRequirementsProps } from '../job-skills-block';

export function OtherRequirements({
  otherRequirements = [],
  education = [],
  certificates = [],
  languages = [],
}: OtherRequirementsProps) {
  const { t: commonTranslate } = useTranslation('common');
  const jobRequirementsCategory: jobRequirementsCategoryType = useMemo(
    () => [
      ...(otherRequirementOptions.length
        ? [
            {
              key: 'other-requirements',
              title: commonTranslate('other_requirements'),
              options: [...otherRequirementOptions].filter((v) => otherRequirements.includes(v.key)),
            },
          ]
        : []),
      ...(educationOptions.length
        ? [
            {
              key: 'education',
              title: commonTranslate('education'),
              options: [...educationOptions].filter((v) => education.includes(v.key)),
            },
          ]
        : []),
      ...(certificateOptions
        ? [
            {
              key: 'certificate',
              title: commonTranslate('certificate'),
              options: [...certificateOptions].filter((v) => certificates.includes(v.key)),
            },
          ]
        : []),
      ...(languages
        ? [
            {
              key: 'language',
              title: commonTranslate('language'),
              options: languages.map(({ key, label, level }) => ({
                key,
                label: `${label} - ${level}`,
                icon: flagMap[key as keyof typeof flagMap] as listIconType,
              })),
            },
          ]
        : []),
    ],
    [certificates, commonTranslate, education, languages, otherRequirements]
  );
  const allOptionsLength = [...jobRequirementsCategory].map((v) => v.options).flat(1);
  return allOptionsLength?.length ? (
    <Wrapper>
      <Title fontSize="base" content={commonTranslate('other_requirements')} />
      <>
        {[...jobRequirementsCategory].map(({ key, options }) =>
          options.length ? (
            <Fragment key={key}>
              <SecondaryList
                fontSize="base"
                textColor="gray"
                itemList={[...options]}
                orientation="vertical"
                isWrapList={false}
              />
            </Fragment>
          ) : null
        )}
      </>
    </Wrapper>
  ) : null;
}

export default OtherRequirements;
