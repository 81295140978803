import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useReducer, useEffect } from 'react';
import { useGlobalsService } from '@restworld/data-services';
var VariableTypes;

(function (VariableTypes) {
  VariableTypes["LOADING"] = "LOADING";
  VariableTypes["SET_VARIABLE_OPTIONS"] = "SET_VARIABLE_OPTIONS";
  VariableTypes["SET_PENDING_VARIABLE_OPTIONS"] = "SET_PENDING_VARIABLE_OPTIONS";
})(VariableTypes || (VariableTypes = {}));

export var useVariableOptions = function useVariableOptions() {
  var globalService = useGlobalsService();

  var _useReducer = useReducer(variableOptionReducer, {
    loading: false,
    variableOptions: {},
    pendingVariableNames: []
  }),
      variableOptionsState = _useReducer[0],
      dispatch = _useReducer[1];

  var variableOptions = variableOptionsState.variableOptions,
      loading = variableOptionsState.loading,
      pendingVariableNames = variableOptionsState.pendingVariableNames;
  var fetchVariable = useCallback(function (variableName) {
    var needUpdate = false;
    variableName.forEach(function (v) {
      if (!(pendingVariableNames !== null && pendingVariableNames !== void 0 && pendingVariableNames.includes(v))) needUpdate = true;
    });
    if (!needUpdate) return;
    var pendingVariableNamesCp = pendingVariableNames ? [].concat(_toConsumableArray(pendingVariableNames), _toConsumableArray(variableName)) : _toConsumableArray(variableName).filter(function (variable, index, self) {
      return self.findIndex(function (v) {
        return v === variable;
      }) === index;
    }).sort();
    dispatch({
      type: VariableTypes.SET_PENDING_VARIABLE_OPTIONS,
      payload: {
        pendingVariableNames: pendingVariableNamesCp
      }
    });
  }, [dispatch, pendingVariableNames]);
  var fetchVariableOptions = useCallback(function (variableNames) {
    dispatch({
      type: VariableTypes.LOADING,
      payload: {
        loading: true
      }
    });
    Promise.all(variableNames.map(function (variableName) {
      return globalService.fetchVariableOptions(variableName);
    })).then(function (res) {
      var newVars = {};
      res.forEach(function (r, index) {
        newVars[variableNames[index]] = r.data;
      });
      dispatch({
        type: VariableTypes.SET_VARIABLE_OPTIONS,
        payload: {
          variableOptions: _objectSpread(_objectSpread({}, variableOptions), newVars)
        }
      });
    })["catch"](function (err) {
      console.error(err, 'error fetching options');
      return err;
    })["finally"](function () {
      dispatch({
        type: VariableTypes.LOADING,
        payload: {
          loading: false
        }
      });
    });
  }, [dispatch, globalService, variableOptions]);
  useEffect(function () {
    if (variableOptions && pendingVariableNames) {
      var _useVariableOptions = pendingVariableNames.filter(function (v) {
        return !(v in variableOptions);
      });

      if (_useVariableOptions.length) fetchVariableOptions(_useVariableOptions);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [pendingVariableNames]);
  return {
    loading: loading,
    fetchVariable: fetchVariable,
    variableOptions: variableOptions
  };
}; // --> reducer

var variableOptionReducer = function variableOptionReducer(state, action) {
  var type = action.type,
      payload = action.payload;

  switch (type) {
    case VariableTypes.LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: payload.loading
      });

    case VariableTypes.SET_VARIABLE_OPTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        variableOptions: payload.variableOptions
      });

    case VariableTypes.SET_PENDING_VARIABLE_OPTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        pendingVariableNames: payload.pendingVariableNames
      });

    default:
      return _objectSpread({}, state);
  }
};