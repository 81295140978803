import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import 'twin.macro';
import { useFormContext, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { TwStyle } from 'twin.macro';

import { OptionsType } from '@restworld/utils-common';
import { ChipCheckboxGroup, Title } from '@restworld/ui-ds';

interface IAppProps {
  options: OptionsType[];
  id: string;
  title?: string | JSX.Element;
  isSingleSelectMode?: boolean;
  customChipGroupStyles?: TwStyle;
  componentType?: string;
  testId?: string;
}
export const ChipCheckboxFormField: React.FunctionComponent<IAppProps> = ({
  options,
  id,
  title,
  isSingleSelectMode,
  customChipGroupStyles,
  componentType,
  testId,
}) => {
  // const { trackEvent } = useAnalytics();
  const { control, setValue, getValues } = useFormContext();
  const renderCallback = useCallback(
    ({ field }: { field: ControllerRenderProps<FieldValues, string> }) => {
      const onSingleMode = {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (field.value?.[0] === e.target.id) field.onChange([]);
          else field.onChange([e.target.id]);
        },
      };
      const onMultiSelectMode = {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          const fieldValue = field.value ? [...field.value] : [];
          const value = e.target.id;
          let newData = fieldValue.includes(value) ? fieldValue.filter((v) => v !== value) : [...fieldValue, value];
          if (id.includes('rating')) newData = getSeriesOfHigherNumbers(+value, 6 - +value).map((v) => v.toString());
          if (id === 'domain_interest' && componentType === 'explorer_filter') {
            const occupationIds = getValues('occupation_ids');
            const newOccupationIds =
              occupationIds &&
              Object.keys(occupationIds).reduce((acc, key) => {
                if (newData.includes(key)) {
                  acc[key] = occupationIds[key];
                }
                return acc;
              }, {});
            setValue('occupation_ids', newOccupationIds);
          }
          field.onChange(newData);
        },
      };
      return (
        <ChipCheckboxGroup
          {...{
            ...field,
            ...(isSingleSelectMode ? onSingleMode : onMultiSelectMode),
            options,
            customChipGroupStyles,
            testId: testId,
          }}
        />
      );
    },
    [componentType, customChipGroupStyles, getValues, id, isSingleSelectMode, options, setValue, testId]
  );
  return (
    <div tw="flex gap-4 flex-col">
      {!!title && <Title content={title} fontSize="large" fontWeight="semibold" />}
      <Controller name={id} control={control} rules={{ required: false }} render={renderCallback} />
    </div>
  );
};

export default ChipCheckboxFormField;
const getSeriesOfHigherNumbers = (startingNumber: number, length: number) => {
  const series: number[] = [];
  series.push(startingNumber);
  for (let i = 1; i < length; i++) {
    const nextNumber = series[i - 1] + 1;
    series.push(nextNumber);
  }
  return series;
};
