import { memo } from 'react';
import { useAtomValue } from 'jotai';
import { isPhoneNumberLoginModalOpen } from '../../context/auth-flow';
import { LoginByPhoneForm, LoginByPhoneHeader } from './login-by-phone-form';

export const LoginByPhoneModal = memo(() => {
  const open = useAtomValue(isPhoneNumberLoginModalOpen);
  if (open) {
    return (
      <>
        <LoginByPhoneHeader {...{ isModal: true }} />
        <LoginByPhoneForm {...{ isModal: true }} />
      </>
    );
  }
});
