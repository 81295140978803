globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useState, useEffect } from 'react';
export var useScroll = function useScroll() {
  var _useState = useState(false),
      isScrolled = _useState[0],
      setScrollTop = _useState[1];

  var handleScroll = function handleScroll() {
    return setScrollTop(window.scrollY > 100);
  };

  var win = true ? window : undefined;
  useEffect(function () {
    win === null || win === void 0 ? void 0 : win.addEventListener('scroll', handleScroll);
    return function () {
      win === null || win === void 0 ? void 0 : win.removeEventListener('scroll', handleScroll);
    };
  }, [win]);
  return {
    isScrolled: isScrolled,
    scrollY: win === null || win === void 0 ? void 0 : win.scrollY
  };
};