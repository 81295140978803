import type { LayerProps } from 'react-map-gl';

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'job-positions',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#fff', 100, '#fff', 750, '#fff'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'job-positions',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
  },
};

export const unclusteredLayer: LayerProps = {
  id: 'unclustered',
  type: 'circle',
  source: 'job-positions',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#fff',
    'circle-radius': 20,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff',
  },
};
export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'symbol',
  source: 'job-positions',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'text-field': '1',
    'text-size': 12,
  },
};
