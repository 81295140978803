import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useReducer, useCallback, useEffect } from 'react';
import { useGlobalsService } from '@restworld/data-services';
var StatusTypes;

(function (StatusTypes) {
  StatusTypes["LOADING"] = "LOADING";
  StatusTypes["SET_STATUS_OPTIONS"] = "SET_STATUS_OPTIONS";
  StatusTypes["SET_PENDING_STATUS_OPTIONS"] = "SET_PENDING_STATUS_OPTIONS";
})(StatusTypes || (StatusTypes = {}));

export var useStatusOptions = function useStatusOptions() {
  var globalService = useGlobalsService();

  var _useReducer = useReducer(statusOptionReducer, {
    loading: false,
    statusOptions: {},
    pendingStatusPrefix: []
  }),
      statusOptionsState = _useReducer[0],
      dispatch = _useReducer[1];

  var statusOptions = statusOptionsState.statusOptions,
      loading = statusOptionsState.loading,
      pendingStatusPrefix = statusOptionsState.pendingStatusPrefix;
  var fetchStatus = useCallback(function (prefix) {
    var needUpdate = false;
    prefix.forEach(function (p) {
      if (!(pendingStatusPrefix !== null && pendingStatusPrefix !== void 0 && pendingStatusPrefix.includes(p))) needUpdate = true;
    });
    if (!needUpdate) return;
    dispatch({
      type: StatusTypes.SET_PENDING_STATUS_OPTIONS,
      payload: {
        pendingStatusPrefix: pendingStatusPrefix ? [].concat(_toConsumableArray(pendingStatusPrefix), _toConsumableArray(prefix)) : _toConsumableArray(prefix).filter(function (prefix, index, self) {
          return self.findIndex(function (p) {
            return p === prefix;
          }) === index;
        }).sort()
      }
    });
  }, [dispatch, pendingStatusPrefix]);
  var fetchStatusOptions = useCallback(function (prefix) {
    dispatch({
      type: StatusTypes.LOADING,
      payload: {
        loading: true
      }
    });
    Promise.all(prefix.map(function (p) {
      return globalService.fetchStatusOptions({
        limit: 100,
        offset: 0,
        prefix: p
      });
    })).then(function (res) {
      var newStatus = {};
      res.forEach(function (r, index) {
        newStatus[prefix[index]] = r.data;
      });

      var statusOptionsCp = _objectSpread(_objectSpread({}, statusOptions), newStatus);

      dispatch({
        type: StatusTypes.SET_STATUS_OPTIONS,
        payload: {
          statusOptions: statusOptionsCp
        }
      });
    })["catch"](function (err) {
      console.error(err, 'error fetching fetchStatusOptions');
      return err;
    })["finally"](function () {
      dispatch({
        type: StatusTypes.LOADING,
        payload: {
          loading: false
        }
      });
    });
  }, [dispatch, globalService, statusOptions]);
  useEffect(function () {
    if (pendingStatusPrefix && statusOptions) {
      var usePendingOptions = pendingStatusPrefix.filter(function (p) {
        return !(p in statusOptions);
      });
      if (usePendingOptions.length) fetchStatusOptions(usePendingOptions);
    }
  }, [pendingStatusPrefix]);
  return {
    fetchStatus: fetchStatus,
    loading: loading,
    statusOptions: statusOptions
  };
}; // --> reducer

var statusOptionReducer = function statusOptionReducer(state, action) {
  var type = action.type,
      payload = action.payload;

  switch (type) {
    case StatusTypes.LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: payload.loading
      });

    case StatusTypes.SET_STATUS_OPTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        statusOptions: payload.statusOptions
      });

    case StatusTypes.SET_PENDING_STATUS_OPTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        pendingStatusPrefix: payload.pendingStatusPrefix
      });

    default:
      return _objectSpread({}, state);
  }
}; // --> type