import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { RestaurantStatistics } from '@restworld/ui-ds';

import { companyProfileAtom } from '../../job-position/job-position.atoms';

export const CompanyStatistics = () => {
  const { coversNumber, staffSize, staffSizeKitchen, staffSizeFloor } = useAtomValue(companyProfileAtom);
  const staffSizeKitchenNumber = useMemo(
    () =>
      staffSizeKitchen && staffSizeFloor
        ? `${staffSizeKitchen + staffSizeFloor}`
        : staffSize
        ? staffSize.toString()
        : '-',
    [staffSize, staffSizeFloor, staffSizeKitchen]
  );
  return (
    <RestaurantStatistics
      {...{
        covers: `${coversNumber || '-'}`,
        staffSize: staffSizeKitchenNumber,
        variant: 'horizontal',
      }}
    />
  );
};
