import { useAtomValue } from 'jotai';

import { DescriptionBlock } from '@restworld/ui-ds';

import { jobPositionApiDataAtom } from '../job-position.atoms';

export const JobDescriptionCard = () => {
  const jobPositionApiData = useAtomValue(jobPositionApiDataAtom);
  return jobPositionApiData?.job_description ? (
    <>
      <DescriptionBlock subTitle={jobPositionApiData?.job_title} jobContractDesc={jobPositionApiData.job_description} />
      <hr tw="text-gray-300" />
    </>
  ) : null;
};
