import { ReactNode } from 'react';
import tw from 'twin.macro';
import { useWizard } from 'react-use-wizard';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline';

import { Title } from '@restworld/ui-ds';
import { ItalyMapIcon, MarkerPinIcon } from '@restworld/utils-common';
import { useAuth } from '@restworld/feature-authentication';

import { StepWrapper, areaSelectionAtom } from '../job-explorer-save-research-v2';

export const RegionalAreaSelection = () => {
  const { isAuthenticated } = useAuth();
  const { goToStep, nextStep } = useWizard();
  const setAreaSelection = useSetAtom(areaSelectionAtom);
  const { t } = useTranslation('job-explorer-page');
  return (
    <StepWrapper>
      <div data-cy="regional-selection-area" tw="flex flex-col gap-6">
        <div tw="flex flex-col gap-2">
          <Title
            content={t('where_looking_for_next_job')}
            fontWeight="semibold"
            fontSize="superLarge"
            twStyle={tw`leading-[28.8px]`}
            testId="save-research-title"
          />
          <Title
            content={!isAuthenticated ? t('tried_of_searching') : t('chose_area_start_search_jp')}
            fontWeight="normal"
            fontSize="base"
            twStyle={tw`leading-[24px]`}
            textColor="gray-500"
          />
        </div>
        <div tw="flex flex-col md:flex-row gap-4 items-center justify-center w-full">
          <SelectCard
            title={t('italy_and_abroad')}
            icon={<GlobeEuropeAfricaIcon className="h-7 w-7" />}
            handleClick={() => {
              setAreaSelection('italy_and_abroad');
              goToStep(2);
            }}
            testId="specific-area"
          />
          <SelectCard
            title={t('all_Italy')}
            icon={<ItalyMapIcon />}
            handleClick={() => {
              goToStep(2);
              setAreaSelection('whole_italy');
            }}
            testId="all-italy"
          />
          <SelectCard
            title={t('specific_area')}
            icon={<MarkerPinIcon />}
            handleClick={() => {
              setAreaSelection('regional');
              nextStep();
            }}
            testId="specific-area"
          />
        </div>
      </div>
    </StepWrapper>
  );
};
const SelectCard = ({
  title,
  icon,
  handleClick,
  testId,
}: {
  title: string;
  icon: ReactNode;
  handleClick: () => void;
  testId?: string;
}) => (
  <StyledSelectCard onClick={() => handleClick()} data-cy={testId}>
    <span tw="flex flex-col gap-3 items-center text-center justify-center">
      {icon}
      {title}
    </span>
  </StyledSelectCard>
);

const StyledSelectCard = tw.div`cursor-pointer border border-primary-50 bg-primary-50 w-full md:w-1/2 min-h-[132px] flex items-center justify-center rounded-lg p-4 hover:(ring-4 ring-primary-200 border border-primary-800) transition ease-in-out duration-200`;
