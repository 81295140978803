import { useCallback } from 'react';
import 'twin.macro';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { Button, Title } from '@restworld/ui-ds';
import { BinocularGirlIllustration, defaultItalyCoordinates } from '@restworld/utils-common';

import { defaultJobCoordinatesPayload, defaultJobListPayload, defaultExplorerSortFilter } from '../job-explorer.types';
import { isRestaurantsExplorerAtom } from '../job-explorer-map';
import { getMapRefAtom, jobCoordinateParamsAtom } from '../job-explorer-map.atoms';
import { jobListParamsAtom, jobSortFilterAtom } from '../job-explorer-list.atoms';
import { isResetFilterAtom, moreFiltersAtom } from '../job-explorer-filters.atoms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDataNotAvailableUIProps {}

const DataNotAvailableUI: React.FunctionComponent<IDataNotAvailableUIProps> = (props) => {
  const { clickHandler } = useDataNotAvailable();
  const isRestaurantsExplorer = useAtom(isRestaurantsExplorerAtom);
  return (
    <div tw="flex flex-col justify-center items-center gap-4">
      <BinocularGirlIllustration />
      <Title
        fontWeight="semibold"
        fontSize="extraLarge"
        content={`Non ci sono ${isRestaurantsExplorer ? 'locali' : 'posizioni'} con i filtri applicati`}
      />
      <Title
        textColor="gray"
        fontWeight="normal"
        fontSize="base"
        content={`Prova a modificare i filtri per trovare ${isRestaurantsExplorer ? 'locali' : 'posizioni'} aperte.`}
      />
      <Button variant="secondary-outline" title="Azzera tutti i filtri" onClick={clickHandler} />
    </div>
  );
};

export default DataNotAvailableUI;
const useDataNotAvailable = () => {
  const setMoreFilters = useSetAtom(moreFiltersAtom);
  const setIsResetFilter = useSetAtom(isResetFilterAtom);
  const setSortFilter = useSetAtom(jobSortFilterAtom);
  const setJobCoordinateParams = useSetAtom(jobCoordinateParamsAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const resetCallback = useCallback(() => {
    setJobCoordinateParams(defaultJobCoordinatesPayload);
    setJobListParams(defaultJobListPayload);
    setSortFilter(defaultExplorerSortFilter);
    setMoreFilters({});
    setIsResetFilter(true);
    mapRef.current?.flyTo({
      center: defaultItalyCoordinates,
      zoom: 5,
      duration: 2000,
    });
  }, [mapRef, setIsResetFilter, setJobCoordinateParams, setJobListParams, setMoreFilters, setSortFilter]);
  const clickHandler = useCallback(() => {
    resetCallback();
  }, [resetCallback]);
  return {
    clickHandler,
  };
};
