import { FunctionComponent } from 'react';
import { JobPosition } from '@restworld/utility-types';
import { JobPostingJsonLd } from 'next-seo';
import { addDays } from 'date-fns';

interface Props {
  jobPositionStatic: Partial<JobPosition>;
}
export const JobPositionSEO: FunctionComponent<Props> = ({ jobPositionStatic: jobPosition }) => {
  const occupation = jobPosition?.occupation?.label_it?.label;
  const title = jobPosition?.job_title || occupation;
  const showRestaurantDetails = jobPosition?.show_restaurant_details || false;
  const restaurant = jobPosition?.restaurant;

  return (
    <JobPostingJsonLd
      title={title ?? ''}
      description={
        '<p>' + (jobPosition?.job_description || '') + '<br />' + (jobPosition?.job_contract_description || '') + '</p>'
      }
      datePosted={jobPosition?.published_at || jobPosition?.inserted_at}
      hiringOrganization={{
        name: showRestaurantDetails ? restaurant?.restaurant_name : 'Restworld',
        sameAs: showRestaurantDetails ? restaurant?.website_url : 'https://www.restworld.it/',
        logo: showRestaurantDetails
          ? restaurant?.restaurant_logo
          : 'https://restworld-illustrations.fra1.cdn.digitaloceanspaces.com/logo/rw_viola.png',
      }}
      validThrough={addDays(new Date(), 30).toISOString()}
      jobLocation={{
        postalCode: restaurant?.postal_code ?? '',
        addressLocality: restaurant?.locality ?? '',
        addressCountry: restaurant?.country ?? '',
        addressRegion: restaurant?.administrative_area_level_1 ?? '',
        streetAddress: showRestaurantDetails ? restaurant?.address : '',
      }}
      baseSalary={
        jobPosition
          ? {
              currency: 'EURO',
              value: formatBaseSalary(jobPosition),
              unitText: formatSalaryUnit(jobPosition),
            }
          : null
      }
    />
  );
};

const formatBaseSalary = (jobPosition: Partial<JobPosition>) => {
  if (jobPosition.salary) return jobPosition.salary;
  if (!!jobPosition.min_salary && !!jobPosition.max_salary) {
    const min = Number(jobPosition.min_salary);
    const max = Number(jobPosition.max_salary);
    if (isNaN(min) || isNaN(max)) return jobPosition.min_salary;
    return (min + max) / 2;
  }
  return jobPosition.min_salary || jobPosition.max_salary;
};

const formatSalaryUnit = (jobPosition: Partial<JobPosition>) => {
  // Given a unitText like "Mensile", it returns "MONTH"
  // https://developers.google.com/search/docs/data-types/job-posting?hl=en
  switch (jobPosition?.payment_type_var?.key) {
    case 'hour':
      return 'HOUR';
    case 'service':
      return 'DAY';
    default:
      return 'MONTH';
  }
};
