import { useAtom, useSetAtom } from 'jotai';
import {
  ContentType as ModalContentType,
  accountExists,
  closeEmailLoginModal,
  setActiveContent as setActiveModalContent,
  openPhoneNumberLoginModal,
  openSignupModal,
  closeAuthFlowModal,
} from '../../../context/auth-flow';
import { useRouter } from 'next/router';
import { useAnalytics } from '@restworld/utils-common';

export type UseLoginFormProps = {
  isModal?: boolean;
};

export function useEmailLoginUtils({ isModal = true }: UseLoginFormProps) {
  const closeEmailModal = useSetAtom(closeEmailLoginModal);
  const [existingAccount, setExistingAccount] = useAtom(accountExists);
  const setActiveContent = useSetAtom(setActiveModalContent);
  const onOpenPhoneNumberLoginModal = useSetAtom(openPhoneNumberLoginModal);
  const onOpenSignupModal = useSetAtom(openSignupModal);
  const closeModal = useSetAtom(closeAuthFlowModal);
  const router = useRouter();
  // const { trackEvent } = useAnalytics();

  function onClose() {
    if (isModal) {
      exitEmailLoginModal();
      setActiveContent(ModalContentType.NO_CONTENT);
      closeModal();
    }
  }
  function exitEmailLoginModal() {
    if (existingAccount.email) {
      setExistingAccount((prev) => ({ ...prev, email: null }));
    }
    if (isModal) {
      closeEmailModal();
    }
  }
  function navigateToPhoneNumberLogin() {
    exitEmailLoginModal();
    setActiveContent(ModalContentType.PHONE_NUMBER_LOGIN);
    if (isModal) {
      onOpenPhoneNumberLoginModal();
    }
    // trackEvent('Auth flow', {
    //   phase: 'Phone number login',
    //   step: 'Start phone number login',
    //   mode: isModal ? 'modal' : 'page',
    // });
  }
  function navigateToSignup() {
    exitEmailLoginModal();
    setActiveContent(ModalContentType.SIGNUP);
    if (isModal) {
      onOpenSignupModal();
    } else {
      router.push('/signup');
    }
  }
  return { navigateToPhoneNumberLogin, exitEmailLoginModal, existingAccount, navigateToSignup, onClose };
}
