import { itemListProps } from '@restworld/utils-common';

const otherRequirementOptions: itemListProps[] = [
  {
    key: 'vehicle',
    label: 'Mezzo proprio',
    icon: 'CarFront',
  },
  {
    key: 'haccp',
    label: 'HACCP',
    icon: 'NewspaperIcon',
  },
];
const educationOptions: itemListProps[] = [
  {
    key: 'associate-degree',
    label: 'Associate degree',
    icon: 'AcademicCapIcon',
  },
  {
    key: 'bachelor-degree',
    label: 'Bachelor degree',
    icon: 'AcademicCapIcon',
  },
  {
    key: 'graduate degree',
    label: 'Graduate degree',
    icon: 'AcademicCapIcon',
  },
  {
    key: 'professional-degree',
    label: 'Professional degree.',
    icon: 'AcademicCapIcon',
  },
];
const certificateOptions: itemListProps[] = [
  {
    key: 'italian-culinary-program',
    label: 'Italian Culinary Program',
    icon: 'NewspaperIcon',
  },
  {
    key: 'italian-pastry-program',
    label: 'Italian Pastry Program',
    icon: 'NewspaperIcon',
  },
];
const flagMap = {
  english: 'UKFlag',
  german: 'GermanFlag',
  russian: 'RussiaFlag',
  italian: 'ItalyFlag',
  french: 'FranceFlag',
  spanish: 'SpainFlag',
};
const languageOptions: itemListProps[] = [
  {
    key: 'english',
    label: 'English - intermediate',
    icon: 'UKFlag',
  },
  {
    key: 'german',
    label: 'German - basic',
    icon: 'GermanFlag',
  },
  {
    key: 'russian',
    label: 'Russian - advance',
    icon: 'RussiaFlag',
  },
  {
    key: 'italian',
    label: 'Italian - basic',
    icon: 'ItalyFlag',
  },
  {
    key: 'french',
    label: 'French - intermediate',
    icon: 'FranceFlag',
  },
  {
    key: 'spanish',
    label: 'Spanish - basic',
    icon: 'SpainFlag',
  },
];
const defaultArgs = {
  otherRequirements: ['vehicle'],
  education: ['associate-degree'],
  certificates: ['italian-culinary-program'],
  languages: [
    {
      key: 'english',
      label: 'English',
      icon: 'UKFlag',
      level: 'intermediate',
    },
    {
      key: 'german',
      label: 'German',
      icon: 'GermanFlag',
      level: 'basic',
    },
  ],
  workerSkills: [92],
};
const otherRequireMentIconMap = {
  vehicle: 'CarFront',
  haccp: 'NewspaperIcon',
};
const jobRequirementArgTypes = {
  other_requirements: { control: 'inline-check', options: otherRequirementOptions.map((v) => v.key) },
  education: { control: 'inline-check', options: educationOptions.map((v) => v.key) },
  certificates: { control: 'inline-check', options: certificateOptions.map((v) => v.key) },
};
export {
  otherRequirementOptions,
  educationOptions,
  certificateOptions,
  languageOptions,
  defaultArgs,
  otherRequireMentIconMap,
  jobRequirementArgTypes,
  flagMap,
};
