import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { aggregateWorkdayFn } from './job-shifts.utils';
export var useJobShiftsList = function useJobShiftsList(_ref) {
  var daysRequiredPerWeek = _ref.daysRequiredPerWeek,
      daysRequiredPerWeekNumber = _ref.daysRequiredPerWeekNumber,
      shifts = _ref.shifts;
  var fullRestTimeItemList = useFullRestTimeItemList({
    daysRequiredPerWeek: daysRequiredPerWeek,
    daysRequiredPerWeekNumber: daysRequiredPerWeekNumber
  });
  var shiftList = useShifts({
    shifts: shifts
  });
  var itemList = useMemo(function () {
    return [].concat(_toConsumableArray(fullRestTimeItemList), _toConsumableArray(shiftList));
  }, [fullRestTimeItemList, shiftList]);
  return itemList;
};

var useShifts = function useShifts(_ref2) {
  var shifts = _ref2.shifts;
  var shiftList = useMemo(function () {
    var list = [];

    if (shifts !== null && shifts !== void 0 && shifts.length) {
      shifts.map(function (data, indx) {
        var daysPerWeekNumber = data.days_per_week_number,
            daysPerWeek = data.days_per_week,
            _data$category_other = data.category_other,
            categoryOther = _data$category_other === void 0 ? '' : _data$category_other,
            _data$hours = data.hours,
            hours = _data$hours === void 0 ? [] : _data$hours,
            categoryVar = data.category_var;
        var subTitle = categoryOther;

        if (hours.length) {
          subTitle = hours.reduce(function (previousValue, currentValue, currentIndex) {
            var startAt = currentValue.start_at,
                endAt = currentValue.end_at;
            var isLastIndex = hours.length > 1 && currentIndex === hours.length - 1;
            var returnString = "".concat(previousValue, " ").concat(isLastIndex ? 'e' : '').concat(currentIndex > 0 ? ' d' : 'D', "alle ").concat(startAt, " alle ").concat(endAt);
            return returnString;
          }, '');
        } else if (categoryVar !== null && categoryVar !== void 0 && categoryVar.label && (categoryVar === null || categoryVar === void 0 ? void 0 : categoryVar.key) !== 'other') subTitle = categoryVar === null || categoryVar === void 0 ? void 0 : categoryVar.label;

        var label = "".concat(daysPerWeek !== null && daysPerWeek !== void 0 && daysPerWeek.length ? "".concat(aggregateWorkdayFn(daysPerWeek)) : daysPerWeekNumber ? "".concat(daysPerWeekNumber, " giorni su 7") : (categoryVar === null || categoryVar === void 0 ? void 0 : categoryVar.label) || categoryOther);
        var shouldBeVisiblePartTime = Boolean(hours.length || (categoryVar === null || categoryVar === void 0 ? void 0 : categoryVar.label) || categoryOther);
        var returnShift = {
          key: indx.toString(),
          label: label,
          icon: 'PartTimeClock',
          subTitle: subTitle
        };
        if (shouldBeVisiblePartTime) list.push(returnShift);
        return returnShift;
      });
    }

    return list;
  }, [shifts]);
  return shiftList;
};

var useFullRestTimeItemList = function useFullRestTimeItemList(_ref3) {
  var daysRequiredPerWeek = _ref3.daysRequiredPerWeek,
      daysRequiredPerWeekNumber = _ref3.daysRequiredPerWeekNumber;

  var _useTranslation = useTranslation('job-position-page'),
      t = _useTranslation.t;

  var shouldBeVisibleFullRest = Boolean((daysRequiredPerWeek === null || daysRequiredPerWeek === void 0 ? void 0 : daysRequiredPerWeek.length) || daysRequiredPerWeekNumber);
  var getSubTitleForFullRest = useCallback(function (isInverted) {
    return daysRequiredPerWeek !== null && daysRequiredPerWeek !== void 0 && daysRequiredPerWeek.length ? aggregateWorkdayFn(daysRequiredPerWeek, isInverted) : "".concat(daysRequiredPerWeekNumber ? "".concat(isInverted ? 7 - daysRequiredPerWeekNumber : daysRequiredPerWeekNumber, " giorni su 7") : '');
  }, [daysRequiredPerWeek, daysRequiredPerWeekNumber]);
  var fullRestTimeItemList = useMemo(function () {
    return [].concat(_toConsumableArray(shouldBeVisibleFullRest ? [{
      key: 'gioni-di-lavoro',
      label: t('job_shift.full_time'),
      icon: 'FullTimeClock',
      subTitle: getSubTitleForFullRest(false)
    }] : []), _toConsumableArray(shouldBeVisibleFullRest ? [{
      key: 'giorni-di-riposo',
      label: t('job_shift.part_time'),
      icon: 'RestTimeSofa',
      subTitle: getSubTitleForFullRest(true)
    }] : []));
  }, [getSubTitleForFullRest, shouldBeVisibleFullRest, t]);
  return fullRestTimeItemList;
};