globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var sliceContent = function sliceContent(content, maxChars) {
  return maxChars && content.length > maxChars ? "".concat(content.slice(0, maxChars), "...") : content;
};