import * as React from 'react';
import tw from 'twin.macro';

import { Button, ExitConfirmation } from '@restworld/ui-ds';

import { useSpontaneousCoverLetterEntryContext } from './spontaneous-apply-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpontaneousApplyCoverLetterLayoutProps {
  closeModal?: () => void;
  children: React.ReactNode;
}

const SpontaneousApplyCoverLetterLayout: React.FunctionComponent<SpontaneousApplyCoverLetterLayoutProps> = ({
  closeModal,
  children,
}) => {
  const {
    onChangeCoverLetterModalSection: onChangeModalSection,
    coverLetterModalSection: modalSection,
    isExitConfirmation,
    toggleExitConfirmation,
  } = useSpontaneousCoverLetterEntryContext();

  const onClickHandler = React.useCallback(() => {
    if (['application-success-component'].includes(modalSection)) {
      if (closeModal) closeModal();
      return;
    }
    if (isExitConfirmation) {
      toggleExitConfirmation(false);
      return;
    }
    if (modalSection === 'cover-letter-quick-guide') {
      onChangeModalSection('cover-letter-input-area');
      return;
    }
    toggleExitConfirmation(true);
  }, [closeModal, isExitConfirmation, modalSection, onChangeModalSection, toggleExitConfirmation]);

  const backButton = React.useMemo(
    () =>
      modalSection === 'cover-letter-input-area' && !isExitConfirmation ? (
        <Button
          icon="ArrowLeftIcon"
          variant="ghost"
          onClick={() => {
            if (modalSection === 'cover-letter-input-area') {
              onChangeModalSection('cover-letter-confirmation');
              return;
            }
          }}
        />
      ) : null,
    [isExitConfirmation, modalSection, onChangeModalSection]
  );

  return (
    <div tw="flex flex-col gap-4 w-full">
      <div tw="flex items-center">
        {backButton}
        {closeModal ? (
          <Button icon="XMarkIconBlack" variant="ghost" onClick={onClickHandler} twStyle={tw`ml-auto`} />
        ) : null}
      </div>
      {isExitConfirmation ? (
        <ExitConfirmation
          onExitApplication={() => {
            if (closeModal) closeModal();
          }}
          onCancel={() => toggleExitConfirmation(false)}
        />
      ) : (
        children
      )}
    </div>
  );
};

export default SpontaneousApplyCoverLetterLayout;
