/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useEffect, useState } from 'react';
import 'twin.macro';
import { FieldValues, FormProvider, UseFormReset, useForm } from 'react-hook-form';
import omitBy from 'lodash.omitby';
import pickBy from 'lodash.pickby';
import tw from 'twin.macro';
import { useRouter } from 'next/router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  useModal,
  formatExplorerOverallFilterData,
  applyMoreFilterAttrs,
  defaultItalyCoordinates,
  // useAnalytics,
} from '@restworld/utils-common';
import { Badge, Button, Title, BaseModal } from '@restworld/ui-ds';
import { JobPositionListParamsType } from '@restworld/data-services';

import { FilterContextValuesType, defaultJobListPayload } from '../job-explorer.types';
import { ExplorerFormContent } from './job-explorere-more-filter.components';
import { isRestaurantsExplorerAtom } from '../job-explorer-map';
import { getMapRefAtom, jobCoordinateParamsAtom } from '../job-explorer-map.atoms';
import { jobListParamsAtom } from '../job-explorer-list.atoms';
import { filterContextValuesAtom, isResetFilterAtom, moreFiltersAtom } from '../job-explorer-filters.atoms';

export const JobExplorerMoreFilter = () => {
  const [onButtonHover, setButtonHover] = useState(false);
  const { t } = useTranslation('common');
  const [isRestaurantsExplorer] = useAtom(isRestaurantsExplorerAtom);
  const { open, close, show } = useModal();
  const moreFilters = useAtomValue(moreFiltersAtom);
  const filterCount = useMemo(() => {
    try {
      return moreFilters
        ? Object.keys(
            omitBy(moreFilters, (value, key) => {
              return (
                value === undefined ||
                (key === 'occupation_ids' &&
                  value &&
                  Object.values(value).flat().filter(Boolean).length === 0 &&
                  key === 'occupation_ids') ||
                value?.length === 0
              );
            })
          ).length
        : 0;
    } catch (error) {
      console.error('error on counting filter', error);
      return 0;
    }
  }, [moreFilters]);
  return (
    <>
      <BaseModal
        open={show}
        onClose={close}
        variant="right"
        modalStyles={[tw`md:(max-w-3xl overflow-y-hidden pb-0)`]}
        title={
          <Title
            fontSize="large"
            fontWeight="semibold"
            content={t('filter')}
            icon="ApplyMoreFiltersIcon"
            isCenter
            wrapperStyle={tw`min-w-[14rem]`}
            testId="job-explorer-more-filter-title"
          />
        }
        noPadding
      >
        <OverallFilters close={close} />
      </BaseModal>
      <div
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
        tw="w-fit pl-0 lg:(pr-1) relative border-primary-800"
      >
        <Button
          onClick={open}
          variant={filterCount && !isRestaurantsExplorer ? 'primary-outline' : 'gray-outline'}
          title={t('filter')}
          icon={
            isRestaurantsExplorer
              ? 'ApplyMoreFilterGrayIcon'
              : onButtonHover && filterCount
              ? 'ApplyMoreFilterWhiteIcon'
              : 'ApplyMoreFilterBlackIcon'
          }
          disabled={isRestaurantsExplorer}
          twStyle={tw`max-h-[40px]`}
          testId={`job-explorer-more-filter-button`}
        />
        {!!filterCount && !isRestaurantsExplorer && (
          <span tw="absolute -top-2 -right-2" data-cy="explorer-filters-filter-count">
            <Badge variant="tertiary">{filterCount}</Badge>
          </span>
        )}
      </div>
    </>
  );
};

export default JobExplorerMoreFilter;
const OverallFilters = ({ close }: { close: () => void }) => {
  const { t } = useTranslation('common');

  const methods = useForm<FilterContextValuesType>();
  const filterContextValues = useAtomValue(filterContextValuesAtom);

  const { handleFilterChange, filterCount } = useJobExplorerMoreFilter({ closeModal: close, reset: methods.reset });

  useEffect(() => {
    methods.reset(filterContextValues || {});
  }, [filterContextValues, methods]);

  return (
    <FormProvider {...methods}>
      <ExplorerFormContent handleFilterChange={handleFilterChange}>
        <div tw="absolute flex flex-row gap-5 border-t border-gray-200 items-center justify-center p-4 bg-white w-full bottom-0 pb-10  lg:bottom-auto">
          <Button title={t('apply_filters')} variant="normal" type="submit" testId="explorer-filters-submit-button" />
          <Button
            title={t('erase_filters')}
            variant="secondary-outline"
            disabled={!filterCount}
            onClick={() => handleFilterChange()}
          />
        </div>
      </ExplorerFormContent>
    </FormProvider>
  );
};
export const useJobExplorerMoreFilter = ({
  closeModal,
  reset,
}: {
  closeModal: () => void;
  reset: UseFormReset<FieldValues>;
}) => {
  const router = useRouter();
  const [moreFilters, setMoreFilters] = useAtom(moreFiltersAtom);
  const [isResetFilter, setIsResetFilter] = useAtom(isResetFilterAtom);
  const setFilterContextValues = useSetAtom(filterContextValuesAtom);
  const [, setJobCoordinateParams] = useAtom(jobCoordinateParamsAtom);
  const jobListParams = useAtomValue(jobListParamsAtom);
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const mapRef = useAtomValue(getMapRefAtom);
  // const { trackEvent } = useAnalytics();
  const filterCount = useMemo(() => (moreFilters ? Object.keys(moreFilters).length : 0), [moreFilters]);
  const shouldRemoveKey = useMemo(() => (value: string, key: string) => applyMoreFilterAttrs.includes(key), []);
  const { processFilters, navigateToQuery } = useProcessFilters();

  const submitFormFilters = useCallback(
    (values: FilterContextValuesType) => {
      try {
        const { query } = processFilters(values, jobListParams);

        setTimeout(() => {
          navigateToQuery(
            query,
            values?.user_location_lon ? { lon: +values.user_location_lon, lat: +values.user_location_lat } : undefined
          );
          closeModal();
        }, 300);
      } catch (error) {
        console.error(`Error on submitting data`, error);
      }
    },
    [closeModal, jobListParams, navigateToQuery, processFilters]
  );

  const resetFormFilters = useCallback(() => {
    try {
      setJobListParams((p) => {
        const listParams = omitBy({ ...p }, shouldRemoveKey);
        const newPayload = {
          ...listParams,
          lon: defaultItalyCoordinates[0],
          lat: defaultItalyCoordinates[1],
          radius: defaultJobListPayload['radius'],
          offset: 0,
        };
        return newPayload;
      });
      setJobCoordinateParams((p) => {
        const listParams = omitBy({ ...p }, shouldRemoveKey);
        const newPayload = { ...listParams };
        return newPayload;
      });
      setFilterContextValues({});
      setMoreFilters({});

      setTimeout(() => {
        mapRef.current?.flyTo({
          center: [defaultItalyCoordinates[0], defaultItalyCoordinates[1]],
          duration: 2000,
          // zoom: 5,
        });
        router.push(
          {
            // pathname: router.query.filter_slug ? `/cerco-lavoro/${router.query.filter_slug}` : '/cerco-lavoro',
            query: omitBy(router.query, (_, key) => key === 'overall_filters'),
          },
          null,
          {
            shallow: true,
          }
        );
        closeModal();
      }, 300);
    } catch (error) {
      console.error(error, 'Error on reset filter fields');
    }
  }, [
    closeModal,
    mapRef,
    router,
    setFilterContextValues,
    setJobCoordinateParams,
    setJobListParams,
    setMoreFilters,
    shouldRemoveKey,
  ]);
  const handleFilterChange = useCallback(
    async (values?: FieldValues) => {
      if (values) submitFormFilters(values);
      else resetFormFilters();
    },
    [resetFormFilters, submitFormFilters]
  );
  useEffect(() => {
    if (isResetFilter) {
      reset({});
      setIsResetFilter(false);
    }
  }, [isResetFilter, reset, setIsResetFilter]);
  return {
    filterCount,
    handleFilterChange,
  };
};

export function useJobExplorerFiltersFromContextValues() {
  const jobListParams = useAtomValue(jobListParamsAtom);
  const { processFilters, navigateToQuery } = useProcessFilters();

  const filterValues = useCallback(
    (values: FilterContextValuesType) => {
      try {
        const { query } = processFilters(values, jobListParams);

        setTimeout(() => {
          navigateToQuery(
            query,
            values?.user_location_lon ? { lon: +values.user_location_lon, lat: +values.user_location_lat } : undefined
          );
        }, 300);
      } catch (error) {
        console.error(`Error on submitting data`, error);
      }
    },
    [jobListParams, processFilters, navigateToQuery]
  );

  return { filterValues };
}

function useProcessFilters() {
  const router = useRouter();
  const mapRef = useAtomValue(getMapRefAtom);
  const setFilterContextValues = useSetAtom(filterContextValuesAtom);
  const setJobCoordinateParams = useSetAtom(jobCoordinateParamsAtom);
  const setJobListParams = useSetAtom(jobListParamsAtom);
  const setMoreFilters = useSetAtom(moreFiltersAtom);

  const processFilters = useCallback(
    (values: FilterContextValuesType, jobListParams: JobPositionListParamsType) => {
      setFilterContextValues({ ...values });

      const formattedFieldValues = formatExplorerOverallFilterData({ values: { ...values } });

      const mapParams = pickBy(jobListParams, (_, key: string) =>
        ['lat', 'lon', 'radius', 'offset', 'limit', 'order_by'].includes(key)
      );

      const listParams = omitBy({ ...mapParams, ...formattedFieldValues }, (v) => typeof v === 'undefined');
      const coordinateParams = omitBy({ ...formattedFieldValues }, (value) => typeof value === 'undefined');

      // coordinates api
      setJobCoordinateParams(coordinateParams);
      //list api
      setJobListParams({ ...listParams, offset: 0 });

      const filterValues = omitBy(
        formattedFieldValues,
        (value, key) =>
          typeof value === 'undefined' ||
          ['min_salary', 'max_salary', 'user_location_lat', 'user_location_lon', 'benefits'].includes(key)
      );

      setMoreFilters(filterValues);

      const query = !Object.keys(filterValues).length
        ? omitBy(router.query, (_, key) => key === 'overall_filters')
        : {
            ...router.query,
            ...(Object.keys(values).length ? { overall_filters: JSON.stringify({ ...values }) } : {}),
          };

      return { query, formattedFieldValues };
    },
    [router, setFilterContextValues, setJobCoordinateParams, setJobListParams, setMoreFilters]
  );

  const navigateToQuery = useCallback(
    (query: Record<string, any>, userLocation?: { lon: number; lat: number }) => {
      if (userLocation?.lon && mapRef?.current) {
        mapRef.current.flyTo({
          center: [userLocation.lon, userLocation.lat],
          duration: 2000,
        });
      }

      router.push(
        {
          query,
        },
        null,
        { shallow: true }
      );
    },
    [router, mapRef]
  );

  return { processFilters, navigateToQuery };
}
