globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
export var borderColorObject = {
  gray: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
    ":focus": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgb(209 213 219 / var(--tw-ring-opacity))"
    },
    ":focus-visible": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(156 163 175 / var(--tw-border-opacity))",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgb(156 163 175 / var(--tw-ring-opacity))"
    }
  },
  primary: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(212 193 245 / var(--tw-border-opacity))",
    ":focus": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(196 177 231 / var(--tw-border-opacity))",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgb(196 177 231 / var(--tw-ring-opacity))"
    },
    ":focus-visible": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(196 177 231 / var(--tw-border-opacity))",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgb(196 177 231 / var(--tw-ring-opacity))"
    }
  },
  'neutral-100': {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(117 117 131 / var(--tw-border-opacity))",
    ":focus": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(117 117 131 / var(--tw-border-opacity))",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgb(117 117 131 / var(--tw-ring-opacity))"
    },
    ":focus-visible": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(117 117 131 / var(--tw-border-opacity))",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgb(117 117 131 / var(--tw-ring-opacity))"
    }
  }
};