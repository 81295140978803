import { useAtomValue } from 'jotai';

import { JobSimilarPositions } from '@restworld/ui-ds';

import { similarJobPositionsAtom } from '../job-position.atoms';

export const SimilarJobs = () => {
  const similarJobPositions = useAtomValue(similarJobPositionsAtom);

  return similarJobPositions?.length ? (
    <>
      <hr tw="text-gray-300" />
      <JobSimilarPositions items={similarJobPositions} />
    </>
  ) : null;
};
