import { useCallback, useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import uniqBy from 'lodash.uniqby';

import { useRestaurantsService } from '@restworld/data-services';

import {
  explorerRestaurantMapLoadingAtom,
  explorerRestaurantListAtom,
  explorerRestaurantHitsAtom,
  explorerRestaurantListPayloadAtom,
  explorerRestaurantMapMarkersAtom,
  restaurantHasLimitReachedAtom,
  explorerRestaurantListLoadingAtom,
} from '../job-explorer-map';
import { defaultJobListPayload } from '../job-explorer.types';

const useRestaurantLocationCoordinates = () => {
  const restaurantService = useRestaurantsService();
  const setExplorerRestaurantsMapMarkers = useSetAtom(explorerRestaurantMapMarkersAtom);
  const setExplorerRestaurantMapLoading = useSetAtom(explorerRestaurantMapLoadingAtom);

  useQuery({
    queryKey: ['fetchRestaurantLocationCoordinates'],
    queryFn: async () => {
      setExplorerRestaurantMapLoading(true);
      const data = (await restaurantService.getRestaurantLocationCoordinates()).data;
      setExplorerRestaurantsMapMarkers(data ?? []);
      setExplorerRestaurantMapLoading(false);
      return data ?? [];
    },
    placeholderData: (previousData) => previousData,
    refetchOnWindowFocus: false,
  });
};
const useFetchExplorerRestaurantList = () => {
  const restaurantService = useRestaurantsService();
  const setExplorerRestaurantsList = useSetAtom(explorerRestaurantListAtom);
  const setExplorerRestaurantHits = useSetAtom(explorerRestaurantHitsAtom);
  const setExplorerRestaurantListLoading = useSetAtom(explorerRestaurantListLoadingAtom);
  const explorerRestaurantListPayload = useAtomValue(explorerRestaurantListPayloadAtom);
  const setRestaurantHasLimitReached = useSetAtom(restaurantHasLimitReachedAtom);

  const callRestaurantListApi = useCallback(
    async (axiosConfig?: AxiosRequestConfig) => {
      setExplorerRestaurantListLoading(true);
      const data = (
        await restaurantService.fetchExploreRestaurantsList(
          {
            ...(explorerRestaurantListPayload ?? defaultJobListPayload),
          },
          { ...axiosConfig }
        )
      ).data?.data?.explore_restaurants;
      setExplorerRestaurantListLoading(false);
      setExplorerRestaurantsList((prev) => {
        if (explorerRestaurantListPayload.offset > 0)
          return uniqBy([...prev, ...(data?.data.map((v) => v.restaurant) ?? [])], 'id');
        return [...(data?.data.map((v) => v.restaurant) ?? [])];
      });
      setRestaurantHasLimitReached(data?.data.length === 0);
      setExplorerRestaurantHits(data?.hits ?? 0);
      return data ?? [];
    },
    [
      explorerRestaurantListPayload,
      restaurantService,
      setExplorerRestaurantHits,
      setExplorerRestaurantListLoading,
      setExplorerRestaurantsList,
      setRestaurantHasLimitReached,
    ]
  );

  return {
    callRestaurantListApi,
  };
};

const useRQueryFetchExplorerRestaurantList = () => {
  const { callRestaurantListApi } = useFetchExplorerRestaurantList();
  const queryKey = useExplorerRestaurantQueryKey();
  useQuery({
    queryKey,
    queryFn: ({ signal }) => callRestaurantListApi({ signal }),
    placeholderData: (previousData) => previousData,
  });
};

export const useExplorerRestaurantQueryKey = () => {
  const explorerRestaurantListPayload = useAtomValue(explorerRestaurantListPayloadAtom);
  return useMemo(() => [`fetchExplorerRestaurantList`, explorerRestaurantListPayload], [explorerRestaurantListPayload]);
};

export { useRestaurantLocationCoordinates, useFetchExplorerRestaurantList, useRQueryFetchExplorerRestaurantList };
