export const jobTypeItems = [
  {
    key: 'partTime',
    label: 'Part time',
    id: 'part_time',
  },
  {
    key: 'fullTime',
    label: 'Full time',
    id: 'full_time',
  },
];
export const radiusItems = [
  {
    key: '5000',
    id: '5000',
    label: '5 km',
  },
  {
    key: '10000',
    id: '10000',
    label: '10 km',
  },
  {
    key: '15000',
    id: '15000',
    label: '15 km',
  },
];
