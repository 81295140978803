globalThis.jotaiAtomCache = globalThis.jotaiAtomCache || {
  cache: new Map(),
  get: function get(name, inst) {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }

    this.cache.set(name, inst);
    return inst;
  }
};
import { config, useSpring } from '@react-spring/web';
import { useResponsiveBreakPoints } from '@restworld/utils-common';
import { useEffect, useState } from 'react';
export function useMobileNavbarCrossingAnimation() {
  var _useState = useState(0),
      scrollY = _useState[0],
      setScrollY = _useState[1];

  var _useResponsiveBreakPo = useResponsiveBreakPoints(),
      isDesktop = _useResponsiveBreakPo.isDesktop;

  var scrollMark = 10;
  var navbarAnimation = useSpring({
    config: config.wobbly
  });
  useEffect(function () {
    var handleScroll = function handleScroll() {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return {
    navbarAnimation: navbarAnimation,
    hasCrossedNavbar: scrollY > scrollMark && !isDesktop
  };
}