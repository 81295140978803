import { useEffect } from 'react';
import Axios from 'axios';
import { MapRef } from 'react-map-gl';
import { useAtomValue, useSetAtom } from 'jotai';

import { Coordinate } from '../job-explorer.types';
import { getUserApproxCoordinatesAtom, setUserApproxCoordinatesAtom } from '../job-explorer-map.atoms';
import { searchBarUrlParamsAtom } from '../job-explorer-filters.atoms';

const APPROX_COORDINATES_API = `http://ip-api.com/json/?fields=lat,lon`;

export const useUserCoordinates = ({ map }: { map: MapRef }) => {
  const { locationUrlParams } = useAtomValue(searchBarUrlParamsAtom);
  const userApproxCoordinates = useAtomValue(getUserApproxCoordinatesAtom);
  const setUserApproxCoordinates = useSetAtom(setUserApproxCoordinatesAtom);
  useEffect(() => {
    const getCoordinates = async () => {
      try {
        const resp = await Axios.get<Coordinate>(APPROX_COORDINATES_API);
        setUserApproxCoordinates(resp.data);
        if (!locationUrlParams) return;
        if (resp.data)
          map?.flyTo({
            center: [resp.data?.['lon'], resp.data?.['lat']],
            duration: 2000,
            zoom: 5,
          });
        return resp?.data;
      } catch (error) {
        return null;
      }
    };
    if (!userApproxCoordinates && map && !locationUrlParams) getCoordinates();
  }, [locationUrlParams, map, setUserApproxCoordinates, userApproxCoordinates]);
};
