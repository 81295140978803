import dynamic from 'next/dynamic';

const WorkerEntryPointButton = dynamic(
  () => import('../worker-entry-point').then((mod) => mod.WorkerEntryPointButton),
  {
    ssr: false,
  }
);

export const JobApplicationEntryPoint = ({ testId }: { testId?: string }) => (
  <WorkerEntryPointButton fullwidth testId={testId} />
);
