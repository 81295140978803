import { JobPosition } from '@restworld/utility-types';

export const getSalary = ({ jobPositionApiData }: { jobPositionApiData: Partial<JobPosition> }) => {
  const jpSalary = jobPositionApiData?.salary;
  const min_salary = jobPositionApiData?.min_salary;
  const max_salary = jobPositionApiData?.max_salary;
  const salary_type = jobPositionApiData?.salary_type;
  const salary_month_number = jobPositionApiData?.salary_month_number;
  const payment_type_var = jobPositionApiData?.payment_type_var;

  const restaurant_id = jobPositionApiData?.restaurant_id;

  let currency = '€';
  // Hard code currency for special cases
  if (restaurant_id === '2eB0y8cB14q3SibMtIpT0n09s9Y') currency = 'CHF ';

  let salaryString = '';

  const isMinSalaryOnlyPresent = min_salary && !max_salary;
  const isMaxSalaryOnlyPresent = max_salary && !min_salary;

  if (isMinSalaryOnlyPresent) salaryString = `Partirà da ${min_salary}${currency}`;
  else if (isMaxSalaryOnlyPresent) salaryString = `Fino a ${max_salary}${currency}`;
  else {
    const salaryAmt = min_salary && max_salary ? `${min_salary} - ${max_salary}` : jpSalary;
    salaryString = `${currency}${salaryAmt || ' -'}`;
  }

  // Append salary type
  const salaryLabel = salary_type === 'gross' ? 'lordi' : 'netti';
  salaryString = salaryString.concat(` ${salaryLabel}`);

  // Append payment type
  if (payment_type_var && !salary_month_number) {
    let paymentTypeLabel = '';
    switch (payment_type_var.key) {
      case 'hour':
        paymentTypeLabel = "all'ora";
        break;
      case 'service':
        paymentTypeLabel = 'a servizio';
        break;
      default:
        paymentTypeLabel = 'mensili';
    }
    salaryString = salaryString.concat(` ${paymentTypeLabel}`);
  }
  return salaryString;
};
