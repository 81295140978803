import { useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import 'twin.macro';
import { useQuery } from '@tanstack/react-query';

import { useWorkerJobPositionsService } from '@restworld/data-services';
import { useAuth } from '@restworld/feature-authentication';
import { QUERY_KEYS } from '@restworld/utils-common';

export const hasUserAppliedSpontaneousApplicationAtom = atom<boolean>(false);
export const setHasUserAppliedSpontaneousApplicationAtom = atom(null, (_get, set, value: boolean) =>
  set(hasUserAppliedSpontaneousApplicationAtom, value)
);

export type RestaurantPopupInfo = {
  restaurantId?: string;
  restaurantEmployerName?: string;
  restaurantOrganizationName?: string;
  restaurantName?: string;
  restaurantAddress?: string;
};
const restaurantPopupInfoAtom = atom<RestaurantPopupInfo>({});
restaurantPopupInfoAtom.debugLabel = 'restaurantPopupInfoAtom';
export const setRestaurantPopupInfoAtom = atom(null, (_get, set, value: RestaurantPopupInfo) =>
  set(restaurantPopupInfoAtom, value)
);
export const getRestaurantPopupInfoAtom = atom((get) => get(restaurantPopupInfoAtom));

export const useShowSpontaneousApplication = ({
  restaurantID,
  isSpontaneousEntry,
}: {
  restaurantID?: string;
  isSpontaneousEntry: boolean;
}) => {
  const { user } = useAuth();
  const workerJobPositionsService = useWorkerJobPositionsService();
  const [, setHasUserAppliedSpontaneousApplication] = useAtom(setHasUserAppliedSpontaneousApplicationAtom);

  const {
    data: jobApplication,
    isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [QUERY_KEYS.WORKER_JOB_POSITIONS.SPONTANEOUS_APPLICATION, restaurantID, user?.id],
    queryFn: () =>
      workerJobPositionsService.showSpontaneousApplication({
        restaurant_id: restaurantID,
        worker_id: user?.id,
      }),
    select: (data) => data.data,
    retry: false,
    enabled: isSpontaneousEntry && !!restaurantID && !!user?.id,
  });

  useEffect(() => {
    if (isSuccess) setHasUserAppliedSpontaneousApplication(true);
    if (isError) setHasUserAppliedSpontaneousApplication(false);
  }, [isError, isSuccess, setHasUserAppliedSpontaneousApplication]);

  return { showSpontaneousApplicationLoading: isLoading, jobApplication };
};
