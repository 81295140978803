import { atom, useAtom, useAtomValue } from 'jotai';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import tw from 'twin.macro';
import { useTranslation } from 'next-i18next';
import { LockClosedIcon } from '@heroicons/react/24/outline';

import { openInNewTab } from '@restworld/utils-common';
import { Button, FormLabelWrapper, PhoneInput, TextInput, Title } from '@restworld/ui-ds';

import { existingPhoneNumber as existingPhoneNumberAtom } from '../../../context/auth-flow';
import { missingFieldFormLoadingAtom } from './cv-errors-field-reinput';
import { InformationHeadLine } from './error-info-headline';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPhoneNumberConflictProps {
  formInputs: React.ReactNode[];
}
const setUpdateNumberFlagAtom = atom<boolean>(false);
const PhoneNumberConflict: React.FunctionComponent<IPhoneNumberConflictProps> = ({ formInputs }) => {
  const { t } = useTranslation();
  const [updateNumberFlag, setUpdateNumberFlag] = useAtom(setUpdateNumberFlagAtom);
  const existingPhoneNumber = useAtomValue(existingPhoneNumberAtom);

  return updateNumberFlag ? (
    <UpdatePhoneNumber formInputs={formInputs} />
  ) : (
    <div tw="flex flex-col gap-4">
      <Title
        fontSize="large"
        fontWeight="semibold"
        content={`${t('onboarding-page:conflicting_phone_number_how_would_you_like_proceed')}`}
      />
      <FormLabelWrapper label="Phone Number" id="readOnly-email">
        <TextInput
          type="email"
          id="readOnly-email"
          placeholder={existingPhoneNumber.phoneNumber ?? '+39 XX XXXXXX'}
          rightIcon={LockClosedIcon}
          required
          readOnly
          hasLeftPadding={false}
        />
      </FormLabelWrapper>
      <Button
        onClick={() =>
          openInNewTab(
            `/login${existingPhoneNumber?.phoneNumber ? `?phone_number=${existingPhoneNumber?.phoneNumber}` : ''}`
          )
        }
        title={t('onboarding-page:login_with_existing_phone_number')}
        variant="normal"
        type="button"
        fullwidth
      />
      <Button
        variant="secondary-outline"
        title={t('onboarding-page:update_phone_number_and_create_a_new_profile')}
        fullwidth
        onClick={() => setUpdateNumberFlag(true)}
      />
    </div>
  );
};
const UpdatePhoneNumber = ({ formInputs }: IPhoneNumberConflictProps) => {
  const loading = useAtomValue(missingFieldFormLoadingAtom);
  const [, setUpdateNumberFlag] = useAtom(setUpdateNumberFlagAtom);
  const { t } = useTranslation();
  const {
    formState: { errors: formErrors },
    control,
  } = useFormContext();
  return (
    <div tw="flex flex-col gap-6">
      <div tw="flex flex-col gap-4">
        <InformationHeadLine
          title={t('onboarding-page:update_the_phone_number')}
          showBackButton
          backButtonClickHandler={() => setUpdateNumberFlag(false)}
        />
      </div>
      {/* <Title fontSize="large" fontWeight="semibold" content={`${t('onboarding-page:update_the_phone_number')}`} /> */}
      {!formInputs && (
        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <PhoneInput
              variant={formErrors?.phone_number ? 'error' : 'primary'}
              priorityCountries={['IT', 'FR', 'ES']}
              menuDialogStyles={[tw`min-w-full`]}
              {...field}
            />
          )}
        />
      )}
      {formInputs}
      <Button loading={loading} variant="normal" title="Update" fullwidth type="submit" />
    </div>
  );
};
export default PhoneNumberConflict;
